import DataService from '@ava/react-common/services/data-service'
import { updateSiteTimezone } from '@ava/react-common/store/actions/site-actions'
import { setTimeZone } from '@ava/react-common/store/actions/timezone-actions'
import { selectSelectedSite } from '@ava/react-common/store/reducers/root-reducer'
import { dateToTimeString, modifyDateToTimezone } from '@ava/react-common/utils/Helper'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Clock from '../../../assets/clock.svg'
import Dropdown from '../../Dropdown/dropdown'
import Modal from '../../Modal/modal'
import ReactSelect from '../../ReactSelect/react-select'
import './time-dropdown.scss'


const TimeDropdown = () => {
   const [time, setTime] = useState('')
   const [isModalVisible, showModal] = useState(false)
   const timeZone = useSelector((state) => state.timeZone)
   const site = useSelector((state) => selectSelectedSite(state))
   const user = useSelector((state) => state.user)
   const dispatch = useDispatch()

   useEffect(() => {
      const updateTime = () => {
         const timeString = dateToTimeString(new Date(), timeZone, false)
         setTime(timeString)
      }
      const timer = setInterval(updateTime, 2000)
      updateTime()

      return () => clearInterval(timer)
   }, [timeZone])


   const menuItems = site ? [
      { label: 'Local time', value: 'local' },
      { label: 'Site time', value: 'site', disabled: !site.timeZone },
      { label: 'UTC', value: 'utc' },
      { label: (site.timeZone ? 'Edit site timezone' : 'Set site timezone'), value: 'edit', disabled: !!((!user || !user.hasEngineerPriviledges())) },
   ] : []
   return (
      <>
         <Dropdown
            id="time-dropdown"
            onSelect={(val) => {
               if (val === 'local') {
                  dispatch(setTimeZone(null))
               } else if (val === 'site') {
                  let timezone = site.timeZone
                  // Detect if site time === local time. If is, use only local time.
                  if (timezone) {
                     const localDate = new Date()
                     const timeZoneDate = modifyDateToTimezone(localDate, timezone)
                     if (localDate.getTime() === timeZoneDate.getTime()) timezone = null
                  }
                  dispatch(setTimeZone(timezone))
               } else if (val === 'utc') {
                  dispatch(setTimeZone('UTC'))
               } else if (val === 'edit') {
                  showModal(true)
               }
            }}
            button={
               <button className="clock-button">
                  <div className="inner-row">
                     <img src={Clock} alt="clock" />
                     <p>{time}</p>
                  </div>
                  <p className="subtitle">{
                     (timeZone && (timeZone === 'UTC' ? 'UTC' : 'Site time'))
                     || 'Local time'
                  }</p>
               </button>
            }
            menuItems={menuItems}
         />
         {isModalVisible
         && <TimezoneModal
            onClose={() => showModal(false)}
         />
         }
      </>
   )
}

export default TimeDropdown


const TimezoneModal = ({ onClose }) => {
   const timeZone = useSelector((state) => selectSelectedSite(state) && selectSelectedSite(state).timeZone) // Timezone in use (stored in redux)
   const siteId = useSelector((state) => state.selectedSite)
   const [timezone, setTimezone] = useState(null) // Selected timezone (dropdown)
   const [isLoading, setLoading] = useState(false)
   const [error, setError] = useState(null)
   const dispatch = useDispatch()

   const tzDatabaseList = useMemo(() => {
      const timezoneList = Intl.supportedValuesOf('timeZone')
      return timezoneList.map((tz) => ({ value: tz, label: tz }))
   }, [])

   useEffect(() => {
      if (timeZone) {
         const timezoneList = Intl.supportedValuesOf('timeZone')
         if (timezoneList.includes(timeZone)) {
            setTimezone({ value: timeZone, label: timeZone })
         }
      }
   }, [timeZone])


   return (
      <Modal
         id="timezoneModal"
         title="Set site timezone"
         isPrimaryBtnDisabled={(!timezone)}
         primaryBtnTitle="Save"
         primaryBtnPressed={() => {
            if (!timezone || !timezone.value) return
            const zone = timezone.value
            setError(null)
            setLoading(true)
            DataService.editSiteTimezone(siteId, zone)
               .then(() => {
                  dispatch(setTimeZone(zone))
                  dispatch(updateSiteTimezone(siteId, zone))
                  onClose()
               })
               .catch((err) => {
                  setLoading(false)
                  setError(err)
               })
         }}
         width={'460px'}
         height={'530px'}
         onClose={onClose}
         secondaryBtnPressed={onClose}
         secondaryBtnTitle="Cancel"
         error={error}
         disabled={isLoading}
      >
         <div style={{ marginBottom: '16px' }}>
            <p style={{ marginBottom: '4px' }}>TZ Database name</p>
            <ReactSelect
               options={tzDatabaseList}
               value={timezone}
               onChange={(tz) => setTimezone(tz)}
            />
            <div style={{ height: '20px' }} />
            <a style={{ color: '#0075BE' }} href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones" target="_blank"
               rel="noopener noreferrer">List of tz database time zones</a>
         </div>
      </Modal>
   )
}
