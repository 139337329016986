import UserService from '@ava/react-common/services/user-service'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { errorToMessage } from '@ava/react-common/utils'
import { cloneDeep } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Column, Table } from 'react-virtualized'
import Button from '../Button/button'
import Dialog from '../Dialog/dialog'
import Dropdown from '../Dropdown/dropdown'
import Input from '../Input/input'
import Modal from '../Modal/modal'
import SecondaryNavbar from '../SecondaryNavbar/secondary-navbar'
import './page-user-managements.css'
// import Draggable from "react-draggable"
import UserManagementModal from './UserManagementModal/user-management-modal'



class PageUserManagement extends Component {

   constructor(props) {
      super(props)

      this.SortDirection = {
         ASC: 'ASC',
         DESC: 'DESC',
      }

      this.state = {
         viewWidth: 0, // TODO: MOVE TO REDUX
         viewHeight: 0,

         editUser: false,
         createUser: false,
         changePassword: false,
         deleteUser: false,

         users: /** @type {import('@ava/react-common/models').User[]} */([]),
         selectedUser: /** @type {import('@ava/react-common/models').User} */(null),
         isLoading: false,
         widths: {},

         sortBy: 'firstName',
         sortedList: [],
         sortDirection: this.SortDirection.DESC,

         error: '',
         newPassword: '',
         repeatedNewPassword: '',
         isChangingPassword: false,

         actionOptions: [
            { value: 'edit', label: 'Edit' },
            { value: 'change-password', label: 'Change password', hidden: false },
            { value: 'delete', label: 'Delete', type: 'danger', hidden: false },
         ],
      }

   }

   // eslint-disable-next-line react/no-deprecated
   componentWillMount() { // TODO: Fix deprecated componentWillMount
      const { sortBy, sortDirection } = this.state
      UserService.getUsers()
         .then((users) => {
            this.setState({ users }, () => {
               this.sortList({ sortBy, sortDirection })
            })
         })
         .catch((err) => {
            this.props.showDialog({
               title: 'Failed to get users',
               text: errorToMessage(err),
            })
         })
   }

   componentDidUpdate(prevProps) {
      const { sortBy, sortDirection } = this.state
      if (prevProps.sites !== this.props.sites) {
         this.sortList({ sortBy, sortDirection })
      }

      if (this.props.dimensions !== prevProps.dimensions) {
         this.updateWindowDimensions()
      }
   }

   componentDidMount() {
      this.setupView()
      this.updateWindowDimensions()
   }

   updateWindowDimensions = () => {
      const width = window.innerWidth
      const height = window.innerHeight - 64//  height - navbar
      this.setState({ viewWidth: width, viewHeight: height })
   }


   setupView = () => {
      const sortBy = 'firstName'
      const sortDirection = this.SortDirection.DESC
      const widths = {
         firstName: 0.15,
         lastName: 0.15,
         username: 0.15,
         role: 0.15,
         strategies: 0.15,
         sites: 0.15,
      }

      this.setState({
         sortBy,
         sortDirection,
         widths,
      }, this.sortList({ sortBy, sortDirection }))
   }



   // This function sorts the list and modifies it for the table
   sortList = ({ sortBy, sortDirection }) => {
      const { users } = this.state
      const { user, sites } = this.props
      const filteredUsers = users.filter((u) => user.id !== u.id) // filter self out

      let rows = []

      // Set data to correct format
      rows = filteredUsers.map((user) => {

         // Add site name
         let sitesString = '-'
         if (user.rules.sites) {
            if (sites) {
               user.rules.sites.forEach((siteId, index) => {
                  const site = sites[siteId]
                  if (site && index === 0) sitesString = site.name
                  else if (site) sitesString += `, ${site.name}`
               })
            } else {
               sitesString = ''
            }
         } else {
            sitesString = '(All)'
         }
         // Admins have always access to all sites
         if (user.accessLevel === 'admin') sitesString = '(All)'

         return ({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            role: user.accessLevel,
            strategies: user.strategies.join(', '),
            sites: sitesString,
         })
      })

      // Sort by strings
      let sortedRows = [rows]
      sortedRows = rows.sort((a, b) => {

         let valA
         let valB

         if (sortBy === 'firstName') {
            valA = a.firstName
            valB = b.firstName
         } else if (sortBy === 'lastName') {
            valA = a.lastName
            valB = b.lastName
         } else if (sortBy === 'username') {
            valA = a.username
            valB = b.username
         } else if (sortBy === 'role') {
            valA = a.role
            valB = b.role
         } else if (sortBy === 'strategies') {
            valA = a.strategies
            valB = b.strategies
         }

         const strA = valA ? valA.toUpperCase() : ''
         const strB = valB ? valB.toUpperCase() : ''

         if (strA < strB) {
            if (sortDirection === 'ASC') return 1
            return -1
         } if (strA > strB) {
            if (sortDirection === 'ASC') return -1
            return 1
         }
         return 0
      })
      this.setState({ sortedList: sortedRows, sortBy, sortDirection })
   }


   // headerRenderer = ({
   //    // columnData,
   //    dataKey,
   //    disableSort,
   //    label,
   //    sortBy,
   //    sortDirection
   // }) => (
   //    <React.Fragment key={dataKey}>
   //       <div className="ReactVirtualized__Table__headerTruncatedText">
   //          {label}
   //       </div>
   //       <div>
   //          {(!disableSort && sortBy === dataKey) &&
   //             this.SortIndicator(sortDirection)
   //          }
   //       </div>
   //       <Draggable
   //          axis="x"
   //          defaultClassName="DragHandle"
   //          defaultClassNameDragging="DragHandleActive"
   //          onDrag={(event, { deltaX }) =>
   //             this.resizeRow({
   //             dataKey,
   //             deltaX
   //             })
   //          }
   //          position={{ x: 0 }}
   //          zIndex={999}
   //       >
   //          <span className="DragHandleIcon" onClick={(event) => event.stopPropagation()}>⋮</span>
   //       </Draggable>
   //    </React.Fragment>
   // )


   rowClassName = ({ index }) => {

      const { sortedList, selectedUser } = this.state

      if (index < 0) {
         return 'headerRow'
      }
      const rowItem = sortedList[index]
      const selected = selectedUser?.id === rowItem.id

      let className = index % 2 === 0 ? 'evenRow' : 'oddRow'
      if (selected) className += ' selectedRow' // Hightlight selected rows
      if (rowItem.error) className += ' errorRow' // Red bg to error rows
      if (rowItem.warning) className += ' warningRow' // Yellow bg to warning rows

      return className

   }


   changeSelection = (id) => {
      const { users, actionOptions, selectedUser } = this.state
      if (selectedUser?.id === id) {
         this.setState({ selectedUser: undefined })
      } else {
         const newSelectedUser = id ? users.find((u) => u.id === id) : undefined
         const newActionOptions = cloneDeep(actionOptions)

         // Show change password option only for local users
         newActionOptions.find((ao) => ao.value === 'change-password').hidden = !newSelectedUser.strategies.includes('local')

         this.setState({
            selectedUser: newSelectedUser,
            actionOptions: newActionOptions,
         })
      }
   }


   render() {

      const { sortBy, sortDirection, sortedList, widths, viewWidth, viewHeight, selectedUser, editUser, createUser, changePassword, deleteUser, newPassword, repeatedNewPassword, isChangingPassword, error } = this.state

      return (
         <div className="page-user-management" style={{ maxHeight: '100vh', overflowY: 'hidden' }}>
            <SecondaryNavbar
               title="User Management"
            >
               <Button
                  title="Add new"
                  styleType={Button.StyleType.PRIMARY}
                  onClick={() => this.setState({ createUser: true })}
                  style={{ marginRight: '12px' }}
               />
               <Dropdown
                  button={
                     <Button
                        title="Actions"
                        disabled={!selectedUser}
                     />
                  }
                  menuItems={this.state.actionOptions}
                  onSelect={(value) => {
                     if (value === 'edit') this.setState({ editUser: true })
                     else if (value === 'change-password') this.setState({ changePassword: true })
                     else if (value === 'delete') this.setState({ deleteUser: true })
                  }}
               />
            </SecondaryNavbar>

            <div className="page-container">

               <Table
                  width={viewWidth}
                  height={viewHeight}
                  headerHeight={26}
                  rowHeight={30}
                  rowClassName={this.rowClassName}
                  headerClassName={'bottom-table-header'}
                  rowCount={sortedList.length}
                  rowGetter={({ index }) => sortedList[index]}
                  onRowClick={(item) => this.changeSelection(item.rowData.id)}
                  /* noRowsRenderer={this._noRowsRenderer}
                  overscanRowCount={overscanRowCount}
                  */
                  sort={this.sortList}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
               >
                  <Column
                     disableSort={false}
                     // headerRenderer={this.headerRenderer}
                     dataKey="firstName"
                     label="First Name"
                     width={widths.firstName * viewWidth}
                  />
                  <Column
                     disableSort={false}
                     // headerRenderer={this.headerRenderer}
                     dataKey="lastName"
                     label="Last Name"
                     width={widths.lastName * viewWidth}
                  />
                  <Column
                     disableSort={false}
                     // headerRenderer={this.headerRenderer}
                     dataKey="username"
                     label="Username"
                     width={widths.username * viewWidth}
                  />
                  <Column
                     disableSort={false}
                     // headerRenderer={this.headerRenderer}
                     dataKey="role"
                     label="Role"
                     width={widths.role * viewWidth}
                  />
                  <Column
                     disableSort={false}
                     // headerRenderer={this.headerRenderer}
                     dataKey="strategies"
                     label="Strategies"
                     width={widths.strategies * viewWidth}
                  />
                  <Column
                     disableSort={true}
                     // headerRenderer={this.headerRenderer}
                     dataKey="sites"
                     label="Sites"
                     width={widths.sites * viewWidth}
                  />
               </Table>

            </div>


            {/* Create User */}
            {(createUser || editUser)
                  && <UserManagementModal
                     user={editUser ? selectedUser : undefined}
                     onClose={() => this.setState({ createUser: false, editUser: false })}
                     onUserCreated={(user) => {
                        this.setState((prevState) => ({
                           users: [user, ...prevState.users],
                        }), () => {
                           this.sortList({ sortBy, sortDirection })
                        })
                     }}
                     onUserEdited={(user) => {
                        this.setState((prevState) => {
                           // Replace previous user object with new
                           const filtered = prevState.users.filter((u) => u.id !== user.id)
                           return { users: [user, ...filtered], editUser: false }
                        }, () => {
                           this.sortList({ sortBy, sortDirection })
                        })
                     }}
                  />
            }

            {/* Change Password */}
            {(changePassword && selectedUser)
                  && <Modal
                     title="Change password"
                     isPrimaryBtnDisabled={isChangingPassword}
                     primaryBtnTitle="Change"
                     primaryBtnPressed={() => {

                        // Validate
                        if ((!newPassword.trim() || newPassword.trim() === '') || (!repeatedNewPassword.trim() || repeatedNewPassword.trim() === '')) {
                           return this.setState({ error: 'All fields are required' })
                        }
                        if (newPassword !== repeatedNewPassword) {
                           return this.setState({ error: 'Passwords do not match' })
                        }
                        if (newPassword.length < 8) {
                           return this.setState({ error: 'Passwords needs to be at least 8 characters' })
                        }
                        this.setState({ isChangingPassword: true, error: '' })
                        UserService
                           .changePassword(selectedUser.id, newPassword)
                           .then(() => {
                              this.setState({ isChangingPassword: false, changePassword: false, newPassword: '', repeatedNewPassword: '', error: '' })
                           })
                           .catch((err) => {
                              this.setState({ isChangingPassword: false, error: "Failed to change user's password" })
                              console.error("Failed to change user's password", err)
                           })
                     }}
                     secondaryBtnTitle="Close"
                     onClose={() => this.setState({ changePassword: false, newPassword: '', repeatedNewPassword: '', error: '' })}
                     error={error}
                  >
                     <div>
                        <Input
                           type="password"
                           placeholder="New password"
                           value={newPassword}
                           onChange={(value) => this.setState({ newPassword: value })}
                           style={{ marginBottom: 12 }}
                        />
                        <Input
                           type="password"
                           placeholder="Repeat new password"
                           value={repeatedNewPassword}
                           onChange={(value) => this.setState({ repeatedNewPassword: value })}
                           style={{ marginBottom: 12 }}
                        />
                     </div>
                  </Modal>
            }

            {/* Delete User */}
            <Dialog
               visible={!!(deleteUser && selectedUser)}
               title={`Delete ${selectedUser?.firstName} ${selectedUser?.lastName}?`}
               message="Are you sure? This cannot be undone."
               buttons={[{
                  text: 'DELETE',
                  style: 'danger',
                  onPress: () => {
                     UserService.removeUser(selectedUser.id)
                        .then(() => {
                           this.setState((prevState) => ({
                              users: prevState.users.filter((user) => user.id !== prevState.selectedUser.id),
                              deleteUser: false,
                           }), () => {
                              this.sortList({ sortBy, sortDirection })
                           })
                        })
                        .catch((err) => {
                           this.props.showDialog({
                              title: 'Failed to remove user',
                              text: errorToMessage(err),
                           })
                        })
                  },
               }, {
                  text: 'CANCEL',
               }]}
               onClose={() => this.setState({ deleteUser: false })}
            />

         </div>
      )
   }

}

const mapStateToProps = (state) => ({
   user: state.user,
   sites: state.sites,
   dimensions: state.device.dimensions,
})

export default connect(mapStateToProps, { showDialog })(PageUserManagement)
