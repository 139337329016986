// import { generateId } from '../../utils/Helper'
import { CLOSE_DIALOG, SHOW_DIALOG } from './types'

/**
 * @param {import('../reducers/dialog-reducer').Dialog} [props]
 * @returns {import('../reducers/root-reducer').ReduxThunkAction<string>} Returns dialogId
 */
export function showDialog(props) {
   const { id, title, text, buttons, cancelable } = props

   return (dispatch) => {
      const dialogId = id// || generateId()

      const buttonsWithCloseAction = (buttons || [{ text: 'OK' }])
         .map((btn) => ({
            ...btn,
            onPress: () => {
               btn.onPress?.()
               dispatch(closeDialog(dialogId))
            },
         }))

      dispatch({
         type: SHOW_DIALOG,
         payload: {
            id: dialogId,
            title,
            text,
            buttons: buttonsWithCloseAction,
            cancelable,
         },
      })

      return dialogId
   }
}


/**
 * @param {string} [id] Dialog id for closing dialog only if its' id matches the id
 * @returns {import('../reducers/root-reducer').ReduxAction<{ id: string }>}
 */
export function closeDialog(id) {
   return {
      type: CLOSE_DIALOG,
      payload: { id },
   }
}
