import { selectSelectedSite } from '@ava/react-common/store/reducers/root-reducer'
import React from 'react'
import { useSelector } from 'react-redux'
import Modal from '../Modal/modal'

/**
 * @param {object} props
 * @param {() => void} props.onClose
 * @returns {import('react').FunctionComponentElement}
 */
export default function SupportModal({ onClose }) {
   const site = useSelector((state) => selectSelectedSite(state))
   const user = useSelector((state) => state.user)
   const supportRequestString = `mailto:support.ava@andritz.com?subject=AVA%20Support%20Request&body=%0D%0A%0D%0A%0D%0A%0D%0ASite:%20${site.name}%0D%0ARoute:%20${window.location.href}%0D%0AUsername:%20${user.username}%0D%0AUser%20role:%20${user.accessLevel}`

   return (
      <Modal
         title="Support"
         primaryBtnTitle="OK"
         primaryBtnPressed={onClose}
         onClose={onClose}
         width="560px"
         height="280px"
      >
         <p>Providing support is our first priority. Please contact us for all support requests. We are happy to help!</p>
         <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ margin: 0, marginRight: 6, fontWeight: 500 }}>Contact us: </p>
            <a href={supportRequestString}>support.ava@andritz.com</a>

         </div>

      </Modal>
   )
}
