import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Logo from '../../assets/andritzLogo.svg'
import './footer.css'

class Footer extends Component {

   static propTypes = {
      styleType: PropTypes.string.isRequired, /* small or default*/
   }


   render() {
      const { styleType, isMobile } = this.props

      // TODO: Add mobile version
      if (isMobile) return null

      switch (styleType) {
         case 'small':
            return (
               <footer className="small">
                  <p>© ANDRITZ {new Date().getFullYear()}</p>
               </footer>
            )
         case 'default':
            return (
               <footer className="default">
                  <p>© ANDRITZ {new Date().getFullYear()}</p>
                  <div className="logo-container">
                     <div className="left-side-angle" />
                     <div className="iiot-row">
                        <p>Digital IIoT Solutions</p>
                        <img src={Logo} alt="" />
                     </div>
                  </div>
               </footer>
            )
         default:

      }
   }

}

const mapStateToProps = (state) => ({
   isMobile: state.device.isMobile,
})

export default connect(mapStateToProps)(Footer)
