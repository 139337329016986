import DataService from '@ava/react-common/services/data-service'
import { selectToolsUnits } from '@ava/react-common/store/reducers/root-reducer'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../Button/button'
import Input from '../../Input/input'
import SettingsTopMenu from '../../SettingsTopMenu/settings-top-menu'
import Toggle from '../../Toggle/toggle'
import './image-settings.scss'

/**
 * @returns {import('react').FunctionComponentElement}
 */
export default function ImageSettings() {
   const units = useSelector((state) => selectToolsUnits(state))
   const [selectedUnit, selectUnit] = useState(undefined)
   const [isEditing, setEditing] = useState(false)
   const user = useSelector((state) => state.user)
   const isEditingEnabled = user.hasEngineerPriviledges()

   useEffect(() => {
      if (units && units.length > 0 && !selectedUnit) {
         selectUnit(units[0].id)
      }
   }, [selectedUnit, units])

   const topMenuOptions = units.map((unit) => ({
      title: unit.name,
      value: unit.id,
      selected: (unit.id === selectedUnit),
   }))

   if (!units || units.length === 0) {
      return (
         <div style={{ padding: '24px' }}>
            <h4>No units exist</h4>
         </div>
      )
   }

   const unit = units.find((u) => u.id === selectedUnit)

   return (
      <div className="image-settings">
         <SettingsTopMenu
            actionOptions={[
               { value: 'edit', label: 'Edit' },
            ]}
            onActionSelected={(action) => {
               if (action === 'edit') {
                  setEditing(true)
               }
            }}
            topMenuOptions={topMenuOptions}
            onTopMenuSelect={(value) => selectUnit(value)}
            topMenuDisabled={isEditing}
            primaryBtnDisabled={!isEditingEnabled}
            actionsDisabled={isEditing || !isEditingEnabled}
         />
         { unit
            && <ImageSettingsForUnit
               key={unit.id}
               unit={unit}
               isEditing={isEditing}
               setEditing={setEditing}
            />
         }
      </div>
   )
}


const ImageSettingsForUnit = ({ unit, isEditing, setEditing }) => {
   const siteId = useSelector((state) => state.selectedSite)
   const tool = useSelector((state) => state.selectedTool)

   const [isLoading, setLoading] = useState(false)
   const [errorMessage, setErrorMessage] = useState(null)
   const [imagesConfig, setImagesConfig] = useState(null)

   // Inputs
   const [fullOriginal, setFullOriginal] = useState('')
   const [fullAnalyzed, setFullAnalyzed] = useState('')
   const [thumbnailOriginal, setThumbnailOriginal] = useState('')
   const [thumbnailAnalyzed, setThumbnailAnalyzed] = useState('')
   // Toggles
   const [keepFullOriginal, setKeepFullOriginal] = useState(false)
   const [keepFullAnalyzed, setKeepFullAnalyzed] = useState(false)
   const [keepThumbnailOriginal, setKeepThumbnailOriginal] = useState(false)
   const [keepThumbnailAnalyzed, setKeepThumbnailAnalyzed] = useState(false)

   const millisecondsInDay = 86400000

   const setInputAndToggleValues = (imagesConfig) => {
      const { original, originalThumbnails, analyzed, analyzedThumbnails } = imagesConfig.clean
      if (original !== -1) setFullOriginal(original / millisecondsInDay)
      else setKeepFullOriginal(true)
      if (originalThumbnails !== -1) setThumbnailOriginal(originalThumbnails / millisecondsInDay)
      else setKeepThumbnailOriginal(true)
      if (analyzed !== -1) setFullAnalyzed(analyzed / millisecondsInDay)
      else setKeepFullAnalyzed(true)
      if (analyzedThumbnails !== -1) setThumbnailAnalyzed(analyzedThumbnails / millisecondsInDay)
      else setKeepThumbnailAnalyzed(true)
   }

   const validateInputs = () => {
      if ((fullOriginal && thumbnailOriginal && Number(thumbnailOriginal) < Number(fullOriginal))
         || (fullAnalyzed && thumbnailAnalyzed && Number(thumbnailAnalyzed) < Number(fullAnalyzed))) {
         if (!errorMessage) setErrorMessage('Thumbnails have to be deleted after full images')
         return false
      } if (errorMessage) {
         setErrorMessage('')
      }
      if (!keepFullOriginal && !Number(fullOriginal)) return false
      if (!keepThumbnailOriginal && !Number(thumbnailOriginal)) return false
      if (!keepFullAnalyzed && !Number(fullAnalyzed)) return false
      if (!keepThumbnailAnalyzed && !Number(thumbnailAnalyzed)) return false
      return true
   }

   useEffect(() => {
      DataService
         .getImagesConfig(tool, unit.id)
         .then((imagesConfig) => {
            setImagesConfig(imagesConfig)
            setInputAndToggleValues(imagesConfig)
         })
         .catch((err) => setErrorMessage(err.message))
         .finally(() => setLoading(false))
   }, [siteId, tool, unit])


   return (
      <div className="image-settings-section">
         <h4>Remove full resolution images after:</h4>
         <SelectionRow
            id="full-original"
            title="Original:"
            value={fullOriginal}
            onValueChanged={setFullOriginal}
            keepAlways={keepFullOriginal}
            onToggle={setKeepFullOriginal}
            isEditing={isEditing}
         />
         <SelectionRow
            id="full-analyzed"
            title="Analyzed:"
            value={fullAnalyzed}
            onValueChanged={setFullAnalyzed}
            keepAlways={keepFullAnalyzed}
            onToggle={setKeepFullAnalyzed}
            isEditing={isEditing}
         />

         <h4>Remove thumbnails after:</h4>
         <SelectionRow
            id="thumb-original"
            title="Original:"
            value={thumbnailOriginal}
            onValueChanged={setThumbnailOriginal}
            keepAlways={keepThumbnailOriginal}
            onToggle={setKeepThumbnailOriginal}
            isEditing={isEditing}
         />
         <SelectionRow
            id="thumb-analyzed"
            title="Analyzed:"
            value={thumbnailAnalyzed}
            onValueChanged={setThumbnailAnalyzed}
            keepAlways={keepThumbnailAnalyzed}
            onToggle={setKeepThumbnailAnalyzed}
            isEditing={isEditing}
         />
         { errorMessage && <p className="image-settings-section__error-txt">{errorMessage}</p> }
         <br />
         { isEditing && (
            <span>
               <Button
                  title="Cancel"
                  disabled={isLoading}
                  onClick={() => {
                     setImagesConfig(imagesConfig)
                     setInputAndToggleValues(imagesConfig)
                     setEditing(false)
                  }}
               />
               <Button
                  className="image-settings-section__btn-save"
                  title="Save"
                  styleType={Button.StyleType.PRIMARY}
                  disabled={isLoading || !validateInputs()}
                  onClick={() => {
                     setErrorMessage(null)
                     setLoading(true)
                     const clean = {
                        original: !keepFullOriginal
                           ? (Number(fullOriginal) * millisecondsInDay)
                           : -1,
                        originalThumbnails: !keepThumbnailOriginal
                           ? (Number(thumbnailOriginal) * millisecondsInDay)
                           : -1,
                        analyzed: !keepFullAnalyzed
                           ? (Number(fullAnalyzed) * millisecondsInDay)
                           : -1,
                        analyzedThumbnails: !keepThumbnailAnalyzed
                           ? (Number(thumbnailAnalyzed) * millisecondsInDay)
                           : -1,
                     }
                     DataService
                        .updateImagesConfig(tool, unit.id, clean)
                        .then((imagesConfig) => {
                           setImagesConfig(imagesConfig)
                           setInputAndToggleValues(imagesConfig)
                           setEditing(false)
                        })
                        .catch((err) => setErrorMessage(err.message))
                        .finally(() => setLoading(false))
                  }}
               />
            </span>
         )}
      </div>
   )
}


const SelectionRow = ({ title, onValueChanged, value, keepAlways, onToggle, disabled, isEditing }) => (
   <div className="image-settings-section__config-row">
      <p>{title}</p>
      { isEditing ? (
         <div className="flex-row">
            <Input
               type="number"
               value={value.toString()}
               onChange={(val) => {
                  if (val === '') onValueChanged('')
                  else onValueChanged(Math.max(val, 1).toString())
               }}
               disabled={disabled || keepAlways}
            />
            <p style={{ marginLeft: '8px', marginRight: '24px' }}>days,</p>
            <p style={{ marginRight: '12px' }}>Keep always</p>
            <Toggle
               isOn={keepAlways}
               onChange={(val) => {
                  onToggle(val)
                  if (val) onValueChanged('')
               }}
               disabled={disabled}
            />
         </div>
      ) : (
         <p style={{ marginRight: '24px', minWidth: '124px' }}>{(keepAlways && 'Keep always') || (value ? `Keep ${value} days` : '')}</p>
      )}
   </div>
)
