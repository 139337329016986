import { SET_SIZE } from './types'


export function setSize(width, height) {
   return {
      type: SET_SIZE,
      payload: {
         width,
         height,
      },
   }
}
