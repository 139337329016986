import { SET_POSITIONS, SET_TAGS, SET_UNITS } from './types'

export function setTags(tags) {
   return {
      type: SET_TAGS,
      payload: tags,
   }
}

export function setUnits(units) {
   return {
      type: SET_UNITS,
      payload: units,
   }
}

export function setPositions(positions) {
   return {
      type: SET_POSITIONS,
      payload: positions,
   }
}
