import Unit from '../../models/unit'
import { CREATE_UNIT, DELETE_UNIT, EDIT_UNIT, SET_UNITS } from '../actions/types'

const initialState = {}

const unitsReducer = (state = initialState, action) => {

   switch (action.type) {

      case SET_UNITS:
         return action.payload.reduce((obj, unit) => {
            obj[unit.id] = unit
            return obj
         }, {})

      case DELETE_UNIT:
         const newState = { ...state }
         delete newState[action.payload.id]
         return newState

      case EDIT_UNIT:
         const newState1 = {
            ...state,
            [action.payload.id]: {
               ...state[action.payload.id],
               name: action.payload.name,
               tools: action.payload.tools,
            },
         }
         return newState1

      case CREATE_UNIT:
         const newUnit = new Unit(action.payload.id, action.payload.name, action.payload.toolbox, action.payload.tools)
         const newState2 = {
            ...state,
            [action.payload.id]: newUnit,
         }
         return newState2

      default:
         return state

   }
}

export default unitsReducer
