import { DELETE_TAG, RENAME_TAG, DELETE_UNIT, EDIT_UNIT, CREATE_TAG, CREATE_UNIT, DELETE_POSITION, EDIT_POSITION, CREATE_POSITION } from './types'

export function deleteTag(id, tool) {
   return {
      type: DELETE_TAG,
      payload: { id, tool },
   }
}

export function renameTag(id, name) {
   return {
      type: RENAME_TAG,
      payload: { id, name },
   }
}

export function createTag(id, name, tool) {
   return {
      type: CREATE_TAG,
      payload: { id, name, tool },
   }
}

export function deleteUnit(id) {
   return {
      type: DELETE_UNIT,
      payload: { id },
   }
}

export function editUnit(id, name, tools) {
   return {
      type: EDIT_UNIT,
      payload: { id, name, tools },
   }
}

export function createUnit(id, name, toolbox, tools) {
   return {
      type: CREATE_UNIT,
      payload: { id, name, toolbox, tools },
   }
}


export function createPosition(id, name, unit, tool) {
   return {
      type: CREATE_POSITION,
      payload: { id, name, unit, tool },
   }
}

export function editPosition(id, name) {
   return {
      type: EDIT_POSITION,
      payload: { id, name },
   }
}

export function deletePosition(id, tool) {
   return {
      type: DELETE_POSITION,
      payload: { id, tool },
   }
}
