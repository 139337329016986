import { SET_CONTINUOUS_ANALYSIS_POS_STATE } from '../actions/types'

/**
 * @typedef {object} ContinuousAnalysisPositionState
 * @property {string} status
 */
/**
 * @typedef {Object<string, ContinuousAnalysisPositionState>} ContinuousAnalysisPositionStates { <positionId>: State }
 */
/** @type {ContinuousAnalysisPositionStates} */
const initialState = {}

/**
 * @type {import("./root-reducer").ReduxReducer<ContinuousAnalysisPositionStates>}
 */
const continuousAnalysisReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_CONTINUOUS_ANALYSIS_POS_STATE:
         const { position, state: caState } = action.payload
         return {
            ...state,
            [position]: caState,
         }

         // Don't clear using CLEAR_DATA. If "Actions" -> "Clear Data" is pressed etc.,
         // app won't know what positions are alive after clear

      default:
         return state

   }
}

export default continuousAnalysisReducer
