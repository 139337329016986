import { SET_SITE } from '../actions/types'


const initialState = null

// TODO: Handle loading and errors
const siteReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_SITE:
         return action.payload

      default:
         return state

   }
}

export default siteReducer
