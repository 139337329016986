import { CLEAR_DATA, SET_POSITION_FILTERS, DELETE_POSITION, EXCLUDE_POSITION, ADD_POSITION_FILTER } from '../actions/types'

const initialState = {}

const positionFiltersReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_POSITION_FILTERS:
         return action.payload.reduce((obj, pos) => {
            obj[pos] = { id: pos, exclude: false }
            return obj
         }, {})
      case ADD_POSITION_FILTER:
         const position = action.payload
         return {
            ...state,
            [position]: { id: position, exclude: false },
         }

      case EXCLUDE_POSITION:
         return {
            ...state,
            [action.payload.id]: { id: action.payload.id, exclude: action.payload.exclude },
         }

      case DELETE_POSITION:
         const newState = { ...state }
         delete newState[action.payload.id]
         return newState

      case CLEAR_DATA:
         return {}

      default:
         return state
   }
}

export default positionFiltersReducer
