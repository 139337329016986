import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './radio-button.css'

export default class RadioButton extends Component {

   static propTypes = {
      onChange: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
   }

   render() {
      const { onChange, title, checked } = this.props

      return (
         <div
            className="radio-button"
            onClick={onChange}
         >
            <input
               type="radio"
               className="checkbox"
               checked={checked}
               readOnly
            />
            <div className="checkmark" />
            <p>{ title }</p>
         </div>
      )
   }

}
