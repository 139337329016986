import { clearSelectedAnalyses } from '@ava/react-common/store/actions/data-actions'
import { selectToolsFilteredAnalyses } from '@ava/react-common/store/reducers/root-reducer'
import { detectBrowser } from '@ava/react-common/utils/Helper'
import classNames from 'classnames/bind'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import arrow from '../../../assets/arrow.svg'
import Button from '../../Button/button'
import BottomMenuTable from './bottom-menu-table'
import './bottom-menu.css'


class BottomMenu extends Component {

   // FIXME: Add animation shen data changes

   constructor(props) {
      super(props)
      this.isResizing = false
      this.lastDownY = 0
      this.minHeight = 44
      this.initialHeight = 126
      this.state = {
         scrollbarWidth: undefined,
         rowCount: 0,
         menuHeight: this.initialHeight, // instead of setting initial height would be better to calculate it (initial height is set on css).
      }
      this.innerContainerObserver = undefined
      this.counter = 0
      this.refBottomMenu = React.createRef()
   }


   componentDidMount() {
      this.observeForInnerContainerScrollbarVisibility()
      this.setState({ rowCount: this.props.analysesLength })

      this.container = document.getElementById('tool-content-container')
      this.bottomMenu = this.refBottomMenu.current

      // Init resize
      document.getElementById('handle').addEventListener('mousedown', this.onMouseDown)
      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)
   }

   observeForInnerContainerScrollbarVisibility = () => {
      if (this.props.isMobile) return
      try {
         const contentContainer = document.getElementsByClassName('content-container')[0]
         const innerContainer = contentContainer.firstChild // innerContainer

         const checkForScrollbar = () => {
            const scrollbarWidth = contentContainer.offsetWidth - contentContainer.clientWidth
            if (this.state.scrollbarWidth !== scrollbarWidth) {
               this.setState({ scrollbarWidth })
            }
         }
         this.innerContainerObserver = new ResizeObserver(checkForScrollbar)
         this.innerContainerObserver.observe(innerContainer)
      }
      catch{
         // Not supported browsers
         this.setState({ scrollbarWidth: 16 })
      }
   }

   componentWillUnmount() {
      if (this.innerContainerObserver) this.innerContainerObserver.disconnect()
      document.getElementById('handle').removeEventListener('mousedown', this.onMouseDown)
      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('mouseup', this.onMouseUp)
   }

   componentDidUpdate(prevProps, prevState) {
      const { analysesLength } = this.props
      if (prevProps.analysesLength !== analysesLength) {
         this.setState({ rowCount: analysesLength }, () => this.rowCountChanged(prevState.rowCount))
      }
   }

   rowCountChanged = (prevCount) => {
      if (prevCount === 0 && this.state.rowCount !== 0) {
         this.setState({ menuHeight: this.initialHeight }, () => this.animateToInitialHeight())
      } else if (this.state.rowCount !== 0) {
         this.animateToCorrectHeight()
      }
   }


   onMouseDown = (e) => {
      document.body.style['user-select'] = 'none'
      document.body.style.cursor = 'ns-resize'
      this.isResizing = true
      this.lastDownY = e.clientY
   }

   onMouseMove = (e) => {

      // Don't do anything if not resizing.
      if (!this.isResizing) return

      // IE and edge browser have difficulties rendering screen every time component position changes --> This reduces update frequency
      const browser = detectBrowser()
      if (browser === 'IE11' || browser === 'MSIE' || browser === 'EDGE') {
         if (this.counter < 4) {
            this.counter += 1
            return
         }
         this.counter = 0

      }

      let maxHeight = 30 * this.state.rowCount + 40
      // if maxHeight is smaller than initialHeight (default height) set max height to initial height
      // if maxHeight is larger than full window height - selected gap --> prevent dragging funrher.
      if (maxHeight < this.initialHeight) { maxHeight = this.initialHeight } else if (maxHeight > (window.innerHeight - 180)) { maxHeight = window.innerHeight - 180 }
      const { height: containerHeight, top: containerTop } = this.container.getBoundingClientRect()
      const menuHeight = containerHeight - (e.clientY - containerTop)
      if (menuHeight < this.minHeight) {
         this.bottomMenu.style.height = `${this.minHeight}px`
         this.setState({ menuHeight: this.minHeight - 4 })
      } else if (menuHeight > maxHeight) {
         this.bottomMenu.style.height = `${maxHeight}px`
         this.setState({ menuHeight: maxHeight - 4 })
      } else {
         this.bottomMenu.style.height = `${menuHeight}px`
         this.setState({ menuHeight: menuHeight - 4 })
      }
   }



   onMouseUp = () => {
      // Stop resizing
      this.isResizing = false
      document.body.style['user-select'] = ''
      document.body.style.cursor = ''
   }


   animateToCorrectHeight = (height, duration, callback) => {
      let start = null
      const initialHeight = this.bottomMenu.getBoundingClientRect().height
      const bottomMenu = this.bottomMenu
      window.requestAnimationFrame(function step(timestamp) {
         if (!start) start = timestamp
         const progress = (timestamp - start) / duration
         const easeInOutSineProgress = -(Math.cos(Math.PI * progress) - 1) / 2
         const newHeight = initialHeight - (initialHeight - height) * (easeInOutSineProgress)
         bottomMenu.style.height = `${newHeight}px`
         if (progress < 1) {
            window.requestAnimationFrame(step)
         } else {
            if (callback) callback()
         }
      })
   }

   animateToInitialHeight = () => {
      const height = this.initialHeight + 4
      const duration = height * 1.5
      this.bottomMenu.style.height = '0'
      setTimeout(() => {
         this.animateToCorrectHeight(height, duration)
      }, 500)
   }

   toggleHeightPressed = () => {
      const currentHeight = this.bottomMenu.getBoundingClientRect().height
      let destination = 0
      const low = this.initialHeight + 4
      const high = 460
      let duration = 0
      if (currentHeight > low + 10) {
         destination = low
         duration = currentHeight - low
         this.animateToCorrectHeight(destination, duration, () => {
            this.setState({ menuHeight: destination - 4 })
         })
      } else {
         destination = high
         const maxHeight = 30 * this.state.rowCount + 40
         if (maxHeight < destination) { destination = maxHeight }
         if (destination < low) { destination = low }
         duration = high - currentHeight
         this.setState({ menuHeight: destination - 4 })
         this.animateToCorrectHeight(destination, duration * 1.3)
      }

   }

   render() {
      const { menuHeight, rowCount, scrollbarWidth } = this.state
      const isHidden = (rowCount === 0)
      const toggleBtnIsHidden = (rowCount < 6 && (menuHeight > 30 * rowCount + 26))

      return (
         <div hidden={isHidden} ref={this.refBottomMenu} className="bottom-menu"
            style={{ height: `${this.initialHeight + 4}px` }}>
            <div className="bottom-menu-buttons-row" style={{ marginRight: scrollbarWidth }}>
               { this.props.selectedAnalysisIds?.length > 0
                  && <Button
                     title="deselect all"
                     className="clear-selection-button"
                     onClick={this.props.clearSelectedAnalyses}
                     styleType={Button.StyleType.TEXT}
                  />
               }
               { !toggleBtnIsHidden
                  && <Button
                     className={classNames('toggle-height', { down: menuHeight > 204 })}
                     onClick={() => this.toggleHeightPressed()}
                     styleType={Button.StyleType.ICON}
                     iconSrc={arrow}
                  />
               }
            </div>
            <div id="handle" className="bottom-menu-handle" />
            <BottomMenuTable
               height={menuHeight}
            />
         </div>
      )
   }

}


const mapStateToProps = (state) => ({
   analysesLength: selectToolsFilteredAnalyses(state).length,
   isMobile: state.device.isMobile,
   selectedAnalysisIds: state.selectedAnalyses,
})

export default connect(mapStateToProps, { clearSelectedAnalyses })(BottomMenu)
