import { encode } from 'base-64'
import LoginMethod from '../models/login-method'
import User from '../models/user'
import { errorToMessage } from '../utils'
import axiosAvaApiClient from '../utils/axiosAvaApiClient'
import StatusCodes from '../utils/StatusCodes'


/**
 * @param {string=} baseUrl
 * @returns {Promise<LoginMethod[]>}
 */
export default class AuthService {

   static async getLoginMethods() {
      const response = await axiosAvaApiClient.get('/login')
      return LoginMethod.fromJsonArr(response.data)
   }


   /**
    * @param {string} username
    * @param {string} password
    */
   static login(username, password) {
      return new Promise((resolve, reject) => {
         const params = {
            username,
            password: encode(password),
         }
         axiosAvaApiClient.post('/login', params)
            .then((response) => {
               resolve(User.userFromJson(response.data.user))
            })
            .catch((err) => {
               if (!err.response) {
                  return reject(err)
               }
               switch (err.response.status) {
                  case StatusCodes.BAD_REQUEST:
                     reject(new Error('Invalid Username or Password'))
                     break
                  case StatusCodes.UNAUTHORIZED:
                     if (err.response.data.error === 'AttemptTooSoonError') {
                        reject(new Error('Too many attempts within time limit'))
                        break
                     } else if (err.response.data.error === 'TooManyAttemptsError') {
                        reject(new Error('Account is locked'))
                        break
                     }
                  default:
                     reject(new Error(errorToMessage(err)))
               }
            })
      })
   }

   static logout() {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.post('/logout')
            .then(() => resolve())
            .catch(() => reject('Error logging out'))
      })
   }


   static async getCurrentUser() {
      const response = await axiosAvaApiClient.get('/currentUser')
      if (!response.data) return
      return User.userFromJson(response.data)
   }

}
