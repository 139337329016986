import { Tool } from '@ava/react-common/models'
import { selectSelectedTool } from '@ava/react-common/store/reducers/root-reducer'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Button from '../Button/button'
import Dropdown from '../Dropdown/dropdown'
import './timeframe-buttons.scss'


/**
 * Form for user login
 *
 * @param {{
 *    timeframe: [Date, Date],
 *    selection: [Date, Date],
 *    zoomSelection: [Date, Date],
 *    onSelectionChange: (from: Date, to: Date) => void,
 *    onZoomSelectionChange: (from: Date, to: Date) => void,
 * }} props
 * @returns {import('react').FunctionComponentElement}
 */
// export default function TimeframeButtons({ timeframe, selection, zoomSelection, onSelectionChange, onZoomSelectionChange }) {
export default function TimeframeButtons({ timeframe, selection, onSelectionChange }) {

   const selectedTool = useSelector(selectSelectedTool)

   // const isTimeframeZoomed = zoomSelection ? (+zoomSelection[0] !== +timeframe[0] || +zoomSelection[1] !== +timeframe[1]) : undefined
   // const isTimeframeZoomEqualToSelection = zoomSelection ? (+selection[0] === +zoomSelection[0] && +selection[1] === +zoomSelection[1]) : undefined

   const MINUTE = 60 * 1000
   const HOUR = 60 * MINUTE
   const DAY = 24 * HOUR
   const WEEK = 7 * DAY
   const MONTH = 30 * DAY
   const YEAR = 365 * DAY

   const selectedMargin = 0.05

   const buttons = useMemo(() => {
      if (!timeframe) return []

      let btns = [{
         range: DAY,
         text: '1d',
      }, {
         range: WEEK,
         text: '1w',
      }, {
         range: MONTH,
         text: '1m',
      }, {
         range: 3 * MONTH,
         text: '3m',
      }, {
         range: 6 * MONTH,
         text: '6m',
      }, {
         range: YEAR,
         text: '1y',
      }]

      const start = +timeframe[0]
      const end = +timeframe[1]
      const hoursDiff = (end - start) / (1000 * 3600)

      let enabledButtons = 0
      if (hoursDiff >= 1 * 24 * 365) {
         enabledButtons = 6
      } else if (hoursDiff >= 1 * 24 * 30 * 6) {
         enabledButtons = 5
      } else if (hoursDiff >= 1 * 24 * 30 * 3) {
         enabledButtons = 4
      } else if (hoursDiff >= 1 * 24 * 28) {
         enabledButtons = 3
      } else if (hoursDiff >= 1 * 24 * 7) {
         enabledButtons = 2
      } else if (hoursDiff >= 1 * 24) {
         enabledButtons = 1
      } else {
         enabledButtons = 0
      }

      // Continuous tools have extra buttons
      if (selectedTool.config.type === Tool.Type.CONTINUOUS) {
         const extraButtons = []
         const minutesDiff = (end - start) / (1000 * 60)
         if (minutesDiff >= 10) {
            extraButtons.push({
               range: MINUTE * 10,
               text: '10min',
            })
         }
         if (minutesDiff >= 30) {
            extraButtons.push({
               range: MINUTE * 30,
               text: '30min',
            })
         }
         if (hoursDiff >= 1) {
            extraButtons.push({
               range: HOUR,
               text: '1h',
            })
         }
         btns.unshift(...extraButtons)
         enabledButtons += extraButtons.length
      }


      btns = btns.slice(0, enabledButtons)

      /*
      // YTD
      let currentTime = new Date()
      currentTime.setHours(0,0,0,0)
      currentTime = currentTime.getTime()
      if (firstTimestamp <= currentTime && lastTimestamp >= currentTime && new Date(firstTimestamp).getYear() !== new Date().getYear()) {
         btns.push({
            text: 'YTD',
            range: 'ytd',
         })
      } */

      return [
         ...btns,
         {
            text: 'All',
            range: 'all',
         },
      ]
   }, [timeframe, selectedTool, DAY, HOUR, MINUTE, MONTH, WEEK, YEAR])

   if (!selection) return null



   let selected
   const dropdownItems = buttons.map((button) => {
      if (!selected) {
         let isSelected = false
         if (button.range === 'all') {
            isSelected = (+selection[0] === +timeframe[0] && +selection[1] === +timeframe[1])
         } else {
            const selectionRange = (+selection[1] - +selection[0])
            isSelected = (selectionRange >= +button.range * (1 - selectedMargin) && selectionRange <= +button.range * (1 + selectedMargin))
         }
         if (isSelected) selected = button.range
      }
      return {
         label: button.text,
         value: button.range,
      }
   })


   return (
      <div className="timeframe-buttons">

         {/* SELECTION SELECTOR BUTTONS */}
         <div style={{
            fontSize: '14px',
            textAlign: 'center',
            marginBottom: '4px',
            width: '100%',
            color: 'white',
            display: 'inline-block',
            paddingTop: 4,
         }}>
            Selection
         </div>
         <Dropdown
            width={80}
            menuItems={dropdownItems}
            onSelect={(range) => {
               if (range === 'all') {
                  onSelectionChange(timeframe[0], timeframe[1])
               } else {
                  let clampToBoundsAddition = 0
                  if (+selection[1] - +range < +timeframe[0]) {
                     clampToBoundsAddition = +timeframe[0] - (+selection[1] - +range)
                  }
                  onSelectionChange(new Date(+selection[1] - +range + clampToBoundsAddition), new Date(+selection[1] + clampToBoundsAddition))
               }
            }}
            dropdownToButtonWidth={true}
            selected={selected}
            menuStyle={{ padding: 0 }}
            itemStyle={{ fontSize: 14, margin: 0, padding: 2, textAlign: 'center' }}
            button={
               <Button
                  title={
                     ` ${dropdownItems.find((i) => i.value === selected)?.label || '-'} ▾`
                  }
                  style={{ width: 80, height: 26, marginBottom: 0, fontSize: 15 }}
               />
            }
         />

         {/* ZOOM BUTTONS - Disabled for now */}
         {/* {(isTimeframeZoomed || !isTimeframeZoomEqualToSelection) &&
            <>
               <div
                  className="timeframe-buttons__group-title"
                  style={{ marginLeft: "16px" }}
               >
                  Zoom
               </div>
               { isTimeframeZoomed &&
                  <div
                     className="timeframe-buttons__button timeframe-buttons__button--action"
                     onClick={() => onZoomSelectionChange(...timeframe)}
                  >
                     Show all
                  </div>
               }
               { !isTimeframeZoomEqualToSelection &&
                  <div
                     className="timeframe-buttons__button timeframe-buttons__button--action"
                     onClick={() => onZoomSelectionChange(...selection)}
                  >
                     To selection
                  </div>
               }
            </>
         } */}

      </div>
   )
}

TimeframeButtons.propTypes = {
   timeframe: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
   selection: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
   onSelectionChange: PropTypes.func.isRequired,
   onZoomSelectionChange: PropTypes.func.isRequired,
}
