import { isEmpty } from 'lodash'
import { ADD_UNIT_FILTER, CLEAR_DATA, DELETE_UNIT, EXCLUDE_UNIT, SET_UNIT_FILTERS } from '../actions/types'

const initialState = {}

const unitFiltersReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_UNIT_FILTERS:
         return action.payload.reduce((obj, unit, index) => {
            obj[unit] = { id: unit, exclude: (index !== 0) }
            return obj
         }, {})
      case ADD_UNIT_FILTER:
         const unit = action.payload
         return {
            ...state,
            [unit]: { id: unit, exclude: !isEmpty(state) },
         }
      case EXCLUDE_UNIT:
         return {
            ...state,
            [action.payload.id]: { id: action.payload.id, exclude: action.payload.exclude },
         }

      case DELETE_UNIT:
         const newState = { ...state }
         delete newState[action.payload.id]
         return newState

      case CLEAR_DATA:
         return initialState

      default:
         return state

   }
}

export default unitFiltersReducer
