import { SET_CURRENT_INSTANCE } from '../actions/types'

const initialState = null

const CurrentInstanceReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_CURRENT_INSTANCE:
         return action.payload
      default:
         return state

   }
}

export default CurrentInstanceReducer
