import { selectSelectedTool, selectToolsPositions, selectToolsUnits } from '@ava/react-common/store/reducers/root-reducer'
import { getLanguageString } from '@ava/react-common/utils/Helper'
import XLSX from 'xlsx'
import store from '../store'


/**
 * Downloads DCS tags excel file
 * @param {Array} dcsTags List of all tools dcs tags
 */
export function exportDcsTagsXLSX(dcsTags) {
   const state = store.getState()
   const tool = selectSelectedTool(state)
   const elements = tool.resultModels[0].elements
   const toolsPositions = selectToolsPositions(state)
   const toolsUnits = selectToolsUnits(state)
   const writableTags = selectSelectedTool(state).resultModels[0].writableTags

   const wb = XLSX.utils.book_new()
   wb.Props = {
      Title: 'AVA',
      Subject: 'Exported DCS Tags',
      Author: '-',
      CreatedDate: new Date(),
   }

   let wsData = []

   const rowsForPosition = (positionId, positionName) => {
      const positionTitleRow = [positionName]
      const titleRow = ['Element', 'Value', 'LAL', 'UAL', 'MLL', 'MUL', 'Processing unit', 'Writable']
      wsData.push(positionTitleRow)
      wsData.push(titleRow)
      Object.keys(elements).forEach((elementId) => {
         const row = []
         const tag = dcsTags.find((tag) => (tag.position === positionId && tag.id === elementId))
         const element = elements[elementId]
         row.push(getLanguageString(element.name))
         row.push(tag?.value)
         row.push(tag?.alarmMin || element.alarmLimits?.min)
         row.push(tag?.alarmMax || element.alarmLimits?.max)
         row.push(element.rangeLimits?.min)
         row.push(element.rangeLimits?.max)
         row.push(element.unit)
         row.push(tag?.isWritable)
         wsData.push(row)
      })
      // Writable tags
      if (writableTags) {
         Object.keys(writableTags).forEach((tagId) => {
            const row = []
            const writableTag = writableTags[tagId]
            const dbTag = dcsTags.find((tag) => (tag.position === positionId && tag.id === tagId))
            row.push(getLanguageString(writableTag.name))
            row.push(dbTag?.value)
            row.push(null)
            row.push(null)
            row.push(null)
            row.push(null)
            row.push(null)
            row.push(true)
            wsData.push(row)
         })
      }
      wsData.push([])
   }

   toolsUnits.forEach((unit) => {
      // Set unit name as sheet name
      wb.SheetNames.push(unit.name)

      // Get data for every position in unit
      toolsPositions.forEach((position) => {
         if (position.unit === unit.id) {
            rowsForPosition(position.id, position.name)
         }
      })

      // Write data to sheet
      const ws = XLSX.utils.aoa_to_sheet(wsData)
      wb.Sheets[unit.name] = ws

      // Clear data before moving to next unit
      wsData = []
   })


   XLSX.writeFile(wb, `${tool.id}-dcs-tags.xlsx`)
}
