import Axios from 'axios'
import qs from 'qs'
import { errorToMessage, isApiError } from './error-utils'

const axiosAvaApiClient = Axios.create({
   paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
   },
})

export default axiosAvaApiClient


// Add a response interceptor to log API failures
axiosAvaApiClient.interceptors.response.use(
   (response) => response, // Success event
   (error) => { // Failure event
      if (isApiError(error)) {
         console.debug(error.config.method.toUpperCase(), error.config.url.replace(/^\/?/, '/'), `\n${errorToMessage(error)}`)
      }
      return Promise.reject(error)
   })


/**
 * @param {string} baseURL
 */
export function setAxiosAvaClientBaseUrl(baseURL) {
   axiosAvaApiClient.defaults.baseURL = baseURL
}
