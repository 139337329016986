export default class DCSTag {

   /**
    *
    * @param {string} id isWritable ? writable tags id : element id -- (See tools-config)
    * @param {string} value tag value
    * @param {string} site Site id
    * @param {string} tool Tool id
    * @param {string} position Position id
    * @param {boolean} isWritable is tag writable
    * @param {number} alarmMin alarm lower limit
    * @param {number} alarmMax alarm upper limit
    */
   constructor(id, value, site, tool, position, isWritable = false, alarmMin, alarmMax) {
      this.id = id
      this.value = value
      this.site = site
      this.tool = tool
      this.position = position
      this.isWritable = isWritable
      this.alarmMin = alarmMin
      this.alarmMax = alarmMax
   }

   /**
    *
    * @param {Object} data
    */
   static fromJson(data) {
      return new DCSTag(data.id, data.value, data.site, data.tool, data.position, data.isWritable, data.alarmMin, data.alarmMax)
   }

}
