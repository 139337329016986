import { ENABLE_TAGLESS_FILTERING, EXCLUDE_TAGLESS } from '../actions/types'

const initialState = {
   enabled: false,
   exclude: false,
}

const taglessFilterReducer = (state = initialState, action) => {
   switch (action.type) {
      case ENABLE_TAGLESS_FILTERING:
         return {
            enabled: action.payload,
            exclude: false,
         }
      case EXCLUDE_TAGLESS:
         const newState = {
            ...state,
            exclude: action.payload,
         }
         return newState
      default:
         return state
   }
}

export default taglessFilterReducer
