import { useEffect, useState } from 'react'

/**
 * Returns element's width and height and updates them on resize
 * @param {*} ref
 * @returns {{ width: number, height: number }} ref
 */
export default function useResize(ref) {
   const [width, setWidth] = useState()
   const [height, setHeight] = useState()

   useEffect(() => {
      const handleResize = () => {
         setWidth(ref.current.offsetWidth)
         setHeight(ref.current.offsetHeight)
      }

      window.addEventListener('resize', handleResize)

      // Initial width & height
      handleResize()

      return () => window.removeEventListener('resize', handleResize)
   }, [ref])

   return { width, height }
}
