import { BEND_TIMEFRAME, CLEAR_DATA, SET_TIMEFRAME, UPDATE_TIMEFRAME } from '../actions/types'

/**
 * Timeframe of loaded analyses
 *
 * @typedef {object} Timeframe
 * @property {('timestamp'|'dateAnalyzed')} by
 * @property {Date} from
 * @property {Date} to
 */
const initialState = null

/**
 * @type {import("./root-reducer").ReduxReducer<Timeframe>}
 */
const timeframeReducer = (state = initialState, action) => {

   if (action.type === SET_TIMEFRAME) {
      return action.payload
   }
   if (action.type === UPDATE_TIMEFRAME) {
      const { by, from, to } = action.payload
      const updated = { ...state }
      if (by) updated.by = by
      if (from) updated.from = from
      if (to) updated.to = to
      return updated
   }
   if (action.type === BEND_TIMEFRAME) {
      const { from, to } = action.payload
      const updated = { ...state }
      if (!updated.from || updated.from > from) updated.from = from
      if (!updated.to || updated.to < to) updated.to = to
      return updated
   }
   if (action.type === CLEAR_DATA) {
      return null
   }

   return state


}

export default timeframeReducer
