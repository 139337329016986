const StatusCodes = {
   SUCCESS: 200,
   CREATED: 201,
   BAD_REQUEST: 400,
   UNAUTHORIZED: 401,
   FORBIDDEN: 403,
   NOT_FOUND: 404,
   REQUEST_TIMEOUT: 408,
   CONFLICT: 409,
   INTERNAL_SERVER_ERROR: 500,
   BAD_GATEWAY: 502,
   GATEWAY_TIMEOUT: 504,
}

export default StatusCodes

/**
 * @param {number} statusCode
 * @returns {string}
 */
export function statusCodeToMessage(statusCode) {
   const index = Object.values(StatusCodes).findIndex((code) => (code === statusCode))
   const message = (Object.keys(StatusCodes)[index] || '')
      .split('_')
      .map((word) => word.toLowerCase().charAt(0).toUpperCase() + word.toLowerCase().slice(1))
      .join(' ')
   return `${statusCode} ${message}`
}
