import DataService from '@ava/react-common/services/data-service'
import { selectSelectedTool, selectToolsPositions, selectToolsUnits } from '@ava/react-common/store/reducers/root-reducer'
import { getLanguageString } from '@ava/react-common/utils/Helper'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { exportDcsTagsXLSX } from '../../../utils/exportDcsTagsXLSX'
import Button from '../../Button/button'
import HoverInfo from '../../Hoverable/hover-info'
import Input from '../../Input/input'
import SettingsTopMenu from '../../SettingsTopMenu/settings-top-menu'
import './dcs-tags-settings.scss'

/**
 * @param {object} props
 * @param {{ height: number }} props.dimensions
 * @param {(err: string) => void} props.onError
 * @returns {import('react').FunctionComponentElement}
 */
export default function DCSTagsSettings({ dimensions, onError }) {
   const [isEditing, setEditing] = useState(false)
   const [selectedUnit, selectUnit] = useState(undefined)
   const [dcsTags, setDcsTags] = useState([])
   const [isLoading, setLoading] = useState(false)
   const units = useSelector((state) => selectToolsUnits(state))
   const user = useSelector((state) => state.user)
   const isEditingEnabled = user.hasEngineerPriviledges()
   const tool = useSelector((state) => state.selectedTool)
   const site = useSelector((state) => state.selectedSite)

   useEffect(() => {
      if (units && units.length > 0 && !selectedUnit) {
         selectUnit(units[0].id)
      }
   }, [selectedUnit, units])

   useEffect(() => {
      getTagsFromApi()
   }, []) // eslint-disable-line

   const getTagsFromApi = () => {
      setLoading(true)
      DataService
         .getDCSTags(site, tool)
         .then((dcsTags) => {
            setDcsTags(dcsTags)
            setEditing(false) // If was editing -> set editing to false when dcs tags are fetched
         })
         .catch((err) => {
            console.error(err)
            onError('Failed to get DCS Tags')
         })
         .finally(() => {
            setLoading(false)
         })
   }

   const topMenuOptions = units.map((unit) => ({
      title: unit.name,
      value: unit.id,
      selected: (unit.id === selectedUnit),
   }))

   if (!units || units.length === 0) {
      return (
         <div style={{ padding: '24px' }}>
            <h4>No units exist</h4>
         </div>
      )
   }

   return (
      <div>
         <SettingsTopMenu
            topMenuOptions={topMenuOptions}
            onTopMenuSelect={(value) => selectUnit(value)}
            topMenuDisabled={isEditing}
            actionOptions={[
               { value: 'edit', label: 'Edit' },
               { value: 'export', label: 'Export xlsx' },
            ]}
            onActionSelected={(action) => {
               if (action === 'edit') {
                  setEditing(true)
               } else if (action === 'export') {
                  exportDcsTagsXLSX(dcsTags)
               }
            }}
            primaryBtnDisabled={!isEditingEnabled}
            actionsDisabled={isEditing || !isEditingEnabled}
         />
         { selectedUnit
            && <span>
               <TagsForUnit
                  height={dimensions.height}
                  unit={selectedUnit}
                  onError={onError}
                  isEditing={isEditing}
                  setEditing={setEditing}
                  dcsTags={dcsTags}
                  refetchTags={getTagsFromApi}
                  isLoading={isLoading}
                  setLoading={setLoading}
               />
            </span>
         }
      </div>
   )
}



const ElementRow = ({
   elementName,
   tagValue,
   onTagValueChanged,
   alarmMin,
   alarmMinPlaceholder,
   onAlarmMinChange,
   alarmMax,
   alarmMaxPlaceholder,
   onAlarmMaxChange,
   rangeMin,
   rangeMax,
   unit,
   isWritable,
   disabled,
}) => {

   const inputStyle = {
      width: '138px',
      marginRight: '2px',
   }
   return (
      <div className="dcs-tags-position-container__elements">
         <p className="element-name" style={{ flexShrink: 0 }}>{elementName}</p>
         <Input
            style={inputStyle}
            disabled={disabled}
            value={tagValue != null ? tagValue : ''}
            onChange={onTagValueChanged}
         />
         { onAlarmMinChange
            ? <Input
               style={inputStyle}
               disabled={disabled || !onAlarmMinChange}
               value={alarmMin != null ? alarmMin : ''}
               onChange={onAlarmMinChange}
               placeholder={alarmMinPlaceholder?.toString()}
            />
            : <div style={inputStyle} />
         }
         { onAlarmMaxChange
            ? <Input
               style={inputStyle}
               disabled={disabled || !onAlarmMaxChange}
               value={alarmMax != null ? alarmMax : ''}
               onChange={onAlarmMaxChange}
               placeholder={alarmMaxPlaceholder?.toString()}
            />
            : <div style={inputStyle} />
         }
         <p style={{ width: '110px', flexShrink: 0, paddingLeft: '16px' }}>{rangeMin != null ? rangeMin : '-'}</p>
         <p style={{ width: '110px', flexShrink: 0, paddingLeft: '16px' }}>{rangeMax != null ? rangeMax : '-'}</p>
         <p style={{ width: '90px', flexShrink: 0, paddingLeft: '16px' }}>{unit}</p>
         <p style={{ width: '82px', flexShrink: 0, paddingLeft: '16px' }}>{isWritable ? 'true' : ''}</p>
      </div>
   )
}



/* LIST OF UNITS TAGS */
const TagsForUnit = ({ dcsTags, unit, onError, height, isEditing, setEditing, refetchTags, isLoading, setLoading }) => {
   const [editedDCSTags, setEditedDCSTags] = useState({})
   const toolsPositions = useSelector((state) => selectToolsPositions(state))
   const elements = useSelector((state) => selectSelectedTool(state).resultModels[0].elements)
   const writableTags = useSelector((state) => selectSelectedTool(state).resultModels[0].writableTags)
   const tool = useSelector((state) => state.selectedTool)
   const site = useSelector((state) => state.selectedSite)


   useEffect(() => {
      if (isEditing) return
      setEditedDCSTags({})
   }, [isEditing])

   const setValuesForTag = (positionId, elementId, tagValue, alarmMin, alarmMax) => {
      const prevTag = getTag(positionId, elementId) // tag before editing
      const newState = {
         ...editedDCSTags,
         [positionId]: {
            ...editedDCSTags[positionId],
            [elementId]: {
               value: (tagValue != null) ? (tagValue?.trim() || null) : prevTag?.value,
               alarmMin: (alarmMin != null) ? alarmMin : prevTag?.alarmMin,
               alarmMax: (alarmMax != null) ? alarmMax : prevTag?.alarmMax,
            },
         },
      }
      setEditedDCSTags(newState)
   }

   /**
    * Get tag object. If tag is edited, returns edited object (newDCSTag). Otherwise returns DCSTag object from dcsTags
    * @param {string} positionId
    * @param {string} tagId
    * @returns {string}
    */
   const getTag = (positionId, tagId) => {
      // If exists in edited tags
      if (editedDCSTags[positionId] && editedDCSTags[positionId][tagId] !== undefined) {
         return editedDCSTags[positionId][tagId]
      }

      // otherwise return original tag (Also filters out writable tags)
      const originalDCSTag = (dcsTags.find((tag) => (tag.position === positionId && tag.id === tagId)))
      return originalDCSTag
   }


   const tagsForPosition = (position, first) => (
      <div key={position.id} className="dcs-tags-position-container">
         <div className="flex-row" style={{ marginBottom: '4px' }}>
            <h3 style={{ flexShrink: 0 }} className="position-label">{position.name}</h3>

            {/* Add titles and hoverable descriptions */}
            { first
                  && <>
                     <HoverInfo title="DCS Tag value" description={`Value has to be in format "ppp" where ppp is position identifier`}>
                        <h4 style={{ width: '140px', flexShrink: 0 }}>DCS Tag</h4>
                     </HoverInfo>
                     <HoverInfo title="LAL" description="Lower alarm limit ">
                        <h4 style={{ width: '140px', flexShrink: 0 }}>Alarm min</h4>
                     </HoverInfo>
                     <HoverInfo title="UAL" description="Upper alarm limit">
                        <h4 style={{ width: '140px', flexShrink: 0 }}>Alarm max</h4>
                     </HoverInfo>
                     <HoverInfo title="MLL" description="Measurement lower limit">
                        <h4 style={{ width: '110px', paddingLeft: '16px', flexShrink: 0 }}>Range min</h4>
                     </HoverInfo>
                     <HoverInfo title="MUL" description="Measurement upper limit">
                        <h4 style={{ width: '110px', paddingLeft: '16px', flexShrink: 0 }}>Range max</h4>
                     </HoverInfo>
                     <HoverInfo title="Unit" description="Unit of value that tool retuns for the element">
                        <h4 style={{ width: '90px', paddingLeft: '16px', flexShrink: 0 }}>Unit</h4>
                     </HoverInfo>
                     <HoverInfo title="isWritable" description="Is DCS tag writable">
                        <h4 style={{ width: '82px', paddingLeft: '16px', flexShrink: 0 }}>Writable</h4>
                     </HoverInfo>
                  </>
            }
         </div>
         {/* TAGS FOR ELEMENT */}
         { Object.keys(elements).map((elementId) => {
            const element = elements[elementId]
            const unit = element.unit || ''
            return (
               <ElementRow
                  key={position.id + elementId}
                  elementName={getLanguageString(element.name)}
                  tagValue={getTag(position.id, elementId)?.value}
                  onTagValueChanged={(value) => { setValuesForTag(position.id, elementId, value) }}
                  alarmMin={getTag(position.id, elementId)?.alarmMin}
                  onAlarmMinChange={(value) => setValuesForTag(position.id, elementId, null, value, null)}
                  alarmMinPlaceholder={element.alarmLimits?.min}
                  alarmMax={getTag(position.id, elementId)?.alarmMax}
                  onAlarmMaxChange={(value) => setValuesForTag(position.id, elementId, null, null, value)}
                  alarmMaxPlaceholder={element.alarmLimits?.max}
                  rangeMin={element.rangeLimits?.min}
                  rangeMax={element.rangeLimits?.max}
                  unit={unit}
                  isWritable={false}
                  disabled={!isEditing}
               />
            )
         })}
         {/* WRITABLE TAGS */}
         { writableTags
               && Object.keys(writableTags).map((tagId) => {
                  const writableTag = writableTags[tagId]
                  return (
                     <ElementRow
                        key={position.id + tagId}
                        elementName={getLanguageString(writableTag.name)}
                        tagValue={getTag(position.id, tagId)?.value}
                        onTagValueChanged={(value) => setValuesForTag(position.id, tagId, value)}
                        unit={''}
                        isWritable={true}
                        disabled={!isEditing}
                     />
                  )
               })
         }
      </div>
   )


   const save = () => {
      const dcsTagsToEdit = []

      for (const position in editedDCSTags) {
         for (const tagId in editedDCSTags[position]) {
            const isWritable = !!((writableTags && writableTags[tagId]))
            const newTag = editedDCSTags[position][tagId]

            if (!!newTag.value && !newTag.value.match(/^[^\0;=.]{1,4096}(\.[^\0;=.]{1,4096})?$/)) {
               onError('DCS Tags has to be in format "ppp" or "ppp.eee" where ppp is position identifier and eee is element identifier')
               return
            }

            dcsTagsToEdit.push({
               value: newTag.value,
               position,
               id: tagId,
               isWritable,
               ...(newTag.value && {
                  alarmMin: newTag.alarmMin,
                  alarmMax: newTag.alarmMax,
               })
            })
         }
      }

      setLoading(true)
      DataService
         .editDCSTags(site, tool, dcsTagsToEdit)
         .then(() => {
            // Fetch new tags and replace old ones
            refetchTags()
         })
         .catch((err) => {
            console.error(err)
            onError('Failed to save DCS Tags')
            setLoading(false)
         })
   }

   const positionList = () => {
      let first = true
      return toolsPositions.map((position) => {
         if (position.unit === unit) {
            const item = tagsForPosition(position, first)
            first = false
            return item
         } return null
      })
   }

   return (
      <div style={{ padding: '24px', overflowY: 'auto', height: `calc(${height}px - 52px)` }}>
         { editedDCSTags && positionList()}
         { isEditing
            && <span>
               <Button
                  title="Cancel"
                  disabled={isLoading}
                  onClick={() => setEditing(false)}
               />
               <Button
                  className="image-settings-section__btn-save"
                  title="Save"
                  styleType={Button.StyleType.PRIMARY}
                  disabled={isLoading}
                  onClick={save}
               />
            </span>
         }
      </div>
   )
}
