import { selectSite } from '@ava/react-common/store/actions/site-actions'
import { selectSelectedSite, selectSites } from '@ava/react-common/store/reducers/root-reducer'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './mobile-site-selector.css'

class MobileSiteSelector extends Component {

   render() {
      const { selectedSite, sites } = this.props

      return (
         <div className="mobile-site-selector">
            { sites
               && <div>
                  <h2>Site</h2>
                  <div id="select" className="dropdown">
                     <select
                        value={selectedSite ? selectedSite.id : ''}
                        onChange={(event) => this.props.selectSite(event.currentTarget.value)
                           .then(() => {
                              localStorage.setItem('currentSite', event.currentTarget.value)
                           })
                           .catch((err) => {
                              // eslint-disable-next-line no-alert
                              alert(err) // TODO: Show dialog component instead of alerting
                              localStorage.removeItem('currentSite')
                           })
                        }
                     >
                        { sites.map((site) => (<option
                           key={site.id}
                           value={site.id}
                        >{site.name}</option>)
                        )}
                     </select>
                     <button
                        id="dLabel"
                        className="flex-button"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <h3>{ selectedSite ? selectedSite.name : 'Select site' }</h3>
                        <div className="arrow-down" />
                     </button>
                  </div>
                  <div className="cell-separator" />
               </div>
            }
         </div>
      )
   }

}

const mapStateToProps = (state) => ({
   selectedSite: selectSelectedSite(state),
   sites: selectSites(state),
})

export default connect(mapStateToProps, { selectSite })(MobileSiteSelector)
