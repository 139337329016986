import { CLEAR_ONGOING_ANALYSIS, SET_ONGOING_ANALYSIS } from './types'


/**
 * @param {{
      socketId: string,
   }} ongoingAnalysis
 */
export function setOngoingAnalysis(ongoingAnalysis) {
   return {
      type: SET_ONGOING_ANALYSIS,
      payload: ongoingAnalysis,
   }
}

export function clearOngoingAnalysis() {
   return {
      type: CLEAR_ONGOING_ANALYSIS,
   }
}
