import UserService from '@ava/react-common/services/user-service'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { addResultComponentModel } from '@ava/react-common/store/actions/result-component-actions'
import { setUserToolConfig } from '@ava/react-common/store/actions/user-tool-config-actions'
import { selectSelectedTool } from '@ava/react-common/store/reducers/root-reducer'
import { errorToMessage } from '@ava/react-common/utils'
import { generateRandomStringId, getLanguageString } from '@ava/react-common/utils/Helper'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chart from '../../Charts/chart'
import Input from '../../Input/input'
import Modal from '../../Modal/modal'
import Toggle from '../../Toggle/toggle'

/**
 * @param {object} props
 * @param {() => void} props.onClose
 * @returns {React.FunctionComponentElement}
 */
export default function CreateChartModal({ onClose }) {
   const [title, setTitle] = useState('')
   const [elements, setElements] = useState({})
   const [unit, setUnit] = useState(undefined)
   const [error, setError] = useState(undefined)
   const selectedTool = useSelector((state) => selectSelectedTool(state))
   const userToolConfig = useSelector((state) => state.userToolConfig)
   const user = useSelector((state) => state.user)
   const dispatch = useDispatch()

   // TODO: Add versions to result model (currently only 0.0 in use)
   const resultModel = selectedTool.resultModels[0]
   const resultModelElements = resultModel.elements

   const savePressed = () => {
      setError(undefined)
      // Validate
      const trimmedTitle = title?.trim()
      if (!trimmedTitle || trimmedTitle === '') return setError('Title is Required')
      let noElements = true
      Object.values(elements).forEach((exist) => {
         if (exist) noElements = false
      })
      if (noElements) return setError('Select elements')
      if (!unit) return setError('Failed to add chart')

      // Create model
      const e = []
      Object.keys(elements).forEach((key) => {
         if (elements[key]) e.push(key)
      })

      // TODO: Get chart type from somewhere else than unit
      const defaultType = (unit === 'status')
         ? Chart.Type.BOOLEAN
         : Chart.Type.LINE

      const newChart = {
         id: generateRandomStringId(),
         unit: (unit === '_undefined') ? null : unit,
         defaultType,
         types: [defaultType],
         title: trimmedTitle,
         isVisible: true,
         isAutoscaling: true,
         elements: e,
         scale: { min: 0, max: 100 },
      }

      UserService
         .editUserToolConfig(
            userToolConfig,
            { chartsToAdd: [newChart] },
            selectedTool.id,
            user.id,
            (newConfig) => {
               const model = UserService.userChartConfigToResultComponentModel(newChart)
               dispatch(setUserToolConfig(newConfig))
               dispatch(addResultComponentModel(model))
               onClose()
            }
         )
         .catch((err) => {
            dispatch(showDialog({
               title: 'Failed to update User Tool Config',
               text: errorToMessage(err),
            }))
         })
   }

   return (
      <Modal
         title="New Chart"
         width="500px"
         onClose={onClose}
         primaryBtnTitle="Done"
         primaryBtnPressed={savePressed}
         secondaryBtnTitle="Cancel"
         error={error}
      >
         <p style={{ margin: 0, fontWeight: '400' }}>Title:</p>
         <Input
            value={title}
            title="Title"
            onChange={((value) => setTitle(value))}
         />

         <p style={{ marginTop: '20px', marginBottom: '8px', fontWeight: '400' }}>Elements:</p>
         { Object.keys(resultModelElements).map((key) => {
            const element = resultModelElements[key]
            const name = getLanguageString(element.name)
            const elementUnit = (element.convertedUnit || element.unit)
            const title = name + (elementUnit ? ` (${elementUnit})` : '')
            const disabled = (unit && (unit === '_undefined' ? elementUnit != null : (unit !== elementUnit)))
            return (
               <div key={key} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                  <p style={{ marginBottom: 0, opacity: (disabled ? 0.5 : 1) }}>{title}</p>
                  <Toggle
                     isOn={!!elements[key]}
                     onChange={(on) => {
                        // Set unit
                        if (on) {
                           const selectedUnit = elementUnit || '_undefined'
                           setUnit(selectedUnit)
                        } else {
                           let noElements = true
                           Object.keys(elements).forEach((k) => {
                              const exist = elements[k]
                              if (exist && key !== k) noElements = false
                           })
                           if (noElements) setUnit(undefined)
                        }
                        // Set elements
                        const copy = { ...elements }
                        copy[key] = on
                        setElements(copy)
                     }}
                     disabled={disabled}
                  />
               </div>
            )
         })

         }
      </Modal>
   )
}
