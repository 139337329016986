import { io } from 'socket.io-client'
import SocketIOService from '../services/socket-io-service'
import Analysis from './analysis'


const EVENT__CONTINUOUS_ANALYSES_STATUS = 'status'
const EVENT__NEW_ANALYSES = 'analysis'

/**
 * @typedef {{ unit?: string, tool?: string }} Filter
 */

export default class SocketSiteNamespace {

   /**
    * @param {string} siteId
    * @param {Filter} [filter]
    */
   constructor(siteId, filter) {
      if (!SocketIOService.socket) throw new Error('SocketIOService must be initialized first')

      this.socket = io(`${SocketIOService.baseURL}/sites/${siteId}`, SocketIOService.connectOptions)
      this.filter = filter

      let socketId
      this.socket.io.on('open', () => console.debug('Socket SiteNamespace opened'))
      this.socket.io.on('error', (error) => console.debug('Socket SiteNamespace error', { socketId, error }))
      this.socket.io.on('reconnect', () => console.debug('Socket SiteNamespace reconnected'))
      this.socket.io.on('close', () => console.debug('Socket SiteNamespace closed'))
      this.socket.on('connect', () => {
         socketId = this.socket.id
         console.debug('Socket SiteNamespace connected', { socketId })
      })
      this.socket.on('connect_error', (error) => console.debug('Socket SiteNamespace failed to connect connected', { error }))
      this.socket.on('disconnect', () => console.debug('Socket SiteNamespace disconnected', { socketId }))

      this.connectionListenerUnsubFuncs = []
   }

   /**
    * @param {object} [events]
    * @param {() => void} [events.onConnected]
    * @param {() => void} [events.onTimeout]
    * @param {(err) => void} [events.onError]
    * @returns {() => void} Unsubscribe
    */
   connectWebSocket({ onConnected, onTimeout, onError } = {}) {
      const unsub = SocketIOService.connectWebSocket(this.socket, {
         onConnected: (wasConnectedAlready) => {
            if (!wasConnectedAlready && this.filter) this.emitAnalysisFilter()
            onConnected?.()
         },
         onReconnect: () => {
            if (this.filter) this.emitAnalysisFilter()
         },
         onTimeout,
         onError,
      })
      this.connectionListenerUnsubFuncs.push(unsub)
      return unsub
   }


   /**
    * @param {Filter} filter
    */
   setFilter(filter) {
      if (this.filter?.tool === filter.tool && this.filter?.unit === filter.unit) return
      this.clearSiteNspFilter()
      this.filter = filter
      if (this.socket.connected) this.emitAnalysisFilter()
   }

   /** @private */
   emitAnalysisFilter() {
      this.socket.emit('add analysis filter', this.filter)
      console.debug('Socket SiteNamespace analysis filters set', { filters: this.filter })
   }

   clearSiteNspFilter() {
      if (this.filter && this.socket.connected) {
         this.socket.emit('remove analysis filter', this.filter)
         console.debug('Socket SiteNamespace analysis filters removed', { filters: this.filter })
      }
      this.filter = undefined
   }


   /**
    * @param {((error?: object, analysis?: Analysis) => void)} callback Error can only exist when user filter is used
    * @returns {{
    *   unsubscribe: () => void,
    *   onConnectedPromise: Promise,
    * }} Unsubscribe - onConnectedPromise throws "cancelled" error message when unsubscribed if it is still connecting. throws "timeout" if timeout while connecting socket io.
    */
   subscribeToNewAnalyses(callback) {
      let unsubFromConnection
      let wsConnectedPromiseReject
      let unsubFromWSAnalyses

      const cb = (result) => {
         if (result.error) return callback(result.error)
         const analysis = Analysis.fromJson(result.analysis)
         callback(null, analysis)
      }

      const onConnectedPromise = new Promise((resolve, reject) => {
         unsubFromConnection = this.connectWebSocket({
            onConnected: () => {
               SocketIOService.sockets[this.socket.id] = this.socket
               wsConnectedPromiseReject = undefined
               unsubFromWSAnalyses = SocketIOService.subscribeToEvent(this.socket, EVENT__NEW_ANALYSES, cb)
               resolve()
            },
            onTimeout: () => {
               reject(new Error('timeout'))
               wsConnectedPromiseReject = undefined
            },
            onError: reject,
         })
         wsConnectedPromiseReject = reject
      })


      const unsubscribe = () => {
         unsubFromConnection()
         unsubFromWSAnalyses?.()
         wsConnectedPromiseReject?.(new Error('cancelled'))
      }
      return {
         unsubscribe,
         onConnectedPromise,
      }
   }


   /**
    * @callback ContinuousAnalysisStateCallback
    * @param {object} props
    * @param {string} props.status
    * @param {string} props.unit
    * @param {string} props.tool
    * @param {string} props.position
    * @param {string} props.exception
    * @param {string} props.keepRunning
    * @returns {void}
    */
   /**
    * Subscribe to continuous analysis state changes
    * @param {ContinuousAnalysisStateCallback} callback
    * @returns {() => void} Unsubscribe
    */
   subscribeToContinuousAnalysisState(callback) {
      let unsubEventListener
      const unsubConnectListener = this.connectWebSocket({
         onConnected: () => {
            unsubEventListener = SocketIOService.subscribeToEvent(this.socket, EVENT__CONTINUOUS_ANALYSES_STATUS, callback)
         },
      })
      return () => {
         unsubConnectListener()
         unsubEventListener?.()
      }
   }


   /**
    * Removes all subscriptions and closes
    */
   clean() {
      delete SocketIOService.sockets[this.socket.id]
      delete SocketIOService.numberOfSubscriptions[this.socket.id]
      this.connectionListenerUnsubFuncs.forEach((unsub) => unsub())
      this.connectionListenerUnsubFuncs = []
      this.socket.close()
      this.socket = undefined
   }

}
