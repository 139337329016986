import { SET_TOOLBOXES, SET_TOOLS } from './types'


export function setToolboxes(toolboxes) {
   return {
      type: SET_TOOLBOXES,
      payload: toolboxes,
   }
}

export function setTools(tools) {
   return {
      type: SET_TOOLS,
      payload: tools,
   }
}
