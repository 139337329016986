import DataService from '@ava/react-common/services/data-service'
import { editAnalysis } from '@ava/react-common/store/actions/data-actions'
import { selectToolsAnalyses, selectToolsTags, selectToolsUnits } from '@ava/react-common/store/reducers/root-reducer'
import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from '../../Dialog/dialog'
import Modal from '../../Modal/modal'
import ReactSelect from '../../ReactSelect/react-select'
import './edit-modal.css'


class EditModal extends Component {

   static propTypes = {
      analysisId: propTypes.string.isRequired,
   }

   constructor(props) {
      super(props)

      this.state = {
         comment: '',
         tags: [],
         unit: null,
         isLoading: false,
         dialogMessage: null,
      }

      this.containsHasImagesTag = false

      this.unitOptions = this.props.units.map((unit) => ({ value: unit.id, label: unit.name }))
      this.tagOptions = this.props.tags
         .map((tag) => ({ value: tag.id, label: tag.name }))
         .filter((tag) => tag.value !== `${this.props.toolId}_has_images`) // Do not add #has_images tag to options (#has_images tag is generated in client and not saved to db)
   }

   componentDidMount() {
      const analysis = this.props.analyses.find((a) => a.id === this.props.analysisId)
      const comment = analysis.comment || ''
      const unit = this.unitOptions.find((unit) => unit.value === analysis.unit)
      const tags = this.tagOptions.filter((tag) => (analysis.tags.includes(tag.value) && tag.value !== `${this.props.toolId}_has_images`)) // Filter out #has_images tag
      this.setState({ comment, tags, unit })
      this.containsHasImagesTag = analysis.tags.includes(`${this.props.toolId}_has_images`)
   }


   editingArea = () => (
      <div className="edit-area">

         <h4>Processing unit</h4>
         <ReactSelect
            options={this.unitOptions}
            value={this.state.unit}
            onChange={(unit) => this.setState({ unit })
            }
         />
         <br />

         <h4>Tags</h4>
         <ReactSelect
            value={this.state.tags}
            onChange={(tags) => this.setState({ tags })}
            isMulti={true}
            options={this.tagOptions}
         />
         <br />
         <br />

         <h4>Comment</h4>
         <textarea
            id="comment-area"
            onChange={() => this.setState({ comment: (document.getElementById('comment-area').value) })}
            value={(this.state.comment)}
            name="comment"
            cols="40"
            rows="4"
         />
         <br />
      </div>
   )



   savePressed = () => {
      // Validation
      if (!this.state.unit) {
         this.setState({ dialogMessage: 'Select processing unit' })
         return
      }
      this.setState({ isLoading: true })
      const tags = this.state.tags ? this.state.tags.map((tag) => tag.value) : []

      DataService.editAnalysis(this.props.analysisId, this.state.comment, tags, this.state.unit.value, this.props.toolId)
         .then((data) => {
            const modifiedTags = data.tags || []
            if (this.containsHasImagesTag) modifiedTags.push(`${this.props.toolId}_has_images`) // To prevent #has_images tag from disappearing after editing
            this.props.editAnalysis(this.props.toolId, data.id, modifiedTags, data.unit, data.comment)
            this.props.onClose()
         })
         .catch(() => {
            this.setState({ dialogMessage: 'Editing failed' })
            this.setState({ isLoading: false })
         })
   }



   render() {

      const { onClose } = this.props
      const { isLoading, dialogMessage } = this.state

      return (
         <Modal
            title="Add files"
            disabled={isLoading}
            primaryBtnTitle={'SAVE'}
            secondaryBtnTitle={'CANCEL'}
            primaryBtnPressed={this.savePressed}
            onClose={onClose}
            height="600px"
            width="800px"
         >
            <div className="edit-modal">
               {this.editingArea()}
            </div>

            <Dialog
               visible={dialogMessage}
               title="Alert"
               message={dialogMessage}
               buttons={[{ text: 'OK' }]}
               onClose={() => this.setState({ dialogMessage: null })
               }
            />

         </Modal>
      )
   }

}



const mapStateToProps = (state) => ({
   // siteId: state.selectedSite,
   toolId: state.selectedTool,
   tags: selectToolsTags(state),
   units: selectToolsUnits(state),
   analyses: selectToolsAnalyses(state),
})

export default connect(mapStateToProps, { editAnalysis })(EditModal)
