import React from 'react'
import { Link } from 'react-router-dom'


/**
 * @param {{
 *    to: string,
 *    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {},
 *    disabled?: boolean,
 *    children?: import('react').ReactNode,
 * }} props
 * @returns {import('react').FunctionComponentElement}
 */
export default function RouterLink({ to, onClick, disabled, children }) {
   return (
      <Link
         to={to}
         style={{ pointerEvents: disabled ? 'none' : undefined }}
         onClick={(e) => {
            if (disabled) e.preventDefault()
            else if (onClick) onClick(e)
         }}
      >
         { children }
      </Link>
   )
}
