import { CLEAR_DATA, START_LIVE_UPDATE, STOP_LIVE_UPDATE } from '../actions/types'

const initialState = null

const liveUpdateReducer = (state = initialState, action) => {
   switch (action.type) {

      case START_LIVE_UPDATE:
         return {
            tool: action.payload.tool,
         }

      case STOP_LIVE_UPDATE:
      case CLEAR_DATA:
         return initialState

      default:
         return state

   }
}

export default liveUpdateReducer
