import { useEffectWhen, useMemoValue, usePrevious, useResize } from '@ava/react-common/hooks'
import { hexToRgb } from '@ava/react-common/utils'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'


/*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
|                        RAIL                        |
\*__________________________________________________*/

const railOuterStyle = {
   position: 'absolute',
   width: '100%',
   height: 34,
   transform: 'translate(0%, calc(-50% + 3px))',
}

const railInnerStyle = {
   position: 'absolute',
   width: '100%',
   height: 28,
   transform: 'translate(0%, -50%)',
   borderRadius: 0,
   pointerEvents: 'none',
   backgroundColor: 'rgb(200,200,200)',
   boxShadow: '-4px 0px 0px 0px rgb(200, 200, 200), 4px 0px 0px 0px rgb(200, 200, 200)',
}

/**
 * @param {{
 *   onValueSelected?: (value: number) => void,
 *   domain?: [number, number],
 *   dataPoints: { data: number[], color: string }[],
 *   onBeingChanged?: (isBeingChanged: boolean) => void,
 * }} props
 * @returns {import('react').FunctionComponentElement}
 */
export function SliderRail(props) {
   const { onValueSelected, dataPoints } = props
   const domain = useMemoValue(props.domain)

   const refRail = useRef()

   const [canvas] = useState(document.createElement('canvas'))
   const [imageURL, setImageURL] = useState('')
   const { width, height } = useResize(refRail)

   useEffect(() => {
      if (width === undefined || !domain) return
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      const canvasData = ctx.getImageData(0, 0, width, height)

      const difference = +domain[1] - +domain[0]

      const drawPixel = (x, y, r, g, b, a = 255) => {
         const index = (x + y * width) * 4
         canvasData.data[index + 0] = r
         canvasData.data[index + 1] = g
         canvasData.data[index + 2] = b
         canvasData.data[index + 3] = a
      }

      const updateCanvas = () => {
         ctx.putImageData(canvasData, 0, 0)
      }

      const lineHeight = (28 - 1) / dataPoints.length // (height - end padding) / rows

      dataPoints.forEach((dataPoints, i) => {
         const { r, g, b } = hexToRgb(dataPoints.color)

         dataPoints.data.forEach((item) => {
            const percentage = 1 - (+domain[1] - item) / difference
            if (percentage < 0 || percentage > 1) return

            const x = Math.round((width - 1) * percentage)
            const yStart = Math.floor(1 + i * lineHeight) // start padding + row * line height
            const yEnd = Math.floor((i + 1) * lineHeight)

            for (let y = yStart; y < yEnd; ++y) {
               drawPixel(x, y, r, g, b, 200)
            }
         })
      })

      updateCanvas()
      const dataURL = canvas.toDataURL()
      setImageURL(dataURL)

   }, [canvas, width, height, dataPoints, domain])

   const onMouseDown = useDrag('rail', domain, ({ value }) => {
      onValueSelected(value)
   }, props.onBeingChanged)

   return (
      <>
         <div
            style={railOuterStyle}
            onMouseDown={onMouseDown}
         />
         <div style={railInnerStyle} ref={refRail} />
         { imageURL && <img
            style={railInnerStyle}
            src={imageURL}
            alt=""
         />}
      </>
   )
}

SliderRail.propTypes = {
   dataPoints: PropTypes.arrayOf(PropTypes.object),
}


/**
 * @param {{
 *   timeframe?: [number, number],
 *   onValueSelected?: (value: number) => void,
 *   format?: (time: number) => string,
 *   onBeingChanged?: (isBeingChanged: boolean) => void,
 * }} props
 * @returns {import('react').FunctionComponentElement}
 */
export function ZoomSliderRail({ timeframe, onValueSelected, onBeingChanged }) {

   const onMouseDown = useDrag('rail', timeframe, ({ value }) => {
      if (onValueSelected) onValueSelected(value)
   }, onBeingChanged)

   return (
      <div
         style={{
            position: 'absolute',
            width: '100%',
            height: 6,
            transform: 'translate(0%, -50%)',
            borderRadius: 0,
            backgroundColor: 'rgb(120,120,120)',
         }}
         onMouseDown={onMouseDown}
      />
   )
}


/*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
|                       HANDLES                      |
\*__________________________________________________*/

/**
 * @param {object} props
 * @param {[min: number, max: number]} props.domain
 * @param {number} props.selectedValue
 * @param {(val: number) => void} props.onSelectionChange
 * @param {(disabled: boolean) => import('react').ReactNode} props.getHandleElement
 * @param {(isBeingChanged: boolean) => void} [props.onBeingChanged]
 * @param {boolean} [props.disabled]
 * @returns {import('react').FunctionComponentElement}
 */
export function Handle({
   domain: [min, max],
   selectedValue,
   onSelectionChange,
   onBeingChanged,
   getHandleElement,
   disabled,
}) {

   const onMouseDown = useDrag('handle', [min, max], ({ valueDragged }) => {

      let newSelected = selectedValue + valueDragged
      if (newSelected > max) newSelected = max
      else if (newSelected < min) newSelected = min
      onSelectionChange(newSelected)
   }, onBeingChanged)

   const percent = (((selectedValue - min) / (max - min)) * 100)

   return (
      <div
         role="slider"
         aria-valuemin={min}
         aria-valuemax={max}
         aria-valuenow={selectedValue}
         style={{
            paddingTop: 2,
            paddingBottom: 2,
            left: `${percent}%`,
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
            zIndex: 3,
         }}
         onMouseDown={!disabled && onMouseDown}
      >
         <div style={{
            pointerEvents: 'none',
         }}>
            {getHandleElement(disabled)}
         </div>
      </div>
   )
}


/**
 * @param {object} props
 * @param {[min: number, max: number]} props.domain
 * @param {[domainSelectedFrom: number, domainSelectedTo: number]} props.domainSelected
 * @param {([min: number, max: number]: selection) => void} props.onSelectionChange
 * @param {(isBeingChanged: boolean) => void} props.onBeingChanged
 * @param {(pos: 'left'|'right', disabled: boolean) => import('react').ReactNode} props.getHandleElement
 * @param {React.CSSProperties} [props.trackStyle]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.isTrackEnabled]
 * @param {boolean} [props.areHandlesBehindTrack]
 * @returns {import('react').FunctionComponentElement}
 */
export function Handles({
   domain,
   onSelectionChange,
   onBeingChanged,
   domainSelected,
   getHandleElement,
   trackStyle,
   disabled = false,
   isTrackEnabled = true,
   areHandlesBehindTrack = false,
}) {
   const [min, max] = domain
   const [domainSelectedFrom, domainSelectedTo] = domainSelected
   const [isHandlesOrderFlipped, setHandlesOrderFlipped] = useState(false)

   const onMouseDown = useDrag('handle', [min, max], ({ valueDragged, draggedElementId }) => {
      const position = ((draggedElementId === 'zoom-handles--first' && !isHandlesOrderFlipped) || (draggedElementId === 'zoom-handles--second' && isHandlesOrderFlipped))
         ? 'left'
         : 'right'

      let newSelected
      if (position === 'left') newSelected = domainSelectedFrom + valueDragged
      else newSelected = domainSelectedTo + valueDragged

      if (newSelected > max) newSelected = max
      else if (newSelected < min) newSelected = min

      let val
      if (position === 'left') val = [newSelected, domainSelectedTo]
      else val = [domainSelectedFrom, newSelected]

      if (val[0] > val[1] && !isHandlesOrderFlipped) {
         setHandlesOrderFlipped(true)
         val = [val[1], val[0]]
      } else if (val[0] > val[1] && isHandlesOrderFlipped) {
         setHandlesOrderFlipped(false)
         val = [val[1], val[0]]
      }

      // Clamp handles to start/end if start/end less than 0.0001% away from handle value
      if ((val[0] - min) / (max - min) < 0.0001) val[0] = min
      if ((max - val[1]) / (max - min) < 0.0001) val[1] = max

      if (val[1] === min) ++val[1]
      if (val[0] === max) --val[0]

      onSelectionChange(val)
   }, onBeingChanged)

   return (
      <>
         {['zoom-handles--first', 'zoom-handles--second'].map((id) => {
            const isZoomHandleFirst = (id === 'zoom-handles--first')
            const isLeft = ((isZoomHandleFirst && !isHandlesOrderFlipped) || (!isZoomHandleFirst && isHandlesOrderFlipped))

            const value = (isLeft)
               ? domainSelectedFrom
               : domainSelectedTo

            const percent = (isLeft)
               ? (((domainSelectedFrom - min) / (max - min)) * 100)
               : (((domainSelectedTo - min) / (max - min)) * 100)

            return (
               <div key={id}>
                  <div
                     id={id}
                     role="slider"
                     aria-valuemin={min}
                     aria-valuemax={max}
                     aria-valuenow={value}
                     style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        left: `${percent}%`,
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        cursor: 'pointer',
                        zIndex: areHandlesBehindTrack ? 1 : 3,
                     }}
                     onMouseDown={!disabled && onMouseDown}
                  >
                     <div style={{
                        position: 'relative',
                        pointerEvents: 'none',
                     }}>
                        { getHandleElement(isLeft ? 'left' : 'right', disabled) }
                     </div>
                  </div>
               </div>
            )
         })}

         { isTrackEnabled
         && <Track
            domain={domain}
            domainSelected={domainSelected}
            onScrollValue={onSelectionChange}
            style={trackStyle}
            onBeingChanged={onBeingChanged}
         />
         }
      </>
   )
}


/*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
|                   TRACK COMPONENT                  |
\*__________________________________________________*/

/**
 * @param {object} props
 * @param {[domainFrom: number, domainTo: number]} props.domain
 * @param {[domainSelectedFrom: number, domainSelectedTo: number]} props.domainSelected
 * @param {[newSelectedFrom: number, newSelectedTo: number]} props.onScrollValue
 * @param {(isBeingChanged: boolean) => void} [props.onBeingChanged]
 * @param {React.CSSProperties} [props.style]
 * @param {boolean} [props.disabled]
 * @returns {import('react').FunctionComponentElement}
 */
export function Track({ domain, domainSelected, onScrollValue, onBeingChanged, style, disabled }) {
   const [domainFrom, domainTo] = domain
   const [domainSelectedFrom, domainSelectedTo] = domainSelected

   const percentStart = (((+domainSelectedFrom - domainFrom) / (domainTo - domainFrom)) * 100)
   const percentEnd = (((+domainSelectedTo - domainFrom) / (domainTo - domainFrom)) * 100)

   const onMouseDown = useDrag('track', domain, ({ valueDragged }) => {
      let newSelectedFrom = +domainSelectedFrom + valueDragged
      let newSelectedTo = +domainSelectedTo + valueDragged
      if (newSelectedFrom < +domainFrom) {
         newSelectedTo = +domainSelectedTo + (domainFrom - domainSelectedFrom)
         newSelectedFrom = domainFrom
      } else if (newSelectedTo > +domainTo) {
         newSelectedFrom = +domainSelectedFrom + (domainTo - domainSelectedTo)
         newSelectedTo = domainTo
      }
      onScrollValue([
         newSelectedFrom,
         newSelectedTo,
      ])
   }, onBeingChanged)

   return (
      <div
         style={{
            position: 'absolute',
            transform: 'translate(0%, -50%)',
            height: 28,
            zIndex: 2,
            backgroundColor: disabled ? '#999' : '#0075be',
            borderBottom: '1px solid #0075be',
            borderTop: '1px solid #0075be',
            borderRadius: 0,
            cursor: 'pointer',
            left: `${percentStart}%`,
            width: `${percentEnd - percentStart}%`,
            ...style,
         }}
         onMouseDown={!disabled && onMouseDown}
      />
   )
}



/*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
|                   TICK COMPONENT                   |
\*__________________________________________________*/

/**
 * @typedef {object} Tick
 * @property {string} id
 * @property {number} value
 * @property {number} percent
 */
/**
 * @param {object} props
 * @param {Tick} props.tick
 * @param {number} props.count
 * @param {(tickValue: number) => void} [props.format]
 * @returns {import('react').FunctionComponentElement}
 */
export function Tick({ tick, count, format }) {
   return (
      <div>
         <div
            style={{
               position: 'absolute',
               marginTop: 14,
               width: 1,
               height: 5,
               // backgroundColor: "rgb(100,100,100)",
               backgroundColor: 'rgb(200,200,200)',
               left: `${tick.percent}%`,
            }}
         />
         <div
            style={{
               position: 'absolute',
               marginTop: 22,
               fontSize: 10,
               textAlign: 'center',
               marginLeft: `${-(100 / count) / 2}%`,
               width: `${100 / count}%`,
               left: `${tick.percent}%`,
            }}
         >
            {format ? format(tick.value) : tick.value}
         </div>
      </div>
   )
}



/*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
|                        UTILS                       |
\*__________________________________________________*/

/**
 * @param {('handle'|'track'|'rail')} type
 * @param {[number, number]} domain
 * @param {(event: {
 *   value?: number,
 *   valueDragged?: number,
 *   draggedElementId?: string,
 * }) => void} callback - "rail" returns "value", others "valueDragged"
 * @param {(isBeingChanged: boolean) => void} [onBeingChanged]
 * @returns {*} onMouseDown
 */
function useDrag(type, domain, callback, onBeingChanged) {
   const [isMouseDown, setIsMouseDown] = useState(false)
   const [mouseDownElement, setMouseDownElement] = useState()
   const [railBoundingClientRect, setRailBoundingClientRect] = useState()
   const [mouseX, setMouseX] = useState()
   const prevMouseX = usePrevious(mouseX)

   const getValue = useCallback(() => {
      const x = mouseX - railBoundingClientRect.left
      const percentage = (x / railBoundingClientRect.width)
      const value = (domain[1] - domain[0]) * percentage + domain[0]
      callback({ value })
   }, [railBoundingClientRect, callback, domain, mouseX])

   // There will be "Maximum update depth exceeded" error if useCallack is removed or onBeingChanged is added as dependency
   // eslint-disable-next-line react-hooks/exhaustive-deps
   const onBeingChangedCb = useCallback(onBeingChanged, [])

   useEffect(() => {
      if (onBeingChangedCb) {
         if (isMouseDown) onBeingChangedCb(true)
         else onBeingChangedCb(false)
      }

      if (!isMouseDown) return
      const mouseupListener = () => {
         setIsMouseDown(false)
         setMouseDownElement(undefined)
      }
      const mousemoveListener = (e) => {
         if (e.clientX < railBoundingClientRect.left) setMouseX(railBoundingClientRect.left)
         else if (e.clientX > railBoundingClientRect.right) setMouseX(railBoundingClientRect.right)
         else setMouseX(e.clientX)
      }
      window.addEventListener('mouseup', mouseupListener)
      window.addEventListener('mousemove', mousemoveListener)
      return () => {
         setMouseX(undefined)
         window.removeEventListener('mouseup', mouseupListener)
         window.removeEventListener('mousemove', mousemoveListener)
      }
   }, [isMouseDown, railBoundingClientRect, onBeingChangedCb])

   useEffectWhen(() => {
      // RAIL
      if (type === 'rail' && mouseX !== undefined) {
         getValue()
         return
      }
      // HANDLE & TRACK
      if (mouseX === undefined || prevMouseX === undefined) return
      const mouseXMovement = (mouseX - prevMouseX)
      if (mouseXMovement === 0) return
      const value = (domain[1] - domain[0])
      const percentage = (mouseXMovement / railBoundingClientRect.width)
      callback({
         valueDragged: percentage * value,
         draggedElementId: mouseDownElement.id,
      })
   }, [mouseX, prevMouseX, railBoundingClientRect, mouseDownElement, type], [domain, callback, getValue])

   return (e) => {
      setRailBoundingClientRect(e.target.parentElement.getBoundingClientRect())
      setIsMouseDown(true)
      setMouseDownElement(e.target)
      if (type === 'handle') setMouseX(e.clientX + (e.target.getBoundingClientRect().right - e.clientX - e.target.getBoundingClientRect().width / 2)) // Snap handle to center of mouse
      else setMouseX(e.clientX)
   }
}
