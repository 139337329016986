import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import './react-select.css'

class ReactSelect extends Component {

   // Styling: https://react-select.com/styles

   static propTypes = {
      options: PropTypes.array.isRequired,
      isMulti: PropTypes.bool,
      onChange: PropTypes.func,
      value: PropTypes.any,
      disabled: PropTypes.bool,
      maxMenuHeight: PropTypes.number,
   }

   render() {
      const { options, isMulti, value, onChange, isMobile, disabled, maxMenuHeight } = this.props

      const styles = {
         control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? '#0075BE' : '#989898',
            boxShadow: 'none',
            ':hover': !state.isFocused && {
               borderColor: '#989898',
            },
         }),
         // multiValue: (styles) => {
         //    return {
         //      ...styles,
         //      backgroundColor: '#0075BE',
         //    };
         //  },
         // multiValueLabel: (styles) => ({
         //    ...styles,
         //    color: 'white',
         // }),
         multiValueRemove: (styles) => ({
            ...styles,
            color: '#989898',
            // backgroundColor: '#0075BE',
            ':hover': {
               backgroundColor: '#0075BE80',
               color: 'white',
               cursor: 'pointer',
            },
         }),
      }

      return (
         <Select
            className="react-select"
            isSearchable={!isMobile} // FIXME: Disabled in mobile devices since keyboard covers too much screen. Figure out a solution... (e.g. show keyboard on second tap)
            value={value}
            onChange={onChange}
            maxMenuHeight={maxMenuHeight}
            options={options}
            isMulti={isMulti}
            theme={(theme) => ({
               ...theme,
               borderRadius: 0,
               colors: {
                  ...theme.colors,
                  primary25: '#98989880',
                  primary: '#0075BE',
               },
            })}
            styles={styles}
            isDisabled={disabled}
         />
      )
   }

}

const mapStateToProps = (state) => ({
   isMobile: state.device.isMobile,
})

export default connect(mapStateToProps)(ReactSelect)
