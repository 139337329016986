import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './hoverable.scss'

/**
 * @param {object} props
 * @param {import('react').ReactNode} props.component
 * @param {import('react').ReactNode} [props.children]
 * @param {boolean} [props.disabled]
 * @returns {import('react').FunctionComponentElement}
 */
export default function Hoverable({ children, component, disabled }) {
   const [isHovering, setHovering] = useState(false)

   let timeout = null

   if (disabled) return children

   const onMouseOver = () => {
      timeout = setTimeout(() => {
         setHovering(true)
      }, 1000)
   }

   const onMouseLeave = () => {
      setHovering(false)
      if (timeout) clearTimeout(timeout)
      timeout = null
   }

   return (
      <div
         onMouseEnter={onMouseOver}
         onMouseLeave={onMouseLeave}
         className="hoverable"
      >
         { isHovering
         && <> {component} </>
         }
         {children}
      </div>
   )
}

Hoverable.propTypes = {
   component: PropTypes.object, // component that will be displayed when hovering
   disabled: PropTypes.bool,
}
