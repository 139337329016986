import Tool from '@ava/react-common/models/tool'
import { selectFilteredToolsElementsPositionIdsThatHaveAnalysisData, selectSelectedTool, selectToolsFilteredAnalyses } from '@ava/react-common/store/reducers/root-reducer'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ChartCard from '../ChartCard/chart-card'
import SectionTitle from '../SectionTitle/section-title'

class ChartCardsContainer extends Component {

   /**
    * Transforms combined element to format that chart can read.
    * (Element id references are transformed to element objects)
    * Single elements prop is set to charts so combinedElements are transformed to "element".
    * @param {*} combinedElement
    * @returns {import('react').FunctionComponentElement}
    */
   processCombinedElement = (combinedElement) => {
      const processedCE = { ...combinedElement }
      const elementIdsToReplaceWithElementObj = ['mean', 'standardDeviation']

      for (const key of elementIdsToReplaceWithElementObj) {
         const elementId = combinedElement[key]
         const element = {
            ...this.props.resultModel.elements[elementId],
            id: elementId,
         }
         if (element) processedCE[key] = element
         else console.error(`Requested element "${elementId}" is not defined in result model`)
      }
      return processedCE
   }

   render() {
      const { selectedTool, resultModel, filteredAnalyses, resultComponents, elementsPositionsWithData } = this.props // TODO: Add title when/if more than one units are rendered - currently only one unit is rendered

      if (!resultModel || !resultModel.elements || !resultComponents.models) return null

      const elements = resultModel.elements
      const combinedElements = resultModel.combinedElements || {}
      const components = resultComponents.models

      // Validate if enough analyses to render the chart (only analyses with result) (Show always if continuous)
      const requiredNumber = (selectedTool.config.type === Tool.Type.CONTINUOUS) ? 0 : 2
      const analysesWithResult = filteredAnalyses.filter((analysis) => {
         if (analysis.items) {
            const itemsWithResult = analysis.items.filter((item) => item.result?.elements)
            if (itemsWithResult.length > 0) return true
         }
         return false
      })
      if (requiredNumber > analysesWithResult.length) return null

      const data = []
      let first = true
      components.forEach((component) => {
         if (component.chart && component.elements) {

            // Skip if filtered
            if (!component.chart.isVisible) return

            // Skip if no data
            const isDataInChart = !!component.elements.find((element) => (elementsPositionsWithData[element]?.length > 0)) // TODO: doesn't support min nuber of "analyses" requirement (check "requiredNumber" validation in this component)
            if (!isDataInChart) return

            const chartElements = {}
            const chartElementsPositionsWithData = {}
            component.elements.forEach((e) => {
               if (elements[e]) chartElements[e] = elements[e]
               else if (combinedElements[e]) chartElements[e] = this.processCombinedElement(combinedElements[e])
               else return console.error('Requested element is not defined in result model')
               chartElementsPositionsWithData[e] = elementsPositionsWithData[e]
            })

            if (first && analysesWithResult.length > 1) { // Show title only if more than one analyses
               data.push(
                  <SectionTitle key="section-title" title="Charts" />
               )
               first = false
            }

            data.push(
               <ChartCard
                  key={`chart-${component.id}`}
                  component={component}
                  analyses={analysesWithResult}
                  elements={chartElements}
                  elementsPositionsWithData={chartElementsPositionsWithData}
               />
            )

         }
      })

      return data
   }

}


const mapStateToProps = (state) => {
   const selectedTool = selectSelectedTool(state)
   return {
      selectedTool,
      resultModel: selectedTool.resultModels[0], // TODO: use correct version instead of first (currently only one model)
      filteredAnalyses: selectToolsFilteredAnalyses(state),
      resultComponents: state.resultComponents,
      elementsPositionsWithData: selectFilteredToolsElementsPositionIdsThatHaveAnalysisData(state),
   }
}

export default connect(mapStateToProps)(ChartCardsContainer)
