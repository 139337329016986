import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'

/**
 * Memoizes objects and arrays and returns value only if its' content is changed
 * lodash isEqual
 * @param {*} value
 * @returns {*} memoized value
 */
export default function useMemoValue(value) {
   const [val, setVal] = useState(value)

   useEffect(() => {
      if (!isEqual(val, value)) setVal(value)
   }, [value])

   return val
}
