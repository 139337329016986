export default class User {

   /**
    * Create an User
    * @param {string} id UUID
    * @param {[User.Strategy]} strategies
    * @param {User.AccessLevel} accessLevel
    * @param {string} firstName
    * @param {string} lastName
    * @param {string} username
    * @param {User.Rules} rules
    */
   constructor(id, strategies, accessLevel, firstName, lastName, username, rules) {
      this.id = id
      this.strategies = strategies
      this.accessLevel = accessLevel
      this.firstName = firstName
      this.lastName = lastName
      this.username = username
      this.rules = rules
   }

   /**
    * Check if user has local auth strategy
    * @returns {boolean}
    */
   hasLocalStrategy() {
      return !!this.strategies.find((s) => (s === User.Strategy.LOCAL))
   }

   /**
    * Check if user is Admin
    * @returns {boolean}
    */
   hasAdminPriviledges() {
      return this.accessLevel === User.AccessLevel.ADMIN
   }

   /**
    * Check if user is Engineer or Admin
    * @returns {boolean}
    */
   hasEngineerPriviledges() {
      return (
         this.accessLevel === User.AccessLevel.ADMIN
         || this.accessLevel === User.AccessLevel.ENGINEER
      )
   }

   /**
    * Check if user is User, Engineer or Admin
    * @returns {boolean}
    */
   hasUserPriviledges() {
      return (
         this.accessLevel === User.AccessLevel.ADMIN
         || this.accessLevel === User.AccessLevel.ENGINEER
         || this.accessLevel === User.AccessLevel.USER
      )
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                       STATIC                       |
   \*__________________________________________________*/

   /**
    * @param {object[]} data
    * @returns {User[]}
    */
   static createUsersFromJson(data) {
      return data.map((userData) => User.userFromJson(userData))
   }

   static userFromJson(userData) {
      const rules = User.Rules.fromJson(userData.rules)
      if (!userData.strategies && userData.type) userData.strategies = [userData.type] // TODO: Remove after all instances are upgraded to new version
      return new User(userData.id, userData.strategies, userData.accessLevel, userData.firstName, userData.lastName, userData.username, rules)
   }


   /* For react select component */
   static getRoleOptions() {
      const roleOptions = [
         { value: User.AccessLevel.VIEWER, label: 'Viewer' },
         { value: User.AccessLevel.USER, label: 'User' },
         { value: User.AccessLevel.ENGINEER, label: 'Engineer' },
         { value: User.AccessLevel.ADMIN, label: 'Admin' },
      ]
      return roleOptions
   }

}


/**
 * Different Access Levels for validating if user has access to do certain things
 * @enum {string}
 */
User.AccessLevel = {
   ADMIN: 'admin',
   ENGINEER: 'engineer',
   USER: 'user',
   VIEWER: 'viewer',
}

/** @enum {string} */
User.Strategy = {
   LOCAL: 'local',
   AZURE_AD: 'azureAd',
}



/*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
|                                                    |
|                     USER RULES                     |
|                                                    |
\*__________________________________________________*/

/**
 * Class representing User Rules
 */
User.Rules = class Rules {

   /**
    * Create User Rules
    * @param {[string]} sites
    */
   constructor(sites) {
      this.sites = sites
   }

   /**
    * Creates Rules from json
    * @param {Object} json
    * @returns {User.Rules}
    */
   static fromJson(json) {
      const { sites } = json
      return new User.Rules(sites)
   }

}
