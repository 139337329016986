import { SET_LIVE_UPDATE_PAUSED } from '../actions/types'

/**
 * @typedef {object} LiveUpdatePaused
 * @property {boolean} isPaused
 * @property {string} dueToMessage
 */
/** @type {LiveUpdatePaused} */
const initialState = {
   isPaused: false,
   dueToMessage: null,
}

export default function liveUpdatePausedReducer(state = initialState, action) {

   if (action.type === SET_LIVE_UPDATE_PAUSED) {
      return action.payload
   }
   return state

}
