import React from 'react'
import './section-title.scss'

/**
 * @param {object} props
 * @param {string} props.title
 * @returns {import('react').FunctionComponentElement}
 */
export default function SectionTitle({ title }) {
   return (
      <h3 className="section-title">
         {title}
      </h3>
   )
}
