import React, { Component } from 'react'
import './loading-spinner.css'
import PropTypes from 'prop-types'

export default class LoadingSpinner extends Component {

   static propTypes = {
      progress: PropTypes.number,
      text: PropTypes.string,
   }

   constructor(props) {
      super(props)
      this.radius = 38
      this.stroke = 6
      this.normalizedRadius = this.radius - this.stroke * 2
      this.circumference = this.normalizedRadius * 2 * Math.PI
   }

   render() {
      const { text } = this.props
      const isSpinner = !this.props.progress
      const progress = this.props.progress || 25
      const strokeDashoffset = this.circumference - ((progress / 100) * this.circumference)

      return (
         <div className="loader">
            <div className={isSpinner ? 'loader-spinner' : 'loader-progress'}>
               <svg
                  height={this.radius * 2}
                  width={this.radius * 2}
               >
                  <circle
                     stroke="#0075BE"
                     fill="transparent"
                     strokeWidth={this.stroke}
                     strokeDasharray={`${this.circumference} ${this.circumference}`}
                     style={{ strokeDashoffset }}
                     r={this.normalizedRadius}
                     cx={this.radius}
                     cy={this.radius}
                  />
               </svg>
            </div>
            { text
               && <p className="loader-txt">{ text }</p>
            }
         </div>
      )
   }

}
