import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import Dropdown from '../Dropdown/dropdown'
import './dropdown-button.css'

export default class DropdownButton extends Component {


   static propTypes = {
      menuItems: PropTypes.array.isRequired,
      selected: PropTypes.any,
      onSelect: PropTypes.func.isRequired,
      alignRight: PropTypes.bool,
      isBlack: PropTypes.bool,
      className: PropTypes.string,
      disabled: PropTypes.bool,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      dropdownToButtonWidth: PropTypes.bool,
   }

   render() {

      const { title, subtitle, menuItems, selected, onSelect, alignRight, isBlack, className, disabled, dropdownToButtonWidth } = this.props

      return (
         <Dropdown
            button={
               <button className={classNames('dropdown-button', className, { black: isBlack })}>
                  <div className="dropdown-button-titles">
                     <p>{title}</p>
                     <p className="subtitle">{ subtitle }</p>
                  </div>
                  <i className="arrow-down" />
               </button>
            }
            disabled={disabled}
            menuItems={menuItems}
            selected={selected}
            onSelect={onSelect}
            alignRight={alignRight && true}
            dropdownToButtonWidth={dropdownToButtonWidth}
         />
      )
   }


}
