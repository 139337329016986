import Analysis from '@ava/react-common/models/analysis'
import UserService from '@ava/react-common/services/user-service'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { showLatestImages } from '@ava/react-common/store/actions/result-component-actions'
import { setUserToolConfig } from '@ava/react-common/store/actions/user-tool-config-actions'
import { selectSelectedTool, selectToolsAnalyses, selectToolsFilteredAnalyses, selectToolsPositions } from '@ava/react-common/store/reducers/root-reducer'
import { errorToMessage, useTypedSelector } from '@ava/react-common/utils'
import { dateToString, loadImage } from '@ava/react-common/utils/Helper'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Card from '../../Card/card'
import PieChart from '../../Charts/pie-chart'
import SectionTitle from '../SectionTitle/section-title'
import './latest-image-card.scss'


// TODO: Add react memo
/**
 * @returns {import('react').FunctionComponentElement}
 */
export default function LatestImagesCard() {

   const dispatch = useDispatch()
   const useSelector = useTypedSelector()

   const [isFullScreen, setFullScreen] = useState(false)
   const [latestAnalyses, setLatestAnalysis] = useState(null)

   const filteredAnalyses = useSelector((state) => selectToolsFilteredAnalyses(state))
   const analyses = useSelector((state) => selectToolsAnalyses(state))
   const unitFilters = useSelector((state) => state.unitFilters)
   const toolsPositions = useSelector((state) => selectToolsPositions(state))
   const positions = useSelector((state) => state.positions)
   const timezone = useSelector((state) => state.timeZone)
   const selectedToolId = useSelector((state) => selectSelectedTool(state).id)
   const userId = useSelector((state) => state.user.id)
   const userToolConfig = useSelector((state) => state.userToolConfig)


   useEffect(() => {
      const selectedUnits = Object.values(unitFilters).filter((filter) => !filter.exclude).map((filter) => filter.id)
      const unitsPositions = toolsPositions.filter((pos) => {
         if (selectedUnits.includes(pos.unit)) return true
         return false
      }).sort((a, b) => b.name - a.name)
      // Continuous analysis tool container analyses only one position at a time but all positions needs to be shown in HMI. Hence we need to group analyses so that LatestImageCard displayes latest result from all positions
      // TODO: Now since point analyses needs have option for continuous listening:
      const sortBy = 'dateAnalyzed'
      const withImage = filteredAnalyses.filter((analysis) => { // Only analyses with images
         let imageExists = false
         if (analysis.items && analysis.items.length > 0) {
            analysis.items.forEach((i) => {
               if (i.imageId) imageExists = true
            })
         }
         if (imageExists) return true
         return false
      }).sort((a, b) => ( // Latest first
         b[sortBy] - a[sortBy]
      ))


      // If analysis contains only single item (continuous analysis), find all positions from latest 10 analyses. If analysis contains more than one item, add only one analysis. Idea is to display only latest results items if not continuous analysis
      // TODO: Tarkasta!
      const itemsToShow = []
      let positionsInCard = []
      for (const position of unitsPositions) {
         if (positionsInCard.includes(position.id)) continue // Then if position has already been set
         let x = 1
         for (const analysis of withImage) {
            const analysisPositions = analysis.items.map((item) => item.position)

            // Check if analysis has item with position that is already displayed in other analysis
            let analysisHasExistingItem = false
            // eslint-disable-next-line no-loop-func
            analysisPositions.forEach((p) => {
               if (positionsInCard.includes(p)) analysisHasExistingItem = true
            })

            if (analysisPositions.includes(position.id) && !analysisHasExistingItem) { // Don't add analysis if any of the positions is already displayed
               positionsInCard = [...positionsInCard, ...analysisPositions]
               itemsToShow.push(analysis.id)
               break
            }
            if (x >= 10) break // Do not use more than 10 latest analyses
            x++
         }

      }
      setLatestAnalysis(itemsToShow)

   }, [filteredAnalyses, toolsPositions, unitFilters])

   if (!latestAnalyses || latestAnalyses.length === 0) return null

   const getPositionRows = (analysis) => {

      const analysisItemsSortedByPositionName = (analysis.items || []).sort((a, b) => {
         const strA = (positions[a.position]?.name || '').toLowerCase()
         const strB = (positions[b.position]?.name || '').toLowerCase()
         if (strA < strB) return -1
         if (strA > strB) return 1
         return 0
      })

      const positionRows = analysisItemsSortedByPositionName.map((item, index) => {
         const position = positions[item.position]
         const title = (position && position.name) && position.name

         const originalFileContentType = item?.imageMetadata?.contentType
         const originalFileType = originalFileContentType?.split('/')[0]
         const resultFileContentType = item.result?.imageMetadata?.contentType
         const resultFileType = resultFileContentType?.split('/')[0]

         let originalSrc
         if (item.imageId) {
            originalSrc = (isFullScreen || originalFileType === 'video')
               ? Analysis.getFileUrl(process.env.PUBLIC_URL, item.imageId)
               : Analysis.getFileThumbUrl(process.env.PUBLIC_URL, item.imageId)
         }
         let analyzedSrc
         if (item.result?.imageId) {
            analyzedSrc = (isFullScreen || resultFileType === 'video')
               ? Analysis.getFileUrl(process.env.PUBLIC_URL, item.result.imageId)
               : Analysis.getFileThumbUrl(process.env.PUBLIC_URL, item.result.imageId)
         }

         return (
            <div key={index}>
               {title
                  && <h4 style={{ color: '#989898', fontWeight: 300, fontSize: 18 }}>{title}</h4>
               }
               <div className={classNames('latest-images-card', { 'is-full-screen': isFullScreen })}>
                  { originalSrc
                     && <ImageBox
                        src={originalSrc}
                        type={originalFileType}
                        contentType={originalFileContentType}
                     />
                  }
                  { analyzedSrc
                     && <ImageBox
                        src={analyzedSrc}
                        type={resultFileType}
                        removeRightPadding={isFullScreen}
                        contentType={resultFileContentType}
                     />
                  }
               </div>
               <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <ResultRow item={item} />
               </div>
            </div>
         )
      })
      return positionRows
   }

   return (
      <>
         <SectionTitle
            key="latest-images"
            title="Latest images"
         />
         <Card
            onClose={() => {
               dispatch(showLatestImages(false))
               UserService
                  .editUserToolConfig(
                     userToolConfig,
                     { continuousAnalysis: { isLatestImagesCardVisible: false } },
                     selectedToolId,
                     userId,
                     (newConfig) => dispatch(setUserToolConfig(newConfig))
                  )
                  .catch((err) => {
                     dispatch(showDialog({
                        title: 'Failed to update User Tool Config',
                        text: errorToMessage(err),
                     }))
                  })
            }}
            isFullScreen={isFullScreen}
            onFullScreen={() => setFullScreen(!isFullScreen)}
         >
            <div style={isFullScreen ? { overflowY: 'auto', height: '100%' } : {}}>
               { latestAnalyses && latestAnalyses.map((id, index) => {
                  const analysis = analyses.find((a) => a.id === id)
                  if (!analysis) return null
                  return (
                     <div key={id}>
                        {(index > 0) && <hr />}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                           <h4 style={{ marginRight: '8px', marginBottom: 0 }}>Timestamp:</h4>
                           <p style={{ marginBottom: 0 }}>{dateToString(analysis.timestamp, timezone, true)}</p>
                        </div>
                        {/* <hr/> */}
                        {getPositionRows(analysis)}
                     </div>
                  )
               })}
            </div>
         </Card>
      </>
   )
}



const ResultRow = ({ item }) => {

   const useSelector = useTypedSelector()
   const resultModels = useSelector((state) => selectSelectedTool(state).resultModels)

   const resultModel = resultModels[0]
   if (!resultModel || !resultModel.elements || !resultModel.components) return null
   const elements = resultModel.elements
   const components = resultModel.components

   const content = []

   if (item.result && elements && components) {
      components.forEach((component, index) => {
         if (!component.elements) return
         // PIE CHART
         if (component.pieChart) {
            content.push(
               <PieChartBox
                  key={`${index}pie-chart-temp`}
                  component={component}
                  elements={elements}
                  item={item}
               />
            )
         }
         // COLUMN CHART - columnChart has been moved as separate chart - enable when this is needed
         // if (component.columnChart) {
         //    // Check if value (currently woodscan does not have diameter for every site)
         //    var resultsExist = false
         //    if (item.result && item.result.elements && component.elements && component.elements.length !== 0) {
         //       component.elements.forEach(key => {
         //          if (item.result.elements[key]) resultsExist = true
         //       })
         //    }

         //    if (resultsExist) {
         //       content.push(
         //          <Box maxWidth='50%' minWidth='500px' isMobile={false} key={"column-chart-box-" + index}>
         //             <ColumnChart
         //                height={230}
         //                modelElements={elements}
         //                resultElements={item.result && item.result.elements}
         //                component={component}
         //             />
         //          </Box>
         //       )
         //    }
         // }
      })
   }

   if (item.error || item.exception) {
      content.push(<ErrorBox item={item} />)
   }

   return content
}

// Duplicate code
const PieChartBox = ({ component, elements, item }) => {
   const chartElements = {}
   component.elements.forEach((e) => {
      if (elements[e]) {
         chartElements[e] = elements[e]
      } else {
         console.error('Requested element is not defined in result model')
      }
   })

   return (
      <Box maxWidth="412px" minWidth="220px">
         <PieChart
            item={item}
            elements={chartElements}
            animate={false}
         />
      </Box>
   )
}

// Duplicate code
const ErrorBox = ({ item }) => (
   <Box style={{ minWidth: 460 }}>
      { item.error && (
         <div className="flex-row" style={{ alignItems: 'flex-start', marginBottom: 12 }}>
            <p className="error-message-title">Error: </p>
            <p>{Analysis.getErrorMessage(item.error)}</p>
         </div>
      )}
      { item.exception && (
         <div className="flex-row" style={{ alignItems: 'flex-start' }}>
            <p className="error-message-title">Exception: </p>
            <p>{item.exception}</p>
         </div>
      )}
   </Box>
)



// ImageBox.propTypes = {
//    title: PropTypes.string,
//    item: PropTypes.object.isRequired,
//    src: PropTypes.string.isRequired,
//    isMobile: PropTypes.bool,
//    isResult: PropTypes.bool,
//    onClick: PropTypes.func.isRequired,
// }

const ImageBox = ({ src, type = 'image', removeRightPadding = false, contentType }) => {
   const [isImageLoaded, setImageLoaded] = useState(false)
   const [showVideo, setShowVideo] = useState(false)
   const [error, setError] = useState('')

   const onClick = useMemo(() => {
      if (type === 'video' && showVideo === false) {
         return () => setShowVideo(true)
      }
      return undefined
   }, [type, showVideo])

   useEffect(() => {
      if (type === 'video') return
      loadImage(src)
         .then(() => setImageLoaded(true))
         .catch(() => setError('Failed to load'))
   }, [src, type])

   return (
      <div style={{ paddingLeft: '8px', paddingRight: (removeRightPadding ? '0px' : '8px'), paddingBottom: '16px', flexBasis: 0, flexGrow: 1, maxWidth: '50%' }}>
         <div className="image-container"
            style={{
               cursor: onClick ? 'pointer' : undefined,
               width: '100%',
               height: '100%',
               backgroundColor: 'black',
            }}
            onClick={onClick}
         >
            {!!error && (
               <div style={{ color: '#ffffffaa', display: 'flex', alignItems: 'center', margin: 'auto' }}>{error}</div>
            )}
            {(type === 'image' && !error) && (
               <div className={classNames('image-holder', { 'image-holder--is-loaded': isImageLoaded })}
                  style={{
                     backgroundImage: `url(${src})`,
                  }}
               />
            )}
            {(type === 'video' && !error) && <>
               {(!showVideo) && (
                  <div
                     style={{
                        boxSizing: 'border-box',
                        margin: 'auto',
                        borderStyle: 'solid',
                        width: '40px',
                        height: '40px',
                        borderWidth: '24px 0px 24px 40px',
                        borderColor: 'transparent transparent transparent #fff',
                     }}
                  />
               )}
               {(showVideo) && (
                  <video
                     style={{ width: '100%', height: '100%' }}
                     autoPlay={true}
                     controls
                  >
                     <source src={src} type={contentType} />
                  </video>
               )}
            </>}
         </div>
      </div>
   )
}

const Box = ({ className, children, minWidth, maxWidth, style = {} }) => (
   <div className={className} style={{ paddingLeft: '8px', paddingRight: '8px', paddingBottom: '16px', flexBasis: 0, flexGrow: 1, minWidth, maxWidth, ...style }}>
      {/* <p style={{fontWeight: '400', height: '22px', marginBottom: 0}}>{title}</p> */}
      <div style={{ height: '100%' }}>
         {children}
      </div>
   </div>
)
