// import './page-user-managements.css'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Column, Table } from 'react-virtualized'


export default class VirtualizedTable extends Component {

   static propTypes = {
      rowHeigth: PropTypes.number,
      headerHeight: PropTypes.number,
      items: PropTypes.array.isRequired,
      columns: PropTypes.array.isRequired,
      initialSortBy: PropTypes.string,
      selectedItems: PropTypes.array.isRequired,
      onItemSelected: PropTypes.func.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
   }

   /*
      colums structure
      [
         {
            label: "String",
            dataKey: "string"
            width: Double,
            type: "string" or "number" (affects sorting),
            sortable: bool
         }
      ]
   */

   static defaultProps = {
      rowHeight: 30,
      headerHeight: 26,
   }

   constructor(props) {
      super(props)

      this.SortDirection = {
         ASC: 'ASC',
         DESC: 'DESC',
      }

      this.state = {

         selectedItem: undefined,
         isLoading: false,

         sortBy: this.props.initialSortBy,
         sortedList: [],
         sortDirection: this.SortDirection.DESC,

         actionOptions: [
            { value: 'edit', label: 'Edit' },
            { value: 'delete', label: 'Delete', type: 'danger', hidden: false },
         ],
      }
   }

   componentDidUpdate(prevProps) {
      const { sortBy, sortDirection } = this.state
      if (prevProps.items !== this.props.items) {
         this.sortList({ sortBy, sortDirection })
      }
   }

   componentDidMount() {
      this.setupView()
   }


   setupView = () => {
      const sortBy = this.props.initialSortBy
      const sortDirection = this.SortDirection.DESC

      this.setState({
         sortBy,
         sortDirection,
      }, this.sortList({ sortBy, sortDirection }))
   }



   // This function sorts the list and modifies it for the table
   sortList = ({ sortBy, sortDirection }) => {
      const { items, columns } = this.props

      // TODO: Add detection if numbre or string field

      // Sort by strings
      const rows = items.map((item) => item)
      rows.sort((a, b) => {
         const valA = a[sortBy]
         const valB = b[sortBy]
         const strA = valA ? valA.toUpperCase() : ''
         const strB = valB ? valB.toUpperCase() : ''

         if (strA < strB) {
            if (sortDirection === 'ASC') return 1
            return -1
         } if (strA > strB) {
            if (sortDirection === 'ASC') return -1
            return 1
         }
         return 0
      })

      const sortedRows = rows.map((item) => {
         const obj = {
            id: item.id,
         }
         columns.forEach((column) => {
            const dataKey = column.dataKey
            obj[dataKey] = item[dataKey]
         })
         return obj
      })

      this.setState({ sortedList: sortedRows, sortBy, sortDirection })
   }


   rowClassName = ({ index }) => {

      const { sortedList } = this.state
      const { selectedItems } = this.props

      if (index < 0) {
         return 'headerRow'
      }
      const rowItem = sortedList[index]
      const selected = selectedItems.includes(rowItem.id)

      let className = index % 2 === 0 ? 'evenRow' : 'oddRow'
      if (selected) className += ' selectedRow' // Hightlight selected rows
      if (rowItem.error) className += ' errorRow' // Red bg to error rows
      if (rowItem.warning) className += ' warningRow' // Yellow bg to warning rows

      return className

   }

   render() {
      const { rowHeight, headerHeight, columns, onItemSelected, width, height } = this.props
      const { sortBy, sortDirection, sortedList } = this.state

      //      const _selectedUser = selectedUser ? users.find(u => u.id === selectedUser) : undefined

      return (
         <div className="page-container">

            <Table
               width={width}
               height={height}
               headerHeight={headerHeight}
               rowHeight={rowHeight}
               rowClassName={this.rowClassName}
               headerClassName={'bottom-table-header'} // This is in bottom-table-menu.css. TODO: Move to here
               rowCount={sortedList.length}
               rowGetter={({ index }) => sortedList[index]}
               onRowClick={(item) => onItemSelected(item.rowData.id)}
               /* noRowsRenderer={this._noRowsRenderer}
                  overscanRowCount={overscanRowCount}
                  */
               sort={this.sortList}
               sortBy={sortBy}
               sortDirection={sortDirection}
            >
               { columns.map((column) => (
                  <Column
                     key={column.dataKey}
                     disableSort={!column.sortable}
                     dataKey={column.dataKey}
                     label={column.label}
                     width={column.width ? (column.width * width) : (1 / columns.length)}
                  />
               ))}
            </Table>

         </div>
      )
   }

}
