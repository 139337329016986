import React, { useEffect, useState } from 'react'
import DataService from '@ava/react-common/services/data-service'
import { dateToString } from '@ava/react-common/utils/Helper'
import { useSelector } from 'react-redux'
import Modal from '../Modal/modal'

/**
 * @param {object} props
 * @param {() => void} props.onClose
 * @returns {import('react').FunctionComponentElement}
 */
export default function InfoModal({ onClose }) {
   const timeZone = useSelector((state) => state.timeZone)
   const [info, setInfo] = useState(null)
   const [error, setError] = useState(null)

   useEffect(() => {
      DataService.getVersionInfo()
         .then(setInfo)
         .catch(setError)
   }, [])

   const getTimeString = () => {
      if (!info.buildDate) return ''
      return dateToString(new Date(info.buildDate), timeZone, true, false, false)
   }


   return (
      <Modal
         title="Version info"
         primaryBtnTitle="OK"
         primaryBtnPressed={onClose}
         onClose={onClose}
         width="560px"
         height="320px"
      >
         {info
            && <div style={{ userSelect: 'text' }}>
               <Row title="Build date" value={getTimeString()} />
               <Row title="Tag" value={info.tag && info.tag} />
               <Row title="Commit" value={info.commit && info.commit} />
            </div>
         }

         {error && <p><strong>{error}</strong></p>}

      </Modal>
   )
}


const Row = ({ title, value }) => (
   <div className="flex-row" style={{ marginBottom: '6px' }}>
      <p style={{ minWidth: '100px', margin: 0 }}><strong>{title}:</strong></p>
      <p style={{ margin: 0 }}>{value}</p>
   </div>
)
