import { selectAnalysis } from '@ava/react-common/store/actions/data-actions'
import { selectToolsFilteredAnalyses } from '@ava/react-common/store/reducers/root-reducer'
import { dateToString } from '@ava/react-common/utils/Helper'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Triangle from '../../../assets/triangle.svg'
import './mobile-data.list.css'

class MobileDataList extends Component {

   static propTypes = {
      analysisSelected: PropTypes.func,
   }

   constructor(props) {
      super(props)

      this.SortDirection = {
         ASC: 'ASC',
         DESC: 'DESC',
      }

      this.state = {
         hightlighted: null,
         sortBy: 'timestamp',
         sortDirection: this.SortDirection.DESC,
      }
   }

   // componentDidMount() {
   //    window.addEventListener('scroll', this.handleScroll);
   // }

   // componentWillUnmount() {
   //    window.removeEventListener('scroll', this.handleScroll);
   // }

   // // Check if title cell is on top -> set position fixed.
   // handleScroll() {
   //    const titleCell = $("#title-cell")
   //    const listContainer = $("#list-container")
   //    const position = listContainer.offset().top - $(window).scrollTop()
   //    if (position <= 60) { // navbar 60px
   //       titleCell.css('position', 'fixed');
   //       titleCell.css('top', '60px');
   //       titleCell.css('left', '0');
   //       titleCell.css('right', '0');
   //    } else {
   //       titleCell.css('position', 'absolute');
   //       titleCell.css('top', '0');
   //    }
   // }


   createCell = (analysis, darker) => {

      const { selectAnalysis, analysisSelected, timezone } = this.props

      let hasError = false
      let hasWarning = false
      analysis.items.forEach((item) => {
         if (item.error) hasError = true
         if (item.warning) hasWarning = true
      })

      let className = 'data-cell'
      if (hasError) className += ' data-cell--error'
      else if (hasWarning) className += ' data-cell--warning'
      if (analysis.id === this.state.highlighted) className += ' highlighted'
      if (darker) className += ' darker'

      return (
         <div key={analysis.id} className={className} onClick={() => {
            analysisSelected()
            this.setState({ highlighted: analysis.id })
            selectAnalysis(analysis.id)
         }}>
            <p className="first">{dateToString(analysis.timestamp, timezone, true)}</p>
            <p className="second">{dateToString(analysis.dateAnalyzed, timezone)}</p>
         </div>
      )
   }

   changeSorting = (sortBy) => {
      // Change direction
      if (this.state.sortBy === sortBy) {
         let sortDirection
         if (this.state.sortDirection === this.SortDirection.DESC) sortDirection = this.SortDirection.ASC
         else sortDirection = this.SortDirection.DESC
         this.setState({ sortDirection })
      } else {
         // Change sorting
         this.setState({ sortBy, sortDirection: this.SortDirection.DESC })
      }
   }

   render() {
      const { filteredAnalyses } = this.props
      const { sortDirection, sortBy } = this.state

      const sortedAnalyses = [...filteredAnalyses]
      sortedAnalyses.sort((a, b) => {
         if (sortDirection === this.SortDirection.ASC) {
            return a[sortBy] - b[sortBy]
         }
         return b[sortBy] - a[sortBy]

      })

      return (
         <div className="mobile-data-list">
            <div id="title-cell" className="title-cell">
               <div className="first flex-row" onClick={() => this.changeSorting('timestamp')}>
                  <p>Timestamp</p>
                  { (sortBy === 'timestamp') && <SortIndicator direction={sortDirection} /> }
               </div>
               <div className="second flex-row" onClick={() => this.changeSorting('dateAnalyzed')}>
                  <p>Analyzed</p>
                  { (sortBy === 'dateAnalyzed') && <SortIndicator direction={sortDirection} /> }
               </div>
            </div>

            <div id="list-container" className="list-container">
               {(filteredAnalyses)
                  && filteredAnalyses.map((analysis) => this.createCell(analysis)
                  )
               }

            </div>
         </div>
      )
   }

}


const mapStateToProps = (state) => ({
   filteredAnalyses: selectToolsFilteredAnalyses(state),
   timezone: state.timeZone,
})

export default connect(mapStateToProps, { selectAnalysis })(MobileDataList)


const SortIndicator = (props) => {
   const { direction } = props
   return (
      <img
         alt="sort"
         className={classNames('triangle-sort-icon', { rotated: direction === 'DESC' })}
         src={Triangle}
      />
   )
}
