import { useEffect, useRef } from 'react'

/**
 * @param {any} value
 * @returns {*} previous value
 */
export default function usePrevious(value) {
   const ref = useRef()
   useEffect(() => {
      ref.current = value
   })
   return ref.current
}
