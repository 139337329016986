// This file was created since some actions required local store in data-actions.js which was moved to js/react-common/store/actions
// TODO: Do not use store in actions. Create helper functions instead and use onplu clean actions. Remove actions folder after helpers created and actiosn moved back to data-actions. All actions that are here are commented in data-actios.js
import Analysis, { AnalysisImageMetadata } from '@ava/react-common/models/analysis' // eslint-disable-line
import { addPositionFilter, addTagFilter, addUnitFilter, enableTaglessFiltering, setTimeframe, setTimeframeSelection, TIMEFRAME_BY_TIMESTAMP, updateAnalyses } from '@ava/react-common/store/actions/data-actions'
import { ADD_ANALYSES } from '@ava/react-common/store/actions/types'
import { isEmpty } from 'lodash'
import { batch } from 'react-redux'
import store from '../store'

/**
 * @typedef {object} AddAnalysesOptions
 * @property {number} [limit]
 * @property {boolean} [sort]
 * @property {[Date?, Date?]} [timeframe]
 */

/**
 * @param {string} tool
 * @param {Analysis} analysis
 * @param {AddAnalysesOptions} [options]
 * @returns {import('@ava/react-common/store/reducers/root-reducer').ReduxThunkAction}
 */
export function addAnalysis(tool, analysis, options) {
   return addAnalyses(tool, [analysis], options)
}

// TODO: Move filters etc to their reducers???
/**
 * Adds analyses and updates timeframe (supports only TIMEFRAME_BY_TIMESTAMP)
 * @param {string} tool
 * @param {Analysis[]} analyses
 * @param {AddAnalysesOptions} [options]
 * @returns {import('@ava/react-common/store/reducers/root-reducer').ReduxThunkAction}
 */
export function addAnalyses(tool, analyses, options = {}) {
   return (dispatch, getState) => {
      batch(() => {
         const state = getState()
         const tagFilters = state.tagFilters
         const unitFilters = state.unitFilters
         const taglessFilter = state.taglessFilter
         const positionFilters = state.positionFilters
         const timeframe = state.timeframe

         const { limit, sort, timeframe: timeframeManuallySetTo } = options

         const isLiveUpdate = !!state.liveUpdate
         const liveUpdateFollowRange = state.liveUpdateFollowRange

         const unitFiltersToAdd = []
         let shouldEnableTaglessFiltering = false
         let timestampFrom = timeframe?.from
         let timestampTo = timeframe?.to

         for (const analysis of analyses) {
            const { timestamp, tags, unit, items } = analysis

            if (!timestampFrom && timeframeManuallySetTo?.[0]) {
               timestampFrom = timeframeManuallySetTo?.[0]
            } else if (!timestampFrom || (timestamp < timestampFrom)) {
               timestampFrom = timestamp
            }
            if (!timestampTo && timeframeManuallySetTo?.[1]) {
               timestampTo = timeframeManuallySetTo?.[1]
            } else if (!timestampTo || (timestamp > timestampTo)) {
               timestampTo = timestamp
            }
            if ((isEmpty(unitFilters) || !unitFilters[unit]) && !unitFiltersToAdd.includes(unit)) {
               unitFiltersToAdd.push(unit)
            }

            for (const item of items) {
               const { position } = item
               if (isEmpty(positionFilters) || !positionFilters[position]) {
                  dispatch(addPositionFilter(position))
               }
            }
            if (!taglessFilter.enabled && !shouldEnableTaglessFiltering && (!tags || tags.length === 0)) {
               shouldEnableTaglessFiltering = true
            }

            if (tags && tags.length > 0) { // TODO: Check if removed tags needs to be filtered
               tags.forEach((tag) => {
                  if (isEmpty(tagFilters) || !tagFilters[tag]) {
                     dispatch(addTagFilter(tag))
                  }
               })
            }
         }

         if (shouldEnableTaglessFiltering) {
            dispatch(enableTaglessFiltering(true))
         }

         unitFiltersToAdd.forEach((f) => dispatch(addUnitFilter(f))
         )

         // Timeframe
         if (timeframe && timeframe.by !== TIMEFRAME_BY_TIMESTAMP) {
            throw new Error(`Timeframe by was "${timeframe.by}" instead of "${TIMEFRAME_BY_TIMESTAMP}". "${timeframe.by}" is not supported when adding analyses.`)
         }

         dispatch(setTimeframe(TIMEFRAME_BY_TIMESTAMP, timestampFrom, timestampTo))

         // Live update timeframe follow range
         if (isLiveUpdate && liveUpdateFollowRange) {
            if (liveUpdateFollowRange === 'all') {
               dispatch(setTimeframeSelection(timestampFrom, timestampTo))
            } else {
               if (+timestampTo - liveUpdateFollowRange < +timestampFrom) {
                  dispatch(setTimeframeSelection(timestampFrom, timestampTo))
               } else {
                  dispatch(setTimeframeSelection(timestampTo - liveUpdateFollowRange, timestampTo))
               }
            }
         }

         dispatch({
            type: ADD_ANALYSES,
            payload: {
               tool,
               analyses,
               limit,
               sort,
            },
         })
      })
   }
}



/**
 * @param {string} tool
 * @param {Object.<string, AnalysisImageMetadata>} imageMetadatas { <imageId>: AnalysisImageMetadata, ... }
 * @returns {import('@ava/react-common/store/reducers/root-reducer').ReduxThunkAction}
 */
export function keepImages(tool, imageMetadatas) {
   return (dispatch) => {
      const updatedAnalyses = []
      const state = store.getState()
      for (const imageId in imageMetadatas) {
         const imageMetadata = imageMetadatas[imageId]
         const { analysisId, isKept } = imageMetadata
         let analysis
         analysis = updatedAnalyses.find((analysis) => analysis.id === analysisId)
         if (!analysis) {
            analysis = state.analyses[tool].find((a) => a.id === analysisId)
            updatedAnalyses.push(analysis)
         }
         if (!analysis || !analysis.items) continue
         for (const item of analysis.items) {
            if (item.imageId === imageId) {
               item.imageMetadata.isKept = isKept
            } else if (item.result && item.result.imageId === imageId) {
               item.result.imageMetadata.isKept = isKept
            }
         }
      }
      dispatch(updateAnalyses(tool, updatedAnalyses))
   }
}
