import DataService from '@ava/react-common/services/data-service'
import { createUnit, deleteUnit, editUnit } from '@ava/react-common/store/actions/configuration-action'
import { selectTools, selectUnits } from '@ava/react-common/store/reducers/root-reducer'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Analytics from '../../../assets/analytics.svg'
import Cogwheel from '../../../assets/cogwheel-black.svg'
import menu from '../../../assets/menu.svg'
import Plus from '../../../assets/plus.svg'
import Button from '../../Button/button'
import Dialog from '../../Dialog/dialog'
import Dropdown from '../../Dropdown/dropdown'
import Input from '../../Input/input'
import Modal from '../../Modal/modal'
import ReactSelect from '../../ReactSelect/react-select'
import RouterLink from '../../RouterLink/RouterLink'
import ToolCard from '../ToolCard/tool-card'
import './toolbox-component.css'


class ToolboxComponent extends Component {

   static propTypes = {
      toolbox: PropTypes.string.isRequired,
      // tools: PropTypes.array.isRequired,
   }

   constructor(props) {
      super(props)

      this.state = {
         isModalVisible: false,
         toolOptions: [],
         createNew: false,
         inEdit: null,
         inDelete: null,
         selectValue: null,
         inputValue: null,
         showDeleteDialog: false,
         errorDialogMessage: null,
         isLoading: false,
      }

      this.editingEnabled = this.props.user.hasEngineerPriviledges()

      this.unitMenuOptions = [
         { value: 'edit', label: 'Edit' },
         { value: 'delete', label: 'Delete', type: 'danger' },
      ]
   }

   componentDidMount() {
      const toolOptions = this.toolOptions()
      this.setState({ toolOptions })
   }

   getToolbox = () => this.props.toolboxes[this.props.toolbox]

   getTools = () => this.props.tools.filter((tool) => tool.toolbox === this.props.toolbox)

   toolOptions = () => {
      const options = []
      this.props.tools.forEach((tool) => {
         if (tool.toolbox === this.props.toolbox) {
            options.push({ value: tool.id, label: tool.name })
         }
      })
      return options
   }

   deleteUnit = (callback) => {
      this.setState({ isLoading: true })
      DataService.deleteUnit(this.state.inDelete)
         .then(() => callback(true))
         .catch(() => {
            this.setState({ errorDialogMessage: 'Failed to delete unit' })
            callback(false)
         })
   }

   saveEditedUnit = () => {
      if (!this.state.inputValue || this.state.inputValue === ' ' || this.state.inputValue === '') {
         this.setState({ errorDialogMessage: 'Name is required' })
         return
      }
      if (this.state.selectValue === null || this.state.selectValue.length === 0) {
         return this.setState({ errorDialogMessage: 'Select at least one tool' })
      }
      this.setState({ isLoading: true })

      const tools = this.state.selectValue ? this.state.selectValue.map((toolOption) => toolOption.value) : []
      DataService.editUnit(this.state.inEdit, this.state.inputValue, tools).then(() => {
         this.setState({ isLoading: false })
         this.props.editUnit(this.state.inEdit, this.state.inputValue, tools)
         this.stopEditing()
      }).catch(() => {
         this.setState({ isLoading: false })
         this.setState({ errorDialogMessage: 'Failed to save changes' })
         this.stopEditing()
      })
   }

   saveNewUnit = () => {
      if (!this.state.inputValue || this.state.inputValue === ' ' || this.state.inputValue === '') {
         this.setState({ errorDialogMessage: 'Name is required' })
         return
      }
      if (this.state.selectValue === null || this.state.selectValue.length === 0) {
         return this.setState({ errorDialogMessage: 'Select at least one tool' })
      }
      this.setState({ isLoading: true })

      const tools = this.state.selectValue ? this.state.selectValue.map((toolOption) => toolOption.value) : []
      DataService.createUnit(this.state.inputValue, tools, this.props.site).then((data) => {
         this.props.createUnit(data.id, data.name, data.toolbox, data.tools)
         this.setState({ isLoading: false })
         this.stopEditing()
      }).catch(() => {
         this.stopEditing()
         this.setState({ isLoading: false, errorDialogMessage: 'Failed to create unit' })
      })
   }

   stopEditing = () => {
      this.setState({ inEdit: null, inputValue: null, selectValue: null, createNew: false, inDelete: null })
   }

   unitMenuItemSelected = (value, unit) => {
      switch (value) {
         case 'edit': {
            const tools = this.state.toolOptions.filter((item) => unit.tools.includes(item.value))
            this.setState({ inEdit: unit.id, inputValue: unit.name, selectValue: tools, createNew: false })
            break
         }
         case 'delete':
            this.setState({ inDelete: unit.id, showDeleteDialog: true })
            break
         default:
            break
      }
   }

   // If !unit -> Create new unit
   unit = (unit) => {
      const createNew = !unit
      let selectedTools = []
      let inEdit = false

      if (!createNew) {
         selectedTools = this.state.toolOptions.filter((item) => unit.tools.includes(item.value))
         inEdit = this.state.inEdit ? this.state.inEdit === unit.id : false
      }

      return (
         <div key={createNew ? 'new-unit' : unit.id}>
            <div className="unit-section">
               <div className="unit-section-row">
                  {(this.editingEnabled && !this.state.inEdit && !createNew && !this.state.createNew)
                     && <Dropdown
                        className="unit-section-menu-dropdown"
                        button={<Button styleType={Button.StyleType.ICON} iconSrc={menu} />}
                        menuItems={this.unitMenuOptions}
                        onSelect={(value) => this.unitMenuItemSelected(value, unit)}
                        alignRight={true}
                     />
                  }
                  <p className="title">
                     <strong>Name:</strong>
                  </p>
                  {(!inEdit && !createNew)
                     && <p>{unit.name}</p>
                  }
               </div>
               {(inEdit || createNew)
                  && <Input
                     className="edit-unit-input"
                     autoFocus={true}
                     value={this.state.inputValue}
                     onChange={(value) => this.setState({ inputValue: value })}
                  />
               }
               <div className="unit-section-row">
                  <p className="title">
                     <strong>Tools:</strong>
                  </p>
                  {!inEdit
                     && selectedTools.map((item, i) => (i > 0 ? `, ${item.label}` : item.label))
                  }
               </div>
               {(inEdit || createNew)
                  && <ReactSelect
                     isMulti={true}
                     options={this.state.toolOptions}
                     value={this.state.selectValue}
                     onChange={(value) => this.setState({ selectValue: value })}
                  />
               }

               {(inEdit || createNew)
                  && <div>
                     <br />
                     <div className="unit-section-row">
                        <Button
                           title="CANCEL"
                           styleType={Button.StyleType.OUTLINED}
                           onClick={this.stopEditing}
                        />
                        <Button
                           className="save-button"
                           title="SAVE"
                           styleType={Button.StyleType.PRIMARY}
                           onClick={createNew ? this.saveNewUnit : this.saveEditedUnit}
                        />
                     </div>
                  </div>
               }

            </div>
            <br />
         </div>
      )
   }

   render() {

      if (!this.props.toolboxes || !this.props.tools) return

      const toolbox = this.getToolbox()
      const tools = this.getTools()

      const { isModalVisible, showDeleteDialog, inDelete, createNew, errorDialogMessage, inEdit } = this.state

      const units = this.props.units.filter((unit) => unit.toolbox === this.props.toolbox)

      return (
         <div className="toolbox">
            <div className="toolbox-title-row">
               <h2>{toolbox.name}</h2>
               <Button
                  className="toolbox-icon-btn"
                  style={{ marginLeft: '20px' }}
                  onClick={() => this.setState({ isModalVisible: true })}
                  styleType={Button.StyleType.ICON}
                  iconSrc={Cogwheel}
               />
               <RouterLink
                  to={`/statistics/${toolbox.id}`}
               >
                  <Button
                     className="toolbox-icon-btn"
                     style={{ marginLeft: '12px' }}
                     styleType={Button.StyleType.ICON}
                     iconSrc={Analytics}
                  />
               </RouterLink>
            </div>
            <div className="toolbox-row">
               {tools.map((tool) => (
                  <ToolCard key={tool.id} history={this.props.history} tool={tool} />
               ))}

            </div>

            { isModalVisible
               && <Modal
                  title={toolbox.name}
                  onClose={() => this.setState({ isModalVisible: false })}
                  primaryBtnTitle="OK"
                  isPrimaryBtnDisabled={!!(inDelete || inEdit || createNew)}
                  primaryBtnPressed={() => this.setState({ isModalVisible: false })}
                  width="480px"
                  height="500px"
               >
                  <div className="toolbox-modal-content">
                     <div className="flex-row">
                        <h3>Processing units</h3>
                        { this.editingEnabled
                           && <button
                              className="add-new-unit"
                              onClick={() => {
                                 this.stopEditing()
                                 this.setState({ createNew: true, inputValue: '' })
                              }}
                           >
                              <img src={Plus} alt="+" />
                           </button>
                        }
                     </div>
                     { createNew && this.unit(null) }
                     { units.map((unit) => this.unit(unit)) }
                  </div>

                  <Dialog
                     visible={showDeleteDialog && inDelete}
                     title={`Delete ${units.find((unit) => unit.id === inDelete)?.name}?`}
                     message="This cannot be undone"
                     buttons={[{
                        text: 'DELETE',
                        style: 'danger',
                        onPress: () => {
                           this.deleteUnit((success) => {
                              if (success) {
                                 this.setState({ showDeleteDialog: false }, () => {
                                    this.props.deleteUnit(this.state.inDelete)
                                    this.setState({ inDelete: null })
                                 })
                              } else {
                                 this.setState({ showDeleteDialog: false, errorDialogMessage: 'Failed to delete unit', inDelete: null })
                              }
                           })
                        },
                     }, { text: 'CANCEL' }]}
                     onClose={() => {
                        this.setState({ showDeleteDialog: false, inDelete: null }) }
                     }
                  />

               </Modal>
            }

            <Dialog
               visible={errorDialogMessage}
               title="Error"
               buttons={[{ text: 'OK' }]}
               message={errorDialogMessage}
               onClose={() => this.setState({ errorDialogMessage: null })}
            />

         </div>
      )
   }

}

const mapStateToProps = (state) => ({
   user: state.user,
   units: selectUnits(state),
   toolboxes: state.toolboxes,
   tools: selectTools(state),
   site: state.selectedSite,
})

export default connect(mapStateToProps, { createUnit, editUnit, deleteUnit })(ToolboxComponent)
