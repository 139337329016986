import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Collapse } from 'react-collapse'
import Arrow from '../../assets/arrow.svg'
import Menu from '../../assets/burgerMenu.svg'
import MetrisAvaLogo from '../../assets/metrisAva.svg'
import Button from '../Button/button'
import './mobile-navbar.scss'

MobileNavbar.propTypes = {
   menuItems: PropTypes.array,
   menuItemSelected: PropTypes.func,
   title: PropTypes.string,
   backPressed: PropTypes.func,
   logo: PropTypes.bool,
}
/**
 * @param {any} props
 * @param {object[]} props.menuItems
 * @param {string} props.title
 * @param {boolean} props.logo
 * @param {() => void} props.backPressed
 * @param {(itemValue: any) => void} props.menuItemSelected
 * @returns {import('react').FunctionComponentElement}
 */
export default function MobileNavbar({ menuItems, title, logo, backPressed, menuItemSelected }) {
   const [isCollapsed, setCollapsed] = useState(true)

   return (
      <div className="mobile-navbar">
         { logo
            && <img className="mobile-ava-logo" src={MetrisAvaLogo} alt="METRISAVA" />
         }
         { title
            && <p className="title">{ title }</p>
         }
         { backPressed
            && <div className="back-btn-container">
               <Button
                  onClick={backPressed}
                  styleType={Button.StyleType.ICON}
                  iconSrc={Arrow}
               />
            </div>
         }
         {(menuItems && menuItemSelected)
            && <div>
               <button
                  type="button"
                  className="toggle-menu-btn"
                  onClick={() => {
                     setCollapsed(!isCollapsed)
                  }}
               >
                  <img className="toggle-icon" src={Menu} alt="Toggle" />
               </button>


               <Collapse isOpened={!isCollapsed}>
                  <div className="menu-items">
                     { menuItems.map((item) => (
                        <div
                           key={item.value}
                           className={classNames('toggle-menu-item', { disabled: item.disabled })}
                           onClick={() => {
                              menuItemSelected(item.value)
                              setCollapsed(true)
                           }}
                        >
                           <p>{ item.label }</p>
                        </div>
                     ))}
                  </div>
               </Collapse>
            </div>
         }
      </div>
   )

}
