import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Button from '../Button/button'
import Dropdown from '../Dropdown/dropdown'
import TopMenu from '../TopMenu/top-menu'
import './settings-top-menu.scss'

export default class SettingsTopMenu extends Component {

   static propTypes = {
      topMenuOptions: PropTypes.array,
      onTopMenuSelect: PropTypes.func,
      topMenuDisabled: PropTypes.bool,

      primaryBtnTitle: PropTypes.string,
      primaryBtnPressed: PropTypes.func,
      primaryBtnDisabled: PropTypes.bool,

      actionOptions: PropTypes.array,
      onActionSelected: PropTypes.func,
      actionsDisabled: PropTypes.bool,
   }

   render() {

      const { topMenuOptions, onTopMenuSelect, actionOptions, onActionSelected, actionsDisabled, primaryBtnTitle, primaryBtnPressed, primaryBtnDisabled, topMenuDisabled } = this.props

      return (
         <div className="settings-top-menu">
            { (topMenuOptions && onTopMenuSelect)
               && <TopMenu
                  style={{ height: '100%', marginTop: 'auto', display: 'flex', alignItems: 'flex-end', paddingLeft: '24px' }}
                  onSelect={onTopMenuSelect}
                  options={topMenuOptions}
                  disabled={topMenuDisabled}
               />
            }
            <div style={{ width: '1px', height: '1px', backgroundColor: 'transparent', marginRight: '24px' }} />
            { primaryBtnPressed
               && <Button
                  title={primaryBtnTitle}
                  styleType={Button.StyleType.PRIMARY}
                  onClick={primaryBtnPressed}
                  disabled={primaryBtnDisabled}
                  style={{ marginRight: '20px', marginBottom: 0, flexShrink: 0 }}
               />
            }
            { actionOptions
               && <Dropdown
                  button={
                     <Button
                        title="Actions"
                        disabled={actionsDisabled}
                        style={{ marginBottom: 0, marginRight: '24px', flexShrink: 0 }}
                     />
                  }
                  menuItems={actionOptions}
                  onSelect={onActionSelected}
               />
            }

         </div>
      )
   }

}
