import React, { useState } from 'react'
import Close from '../../assets/close-white.svg'
import Download from '../../assets/download.svg'
import StarRegular from '../../assets/star-regular.svg'
import StarSolid from '../../assets/star-solid.svg'
import Button from '../Button/button'
import Image from '../Image/image'
import './media-viewer.css'

/**
 * @param {object} props
 * @param {string} props.src
 * @param {string} props.contentType
 * @param {boolean} props.isKept
 * @param {(isKept: boolean) => void} props.onKeep
 * @param {() => void} props.onClose
 * @param {'image'|'video'} [props.type] Default: image
 * @returns {React.FunctionComponentElement}
 */
export default function MediaViewer(props) {
   const { src, isKept, onKeep, onClose, type = 'image', contentType } = props

   const [error, setError] = useState('')

   return (
      <div className="media-viewer">
         <Button
            className="btn-keep"
            styleType={Button.StyleType.ICON}
            iconSrc={isKept ? StarSolid : StarRegular}
            onClick={() => onKeep(!isKept)}
         />
         {/* <Button onClick={() => console.log("download")} className="download-file" styleType={Button.StyleType.ICON} iconSrc={Download}/> */}
         <a
            className="download-file"
            href={src}
            download
         >
            <img src={Download} alt="Download" />
         </a>
         <Button
            onClick={onClose}
            className="close-viewer"
            styleType={Button.StyleType.ICON}
            iconSrc={Close}
         />
         {!!error && (
            <div style={{ color: 'white' }}>{error}</div>
         )}
         {(type === 'image') && (
            <Image
               className="center-media-view"
               src={src}
               onError={() => setError('Failed to load')}
            />
         )}
         {(type === 'video') && (
            <video
               className="center-media-view"
               style={{
                  maxWidth: 'calc(100vw - 160px)',
                  maxHeight: '100vh',
               }}
               onError={() => setError('Failed to load')}
               controls
            >
               <source src={src} type={contentType} />
            </video>
         )}
      </div>
   )
}
