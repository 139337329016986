import axios from 'axios'
import React from 'react'


/**
 * Image component that checks authorization on error
 * It works by making axios request if image fails to load. There is an axios interceptor in App.js which logs out if API returns 401 UNAUTHORIZED status
 * @param {object} props
 * @param {string} props.src
 * @param {string} [props.className]
 * @param {string} [props.alt]
 * @param {(err: Error) => void} [props.onError]
 * @param {import('react').CSSProperties} [props.style]
 * @returns {import('react').FunctionComponentElement}
 */
export default function Image({ src, className = '', alt = '', style = {}, onError }) {

   return (
      <img
         className={className}
         style={style}
         src={src}
         alt={alt}
         onError={() => {
            axios
               .get(src)
               .catch((err) => {
                  if (onError) onError(err)
                  else console.error('Failed to load image', err)
               })
         }}
      />
   )

}
