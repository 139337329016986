export default class ImagesConfig {

   /**
    * Images configuration (unit specific)
    *
    * @param {string} tool
    * @param {Buffer} unit UUID
    * @param {Object} clean - Clean images older than X milliseconds. Don't clean at all if "-1"
    * @param {number} clean.original
    * @param {number} clean.originalThumbnails
    * @param {number} clean.analyzed
    * @param {number} clean.analyzedThumbnails
    */
   constructor(tool, unit, clean) {
      this.tool = tool
      this.unit = unit
      this.clean = clean
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                       STATIC                       |
   \*__________________________________________________*/

   /**
    * Creates Unit from json
    * @param {Object} json
    * @returns {ImagesConfig|null}
    */
   static fromJson(json) {
      const { tool, unit, clean } = json
      if (!tool || !unit || !clean) return null
      return new ImagesConfig(tool, unit, clean)
   }

}
