import { CLEAR_USER_TOOL_CONFIG, SET_USER_TOOL_CONFIG } from './types'

export function setUserToolConfig(config) {
   return {
      type: SET_USER_TOOL_CONFIG,
      payload: config,
   }
}

export function clearUserToolConfig() {
   return {
      type: CLEAR_USER_TOOL_CONFIG,
   }
}
