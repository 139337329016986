import Analysis from '@ava/react-common/models/analysis'
import DataService from '@ava/react-common/services/data-service'
import { clearSelectedAnalyses, removeAnalysis } from '@ava/react-common/store/actions/data-actions'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { selectAnalysesObj, selectSelectedTool } from '@ava/react-common/store/reducers/root-reducer'
import { errorToMessage } from '@ava/react-common/utils'
import { dateToString, getLanguageString } from '@ava/react-common/utils/Helper'
import OpcUaStatusCodes from '@ava/react-common/utils/OpcUaStatusCodes'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect, useSelector } from 'react-redux'
import { keepImages } from '../../../actions/local-data-actions'
import { exportAnalysesToXLSX } from '../../../utils/exportAnalysesToXLSX'
import Card from '../../Card/card'
import Dialog from '../../Dialog/dialog'
import MediaViewer from '../../mediaViewer/media-viewer'
import MobileNavbar from '../../MobileNavbar/mobile-navbar'
import Modal from '../../Modal/modal'
import EditModal from '../EditModal/edit-modal'
import './analysis-card.css'
import AnalysisPosition from './analysis-position'

class AnalysisCard extends Component {

   static propsTypes = {
      id: PropTypes.string,
      analysisId: PropTypes.string.isRequired,
      title: PropTypes.string,
      showNoImagePlaceholders: PropTypes.bool, // Draws image containers with "No image" text when images don't exist
      isHighlighted: PropTypes.bool, // Displays blue borders
      // editingEnabled: PropTypes.bool,
   }

   // static defaultProps = {
   //    editingEnabled: true,
   // }

   constructor(props) {
      super(props)

      this.state = {
         showDeleteDialog: false,
         deleteDialogDisabled: false,
         showFailedToDeleteDialog: false,
         showStatusCodes: false,
         isEditing: false,
         // highlight: true, NOTE: Uncomment this and componentDidMount section to highlight card for sertain time period
         selectedFileId: null,
         selectedFileType: null,
         selectedFileAnalysisId: undefined,
         infoModalContent: null,
         menuItems: this.generateMenuItems(),
      }

      this.highlightTimeout = null
      this.previousHeight = 0
      this.refCard = React.createRef()
   }

   generateMenuItems = () => {
      const { analyses, analysisId } = this.props
      const analysis = analyses[analysisId]

      let imagesWithMetadata = 0
      let imagesKeptCount = 0
      analysis.items.forEach((item) => {
         if (item.imageMetadata) {
            ++imagesWithMetadata
            if (item.imageMetadata.isKept) ++imagesKeptCount
         }
         if (item.result && item.result.imageMetadata) {
            ++imagesWithMetadata
            if (item.result.imageMetadata.isKept) ++imagesKeptCount
         }
      })

      const keepImagesBtnHidden = (!imagesWithMetadata || imagesKeptCount === imagesWithMetadata)
      const dontKeepImagesBtnHidden = (!imagesWithMetadata || imagesKeptCount === 0)

      let statusCodesAvailable = false
      analysis.items.forEach((item) => {
         if (item.result?.statusCodes) statusCodesAvailable = true
      })

      return this.props.user.hasUserPriviledges() ? ([
         { value: 'edit', label: 'Edit' },
         { value: 'keepImages', label: 'Keep images', hidden: keepImagesBtnHidden },
         { value: 'dontKeepImages', label: `Don't keep images`, hidden: dontKeepImagesBtnHidden },
         { value: 'delete', label: 'Delete', type: 'danger' },
         { value: 'export', label: 'Export' },
         { value: 'statusCodes', label: 'OPC UA status codes', disabled: !statusCodesAvailable },
         // {value: 'reanalyze', label: 'Reanalyze', disabled: true},
      ]) : ([
         { value: 'export', label: 'Export' },
         { value: 'statusCodes', label: 'OPC UA status codes', disabled: !statusCodesAvailable },
      ])
   }

   getAnalysis = () => (
      this.props.analyses[this.props.analysisId]
   )

   componentDidMount() {
      if (this.props.isMobile) {
         window.scrollTo(0, 0)
      }
      // else {
      // NOTE: highlight was displayed for a short time period after selection. Currently last selected item is highlighted and highlight does not disapper. Hence commented.
      // this.highlightTimeout = setTimeout(() => {
      //    this.setState({ highlight: false }
      // )}, 700)
      // }

      this.previousHeight = this.refCard.current?.getBoundingClientRect().height
   }

   componentDidUpdate(prevProps) {
      if (prevProps.analyses !== this.props.analyses) {
         this.setState({ menuItems: this.generateMenuItems() })
      }
   }

   componentWillUnmount() {
      if (this.highlightTimeout) {
         clearTimeout(this.highlightTimeout)
      }
   }

   deleteAnalysis = () => {
      const { analysisId, selectedToolId } = this.props

      this.setState({ deleteDialogDisabled: true })
      DataService
         .deleteAnalysis(analysisId, selectedToolId)
         .then(() => {
            this.props.removeAnalysis(selectedToolId, analysisId)
            this.setState({ showDeleteDialog: false })
         })
         .catch((err) => {
            console.error(err)
            this.setState({
               showFailedToDeleteDialog: true,
               showDeleteDialog: false,
               deleteDialogDisabled: false,
            })
         })
   }

   changeImagesKeepState = (keep) => {
      const { selectedToolId, analyses, analysisId } = this.props
      const analysis = analyses[analysisId]

      const images = {}

      analysis.items.forEach((item) => {
         if (item.imageId && item.imageMetadata) {
            images[item.imageId] = keep
         }
         if (item.result && item.result.imageId && item.result.imageMetadata) {
            images[item.result.imageId] = keep
         }
      })


      DataService
         .keepImages(images)
         .then((imageMetadatas) => this.props.keepImages(selectedToolId, imageMetadatas))
         .catch((err) => {
            this.props.showDialog({
               title: 'Failed to set images to be kept',
               text: errorToMessage(err),
            })
         })
   }


   onMenuItemSelected = (value) => {
      const analysis = this.getAnalysis()
      switch (value) {
         case 'edit':
            this.setState({ isEditing: true })
            break
         case 'keepImages':
            this.changeImagesKeepState(true)
            break
         case 'dontKeepImages':
            this.changeImagesKeepState(false)
            break
         case 'delete':
            this.setState({ showDeleteDialog: true })
            break
         case 'export':
            exportAnalysesToXLSX([analysis])
            break
         case 'reanalyze':
            console.log('reanalyze')
            break
         case 'statusCodes':
            this.setState({ showStatusCodes: true })
            break
         default:
            break
      }
   }

   render() {

      const { menuItems, showDeleteDialog, deleteDialogDisabled, showFailedToDeleteDialog, isEditing, highlight, selectedFileId, selectedFileType, selectedFileContentType, selectedFileAnalysisId, showStatusCodes } = this.state
      const { analyses, resultModels, isMobile, onClose, title, clearSelectedAnalyses, tags, positions, selectedToolId, showNoImagePlaceholders, isHighlighted } = this.props

      const analysis = this.getAnalysis()
      if (!analysis) return null

      const validatedTags = analysis.tags.filter((id) => this.props.tags[id]) // Check if does exist
      const tagNames = validatedTags.map((id, index) => (index > 0 ? ', ' : '') + tags[id].name)

      // Select correct model version
      const resultModel = resultModels[0] // FIXME: Should be: resultModels.find((model) => model.version === analysis.version)

      // Sort items alphabetically by position name
      const alphabeticallySortedItems = analysis.items
      alphabeticallySortedItems.sort((a, b) => {
         const valA = positions[a.position] ? positions[a.position].name : ''
         const valB = positions[b.position] ? positions[b.position].name : ''
         const strA = valA ? valA.toUpperCase() : ''
         const strB = valB ? valB.toUpperCase() : ''

         if (strA < strB) return -1
         if (strA > strB) return 1
         return 0
      })

      let isSelectedImageKept
      if (selectedFileId && analyses[selectedFileAnalysisId]) {
         isSelectedImageKept = analyses[selectedFileAnalysisId].isImageKept(selectedFileId)
      }

      const ModalsComponent = (
         <Modals
            analysis={analysis}
            isEditing={isEditing}
            showStatusCodes={showStatusCodes}
            showDeleteDialog={showDeleteDialog}
            showFailedToDeleteDialog={showFailedToDeleteDialog}
            deleteDialogDisabled={deleteDialogDisabled}
            infoModalContent={this.state.infoModalContent}
            parent={this}
            resultModel={resultModel}
         />
      )

      // MOBILE DEVICE
      if (isMobile) {
         return (
            <div id={this.props.id} className="analysis-card-mobile">
               {ModalsComponent}
               <MobileNavbar
                  title="Selected"
                  menuItems={menuItems}
                  menuItemSelected={this.onMenuItemSelected}
                  backPressed={clearSelectedAnalyses}
               />
               <MobileInfo analysis={analysis} tagNames={tagNames} />

               { alphabeticallySortedItems.map((item) => (
                  <AnalysisPosition
                     key={item.position}
                     item={item}
                     resultModel={resultModel}
                  />
               ))}

            </div>
         )
      }

      return (
         <Card
            id={this.props.id}
            ref={this.refCard}
            className={classNames('analysis-card', `analysis-card-${analysis.id}`)}
            title={title}
            menuItems={menuItems}
            menuItemSelected={this.onMenuItemSelected}
            onClose={onClose}
            outline={showDeleteDialog ? 'danger' : (highlight || isHighlighted)}
         >
            <Info analysis={analysis} tagNames={tagNames} />

            { alphabeticallySortedItems.map((item) => (
               <AnalysisPosition
                  key={item.position}
                  item={item}
                  resultModel={resultModel}
                  showNoImagePlaceholders={showNoImagePlaceholders}
                  selectImage={(imageId, fileType, fileContentType, analysisId) => this.setState({
                     selectedFileId: imageId,
                     selectedFileType: fileType,
                     selectedFileContentType: fileContentType,
                     selectedFileAnalysisId: analysisId,
                  })}
               />
            ))}

            {ModalsComponent}

            { selectedFileId && (
               <MediaViewer
                  src={Analysis.getFileUrl(process.env.PUBLIC_URL, selectedFileId)} // TODO: Currently images with only thumbnail are not selectable. Might be good to add that option
                  type={selectedFileType}
                  contentType={selectedFileContentType}
                  isKept={!!isSelectedImageKept}
                  onKeep={async (isKept) => {
                     DataService
                        .keepImages({ [selectedFileId]: isKept })
                        .then((imageMetadata) => this.props.keepImages(selectedToolId, imageMetadata))
                        .catch((err) => {
                           this.props.showDialog({
                              title: 'Failed to set images to kept',
                              text: errorToMessage(err),
                           })
                        })
                  }}
                  onClose={() => (
                     this.setState({
                        selectedFileId: null,
                        selectedFileType: null,
                        selectedFileContentType: null,
                        selectedFileAnalysisId: null,
                     })
                  )}
               />
            )}
         </Card>
      )
   }

}

const mapStateToProps = (state) => ({
   user: state.user,
   analyses: selectAnalysesObj(state),
   selectedToolId: state.selectedTool,
   resultModels: selectSelectedTool(state).resultModels,
   isMobile: state.device.isMobile,
   size: state.device.size,
   tags: state.tags,
   positions: state.positions,
})

export default connect(mapStateToProps, { clearSelectedAnalyses, removeAnalysis, keepImages, showDialog })(AnalysisCard)


/* Info container */
const Info = ({ analysis, tagNames }) => {
   const timeZone = useSelector((state) => state.timeZone)
   return (
      <div className="info-box">
         <div className="info-item-row">
            <div className="info-item-box">
               <h4>Timestamp:</h4>
               <p>{dateToString(analysis.timestamp, timeZone, true, true)}</p>
            </div>
            <div className="info-item-box">
               <h4>Analyzed:</h4>
               <p>{analysis.dateAnalyzed ? dateToString(analysis.dateAnalyzed, timeZone, true, true) : '-'}</p>
            </div>
            <div className="info-item-box">
               <h4>Tags:</h4>
               <p>{(analysis.tags && analysis.tags.length > 0) ? tagNames.map((name) => name) : '-'}</p>
            </div>
            <div className="info-item-box">
               <h4>Comment:</h4>
               <p>{analysis.comment ? analysis.comment : '-'}</p>
            </div>
         </div>
      </div>
   )
}


/* Info card for mobile */
const MobileInfo = ({ analysis, tagNames }) => {
   const timeZone = useSelector((state) => state.timeZone)
   return (
      <Card title="Info">
         <div className="info-container">
            <div className="flex-row"><h4>Date:</h4><p> {dateToString(analysis.timestamp, timeZone, true)}</p> </div>
            <div className="flex-row"><h4> Analyzed:</h4><p>{dateToString(analysis.dateAnalyzed, timeZone, true)}</p></div>
            <div className="flex-row"><h4>Tags:</h4><p>{(analysis.tags && analysis.tags.length > 0) ? tagNames.map((name) => name) : '-'}</p></div>
            <div className="flex-row"><h4>Comment:</h4><p>{analysis.comment ? analysis.comment : '-'}</p></div>
         </div>
      </Card>
   )
}


/* Modal components */
const Modals = (props) => {

   const { analysis, isEditing, showDeleteDialog, showFailedToDeleteDialog, deleteDialogDisabled, infoModalContent, parent, showStatusCodes, resultModel } = props

   return (
      <div>
         { infoModalContent
            && <Modal
               title="Info"
               primaryBtnTitle="OK"
               primaryBtnPressed={() => parent.setState({ infoModalContent: null }
               )}
               onClose={() => parent.setState({ infoModalContent: null }
               )}
               width="800px"
            >
               { infoModalContent }
            </Modal>
         }

         { showStatusCodes
            && <StatusCodesModal
               onClose={() => parent.setState({ showStatusCodes: false })}
               analysis={analysis}
               elements={resultModel.elements}
            />
         }

         { isEditing
            && <EditModal
               analysisId={analysis.id}
               onClose={() => parent.setState({ isEditing: false })}
            />
         }

         <Dialog
            visible={showDeleteDialog}
            title="Delete analysis?"
            message="Analysis will be deleted permanently"
            buttons={[{
               text: 'DELETE',
               style: 'danger',
               onPress: parent.deleteAnalysis,
            }, {
               text: 'CANCEL',
            }]}
            onClose={() => parent.setState({ showDeleteDialog: false })}
            disabled={deleteDialogDisabled}
         />

         <Dialog
            visible={showFailedToDeleteDialog}
            title="Failed to delete the analysis"
            message="Please try again later"
            buttons={[{ text: 'OK' }]}
            onClose={() => parent.setState({ showFailedToDeleteDialog: false })}
         />
      </div>
   )
}



export const StatusCodesModal = ({ onClose, analysis, elements }) => {
   const positions = useSelector((state) => state.positions)
   const codes = {}
   const items = analysis.items
   items.forEach((item) => {
      if (item.result?.statusCodes) {
         codes[item.position] = item.result?.statusCodes
      }
   })

   return (
      <Modal
         title="OPC UA status codes"
         primaryBtnTitle="OK"
         primaryBtnPressed={onClose}
         onClose={onClose}
         width="540px"
      >
         {Object.keys(codes).map((key) => {
            const title = positions[key].name
            const statusCodes = codes[key]
            return (
               <>
                  <h3 style={{ color: '#000' }}>{title}</h3>
                  {Object.keys(statusCodes).map((key, index) => {
                     const value = OpcUaStatusCodes[statusCodes[key]]
                     const elementName = getLanguageString(elements[key]?.name) || key
                     return (
                        <div key={`status-${index}`} style={{ marginBottom: '6px' }}>
                           <div style={{ display: 'flex' }}>
                              <p style={{ marginRight: '12px' }}><strong>{elementName}</strong></p>
                              <p>{value[0]}</p>
                           </div>
                           <p style={{ fontSize: '12px' }}>{value[1]}</p>
                        </div>
                     )
                  })}
               </>
            )
         })}
      </Modal>
   )
}
