import React, { Component } from 'react'
import './media-container.css'


/**
 * Scales and centers image inside black container
 *
 * @typedef {object} Props
 * @property {string} [width="100%"]
 * @property {string} [height="100%"]
 * @property {React.MouseEventHandler<HTMLDivElement>} [onClick]
 * @property {string} src
 * @property {'image'|'video'} [type] Default: 'image'
 * @property {string} contentType
 */

/**
 * @augments {Component<Props>}
 */
export default class MediaContainer extends Component {

   static defaultProps = {
      width: '100%',
      height: '100%',
      type: 'image',
   }


   render() {
      const { width, height, onClick, src, type, contentType } = this.props

      return (
         <div className="media-container-component"
            style={{
               cursor: onClick ? 'pointer' : undefined,
               width,
               height,
            }}
            onClick={onClick}
         >
            {(type === 'video') ? (
               <video
                  className="media-holder"
                  width={width}
                  height={height}
                  onClick={(e) => e.stopPropagation()}
                  controls
               >
                  <source src={src} type={contentType} />
               </video>
            ) : (
               <div className="media-holder"
                  style={{ backgroundImage: `url(${src})` }}
               />
            )}
         </div>
      )
   }

}
