import { setExclusiveRendering } from '@ava/react-common/store/actions/result-component-actions'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Transition } from 'react-transition-group'
import Close from '../../assets/close-black.svg'
import Dash from '../../assets/dash.svg'
import FullScreen from '../../assets/full-screen-black.svg'
import MenuIcon from '../../assets/menu.svg'
import Minimize from '../../assets/minimize-black.svg'
import Button from '../Button/button'
import Dropdown from '../Dropdown/dropdown'
import './card.css'

const Card = React.forwardRef(({ title, menuItems, onClose, menuItemSelected, outline, id, className, minimizable, menuCustomComponent, minimizedTitle, isFullScreen, onFullScreen, children }, ref) => {
   const isMobile = useSelector((state) => state.device.isMobile)
   // const [isVisible, show] = useState(true)
   const [isMinimized, minimize] = useState(false)
   const [transitionIn, setTransitionIn] = useState(true)
   const [exitingTransitionDuration, setExitingTransitionDuration] = useState()
   const [cardHeightBeforeExitAnimation, setCardHeightBeforeExitAnimation] = useState()
   const dispatch = useDispatch()

   const enteringAnimationDuration = 320
   const cardRef = useRef()

   /* Card*/
   const initialStyle = isMobile ? {} : {
      opacity: 1,
      transform: `translateY(6px)`,
   }

   const transitionStyles = isMobile ? {} : {
      entering: {
         transition: `all ${enteringAnimationDuration}ms ease-in`,
         opacity: 1,
         transform: undefined,
      },
      entered: {
         opacity: 1,
         transform: undefined,
         height: cardHeightBeforeExitAnimation,
      },
      exiting: {
         opacity: 0,
         transform: undefined,
         transition: `all ${exitingTransitionDuration}ms ease-in-out, opacity ${exitingTransitionDuration / 1.5}ms linear`,
         overflow: 'hidden',
         margin: 0,
         padding: 0,
         height: 0,
      },
   }

   let outlineClassName = ''
   if (outline === 'danger') {
      outlineClassName = ' card--outline-danger'
   } else if (outline) {
      outlineClassName = ' card--outline'
   }

   return (
      <Transition
         timeout={transitionIn ? enteringAnimationDuration : exitingTransitionDuration}
         in={transitionIn} // isVisible
         appear
         onExited={onClose} // Is there a other way to do a two part exit animation
         exit={!isMobile}
         enter={!isMobile}
      >
         { (status) => (
            <div ref={cardRef}
               className={classNames('card-container', { mobile: isMobile })}
               id={id}
            >
               { !!title
                  && <h3>{title}</h3>
               }
               <div
                  ref={ref}
                  className={classNames('card', className, outlineClassName, { 'is-full-screen': isFullScreen })}
                  style={{
                     ...initialStyle,
                     ...transitionStyles[status],
                  }}
               >
                  <div className="button-row">

                     {(!isMinimized && ((menuItems && menuItemSelected) || menuCustomComponent))
                        && <div className="card-menu-btn">
                           <Dropdown
                              button={<Button styleType={Button.StyleType.ICON} iconSrc={MenuIcon} />}
                              menuItems={menuItems}
                              onSelect={(value) => menuItemSelected(value)}
                              customComponent={menuCustomComponent}
                              direction="left"
                           />
                        </div>
                     }
                     {minimizable
                        && <div className="card-minimize-button">
                           <Button
                              styleType={Button.StyleType.ICON}
                              iconSrc={Dash}
                              onClick={() => minimize(!isMinimized)}
                           />
                        </div>
                     }
                     {onFullScreen
                        && <div>
                           <Button
                              style={{ height: '22px', width: '22px', marginBottom: 0 }}
                              styleType={Button.StyleType.ICON}
                              iconSrc={isFullScreen ? Minimize : FullScreen}
                              onClick={() => {
                                 onFullScreen()
                                 dispatch(setExclusiveRendering(!isFullScreen))
                              }}
                           />
                        </div>
                     }
                     { (onClose && !isFullScreen)
                        && <div className="card-close-btn-container">
                           <Button
                              styleType={Button.StyleType.ICON}
                              iconSrc={Close}
                              onClick={() => {
                                 const cardBoundingRect = cardRef.current.getBoundingClientRect()
                                 setCardHeightBeforeExitAnimation(cardBoundingRect.height)
                                 setExitingTransitionDuration(cardBoundingRect.height / 1.8)
                                 setTimeout(() => setTransitionIn(false), 5) // Start transition after cardHeightBeforeExitAnimation has been set
                              }}
                           />
                        </div>
                     }
                  </div>
                  { !isMinimized ? children : <h4 style={{ marginTop: '-8px', marginBottom: '-20px' }}>{minimizedTitle}</h4> }
               </div>
            </div>
         )}
      </Transition>
   )
})

Card.propTypes = {
   id: PropTypes.string,
   className: PropTypes.string,
   title: PropTypes.string,
   minimizedTitle: PropTypes.string,
   menuItems: PropTypes.array,
   menuItemSelected: PropTypes.func,
   menuCustomComponent: PropTypes.any,
   onClose: PropTypes.func,
   outline: PropTypes.any,
   minimizable: PropTypes.bool,
   isFullScreen: PropTypes.bool,
   onFullScreen: PropTypes.func,
}

const preventRender = (prevProps, nextProps) => {
   if (nextProps.exclusiveRendering && !nextProps.isFullScreen) return true
   return false
}

const CardContainer = React.forwardRef((props, ref) => {
   const exclusiveRendering = useSelector((state) => state.resultComponents.exclusiveRendering)
   return <CardWithMemo ref={ref} {...props} exclusiveRendering={exclusiveRendering} />
})
export default CardContainer

const CardWithMemo = React.memo(Card, preventRender)
