import React from 'react'
import Hoverable from './hoverable'
import './hover-info.scss'

/**
 * This component can be used inside HoverInfoBox as content
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {import('react').ReactNode} [props.children]
 * @param {boolean} [props.disabled]
 * @returns {import('react').FunctionComponentElement}
 */
export default function HoverInfo({ title, description, children, disabled }) {
   const fontSize = '15px'
   return (
      <Hoverable
         disabled={disabled}
         component={
            <div className="hover-info-box">
               <p style={{ marginBottom: '2px', fontSize }}><strong>{title}</strong></p>
               <p style={{ fontWeight: 300, fontSize }}>{description}</p>
            </div>
         }
      >
         {children}
      </Hoverable>
   )
}
