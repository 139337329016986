import { rootReducer } from '@ava/react-common/store/reducers/root-reducer'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

const store = createStore(
   rootReducer,
   applyMiddleware(thunk)
)

export default store
