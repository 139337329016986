import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './modal.scss'
import { Transition } from 'react-transition-group'
import classNames from 'classnames/bind'
import Button from '../Button/button'
import Close from '../../assets/close-blue.svg'

class Modal extends Component {

   constructor(props) {
      super(props)

      this.state = {
         containerStyle: {},
         showModal: true,
      }

      this.dialogAnimationDuration = 180
      this.bgAnimationDuration = 120
      this.footerRef = React.createRef()
   }

   componentDidMount() {
      this.calculateContainerStyle()
   }

   componentDidUpdate(prevProps) {
      const { height, width, details, error } = this.props
      const { isMobile, size } = this.props.device

      if ((height !== prevProps.height || width !== prevProps.width || isMobile !== prevProps.device.isMobile)
         || ((size === 'xs' || prevProps.device.size === 'xs') && size !== prevProps.device.size)
         || (!prevProps.details && !prevProps.error && (details || error))
         || ((prevProps.details || prevProps.error) && !details && !error)) {
         this.calculateContainerStyle()
      }
   }

   static propTypes = {
      id: PropTypes.string,
      className: PropTypes.string,
      title: PropTypes.string,
      details: PropTypes.string,
      error: PropTypes.string,
      headerContent: PropTypes.any,
      isPrimaryBtnDisabled: PropTypes.bool,
      primaryBtnTitle: PropTypes.string,
      secondaryBtnTitle: PropTypes.string,
      leftBtnTitle: PropTypes.string,
      primaryBtnPressed: PropTypes.func,
      secondaryBtnPressed: PropTypes.func, // if null, will close modal
      leftBtnPressed: PropTypes.func,
      onClose: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      height: PropTypes.string,
      width: PropTypes.string,
   }

   calculateContainerStyle() {
      const { height } = this.props
      const { isMobile } = this.props.device
      const containerStyle = {}
      if (isMobile || height) containerStyle.overflow = 'auto'
      if (isMobile) {
         containerStyle.top = '56px'
         containerStyle.bottom = isMobile && `${window.innerHeight - this.footerRef.current.offsetTop}px`
      }
      this.setState({ containerStyle })
   }

   render() {

      const { id, className, title, details, error, children, isPrimaryBtnDisabled, primaryBtnPressed, leftBtnPressed, secondaryBtnPressed, secondaryBtnTitle, primaryBtnTitle, leftBtnTitle, disabled, height, width, onClose, headerContent } = this.props
      const { isMobile } = this.props.device

      const backgroundTapped = (e) => {
         if (e.target.className === 'main-modal') {
            e.stopPropagation()
            if (!disabled) {
               // UNCOMMENT THIS IF YOU WANT TO CLOSE MODAL WHEN BACKGROUND IS TAPPED
               // this.setState({showDialog: false})
            }
         }
      }

      // Animation styles
      // Styles are disabled if mobile since translate property breaks the layout
      const initialModalStyle = isMobile ? {} : {
         transition: `all ${this.bgAnimationDuration}ms ease-in`,
         opacity: 0,
      }

      const initialDialogStyle = isMobile ? {} : {
         transition: `all ${this.dialogAnimationDuration}ms ease-in`,
         opacity: 0,
         transform: `translateY(20px)`,
      }

      const bgTransitionStyles = {
         entering: { opacity: 1 },
         entered: { opacity: 1 },
         exiting: { opacity: 0 },
      }

      const dialogTransitionStyles = isMobile ? {} : {
         entering: { opacity: 1, transform: undefined },
         entered: { opacity: 1, transform: undefined },
         exiting: {
            transition: 'all 120ms ease-out', // faster exit animation
            transform: 'translateY(20px)',
            opacity: 0,
         },
      }

      // Modal basic styles. Not for animation
      const dialogStyles = {}
      if (!isMobile) {
         if (width) {
            dialogStyles.maxWidth = width
            dialogStyles.width = 'calc(100vw - 24px)'
         }
         if (height) {
            dialogStyles.height = height
         } else {
            dialogStyles.display = 'block'
         }
      }


      return (
         <Transition
            timeout={isMobile ? 0 : this.bgAnimationDuration}
            in={this.state.showModal}
            appear
            onExited={onClose}
            exit={!isMobile} // Disable animations on mobile devices
            enter={!isMobile}
         >
            { (status) => (
               <div
                  className="main-modal-component"
                  style={{
                     ...initialModalStyle,
                     ...bgTransitionStyles[status],
                  }}
                  onClick={backgroundTapped}
               >

                  <div
                     id={id}
                     className={classNames('main-modal', { mobile: isMobile }, className)}
                     style={{
                        ...dialogStyles,
                        ...initialDialogStyle,
                        ...dialogTransitionStyles[status],
                     }}
                  >
                     <div className="main-modal__header">
                        { title
                           && <h3>{ title }</h3>
                        }
                        { headerContent }
                        <Button
                           iconSrc={Close}
                           className="main-modal__header__close-btn"
                           styleType={Button.StyleType.ICON}
                           disabled={disabled}
                           onClick={() => this.setState({ showModal: false }
                           )}
                        />
                     </div>

                     <div className="main-modal__content-container" style={this.state.containerStyle}>
                        {children}
                     </div>

                     <div className="main-modal__footer" ref={this.footerRef}>
                        { details
                           && <p className="details-txt">{ details }</p>
                        }
                        { error
                           && <p className="error-txt">{ error }</p>
                        }
                        <div className="main-modal__footer__line" />
                        <div className="main-modal__footer__btn-row">
                           {(leftBtnTitle && leftBtnPressed)
                              && <Button
                                 className="main-modal__left-btn"
                                 disabled={disabled}
                                 title={leftBtnTitle}
                                 styleType={Button.StyleType.PRIMARY}
                                 onClick={leftBtnPressed}
                              />
                           }
                           {(secondaryBtnTitle || (primaryBtnTitle && primaryBtnPressed))
                              && <div className="main-modal__footer__right-btns">
                                 { (secondaryBtnTitle)
                                    && <Button
                                       className="main-modal__footer__cancel-btn"
                                       disabled={disabled}
                                       title={secondaryBtnTitle}
                                       styleType={Button.StyleType.OUTLINED}
                                       onClick={secondaryBtnPressed || (() => {
                                          this.dialogAnimationDuration = 120
                                          this.setState({ showModal: false })
                                       })}
                                    />
                                 }
                                 {(primaryBtnTitle && primaryBtnPressed)
                                    && <Button
                                       className="main-modal__footer__right-btn"
                                       disabled={isPrimaryBtnDisabled || disabled}
                                       title={primaryBtnTitle}
                                       styleType={Button.StyleType.PRIMARY}
                                       onClick={primaryBtnPressed}
                                    />
                                 }
                              </div>
                           }
                        </div>
                     </div>

                  </div>
               </div>
            )}
         </Transition>
      )
   }

}


const mapStateToProps = (state) => ({
   device: state.device,
})

export default connect((mapStateToProps), {})(Modal)
