import AuthService from '@ava/react-common/services/auth-service'
import { setUser } from '@ava/react-common/store/actions/auth-actions'
import { errorToMessage } from '@ava/react-common/utils'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import fingerprint from '../../../assets/fingerprint.svg'
import Button from '../../Button/button'
import Input from '../../Input/input'
import './login-box.css'


class LoginBox extends Component {


   /**
    * @param {object} props
    * @param {string} [props.loginMethod] This can be used for showing hidden login method
    */
   constructor(props) {
      super(props)

      this.state = {
         username: '',
         password: '',
         isLoading: false,
         loginMethods: undefined,
         loginMethod: undefined,
         errorMessage: '',
      }
   }

   componentDidMount() {
      AuthService
         .getLoginMethods()
         .then((loginMethods) => {
            let loginMethod = loginMethods.find((lm) => lm.id === this.props.loginMethod) || null
            if (loginMethods.length === 1) loginMethod = loginMethods[0]

            // If login method is specified or there is only single login method and it is not "local", open the login page immediately
            if (loginMethod !== null && loginMethod.id !== 'local') {
               return LoginBox.loginPressed(loginMethod.url)
            }

            if (this.props.loginMethod !== undefined) this.setState({ loginMethod })
            else this.setState({ loginMethods })
         })
         .catch((err) => {
            this.setState({
               loginMethods: [],
               errorMessage: `Failed to get login methods:\n${errorToMessage(err)}`,
            })
         })
   }

   localLoginPressed = () => {
      const { password, username } = this.state

      if (!username || !password) {
         this.setState({ errorMessage: 'Username and password required!' })
         return
      }

      AuthService
         .login(username, password)
         .then((user) => {
            this.props.setUser(user)
         })
         .catch((err) => {
            this.setState({ errorMessage: err.message })
         })

   }

   static loginPressed = (loginPath) => {
      window.location.assign(`/api/login${loginPath}`)
   }

   render() {
      const { username, password, errorMessage, loginMethod, loginMethods } = this.state
      if (loginMethods === undefined && loginMethod === undefined) return null

      return (
         <div className="login-box">

            <div className="fingerprint-icon-box">
               <img src={fingerprint} alt="" />
            </div>

            <h2>Advanced Visual Analysis</h2>

            {(loginMethod === null)
               && `"${this.props.loginMethod}" is not a valid login method.`
            }

            {/* Username & password */}
            {((this.props.loginMethod === 'local') || loginMethods?.find((lm) => (lm.id === 'local' && !lm.isHidden)))
               && <React.Fragment>
                  <div className="input-container">
                     <Input
                        placeholder="Username"
                        value={username}
                        onChange={(value) => this.setState({ username: value })}
                        onEnterPressed={this.localLoginPressed}
                     />
                     <Input
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(value) => this.setState({ password: value })}
                        onEnterPressed={this.localLoginPressed}
                     />
                  </div>
                  <Button
                     className="login-button login-button--local"
                     styleType={Button.StyleType.PRIMARY}
                     title="LOGIN"
                     onClick={this.localLoginPressed}
                  />
               </React.Fragment>
            }

            {/* Other login methods */}
            { !this.props.loginMethod
               && loginMethods?.map((lm) => {
                  if (lm.id === 'local' || lm.isHidden) return null
                  return (
                     <Button
                        key={lm.title}
                        className="login-button"
                        styleType={Button.StyleType.PRIMARY}
                        title={lm.title.toUpperCase()}
                        onClick={() => LoginBox.loginPressed(lm.url)}
                     />
                  )
               })
            }
            <p className="login-error-message">
               {errorMessage}
            </p>
         </div>
      )
   }

}


export default connect(null, { setUser })(LoginBox)
