import ContinuousAnalysis from '@ava/react-common/models/continuous-analysis'
import DataService from '@ava/react-common/services/data-service'
import UserService from '@ava/react-common/services/user-service'
import { setContinuousAnalysisPositionState } from '@ava/react-common/store/actions/data-actions'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { selectSelectedTool, selectToolsPositions, selectToolsUnits } from '@ava/react-common/store/reducers/root-reducer'
import { dateToString, errorToMessage, useTypedSelector } from '@ava/react-common/utils'
import { getLanguageString } from '@ava/react-common/utils/Helper'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import Cogwheel from '../../../assets/cogwheel.svg'
import Info from '../../../assets/info.svg'
import Play from '../../../assets/play.svg'
import Stop from '../../../assets/stop.svg'
import Button from '../../Button/button'
import Dialog from '../../Dialog/dialog'
import Input from '../../Input/input'
import Modal from '../../Modal/modal'


const ContinuousAnalysesStatus = () => { // TODO: show position states exceptions and errors to user
   const useSelector = useTypedSelector()
   const selectedSite = useSelector((state) => state.selectedSite)
   const selectedTool = useSelector((state) => state.selectedTool)
   const positionStates = useSelector((state) => state.continuousAnalysispositionStates)
   const units = useSelector((state) => selectToolsUnits(state))
   const positions = useSelector((state) => selectToolsPositions(state))
   const dispatch = useDispatch()

   const [continuousAnalyses, setContinuousAnalyses] = useState([])
   const [initializing, setInitializing] = useState(true)
   const [selectedPosition, selectPosition] = useState(null)

   useEffect(() => {
      DataService.getContinuousAnalyses(selectedSite, selectedTool)
         .then(({ continuousAnalyses, positionStates }) => {
            for (const pos in positionStates) {
               dispatch(setContinuousAnalysisPositionState(pos, positionStates[pos]))
            }
            setContinuousAnalyses(continuousAnalyses)
            setInitializing(false)
         })
         .catch((err) => {
            dispatch(showDialog({
               title: 'Failed to get continuous analyses',
               text: errorToMessage(err),
            }))
         })
   }, [dispatch, selectedSite, selectedTool])


   const startPressed = async (analysis, positionName) => {
      await DataService
         .startOrStopContinuousAnalysis(analysis.id, true)
         .catch((err) => {
            dispatch(showDialog({
               title: `Failed to send start request to "${positionName}"`,
               text: errorToMessage(err),
            }))
         })
   }

   const stopPressed = async (analysis, positionName, reason) => {
      await DataService
         .startOrStopContinuousAnalysis(analysis.id, false, reason)
         .catch((err) => {
            dispatch(showDialog({
               title: `Failed to send stop request to "${positionName}"`,
               text: errorToMessage(err),
            }))
         })
   }

   return (
      <div>
         <PositionConfigModal
            onClose={() => selectPosition(null)}
            selectedPosition={selectedPosition}
            onContinuousAnalysisReceived={(continuousAnalysis) => {
               const analyses = [...continuousAnalyses]
               const index = analyses.findIndex((ca) => ca.position === selectedPosition.id)
               if (index === -1) { // New
                  analyses.push(continuousAnalysis)
               } else { // Replace existing
                  analyses[index] = continuousAnalysis
               }
               setContinuousAnalyses(analyses)
            }}
            continuousAnalysis={selectedPosition && continuousAnalyses.find((a) => a.position === selectedPosition.id)}
         />

         {units.map((unit, index) => {
            const unitsPositions = positions.filter((position) => position.unit === unit.id)
            if (unitsPositions.length === 0) return null
            return (
               <div key={unit.id} style={index > 0 ? { marginTop: '26px' } : {}}>
                  <p className="title">{unit.name}</p>
                  { unitsPositions.map((position) => {
                     const state = positionStates[position.id] || { status: ContinuousAnalysis.Status.STOPPED } // TODO: Should we get current state from api GET /continuousAnalyses
                     return (
                        <ItemRow
                           key={position.id}
                           continuousAnalysis={continuousAnalyses.find((ca) => ca.position === position.id)}
                           positionState={state}
                           initializing={initializing}
                           position={position}
                           startPressed={startPressed}
                           stopPressed={stopPressed}
                           onPositionSelected={selectPosition}
                        />
                     )
                  })
                  }
               </div>
            )
         }
         )}
      </div>
   )
}

export default ContinuousAnalysesStatus


/*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
|                  POSITION / ITEM ROW              |
\*__________________________________________________*/

const ItemRow = ({ position, initializing, continuousAnalysis, positionState, startPressed, stopPressed, onPositionSelected, isLoading = false }) => {

   const useSelector = useTypedSelector()
   const user = useSelector((state) => state.user)
   const timeZone = useSelector((state) => state.timeZone)

   const [isStopDialogVisible, showStopDialog] = useState(false)
   const [isStopInfoDialogVisible, setIsStopInfoDialogVisible] = useState(false)
   const [stopperUserName, setStopperUserName] = useState('')

   // Get analysis stopper user name
   useEffect(() => {
      if (!positionState?.stopMetadata?.userId) return
      UserService
         .getUser(positionState.stopMetadata.userId)
         .then(({ firstName, lastName }) => setStopperUserName(`${firstName} ${lastName}`))
         .catch((err) => console.error('Failed to get user', err))
   }, [positionState?.stopMetadata?.userId])

   if (!position) return null

   // STATUS TEXT
   let status = ''
   if (initializing) status = ContinuousAnalysis.Status.LOADING
   else status = ContinuousAnalysis.getStatus(continuousAnalysis, positionState)

   // SET STATUS COLOR
   const statusColors = {
      'not configured': '#989898',
      ...ContinuousAnalysis.StatusTextToColor,
   }
   const statusColor = statusColors[status]

   // RETURN
   return (
      <div style={{ marginTop: '6px' }}>
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p style={{ textOverflow: 'ellipsis', width: '168px' }}>{position.name}</p>
            <div style={{ marginRight: '-8px', display: 'flex', alignItems: 'center' }}>

               {(!positionState.keepRunning) ? (
                  <>
                     <Button // Start
                        style={{ height: '21px', width: '22px', marginBottom: '0px' }}
                        styleType={Button.StyleType.ICON}
                        iconSrc={Play}
                        onClick={() => startPressed(continuousAnalysis, position.name)}
                        disabled={
                           isLoading
                           || !user.hasUserPriviledges()
                           || (status === ContinuousAnalysis.Status.LOADING)
                           || (!continuousAnalysis || continuousAnalysis.isAdditionalConfigurationRequired)
                        }
                     />
                     {(!positionState.keepRunning && positionState.stopMetadata) && <Button // Stop info
                        style={{ height: '23px', width: '23px', marginBottom: '0px' }}
                        styleType={Button.StyleType.ICON}
                        iconSrc={Info}
                        onClick={() => setIsStopInfoDialogVisible(true)}
                        disabled={isLoading}
                     />}
                  </>
               ) : (
                  <Button // Stop
                     style={{ height: '20px', width: '22px', marginBottom: '0px' }}
                     styleType={Button.StyleType.ICON}
                     onClick={() => showStopDialog(true)}
                     iconSrc={Stop}
                     disabled={
                        isLoading
                        || !user.hasUserPriviledges()
                     }
                  />
               )}
               <Button
                  style={{ height: '22px', marginBottom: '0px' }}
                  styleType={Button.StyleType.ICON}
                  iconSrc={Cogwheel}
                  onClick={() => onPositionSelected(position)}
               />

            </div>
         </div>
         <p style={{ color: statusColor, fontSize: '15px', marginTop: '-4px', marginBottom: 0 }}>{status.replace(/-/g, ' ')}</p>

         <StopDialog
            visible={isStopDialogVisible}
            position={position}
            onClose={() => showStopDialog(false)}
            onStop={(reason) => {
               stopPressed(continuousAnalysis, position.name, reason)
               showStopDialog(false)
            }}
         />

         <Dialog
            visible={isStopInfoDialogVisible}
            title={`Stop info`}
            onClose={() => setIsStopInfoDialogVisible(false)}
         >
            {(positionState?.stopMetadata) && <>
               <span style={{ marginTop: 16 }}><b>User:</b> {stopperUserName}</span>
               <span style={{ marginTop: 8 }}><b>Timestamp:</b> {dateToString(new Date(positionState.stopMetadata.timestamp), timeZone, true)}</span>
               <span style={{ marginTop: 8 }}><b>Reason:</b> {positionState.stopMetadata.reason}</span>
            </>}
         </Dialog>
      </div>
   )
}

// STOP DIALOG
const StopDialog = ({ visible, onClose, onStop, position }) => {
   const [reason, setReason] = useState('')

   return (
      <Dialog
         visible={visible}
         title={`Stop analysis: ${position.name}`}
         buttons={[{
            text: 'Stop',
            style: 'danger',
            onPress: () => onStop(reason.trim()),
            disabled: reason.trim().length === 0,
         }, {
            text: 'Cancel',
         }]}
         onClose={() => {
            setReason('')
            onClose()
         }}
      >
         <p style={{ marginTop: 8 }}>Analysis will be stopped.</p>
         <p style={{ marginBottom: 4 }}>Please provide a reason for the stop:</p>
         <textarea
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            rows={3}
            maxLength={1024}
         />
      </Dialog>
   )
}



/*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
|                        MODAL                       |
\*__________________________________________________*/

const InputRow = ({ params, value, onChange, disabled }) => {
   const { label, description, format, unit, range, required } = params
   const title = label ? getLanguageString(label) : ''

   const desc = description && getLanguageString(description)
   const rangeString = range && `Range: ${range[0]} - ${range[1]}`

   let type = 'text'
   if (['float', 'integer', 'number'].includes(format)) type = 'number'

   let placeholder
   if (params.default !== undefined) placeholder = `${params.default}`

   return (
      <div style={{ width: '100%', marginBottom: '14px' }}>
         <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p style={{ color: '#333', minWidth: '260px', marginBottom: 0 }}>
               <strong>{title}</strong>
               {unit && ` (${unit})`}
               {required && '*'}
            </p>
            <Input
               type={type}
               value={value}
               onChange={onChange}
               disabled={disabled}
               placeholder={placeholder}
            />
         </div>
         {desc && <p style={{ color: '#989898', fontSize: '14px', marginBottom: '2px' }}>{desc}</p>}
         {rangeString && <p style={{ color: '#989898', fontSize: '14px', marginBottom: '2px' }}>{rangeString}</p>}
         {params.default && <p style={{ color: '#989898', fontSize: '14px', marginBottom: '2px' }}>{`Default: ${params.default}`}</p>}
      </div>
   )
}


// POSITION CONFIG MODAL COMPONENT
const PositionConfigModal = ({ onClose, selectedPosition, continuousAnalysis, onContinuousAnalysisReceived }) => {
   const useSelector = useTypedSelector()
   const selectedTool = useSelector((state) => state.selectedTool)
   const selectedSite = useSelector((state) => state.selectedSite)
   const user = useSelector((state) => state.user)
   const tool = useSelector((state) => selectSelectedTool(state))
   const [isEditing, setEditing] = useState(false)
   const [isLoading, setLoading] = useState(false)
   const [inputValues, setInputValues] = useState({})
   const [errorMessage, setErrorMessage] = useState(null)

   // TODO: ADD PARAMETERS OBJECT format to model: (required, unit etc.)
   const parameters = (tool && tool.config && tool.config.parameters) && tool.config.parameters

   useEffect(() => {

      // Clear previous input values
      setInputValues({})

      // Set input values (that are included in CA params)
      if (continuousAnalysis && continuousAnalysis.parameters) {
         const inputValuesToSet = {}
         for (const key in continuousAnalysis.parameters) {
            if (Object.keys(parameters).includes(key)) {
               inputValuesToSet[key] = continuousAnalysis.parameters[key]
            }
         }
         setInputValues(inputValuesToSet)
      }
   }, [continuousAnalysis, parameters])

   if (!selectedPosition) return null

   // SAVE BUTTON PRESSED
   const saveContinuousAnalysis = () => {

      // VALIDATION
      let error
      for (const key in parameters) {
         const parameter = parameters[key]
         const value = inputValues[key]
         const title = parameter.label ? getLanguageString(parameter.label) : ''
         const format = (parameter && parameter.format) && parameter.format
         const range = (parameter && parameter.range) && parameter.range

         if (value !== undefined) {
            // URL
            // if (format === "url") {
            // TODO: Add validation for url format if necessary
            // if (false) {
            //    return error = `Invalid url: ${title}`
            // }
            // }

            // INTEGER
            if (format === 'integer') {
               if (!Number.isInteger(Number(value))) {
                  error = `${title} accepts only integer`
                  break
               }
            }

            // RANGE
            if (['float', 'integer', 'number'].includes(format) && range) {
               if (value < range[0] || value > range[1]) {
                  error = `${title} out of range`
                  break
               }
            }
         }

         // REQUIRED
         if (parameter.required && (value === undefined)) {
            error = `${title} is required`
            break
         }
      }

      if (error) return setErrorMessage(error)

      setLoading(true)
      setErrorMessage(null)
      DataService.setContinuousAnalysis(selectedSite, selectedTool, selectedPosition.unit, selectedPosition.id, inputValues)
         .then((continuousAnalysis) => {
            onContinuousAnalysisReceived(continuousAnalysis)
            setLoading(false)
            setEditing(false)
         })
         .catch((err) => {
            let message = null
            if (typeof err === 'string') message = err
            else {
               console.error(err)
               message = 'Unexpected error'
            }
            setErrorMessage(message)
            setLoading(false)
         })
   }

   const createInputs = () => {
      const inputs = []
      for (const key in parameters) {
         const params = parameters[key]
         inputs.push(
            <InputRow
               key={key}
               value={`${inputValues[key] ?? ''}`}
               onChange={(val) => {
                  let value = val
                  if (value === '') value = undefined // Set empty strings as undefined so empty strings are not sent as values to API
                  if (value !== undefined && ['float', 'integer', 'number'].includes(params.format)) value = parseFloat(value) // Parse number values
                  setInputValues({
                     ...inputValues,
                     [key]: value,
                  })
               }}
               params={params}
               disabled={!isEditing || isLoading}
            />
         )
      }
      return inputs
   }

   // RETURN
   return ReactDOM.createPortal(
      <Modal
         title={selectedPosition.name}
         onClose={onClose}
         primaryBtnTitle={isEditing ? 'Save' : 'Close'}
         primaryBtnPressed={() => {
            if (isEditing) {
               // Save changes
               saveContinuousAnalysis()
            } else {
               // Close
               onClose()
            }
         }}
         secondaryBtnPressed={() => {
            if (!user.hasEngineerPriviledges()) return setErrorMessage("You don't have permission to edit")
            if (isEditing) {
               // Cancel editing
               setErrorMessage(null)
               setEditing(false)
               setInputValues((continuousAnalysis && continuousAnalysis.parameters) ? continuousAnalysis.parameters : {})
            } else {
               // set editing
               setEditing(true)
            }
         }}
         secondaryBtnTitle={isEditing ? 'Cancel' : 'Edit'}
         width="580px"
         error={errorMessage}
         disabled={isLoading}
      >
         {createInputs()}
      </Modal>,
      document.body
   )
}
