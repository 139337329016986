import React from 'react'
import SettingsContainer from '../SettingsContainer/settings-container'
import PreInitializedUsers from './pre-initialized-users'

/**
 * @returns {import('react').FunctionComponentElement}
 */
export default function PageUserMenu() {

   const sections = {
      // account: {
      //    title: "Account",
      //    component: page1
      // },
      // userManagement: {
      //    title: "Users",
      //    component: page2,
      // },
      preInitialisedUsers: {
         title: 'Pre Initialized Users',
         component: preInitializeUsers,
      },
   }

   return (
      <SettingsContainer
         title="User Menu"
         sections={sections}
         initial="preInitialisedUsers"
      />
   )
}

const preInitializeUsers = (props) => <PreInitializedUsers {...props} />
