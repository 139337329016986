import { CLEAR_ONGOING_ANALYSIS, SET_ONGOING_ANALYSIS } from '../actions/types'

/*
   socketId: string,
*/
const initialState = null

export default function ongoingAnalysisReducer(state = initialState, action) {
   switch (action.type) {

      case SET_ONGOING_ANALYSIS:
         return action.payload

      case CLEAR_ONGOING_ANALYSIS:
         return initialState

      default:
         return state

   }
}
