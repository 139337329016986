import { io } from 'socket.io-client'
import Analysis from '../models/analysis'

const EVENT__NEW_ANALYSES = 'analysis'

/**
 * SocketIOService class with static methods for
 *
 * Call initialize method before calling other methods
 */
export default class SocketIOService {

   /**
    * Initialization
    * @param {string} baseURL
    * @param {string} [authHeader]
    */
   static initialize(baseURL, authHeader) {
      if (this.socket) throw new Error('SocketIOService already intialized')

      this.baseURL = baseURL
      this.connectOptions = {
         path: `${SocketIOService.PATH}`,
         timeout: 5000,
         autoConnect: false,
         withCredentials: true,
         transportOptions: {
            polling: {
               extraHeaders: {
                  ...(authHeader && { Authorization: authHeader }),
               },
            },
         },
      }
      this.socket = io(`${baseURL}/`, this.connectOptions) // Root namespace

      let socketId
      this.socket.io.on('open', () => console.debug('Socket root opened'))
      this.socket.io.on('error', (error) => console.debug('Socket root error', { socketId, error }))
      this.socket.io.on('reconnect', () => console.debug('Socket root reconnected'))
      this.socket.io.on('close', () => console.debug('Socket root closed'))
      this.socket.on('connect', () => {
         socketId = this.socket.id
         console.debug('Socket root connected', { socketId })
      })
      this.socket.on('connect_error', (error) => console.debug('Socket root failed to connect connected', { error }))
      this.socket.on('disconnect', () => console.debug('Socket root disconnected', { socketId }))
   }

   /**
    * Removes all listeners, closes connection and sets socket to undefined.
    * SocketIOService has to be initialized again before calling other methods after this.
    */
   static closeAndClean() {
      if (!this.socket) return
      this.socket.close()
      Object.values(this.sockets).forEach((socket) => socket.close())
      this.numberOfSubscriptions = {}
      this.socket = undefined
      this.sockets = {}
   }


   /**
    * Connects to the websocket
    * @param {import('socket.io-client').Socket} socket
    * @param {object} [events]
    * @param {(wasConnectedAlready: boolean) => void} [events.onConnected]
    * @param {() => void} [events.onDisconnect]
    * @param {() => void} [events.onReconnect]
    * @param {() => void} [events.onTimeout]
    * @param {(err) => void} [events.onError]
    * @returns {() => void}
    */
   static connectWebSocket(socket, events = {}) {
      const { onConnected, onDisconnect, onReconnect, onTimeout, onError } = events
      let connectError
      let connect
      if (socket.connected) onConnected?.(true)
      else {
         connect = () => onConnected?.(false)
         connectError = (err) => onError?.(err)
         socket.open()
            .once('connect', connect)
            .once('connect_error', connectError)
      }

      const disconnect = () => onDisconnect?.()
      socket.on('disconnect', disconnect)

      const errorCallback = (err) => {
         if (err.message === 'timeout') onTimeout?.()
         else onError?.(err)
      }
      const reconnectCallback = () => onReconnect?.()
      socket.io
         .on('reconnect', reconnectCallback)
         .on('error', errorCallback)
      return () => {
         if (connect) socket.off('connect', connect)
         if (disconnect) socket.off('disconnect', disconnect)
         if (connectError) socket.off('connect_error', connectError)
         socket.io.off('reconnect', reconnectCallback)
         socket.io.off('error', errorCallback)
      }
   }

   /**
    * `NOTE:` Doesn't reinitialize analyses listener on after disconnect on reconnect
    * @param {object} events
    * @param {() => void} [events.onDisconnect]
    * @param {(err: Object, analysis: Analysis) => void} events.onNewAnalysis
    * @returns {{ onConnectedPromise: Promise<void>, unsubscribe: () => void }} Unsubscribe will cancel websocket connecting if it is ongoing and stops listening to disconnect event
    */
   static subscribeToOwnAnalysisCompletions({ onDisconnect, onNewAnalysis }) {
      let unsubConnectionListener
      let unsubNewAnalysis

      const newAnalysisCallback = (data) => {
         if (data.error) return onNewAnalysis(data.error, undefined)
         const analysis = Analysis.fromJson(data.analysis)
         onNewAnalysis(null, analysis)
      }

      const onConnectedPromise = new Promise((resolve, reject) => {
         unsubConnectionListener = this.connectWebSocket(this.socket, {
            onConnected: () => {
               unsubNewAnalysis = SocketIOService.subscribeToEvent(this.socket, EVENT__NEW_ANALYSES, newAnalysisCallback)
               resolve()
            },
            onDisconnect: () => onDisconnect?.(),
            onTimeout: () => reject(new Error('timeout')),
            onError: (err) => {
               if (typeof err === 'string') reject(new Error(err))
               else reject(err)
            },
         })
      })

      return {
         unsubscribe: () => {
            unsubConnectionListener()
            onConnectedPromise
               .then(() => unsubNewAnalysis())
               .catch(() => {})
         },
         onConnectedPromise,
      }
   }


   /**
    * `NOTE:` Must be used when subscribing to new events and called after socket is connected due to how unsubscribe is handled (see createUnsubFunc).\
    * `NOTE:` Does not connect to websocket automatically.
    * @param socket
    * @param event
    * @param {(...args: any) => void} callback
    * @returns {() => void} Unsubscribe
    */
   static subscribeToEvent(socket, event, callback) {
      if (!socket.id) throw new Error('Must be called after socket has been connected')
      console.debug('Socket listener added', { socketId: socket.id, event })
      socket.on(event, callback)
      return this.createUnsubFunc(socket, event, callback)
   }

   /**
    * `NOTE:` Closes websocket connection when there are no subscriptions left
    * @param {import('socket.io-client').Socket} socket
    * @param {string} event
    * @param {(...args: any) => any} callback
    * @private
    */
   static createUnsubFunc(socket, event, callback) {
      if (!this.numberOfSubscriptions[socket.id]) this.numberOfSubscriptions[socket.id] = 1
      else ++this.numberOfSubscriptions[socket.id]

      return () => {
         if (!socket) return
         if (socket.listeners(event).indexOf(callback) === -1) {
            console.debug('Socket listener was removed but not found from list', { socketId: socket.id, numOfListenersRemaining: this.numberOfSubscriptions[socket.id] })
            return
         }
         console.debug('Socket listener removed', { socketId: socket.id, event, numOfListenersRemaining: this.numberOfSubscriptions[socket.id] - 1 })
         socket.off(event, callback)
         if (--this.numberOfSubscriptions[socket.id] === 0) {
            console.debug('Socket closed', { socketId: socket.id })
            socket.close()
         }
      }
   }

}


/** @type {Object} numberOfSubscriptions is used for disconnecting from the websocket when there are no subscriptions left */
SocketIOService.numberOfSubscriptions = {}

/** Path to the websocket */
SocketIOService.PATH = '/ws'

/** @type {import('socket.io-client').Socket} */
SocketIOService.socket = undefined
/** @type {Object<string, import('socket.io-client').Socket>} */
SocketIOService.sockets = {}
/** @type {Object<string, (() => void)[]>} */
SocketIOService.socketsIoListeners = {}

/** @type {Partial<import('socket.io-client').ManagerOptions & import('socket.io-client').SocketOptions>} */
SocketIOService.connectOptions = undefined
