import Analysis from '../../models/analysis'
import { ADD_ANALYSES, CLEAR_DATA, EDIT_ANALYSIS, REMOVE_ANALYSIS, SET_ANALYSES, UPDATE_ANALYSES } from '../actions/types'

const ANALYSIS_LIMIT_OVERFLOW = 500
const initialState = {} // { <tool>: Analysis[] }


const analysesReducer = (state = initialState, action) => {

   if (SET_ANALYSES === action.type) {
      const { tool, analyses } = action.payload
      return { [tool]: analyses }
   }

   if (UPDATE_ANALYSES === action.type) {
      const { tool, analyses } = action.payload
      const currentState = {
         ...state,
         [tool]: [...(state[tool] || [])],
      }
      for (const analysis of analyses) {
         const index = currentState[tool].findIndex((a) => a.id === analysis.id)
         if (index === -1) continue
         currentState[tool][index] = analysis
      }
      return currentState
   }

   if (ADD_ANALYSES === action.type) {
      const { tool, analyses, limit, sort } = action.payload
      let existingAnalyses = [...(state[tool] || [])]

      // Delete multiple at once because chart doesn't know how to remove analyses from the beginning and has to redraw the chart compeletely when analyses are limited
      if (limit && (existingAnalyses.length > limit + ANALYSIS_LIMIT_OVERFLOW)) {
         existingAnalyses = existingAnalyses.slice(existingAnalyses.length - limit, existingAnalyses.length)
      }
      existingAnalyses.push(...analyses)
      if (sort) existingAnalyses.sort((a, b) => a.timestamp - b.timestamp)
      return {
         ...state,
         [tool]: existingAnalyses,
      }
   }

   if (EDIT_ANALYSIS === action.type) {
      const { tool, id, comment, tags, unit } = action.payload
      const index = state[tool].findIndex((a) => a.id === id)
      if (index === -1) return state
      const newAnalyses = [...(state[tool] || [])]
      const updatedAnalysis = Object.assign(new Analysis(),
         state[tool].find((a) => a.id === id),
         { comment, tags, unit }
      )
      newAnalyses[index] = updatedAnalysis
      return {
         ...state,
         [tool]: newAnalyses,
      }
   }

   if (REMOVE_ANALYSIS === action.type) {
      const { tool, id } = action.payload
      const index = state[tool].findIndex((a) => a.id === id)
      if (index === -1) return state
      const newAnalyses = [...(state[tool] || [])]
      newAnalyses.splice(index, 1)
      return {
         ...state,
         [tool]: newAnalyses,
      }
   }

   if (CLEAR_DATA === action.type) {
      return {}
   }


   return state

}

export default analysesReducer
