import { CLEAR_DATA, SET_LIVE_UPDATE_FOLLOW_RANGE, STOP_LIVE_UPDATE } from '../actions/types'

/**
 * @typedef {('all'|number)} LiveUpdateFollowRange In milliseconds (3600000 = 1 hour)
 */
const initialState = null


/**
 * Timeframe should keep this value as selection range and moved to end of timeframe.
 * Allowed values: null, <milliseconds>, "all"
 *
 * If value is null, dont follow live data
 *
 * @type {import("./root-reducer").ReduxReducer<LiveUpdateFollowRange>}
 */
export default function liveUpdateFollowRangeReducer(state = initialState, action) {
   switch (action.type) {

      case SET_LIVE_UPDATE_FOLLOW_RANGE:
         return action.payload.followRange

      case STOP_LIVE_UPDATE:
      case CLEAR_DATA:
         return null

      default:
         return state

   }
}
