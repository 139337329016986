import DataService from '@ava/react-common/services/data-service'
import SocketIOService from '@ava/react-common/services/socket-io-service'
import { createTag } from '@ava/react-common/store/actions/configuration-action'
import { clearData } from '@ava/react-common/store/actions/data-actions'
import { clearOngoingAnalysis, setOngoingAnalysis } from '@ava/react-common/store/actions/ongoing-analysis-actions'
import { selectSelectedTool, selectToolsPositions, selectToolsTags, selectToolsUnits } from '@ava/react-common/store/reducers/root-reducer'
import { errorToMessage, isApiError } from '@ava/react-common/utils'
import { dateToString, removeObjectFromArray } from '@ava/react-common/utils/Helper'
import classNames from 'classnames'
import EXIF from 'exif-js'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Close from '../../../assets/close-white.svg'
import Button from '../../Button/button'
import Dialog from '../../Dialog/dialog'
import DropdownButton from '../../DropdownButton/dropdown-button'
import Input from '../../Input/input'
import LoadingSpinner from '../../LoadingSpinner/loading-spinner'
import MediaContainer from '../../MediaContainer/media-container'
import Modal from '../../Modal/modal'
import ReactSelect from '../../ReactSelect/react-select'
import './import-modal.scss'

/**
 * @typedef {{ file: Blob, dateTaken: Date, error: string, position: string }} ImportModalAnalysisItem
 */
/**
 * @typedef {{
 *   status: 'uploading' | 'failed' | 'analyzing' | null
 *   value: number | null
 *   fileNumber: number | null
 *   fileChunkIndex: number | null
 *   analysisId?: string
 *   analysisPositions?: string[]
 * }} AnalyzeProgress
 */

// TODO: REMOVE EDITING. Editing has been moved to editmodal
class ImportModal extends Component {

   constructor(props) {
      super(props)

      this.stateTypes = {
         addFiles: 0,
         addInfo: 1,
      }

      this.state = {
         modalState: this.stateTypes.addFiles,
         /** @type {ImportModalAnalysisItem[]} */
         analysisItems: [],
         comment: '',
         tags: [],
         position: null,
         positionOptions: [],
         tagOptions: [],
         unit: null,
         isLoading: false,
         /** @type {AnalyzeProgress} */
         analyzeProgress: {
            status: null,
            value: null,
            fileNumber: null,
            fileChunkIndex: null,
         },
         /** @type {string | null} */
         dialogMessage: null,

         // Tag creation
         isTagDialogVisible: false,
         tagName: '',
         tagErrorMessage: null,
      }

      this.unitOptions = this.props.units.map((unit) => ({
         value: unit.id,
         label: unit.name,
      }))

      this.cancelAnalysis = null

      this.isDragSupported = () => {
         const div = document.createElement('div')
         return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
      }

      this.acceptedFileTypes = 'image/jpeg,image/png,image/gif'
      if (this.props.tool.config.fileType === 'video') {
         this.acceptedFileTypes = 'video/mp4,video/x-m4v,video/*'
      }

      this.inputRefs = [] // { <position>: <inputRef> }

   }


   componentDidMount() {

      this.props.positions.forEach((position) => {
         this.detectDrag(position.id)
      })

      if (this.unitOptions.length === 1) {
         this.setState({ unit: this.unitOptions[0] })
      }

      this.setPositionOptions()
      this.setTagOptions()
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevState.unit !== this.state.unit) {
         this.setPositionOptions()
      }
      // If new tag is added: reload options
      if (prevProps.tags.length !== this.props.tags.length) {
         this.setTagOptions()
      }
   }

   setPositionOptions = () => {
      if (this.state.unit) {
         const filteredPositions = this.props.positions.filter((p) => p.unit === this.state.unit.value)
         const positionOptions = filteredPositions.map((position) => ({ value: position.id, label: position.name }))
         this.setState({ positionOptions })
      } else {
         this.setState({ positionOptions: [] })
      }
   }

   setTagOptions = () => {
      const tagOptions = this.props.tags.map((tag) => ({ value: tag.id, label: tag.name }))
      this.setState({ tagOptions })
   }

   getExifDateTaken(image, callback) {
      // Get date when picture originally taken
      EXIF.getData(image, () => {
         // console.log(EXIF.getAllTags(this)) // Get all available tags
         const exifDate = EXIF.getTag(this, 'DateTimeOriginal')

         // Exif data does not have timezone.
         // Create date object from exif date string
         if (!exifDate) {
            return callback(null)
         }

         const str = exifDate.split(' ')
         const dateStr = str[0].replace(/:/g, '/')
         const modified = `${dateStr} ${str[1]}`
         const dateTaken = new Date(modified)
         callback(dateTaken)
      })
   }


   /**
    * @param {object} options
    * @param {ImportModalAnalysisItem} options.analysisItem
    * @param {boolean} [options.isAlreadySentToAnalysis]
    * @param {boolean} [options.didAnalysisFail]
    * @returns
    */
   createFilePreview = ({ analysisItem, isAlreadySentToAnalysis, didAnalysisFail }) => {
      const { timeZone } = this.props
      return (
         <div
            key={analysisItem.file.name}
            className={classNames(
               'file-preview',
               { 'file-preview--error': analysisItem.error },
               { 'file-preview--disabled': didAnalysisFail }
            )}>
            <MediaContainer
               src={URL.createObjectURL(analysisItem.file)}
               type={analysisItem.file.type.split('/')?.[0]}
               contentType={analysisItem.file.type}
            />
            {(!didAnalysisFail) && (
               <div>
                  <Button
                     className="remove-file-btn"
                     styleType={Button.StyleType.ICON}
                     iconSrc={Close}
                     onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        this.inputRefs[analysisItem.position].current.value = '' // Clear input value
                        const analysisItems = removeObjectFromArray([...this.state.analysisItems], analysisItem)
                        this.setState({ analysisItems })
                     }}
                  />
               </div>
            )}
            {(isAlreadySentToAnalysis) && (
               <div className="file-preview__already-uploaded-text">Already sent to analysis</div>
            )}
            <div className="image-footer">
               <p>{analysisItem.error ? analysisItem.error : dateToString(analysisItem.dateTaken, timeZone, true)}</p>
            </div>
            {/* <p className="date-str">{this.getDateString(file)}</p> */}
         </div>
      )
   }


   generateModalContent() {
      const { size } = this.props
      const { analyzeProgress } = this.state

      const filePreviewPairs = []
      for (let i = 0; i < Math.ceil(this.state.analysisItems.length / 2); ++i) {
         const filePair = []
         for (let j = 0; j <= 1; ++j) {
            filePair.push(this.state.analysisItems[i * 2 + j])
         }

         const filePreviewPair = (
            <div className={size === 'xs' ? 'file-preview-container' : 'file-preview-pair-container'} key={i}>
               {filePair.map((analysisItem) => {
                  if (analysisItem == null) return null
                  return this.createFilePreview({ analysisItem })
               })}
            </div>
         )
         filePreviewPairs.push(filePreviewPair)
      }

      const importArea = () => {

         let text = null
         if (!this.state.unit) {
            if (this.unitOptions.length === 0) {
               text = `No units available! Add this tool (${this.props.tool.name}) to an existing unit or create a new unit from toolbox options` // TODO: Improve message
            }
         }

         if (this.state.unit && this.state.positionOptions.length === 0) {
            text = 'No positions available. Add positions from tools configuration (Side menu - More - Configuration).'
         }

         return (
            <div className="import-modal__import-area">
            {/* <div className="row"> */}
               {/* TODO: inline style is temporary fix -> marginTop 30% is hack, center correctly*/}
               {text
                  && <h3 style={{ fontWeight: 300, width: '100%', marginTop: '30%', textAlign: 'center' }}>{text}</h3>
               }

               {/* ---- UNITS ---- */}
               { !this.state.unit && (
                  this.props.units.map((unit) => (
                     <div key={unit.id} style={{ }}>
                        <div
                           className={'import-modal__import-area__select-unit-button'}
                           onClick={() => this.setState({ unit: { value: unit.id, label: unit.name } })}
                        >
                           <p>{unit.name}</p>
                        </div>
                     </div>
                  ))
               )}

               {/* ---- POSITIONS ---- */}
               {this.props.positions.map((position) => {
                  const inputRef = React.createRef()
                  this.inputRefs[position.id] = inputRef

                  // If analysis upload failed show only positions that were originally sent to analysis
                  if (this.state.analyzeProgress.status === 'failed' && !this.state.analyzeProgress.analysisPositions.find((pos) => (position.id === pos))) return null

                  const isAlreadySentToAnalysis = !!this.state.analysisItems.find((item, i) => (position.id === item.position && i < this.state.analyzeProgress.fileNumber - 1))
                  const didAnalysisFail = this.state.analyzeProgress.status === 'failed'
                  return (
                     <div key={position.id} hidden={!this.state.unit || position.unit !== this.state.unit.value}>
                        <div
                           className={classNames('import-modal__import-area__position', {
                              'import-modal__import-area__position--error': this.hasErrors(),
                              'import-modal__import-area__position--drag-supported': this.isDragSupported(),
                              'import-modal__import-area__position--disabled': didAnalysisFail,
                           })}
                           onClick={() => inputRef.current.click()} // Click the file input when clicking import area
                        >
                           {(this.state.analysisItems.filter((image) => image.position === position.id).length === 0) && (
                              <p>Drag or Tap</p>
                           )}

                           <input
                              ref={inputRef}
                              className="add-files"
                              accept={this.acceptedFileTypes}
                              type="file"
                              id="file"
                              onClick={(e) => {
                                 if (didAnalysisFail) e.preventDefault()
                              }}
                              onChange={(event) => {
                                 const files = event.target.files
                                 if (files.length > 0) this.addFile(files[files.length - 1], position.id)
                              }}
                           />

                           { this.state.analysisItems
                              .filter((image) => image.position === position.id)
                              .map((analysisItem) => this.createFilePreview({
                                 analysisItem,
                                 isAlreadySentToAnalysis,
                                 didAnalysisFail,
                              }))
                           }

                        </div>
                        <h4 style={{ marginTop: '6px', marginBottom: '20px' }}>{position.name}</h4>
                     </div>
                  ) }
               )}
            </div>
         )
      }

      const editingArea = (
         <div className="edit-area">
            <h4>Tags</h4>
            <ReactSelect
               value={this.state.tags}
               onChange={(tags) => this.setState({ tags })}
               isMulti={true}
               options={this.state.tagOptions}
               disabled={this.state.analyzeProgress.status === 'failed'}
            />
            { this.state.analyzeProgress.status !== 'failed' && (
               <Button
                  className="create-new-tag-btn"
                  title="Create new tag"
                  styleType={Button.StyleType.TEXT}
                  onClick={() => this.setState({ isTagDialogVisible: true })}
               />
            )}
            <br />
            <br />

            <h4>Comment</h4>
            <textarea
               id="comment-area"
               onChange={() => this.setState({ comment: (document.getElementById('comment-area').value) })}
               value={(this.state.comment)}
               name="comment"
               cols={40}
               rows={4}
               disabled={this.state.analyzeProgress.status === 'failed'}
            />
            <br />
         </div>
      )

      const infoArea = (
         <div className="info-area">

            {this.state.isLoading ? (
               <div className="loader-container">
                  <br />
                  <br />
                  <br />
                  <br />
                  <LoadingSpinner
                     progress={analyzeProgress.status === 'uploading' ? analyzeProgress.value : undefined}
                     text={(analyzeProgress.status === 'uploading' ? `${analyzeProgress.value}%` : undefined)}
                  />
                  <p>{ analyzeProgress.status === 'uploading' ? `Uploading file ${analyzeProgress.fileNumber}/${this.state.analysisItems.length}` : 'Analyzing' }</p>
               </div>
            ) : (
               <div>
                  {editingArea}
               </div>
            )}

         </div>
      )

      switch (this.state.modalState) {
         case this.stateTypes.addFiles:
            return importArea()
         case this.stateTypes.addInfo:
            return infoArea
         default:
            return <div />
      }
   }

   detectDrag(positionId) {
      if (!this.isDragSupported()) return

      const form = this.inputRefs[positionId].current.parentElement

      for (const event of ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']) {
         form.addEventListener(event, (e) => {
            e.preventDefault()
            e.stopPropagation()
         })
      }
      for (const event of ['dragover', 'dragstart', 'dragenter']) {
         form.addEventListener(event, () => {
            form.classList.add('is-dragover')
         })
      }
      for (const event of ['dragleave', 'dragend', 'drop']) {
         form.addEventListener(event, () => {
            form.classList.remove('is-dragover')
         })
      }
      form.addEventListener('drop', (e) => {
         // TODO: Check accepted image/file types
         if (this.props.positions.length === 0) {
            this.setState({ dialogMessage: 'Add position first' })
         } else {
            const files = e.dataTransfer.files
            this.addFile(files[files.length - 1], positionId)
         }
      })
   }


   saveTag = () => {
      // validation
      const name = this.state.tagName.trim()
      if (!name || name === '') {
         return this.setState({ tagErrorMessage: 'Name is required' })
      }

      // Create tag
      DataService.createTag(this.state.tagName, this.props.toolId, this.props.siteId)
         .then((tag) => {
            // Add created tag to redux
            this.props.createTag(tag.id, tag.name, tag.tool)
            // Autoselect created tag and close modal
            this.setState((prevState) => ({
               tags: [...prevState.tags, { value: tag.id, label: tag.name }],
               isTagDialogVisible: false,
               tagErrorMessage: null,
               tagName: '',
            }
            ))
         })
         .catch((error) => {
            this.setState({ tagErrorMessage: error })
            console.error(error)
         })
   }

   addFile = (file, position) => {

      // Validate filetypes
      let isValidFileType = false
      for (const acceptedType of this.acceptedFileTypes.split(',')) {
         if (acceptedType === file.type || (
            acceptedType.includes('/*')
               && acceptedType.split('/')[0] === file.type.split('/')[0]
         )
         ) {
            isValidFileType = true
            break
         }
      }
      if (!isValidFileType) {
         const analysisItem = this.state.analysisItems.find((item) => item.position === position)
         const analysisItems = removeObjectFromArray([...this.state.analysisItems], analysisItem)
         this.setState({
            analysisItems,
            dialogMessage: 'Invalid file type',
         })
         return
      }

      this.getExifDateTaken(file, (date) => {
         let fileDetails = null
         let dateTaken = null
         if (date) {
            dateTaken = date
         } else if (file.lastModified) {
            dateTaken = new Date(file.lastModified)
         } else if (file.lastModifiedDate) { // For IE (deprecated)
            dateTaken = file.lastModifiedDate
         }

         if (dateTaken) {
            fileDetails = {
               file,
               dateTaken,
            }
         } else {
            fileDetails = {
               file,
               error: 'Cannot get date',
            }
         }

         if (position) {
            fileDetails.position = position
         }

         const noSimilarName = this.state.analysisItems.find((item) => item.file.name === file.name) == null
         const noSimilarDate = this.state.analysisItems.find((item) => (`${item.dateTaken}`) === (`${dateTaken}`)) == null
         // Check if already exists
         if (noSimilarName || (noSimilarDate)) {
            const filtered = this.state.analysisItems.filter((image) => image.position !== position)
            this.setState({ analysisItems: [...filtered, fileDetails] })
         } else {
            this.setState({ dialogMessage: 'File already exist' })
         }
      })
   }

   nextPressed = () => {
      if (!this.state.unit) {
         return this.setState({ dialogMessage: 'Select Unit!' })
      }

      if (this.state.analysisItems.length === 0) {
         return this.setState({ dialogMessage: 'No files' })
      }


      // Require to upload all positions that were selected in original analysis request
      if (this.state.analyzeProgress.status === 'failed' && (
         this.state.analyzeProgress.analysisPositions
            .map((pos) => !!this.state.analysisItems.find((item) => (item.position === pos)))
            .includes(false)
      )) {
         return this.setState({ dialogMessage: 'Please add file to all positions' })
      }

      for (const image of this.state.analysisItems) {
         if (image.error) {
            return this.setState({ dialogMessage: 'Date missing from file' })
         }
      }
      this.setState({ modalState: this.stateTypes.addInfo })
   }

   previousPressed = () => {
      if (this.state.analyzeProgress.status === 'uploading') this.cancelAnalysis?.()
      else this.setState({ modalState: this.stateTypes.addFiles })
   }

   analyzePressed = () => {
      const { unit, comment, analysisItems } = this.state
      const { toolId, siteId } = this.props

      // Validation
      if (!unit) {
         this.setState({ dialogMessage: 'Select processing unit' })
         return
      }

      if (!analysisItems || analysisItems.length === 0) {
         this.setState({ dialogMessage: 'Something went wrong' })
         return
      }

      const tags = this.state.tags
         ? this.state.tags.map((tag) => tag.value)
         : []


      /**
       * @param {object} options
       * @param {string} options.analysisId
       * @param {number} options.fileNumber
       * @param {number} [options.minChunkSize]
       * @param {number} [options.fileChunkIndex]
       */
      const analyze = (options) => {
         const { analysisId, fileNumber, minChunkSize, fileChunkIndex = 0 } = options

         this.setState({
            isLoading: true,
            analyzeProgress: {
               status: 'uploading',
               value: 0,
               fileNumber,
               fileChunkIndex,
            },
         })

         const onAnalysisProgress = (progress) => {
            const { status, value, fileChunkIndex } = progress
            if (status === 'uploading' || (status === 'analyzing' && fileNumber === analysisItems.length)) {
               this.setState({
                  analyzeProgress: { status, value, fileNumber, fileChunkIndex },
               })
            }
         }

         const socketId = SocketIOService.socket.id
         this.props.setOngoingAnalysis({ socketId })

         const onFailure = (error) => {
            this.cancelAnalysis = null
            this.props.clearOngoingAnalysis()
            this.setState((state) => ({
               isLoading: false,
               analyzeProgress: {
                  ...state.analyzeProgress,
                  status: 'failed',
                  // value: null,
                  fileNumber,
                  analysisId,
                  analysisPositions: analysisItems.map(({ position }) => position),
               },
            }))

            const analysisItem = analysisItems[fileNumber - 1]
            const positionName = this.props.positions.find(({ id }) => (id === analysisItem.position)).name

            // If "Request too large error"
            if (isApiError(error, 413)) {
               this.setState({
                  dialogMessage: `"${positionName}" file is too large for upload.`,
                  modalState: this.stateTypes.addFiles,
               })
            }
            // Other error
            else if (error.message !== 'Cancelled') {
               console.error(error)
               this.setState({ dialogMessage: `Failed to upload "${positionName}" file.\n${errorToMessage(error)}` })
            }
         }

         if (!analysisId) {
            DataService
               .createAnalysis({
                  toolId,
                  siteId,
                  unitId: unit.value,
                  comment,
                  tags,
                  socketId,
                  fileDetails: analysisItems.map(({ dateTaken, position }) => ({ dateTaken, position })),
               })
               .then(({ id, minChunkSize }) => {
                  analyze({ analysisId: id, fileNumber: 1, minChunkSize })
               })
               .catch((err) => onFailure(err))

         } else {
            const analysisItem = analysisItems[fileNumber - 1]
            const { cancel } = DataService.uploadAnalysisFile({
               toolId,
               analysisId,
               positionId: analysisItem.position,
               file: analysisItem.file,
               fileName: analysisItem.file.name,
               continueFromFileChunkIndex: fileChunkIndex,
               dateTaken: analysisItem.dateTaken,
               socketId,
               chunkedUpload: {
                  enabled: true,
                  minChunkSize,
               },
               onCompleted: () => {
                  this.cancelAnalysis = null
                  if (fileNumber < analysisItems.length) {
                     analyze({ analysisId, fileNumber: fileNumber + 1, minChunkSize })
                  } else {
                     this.setState({ isLoading: false })
                     this.props.clearData()
                     this.props.onClose()
                  }
               },
               onFailure,
               onProgress: onAnalysisProgress
            })
            this.cancelAnalysis = cancel
         }
      }

      analyze({
         analysisId: this.state.analyzeProgress.analysisId,
         fileNumber: this.state.analyzeProgress.fileNumber || 1,
         fileChunkIndex: this.state.analyzeProgress.fileChunkIndex || 0,
      })
   }


   getDetails = () => {
      const { unit, analysisItems } = this.state
      if (!unit || !analysisItems) return null

      let error = ''
      let details = analysisItems.length.toString()
      if (analysisItems.length > 6) {
         details += '/6 '
         error = ' | Too many files.'
      }
      if (analysisItems.length === 1) {
         details += ' file'
      } else {
         details += ' files'
      }

      let filesSize = 0
      analysisItems.forEach((item) => {
         filesSize += item.file.size
      })

      if (filesSize >= 1000000) {
         details += `, ${Math.round((filesSize / 1000000) * 100) / 100} mb`
      } else {
         details += `, ${Math.round((filesSize / 1000))} kb`
      }

      for (const item of this.state.analysisItems) {
         if (item.error) {
            error = ' | Date missing from file.'
            break
         }
      }

      return details + error
   }

   hasErrors = () => {
      for (const image of this.state.analysisItems) {
         if (image.error) return true
      }
      return false
   }


   render() {

      const { onClose } = this.props
      const { modalState, isLoading, dialogMessage, isTagDialogVisible, unit, tagName, tagErrorMessage } = this.state

      const isImportState = (modalState === this.stateTypes.addFiles)
      const hasErrors = this.hasErrors()
      const details = this.getDetails()

      const unitDropdown = (
         <DropdownButton
            className="modal-header-unit-dropdown"
            title={unit ? unit.label : 'No processing unit'}
            menuItems={this.unitOptions}
            selected={unit}
            disabled={this.state.modalState !== this.stateTypes.addFiles || this.state.analyzeProgress.status === 'failed'}
            isBlack={true}
            onSelect={(id) => {
               const unit = this.unitOptions.find((unit) => unit.value === id)
               if (unit && (!this.state.unit || unit.value !== this.state.unit.value)) {
                  this.setState({ unit })
                  this.setState({ analysisItems: [] })
               }
            }}
         />
      )

      return (
         <Modal
            title={(!this.state.unit && 'Select processing unit') || (isImportState ? 'Add files' : 'Analyze')}
            details={!hasErrors ? details : null}
            error={hasErrors ? details : null}
            headerContent={unitDropdown}
            isPrimaryBtnDisabled={hasErrors || this.state.analyzeProgress.status === 'uploading'}
            disabled={isLoading && this.state.analyzeProgress.status !== 'uploading'}
            primaryBtnTitle={isImportState ? 'NEXT' : 'ANALYZE'}
            secondaryBtnTitle={(isImportState || this.state.analyzeProgress.status === 'uploading') ? 'CANCEL' : 'PREVIOUS'}
            primaryBtnPressed={isImportState ? this.nextPressed : this.analyzePressed}
            secondaryBtnPressed={isImportState ? null : this.previousPressed} // when null, closes the modal
            onClose={onClose}
            height="720px"
            width="900px"
         >
            <div className="import-modal">
               {this.generateModalContent()}
            </div>

            <Dialog
               visible={!!dialogMessage}
               title="Alert"
               message={dialogMessage}
               buttons={[{ text: 'OK' }]}
               onClose={() => this.setState({ dialogMessage: null })
               }
            />

            {/* Create New Tag Dialog*/}
            <Dialog
               visible={isTagDialogVisible}
               title="Create new tag"
               buttons={[{
                  text: 'Save',
                  onPress: this.saveTag,
               }, {
                  text: 'Cancel',
               }]}
               onClose={() => {
                  this.setState({ isTagDialogVisible: false, tagErrorMessage: null, tagName: '' })
               }}
            >
               <div>
                  <Input
                     placeholder="Name"
                     value={tagName}
                     autoFocus={true}
                     onChange={(value) => {
                        this.setState({ tagName: value })
                     }}
                     onEnterPressed={this.saveTag}
                  />
                  {tagErrorMessage && <p className="error-message">{tagErrorMessage}</p>}

               </div>

            </Dialog>

         </Modal>
      )
   }

}


const mapStateToProps = (state) => ({
   tool: selectSelectedTool(state),
   siteId: state.selectedSite,
   toolId: state.selectedTool,
   tags: selectToolsTags(state),
   units: selectToolsUnits(state),
   positions: selectToolsPositions(state),
   size: state.device.size,
   isMobile: state.device.isMobile,
   items: state.items,
   timeZone: state.timeZone,
})

export default connect(mapStateToProps, { createTag, clearData, setOngoingAnalysis, clearOngoingAnalysis })(ImportModal)
