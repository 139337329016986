import { ADD_TAG_FILTER, CLEAR_DATA, DELETE_TAG, EXCLUDE_TAG, SET_TAG_FILTERS } from '../actions/types'

const initialState = {}

const tagFiltersReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_TAG_FILTERS:
         return action.payload.reduce((obj, tag) => {
            obj[tag] = { id: tag, exclude: false }
            return obj
         }, {})
      case ADD_TAG_FILTER:
         const tagId = action.payload
         return {
            ...state,
            [tagId]: { id: tagId, exclude: false },
         }
      case EXCLUDE_TAG:
         return {
            ...state,
            [action.payload.id]: { id: action.payload.id, exclude: action.payload.exclude },
         }

      case DELETE_TAG:
         const newState = { ...state }
         delete newState[action.payload.id]
         return newState

      case CLEAR_DATA:
         return {}

      default:
         return state
   }
}

export default tagFiltersReducer
