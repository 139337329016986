import Tag from '../../models/tag'
import { CREATE_TAG, DELETE_TAG, RENAME_TAG, SET_TAGS } from '../actions/types'

const initialState = {}

const tagsReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_TAGS:
         return action.payload.reduce((obj, tag) => {
            obj[tag.id] = tag
            return obj
         }, {})

      case DELETE_TAG:
         const newState = { ...state }
         delete newState[action.payload.id]
         return newState

      case RENAME_TAG:
         const newState1 = {
            ...state,
            [action.payload.id]: {
               ...state[action.payload.id],
               name: action.payload.name,
            },
         }
         return newState1

      case CREATE_TAG:
         const newTag = new Tag(action.payload.id, action.payload.name, action.payload.tool)
         const newState2 = {
            ...state,
            [action.payload.id]: newTag,
         }
         return newState2

      default:
         return state

   }
}

export default tagsReducer
