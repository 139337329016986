import Tool from '@ava/react-common/models/tool'
import AuthService from '@ava/react-common/services/auth-service'
import SocketIOService from '@ava/react-common/services/socket-io-service'
import { clearLocalSession, setUser } from '@ava/react-common/store/actions/auth-actions'
import { selectTool } from '@ava/react-common/store/actions/data-actions'
import { setSize } from '@ava/react-common/store/actions/device-actions'
import { closeDialog, showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { setTags, setUnits } from '@ava/react-common/store/actions/setup-actions'
import { loadSites } from '@ava/react-common/store/actions/site-actions'
import { setToolboxes, setTools } from '@ava/react-common/store/actions/toolbox-actions'
import { errorToMessage } from '@ava/react-common/utils'
import axiosAvaApiClient from '@ava/react-common/utils/axiosAvaApiClient'
import { enGB } from 'date-fns/locale'
import { throttle } from 'lodash'
import React, { Component, useEffect } from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { connect, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch, useLocation, withRouter } from 'react-router-dom'
import 'react-virtualized/styles.css' // // only needs to be imported once
import './App.css'
import Dialog from './components/Dialog/dialog'
// import Page3D from './components/Page3D/page-3d'
import PageConnectMobile from './components/PageConnectMobile/page-connect-mobile'
import PageHome from './components/PageHome/page-home'
import PageStatistics from './components/PageHome/PageStatistics/page-statistics'
import PageLogin from './components/PageLogin/page-login'
import PageTool from './components/PageTool/page-tool'
import PageToolConfiguration from './components/PageTool/PageToolConfiguration/page-tool-configuration'
import PageUserManagement from './components/PageUserManagement/page-user-management'
import PageUserMenu from './components/PageUserMenu/page-user-menu'


class App extends Component {

   constructor(props) {
      super(props)
      this.state = {
         referrer: null, // URL path from where user was redirected to login.
         isSocketIoInitialized: false,
      }
   }

   componentDidMount() {

      AuthService
         .getCurrentUser()
         .then((user) => {
            if (user) this.props.setUser(user)
            else this.props.clearLocalSession()
         })
         .catch((err) => {
            this.props.showDialog({
               title: 'Failed to get user',
               text: errorToMessage(err),
            })
         })

      this.props.setSize(window.innerWidth, window.innerHeight)
      window.addEventListener('resize', throttle(this.onWindowResize, 200))

      // Set datepicker locale
      registerLocale('enGB', enGB)
      setDefaultLocale('enGB')


      // Add response interceptor to axios to direct user to front page when credentials expire (https://github.com/axios/axios)
      axiosAvaApiClient.interceptors.response.use((response) => response, (error) => {
         if (error.response && error.response.status === 401) {
            this.props.clearLocalSession()
         }
         return Promise.reject(error)
      })

      // Initialize Socket IO Service
      SocketIOService.initialize(process.env.PUBLIC_URL)

      // Catch CSP errors
      document.addEventListener('securitypolicyviolation', this.onSecurityPolicyViolation) // https://developer.mozilla.org/en-US/docs/Web/API/SecurityPolicyViolationEvent
   }

   componentWillUnmount() {
      window.removeEventListener('resize', this.onWindowResize)
      document.removeEventListener('securitypolicyviolation', this.onSecurityPolicyViolation)
   }

   componentDidUpdate(prevProps) {
      // On sign in
      if (this.props.auth.isAuthenticated && !prevProps.auth.isAuthenticated) {
         try {
            // Site cookie
            const siteFromLocalStorage = localStorage.getItem('currentSite')
            this.props.loadSites(siteFromLocalStorage).then((site) => {
               localStorage.setItem('currentSite', site)
            })
         } catch(err) {
            this.props.clearLocalSession()
         }
      }
   }


   onSecurityPolicyViolation(e) {
      const keys = Object.keys(Object.getPrototypeOf(e))
      this.props.showDialog({
         title: 'Content security policy violation!',
         text: keys.map((key) => `${key}: ${e[key]}`).join('\n'),
      })
   }

   onWindowResize = () => {
      this.props.setSize(window.innerWidth, window.innerHeight)
   }


   render() {
      const { referrer } = this.state
      const { auth, selectedSite, selectedTool, sites, user, dialog } = this.props
      const { isAuthenticating, isAuthenticated } = auth

      const loading = (sites === null || (selectedSite === null && Object.keys(sites).length !== 0))

      return (
         <div className="app">

            {/* Reducer Dialog */}
            <Dialog
               title={dialog?.title}
               visible={dialog}
               message={dialog?.text}
               onClose={() => closeDialog()}
               buttons={dialog?.buttons}
               cancelable={dialog?.cancelable}
               animated={false}
            />


            {/* Render after required configuration information done loading. Add loading screen if necessary*/}
            {!isAuthenticating
               && <BrowserRouter basename={process.env.PUBLIC_URL}>
                  <Switch>
                     {!isAuthenticated && (
                        <Route path="/login/:loginMethod?" component={PageLogin} />
                     )}
                     {!isAuthenticated && (
                        <Route
                           path="*"
                           render={() => (
                              <RedirectToLogin
                                 onReferrerPath={(path) => this.setState({ referrer: path })}
                              />
                           )}
                        />
                     )}
                     { (isAuthenticated && referrer)
                        && <Route
                           path="*"
                           render={() => {
                              this.setState({ referrer: null })
                              return <Redirect to={referrer} />
                           }}
                        />
                     }
                     <Route exact path="/" component={withRouter(PageHome)} />
                     {(selectedSite && selectedTool)
                        && <Route path="/tools/:tool/configuration" component={PageToolConfiguration} />
                     }
                     {(selectedSite && selectedTool)
                        && <Route path="/tools/:tool" component={PageTool} />
                     }
                     {(selectedSite && !selectedTool)
                        && <Route path="/tools/:tool" component={SelectTool} />
                     }
                     {(selectedSite)
                        && <Route path="/statistics/:toolbox" component={PageStatistics} />
                     }
                     {((user && user.hasAdminPriviledges) && user.hasAdminPriviledges())
                        && <Route exact path="/user-management" component={PageUserManagement} />
                     }
                     {((user && user.hasAdminPriviledges) && user.hasAdminPriviledges())
                        && <Route exact path="/user-menu" component={PageUserMenu} />
                     }
                     {/* 3D tool visualizations are disabled for now until they are needed and after security vulnerabilities are fixed by upgrading packages.
                     {((user && user.hasAdminPriviledges) && user.hasAdminPriviledges())
                        && <Route exact path="/3d" component={Page3D} />
                     } */}
                     <Route exact path="/connect-mobile" component={PageConnectMobile} />

                     {!loading && (
                        <Route
                           path="*"
                           render={() => <Redirect to="/" />
                           }
                        />
                     )}
                  </Switch>
               </BrowserRouter>
            }
         </div>
      )
   }

}

const mapStateToProps = (state) => ({
   selectedSite: state.selectedSite ? state.selectedSite : null,
   sites: state.sites,
   auth: state.auth,
   user: state.user,
   dialog: state.dialog,
   selectedTool: state.selectedTool,
})

export default connect(mapStateToProps, { loadSites, setUser, clearLocalSession, setToolboxes, setSize, setUnits, setTags, setTools, closeDialog, showDialog })(App)


// Component for selecting tool based on url parameters
const SelectTool = ({ match, history }) => {
   const dispatch = useDispatch()
   const tools = useSelector((state) => state.tools)
   const toolId = match.params.tool

   useEffect(() => {
      let isToolFound = false
      for (const id in tools) {
         if (id === toolId) {
            if (tools[id].level === Tool.Level.LOCKED) history.push('/')
            else {
               dispatch(selectTool(id))
               isToolFound = true
            }
         }
      }
      if (!isToolFound) history.push('/')
   }, [dispatch, history, tools, toolId])

   return null
}


// Get current path save to state. When logged in user will be directed to this path.
const RedirectToLogin = ({ onReferrerPath }) => {
   const location = useLocation()
   const currentLocation = location.pathname + location.search
   if (currentLocation && currentLocation !== '/') onReferrerPath(currentLocation)
   return (
      <Redirect to={{
         pathname: '/login',
         state: { referrer: currentLocation },
      }} />
   )
}
