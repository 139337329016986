import { useEffect, useState } from 'react'

/**
 * @param {*} ref
 * @returns {boolean} true / false depending on hover state
 */
export default function useIsMouseOver(ref) {
   const [isHovering, setHovering] = useState(false)

   useEffect(() => {
      const handleMouseOver = () => setHovering(true)
      const handleMouseOut = () => setHovering(false)

      ref.current?.addEventListener('mouseover', handleMouseOver)
      ref.current?.addEventListener('mouseout', handleMouseOut)

      return () => {
         ref.current?.removeEventListener('mouseover', handleMouseOver)
         ref.current?.removeEventListener('mouseout', handleMouseOut)
      }
   }, [ref])

   return isHovering
}
