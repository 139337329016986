import classNames from 'classnames'
import React from 'react'
import './side-navigation-menu.scss'

/**
 * @template T
 * @typedef {object} SideNavigationMenuOptions
 * @property {string} title
 * @property {T} value
 * @property {boolean} [disabled]
 */
/**
 * @template T
 * @param {object} props
 * @param {(value: T) => void} props.onSelect
 * @param {SideNavigationMenuOptions<T>[]} props.options
 * @returns {React.FunctionComponentElement}
 */
export default function SideNavigationMenu({ options, onSelect }) {

   const menuButton = (button) => {
      const buttonClass = classNames({
         'side-menu-button': true,
         selected: button.selected,
      })

      return (
         <button
            className={buttonClass}
            key={button.value}
            onClick={() => onSelect(button.value)}
            disabled={button.disabled}
         >
            {button.title}
         </button>
      )
   }

   return (
      <div className="side-navigation-menu">
         {options.map((option) => menuButton(option))}
      </div>
   )
}
