import { clearSelectedAnalyses, excludeUnit } from '@ava/react-common/store/actions/data-actions'
import { selectUnitFilters } from '@ava/react-common/store/reducers/root-reducer'
import React from 'react'
import { useSelector } from 'react-redux'
import FullScreen from '../../../assets/full-screen.svg'
import Minimize from '../../../assets/minimize.svg'
import store from '../../../store'
import Button from '../../Button/button'
import TopMenu from '../../TopMenu/top-menu'

/**
 * @param {object} props
 * @param {boolean} props.isFullScreen
 * @param {() => void} props.onFullScreen
 * @returns {import('react').FunctionComponentElement}
 */
export default function UnitMenu({ isFullScreen, onFullScreen }) {
   const units = useSelector((state) => state.units)
   const unitFilters = useSelector((state) => selectUnitFilters(state))


   const options = unitFilters.map((filter) => ({
      title: units[filter.id].name,
      value: filter.id,
      selected: !filter.exclude,
   }))

   return (
      <div
         style={{
            width: '100%',
            height: '46px',
            backgroundColor: '#000F1A',
            paddingLeft: '32px',
            boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.4)',
            zIndex: 4,
            // paddingTop: '14px'
            display: 'flex',
            flexDirection: 'row',
            flexShrink: 0,
         }}
      >
         <TopMenu
            // style={{
            //    // width: '100%',
            //    height: '44px',
            //    // backgroundColor: '#000F1A',
            //    //paddingLeft: '32px',
            //    //boxShadow: '-1px 0 2px 2px rgba(0, 0, 0, 0.4)',
            //    //zIndex: 4,
            //    paddingTop: '14px',
            // }}
            options={options}
            onSelect={(value) => {
               store.dispatch(clearSelectedAnalyses())
               unitFilters.forEach((filter) => {
                  const exclude = value !== filter.id
                  store.dispatch(excludeUnit(filter.id, exclude))
               })
            }}
         />
         <div style={{ flexGrow: 1 }} />
         <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Button style={{ height: '26px', marginRight: '12px', marginBottom: 0 }} styleType={Button.StyleType.ICON} iconSrc={isFullScreen ? Minimize : FullScreen}
               onClick={onFullScreen} />
         </div>
      </div>
   )
}
