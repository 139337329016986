import { useEffectWhen } from '@ava/react-common/hooks'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import './input.css'


const Input = forwardRef((props, ref) => {
   const { value, onChange, onFocus, onEnterPressed, autoFocus, className, type, placeholder, disabled, style, regexGroups, allowedCharacters, customValidation, allowEmpty = true } = props

   const [hasError, setHasError] = useState(false)
   const [validatedValue, setValidatedValue] = useState(value)


   // Validate input and update value to processed input / show error
   const validate = useCallback((input) => {

      // Trim not allowed characters if allowedCharacters are configured
      let trimmedInput = ''
      if (allowedCharacters) {
         for (const char of input) {
            for (const allowedChar of allowedCharacters) {
               const result = new RegExp(`[${allowedChar}]`).exec(char)
               if (result) trimmedInput += char
            }
         }

         // Remove unallowed characters from input
         if (trimmedInput !== input) {
            return validate(trimmedInput)
         }
      }

      // Skip input validation if input is empty and empty value is allowed and there are no regexGroups
      if (regexGroups
         ? ((allowEmpty && input !== '') || !allowEmpty)
         : (!allowEmpty && input !== '')) {

         // Validate regex groups
         let inputChunk = input
         for (const i in regexGroups) {
            let regex = regexGroups[i]
            regex = `^${regex}`
            if (Number(i) === regexGroups.length - 1) regex += '$'
            const result = new RegExp(regex).exec(inputChunk)
            if (!result) {
               setValidatedValue(input)
               setHasError(true)
               return
            }
            inputChunk = inputChunk.replace(result[0], '')
         }

      }

      // Validate via passed customValidation function
      if (customValidation && !customValidation(input)) {
         setValidatedValue(input)
         setHasError(true)
         return
      }

      if (hasError) setHasError(false)
      if (validatedValue !== input || hasError === true) { // Update value only when it is changed or if value has become valid due to "useImperativeHandle" validate method was called
         setValidatedValue(input)
         onChange(input)
      }
   }, [allowEmpty, allowedCharacters, customValidation, hasError, onChange, regexGroups, validatedValue])

   // When value prop is changed validate it
   useEffectWhen(() => validate(value ?? ''), [value], [validate])


   // Export validate function that can be called via Input reference.
   // This can be used for validating input again for example, if customValidation compares input value to other input value and the other input value is changed.
   useImperativeHandle(ref, () => ({
      validate: () => validate(validatedValue ?? '', )
   }), [validatedValue, validate])


   return (
      <input
         ref={ref}
         style={{
            ...style,
            ...(hasError && {
               border: '1px solid red',
               outlineColor: 'red',
            }),
         }}
         className={classNames('input', className)}
         type={type || 'text'}
         placeholder={placeholder}
         onChange={(e) => validate(e.target.value)}
         value={validatedValue}
         autoFocus={autoFocus}
         onKeyDown={onEnterPressed && ((e) => {
            if (e.keyCode === 13) onEnterPressed()
         })}
         onFocus={onFocus}
         disabled={disabled}
      />
   )

})

export default Input

Input.propTypes = {
   className: PropTypes.string,
   value: PropTypes.string.isRequired,
   onChange: PropTypes.func.isRequired,
   onEnterPressed: PropTypes.func,
   autoFocus: PropTypes.bool,
   type: PropTypes.string,
   placeholder: PropTypes.string,
   disabled: PropTypes.bool,
   style: PropTypes.object,
   regexGroups: PropTypes.arrayOf(PropTypes.string), // In RegExp string format. Example (require numbers and a percentage char to the end): ["[0-9]+", "%"]. Use double backslash for escaping!! For example: "\\." translates to "\." to regex.
   allowedCharacters: PropTypes.arrayOf(PropTypes.string), // In RegExp string format. Example: ["0-9"]
   allowEmpty: PropTypes.bool,
}
