import Tag from '@ava/react-common/models/tag'
import Tool from '@ava/react-common/models/tool'
import DataService from '@ava/react-common/services/data-service'
import { clearSelectedAnalyses, selectAnalysis, setAnalyses, stopLiveUpdate, TIMEFRAME_BY_DATE_ANALYZED, TIMEFRAME_BY_TIMESTAMP } from '@ava/react-common/store/actions/data-actions'
import { clearOngoingAnalysis } from '@ava/react-common/store/actions/ongoing-analysis-actions'
import { selectSelectedSite, selectSelectedTool, selectToolsPositions, selectToolsTags, selectToolsUnits } from '@ava/react-common/store/reducers/root-reducer'
import { dateToString, getBeginningOfDate, getEndOfDate, modifyDateToTimezone } from '@ava/react-common/utils/Helper'
import classNames from 'classnames'
import { Component, createRef } from 'react'
import { Collapse } from 'react-collapse'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import Arrow from '../../../assets/arrow-black.svg'
import Button from '../../Button/button'
import LoadingSpinner from '../../LoadingSpinner/loading-spinner'
import Modal from '../../Modal/modal'
import RadioButton from '../../RadioButton/radio-button'
import ReactSelect from '../../ReactSelect/react-select'
import Toggle from '../../Toggle/toggle'
import './data-modal.css'


class DataModal extends Component {

   constructor(props) {
      super(props)

      this.state = {
         queryDateBy: TIMEFRAME_BY_TIMESTAMP,
         timeFromDatepicker: null, // react-datepicker does not support timezones. We need to handle
         timeToDatepicker: null,
         timeFrom: null,
         timeTo: null,
         units: [],
         tags: [],
         areAdvancedFiltersShown: false,
         advancedFilters: {
            errors: false,
            exceptions: false,
         },
         isLoading: false,
         errorMessage: '',
      }

      this.cancelGettingAnalyses = undefined

      this.unitOptions = this.props.units.map((unit) => ({ value: unit.id, label: unit.name }))

      this.tagOptions = []
      this.props.tags.forEach((tag) => {
         if (tag.isLocal && tag.id !== Tag.getHasImagesTagId(this.props.tool.id)) return
         this.tagOptions.push({
            value: tag.id,
            label: tag.name,
            isLocal: tag.isLocal,
         })
      })

      this.selectDataPressed = this.selectDataPressed.bind(this)
      this.timeFromRef = createRef()
      this.timeToRef = createRef()
   }

   componentDidMount() {
      this.setState({ units: this.unitOptions }) // Select all by default
      DataModal.handleDatePickerBtnFocusColor()
   }

   static handleDatePickerBtnFocusColor = () => {
      // const datePickerLeftElement = $('.data-modal .time-selector > .left-side > .date-picker-btn > .react-date-picker')[0]
      // listenToClassChanges(datePickerLeftElement, classNames => {
      //    if (classNames.includes("react-date-picker--open"))
      //       $('.data-modal .time-selector > .left-side').addClass("left-side--active")
      //    else
      //       $('.data-modal .time-selector > .left-side').removeClass("left-side--active")
      // })
      // const datePickerRightElement = $('.data-modal .time-selector > .right-side > .date-picker-btn-right > .react-date-picker')[0]
      // listenToClassChanges(datePickerRightElement, classNames => {
      //    if (classNames.includes("react-date-picker--open"))
      //       $('.data-modal .time-selector > .right-side').addClass("right-side--active")
      //    else
      //       $('.data-modal .time-selector > .right-side').removeClass("right-side--active")
      // })
   }

   selectDataPressed() {

      if (!this.state.timeFrom || !this.state.timeTo) {
         this.setState({ errorMessage: 'Select timeframe' })
         return
      }
      if (!this.state.units || this.state.units.length === 0) {
         this.setState({ errorMessage: 'Select units' })
         return
      }

      this.setState({ isLoading: true, errorMessage: null })

      const units = this.state.units.map((unit) => unit.value)

      let tags
      let onlyWithImages = false
      const hasImagesTagId = Tag.getHasImagesTagId(this.props.tool.id)
      if (this.state.tags.length > 0) {
         this.state.tags.forEach((tag) => {
            if (tag.value === hasImagesTagId) {
               onlyWithImages = true
            }
            if (!tag.isLocal) {
               if (!tags) tags = []
               tags.push(tag.value)
            }
         })
      }


      /* Get data from db */
      const { promise, cancel } = DataService
         .getAnalyses({
            tool: this.props.tool.id,
            dateFrom: this.state.timeFrom,
            dateTo: this.state.timeTo,
            site: this.props.site.id,
            units,
            tags,
            queryDateBy: this.state.queryDateBy,
            onlyWithImages,
            advancedFilters: this.state.areAdvancedFiltersShown ? this.state.advancedFilters : undefined,
         })

      this.cancelGettingAnalyses = cancel
      promise
         .then((analyses) => {
            if (analyses.length === 0) {
               this.setState({ errorMessage: 'No data found' }) // TODO: Add language options
               this.setState({ isLoading: false })
               return
            }

            this.props.stopLiveUpdate()
            this.props.clearOngoingAnalysis()
            this.props.clearSelectedAnalyses()
            analyses.forEach((analysis) => {
               DataModal.setLocalTagsForAnalysis(analysis, this.props.tool)
            })

            const timeframe = (this.state.queryDateBy === TIMEFRAME_BY_TIMESTAMP)
               ? { from: this.state.timeFrom, to: this.state.timeTo }
               : undefined

            this.props.setAnalyses(this.props.tool.id, analyses, timeframe, this.state.queryDateBy)

            // Autoselect if only one item
            if (analyses.length === 1) {
               this.props.selectAnalysis(analyses[0].id)
            }
            this.props.onClose()
         })
         .catch((err) => {
            this.cancelGettingAnalyses = undefined
            this.setState({
               isLoading: false,
               errorMessage: err,
            })
         })
   }

   // TODO: This is duplicate from page-tool.js FIX IT
   static setLocalTagsForAnalysis = (analysis, tool) => { // TODO: Where should this go?
      const localTags = []
      if (tool.config.type === Tool.Type.CONTINUOUS) {
         if (analysis.items.find((i) => i.imageId)) {
            localTags.push(Tag.getHasImagesTagId(analysis.tool))
         }
      }
      analysis.tags.push(...localTags)
   }

   componentWillUnmount() {
      if (this.cancelGettingAnalyses) this.cancelGettingAnalyses()
   }

   render() {

      const { timeFrom, timeTo, isLoading, errorMessage, timeFromDatepicker, timeToDatepicker, areAdvancedFiltersShown } = this.state
      const { onClose, tool, isMobile, timezone } = this.props
      const isContinuous = tool.config.type === Tool.Type.CONTINUOUS

      return (
         <Modal
            title="Get data"
            primaryBtnTitle="SELECT"
            secondaryBtnTitle="CANCEL"
            primaryBtnPressed={this.selectDataPressed}
            onClose={onClose}
            isPrimaryBtnDisabled={isLoading}
            error={errorMessage}
            width="460px"
         >
            <div className={classNames('data-modal', { 'data-modal--mobile': isMobile })}>

               { isLoading ? (
                  <div className="loader-container">
                     <LoadingSpinner />
                     <p>Fetching data</p>
                  </div>
               ) : (
                  <div>
                     <h4>Select timeframe</h4>
                     <div className="time-selector">
                        <div
                           className="left-side"
                           onClick={() => { this.timeFromRef.current.setOpen(true) }}
                        >
                           <p>{timeFrom ? dateToString(timeFrom, timezone, isContinuous) : 'Select date'}</p>
                           <div className="date-picker-btn" />
                        </div>
                        <div className="arrow" />
                        <div
                           className="right-side"
                           onClick={() => { this.timeToRef.current.setOpen(true) }}
                        >
                           <p>
                              {timeTo ? dateToString(timeTo, timezone, isContinuous) : 'Select date'}
                           </p>
                           <div className="date-picker-btn-right" />
                        </div>
                     </div>

                     <div style={{ display: 'flex', marginBottom: '8px', height: '1px' }}>
                        <DatePicker
                           ref={this.timeFromRef}
                           showTimeSelect={!!isContinuous}
                           timeIntervals={5}
                           selected={timeFromDatepicker}
                           onChange={(startDate) => {
                              const date = isContinuous ? startDate : getBeginningOfDate(startDate)
                              const fixedDate = timezone ? modifyDateToTimezone(date, timezone) : date
                              this.setState({ timeFromDatepicker: date, timeFrom: fixedDate }, () => {
                                 if (!isContinuous && !timeTo) { this.timeToRef.current.setOpen(true) }
                              })
                           }}
                        />
                        <div className={isMobile ? 'mobile-left-deta-picker' : undefined}>
                           <DatePicker
                              ref={this.timeToRef}
                              showTimeSelect={!!isContinuous}
                              timeIntervals={5}
                              selected={timeToDatepicker}
                              onChange={(endDate) => {
                                 const date = isContinuous ? endDate : getEndOfDate(endDate)
                                 const fixedDate = timezone ? modifyDateToTimezone(date, timezone) : date
                                 this.setState({ timeTo: fixedDate, timeToDatepicker: date })
                              }}
                           />
                        </div>
                     </div>
                     <Button
                        styleType={Button.StyleType.TEXT}
                        style={{ color: '#0075BE', paddingTop: '2px', paddingBottom: '2px', marginRight: '4px' }}
                        title="today"
                        onClick={() => {
                           const startDate = getBeginningOfDate(new Date())
                           const endDate = getEndOfDate(new Date())
                           const startDateFixed = timezone ? modifyDateToTimezone(startDate, timezone) : startDate
                           const endDateDateFixed = timezone ? modifyDateToTimezone(endDate, timezone) : endDate
                           this.setState({ timeFrom: startDateFixed, timeTo: endDateDateFixed, timeFromDatepicker: startDate, timeToDatepicker: endDate })
                        }}
                     />
                     <Button
                        styleType={Button.StyleType.TEXT}
                        style={{ color: '#0075BE', paddingTop: '2px', paddingBottom: '2px', marginRight: '4px' }}
                        title="week"
                        onClick={() => {
                           const startDate = getBeginningOfDate(new Date())
                           startDate.setDate(startDate.getDate() - 7)
                           const endDate = getEndOfDate(new Date())
                           const startDateFixed = timezone ? modifyDateToTimezone(startDate, timezone) : startDate
                           const endDateDateFixed = timezone ? modifyDateToTimezone(endDate, timezone) : endDate
                           this.setState({ timeFrom: startDateFixed, timeTo: endDateDateFixed, timeFromDatepicker: startDate, timeToDatepicker: endDate })
                        }}
                     />
                     <Button
                        styleType={Button.StyleType.TEXT}
                        style={{ color: '#0075BE', paddingTop: '2px', paddingBottom: '2px' }}
                        title="month"
                        onClick={() => {
                           const startDate = getBeginningOfDate(new Date())
                           startDate.setDate(startDate.getDate() - 30)
                           const endDate = getEndOfDate(new Date())
                           const startDateFixed = timezone ? modifyDateToTimezone(startDate, timezone) : startDate
                           const endDateDateFixed = timezone ? modifyDateToTimezone(endDate, timezone) : endDate
                           this.setState({ timeFrom: startDateFixed, timeTo: endDateDateFixed, timeFromDatepicker: startDate, timeToDatepicker: endDate })
                        }}
                     />

                     {/* TIMEFRAME BY */}
                     {(tool.config.type === Tool.Type.POINT) // Only "point" tools analyses can be loaded by "date analyzed"
                        && <>
                           <h4 style={{ marginTop: '24px' }}>Timeframe by</h4>
                           <RadioButton
                              title="Image date"
                              onChange={() => this.setState({ queryDateBy: TIMEFRAME_BY_TIMESTAMP })}
                              checked={this.state.queryDateBy === TIMEFRAME_BY_TIMESTAMP}
                           />
                           <RadioButton
                              title="Date analyzed"
                              onChange={() => this.setState({ queryDateBy: TIMEFRAME_BY_DATE_ANALYZED })}
                              checked={this.state.queryDateBy === TIMEFRAME_BY_DATE_ANALYZED}
                           />
                        </>
                     }

                     <h4 style={{ marginTop: '20px' }}>Processing units</h4>
                     <ReactSelect
                        isMulti={true}
                        options={this.unitOptions}
                        value={this.state.units}
                        onChange={(units) => this.setState({ units })
                        }
                     />
                     <br />

                     {/* <h4>Positions</h4>
                     <ReactSelect
                        className="react-select"
                        isMulti={true}
                        options={this.state.positionOptions}
                        value={this.state.positions}
                        onChange={(positions) =>
                           this.setState({positions})
                        }
                     />
                     <br/> */}

                     <h4>Tags</h4>
                     <ReactSelect
                        maxMenuHeight={isMobile ? 168 : 126}
                        isMulti={true}
                        options={this.tagOptions}
                        value={this.state.tags}
                        onChange={(tags) => this.setState({ tags: tags || [] })
                        }
                     />
                     <br />


                     <div style={{ marginBottom: '8px' }}>
                        <div
                           style={{ display: 'flex' }}
                           onClick={() => this.setState({ areAdvancedFiltersShown: !areAdvancedFiltersShown })}
                        >
                           <h4>Advanced filters</h4>
                           <button type="button" style={{
                              background: 'none',
                              border: 'none',
                              marginTop: -4,
                              paddingLeft: 20,
                              paddingBottom: 8,
                              height: 33,
                           }}>
                              { areAdvancedFiltersShown ? (
                                 <div style={{ width: '12px', height: '2px', backgroundColor: '#333', marginRight: '-2px' }} />
                              ) : (
                                 <img
                                    style={{ height: '12px', transform: 'rotate(-90deg)' }}
                                    src={Arrow}
                                    alt="Collapse"
                                 />
                              )}
                           </button>
                        </div>
                        <Collapse isOpened={areAdvancedFiltersShown}>
                           <div style={{ display: 'grid', rowGap: 4, gridTemplateColumns: 'repeat(2, auto)' }}>
                              <div>Errors</div>
                              <Toggle isOn={this.state.advancedFilters.errors} onChange={(isOn) => this.setState({ advancedFilters: { ...this.state.advancedFilters, errors: !this.state.advancedFilters.errors }})} />
                              <div>Exceptions</div>
                              <Toggle isOn={this.state.advancedFilters.exceptions} onChange={(isOn) => this.setState({ advancedFilters: { ...this.state.advancedFilters, exceptions: !this.state.advancedFilters.exceptions }})} />
                           </div>
                        </Collapse>
                     </div>
                     <br />

                  </div>
               )}

            </div>
         </Modal>
      )
   }

}

// remove mapstatetoprops
const mapStateToProps = (state) => ({
   positions: selectToolsPositions(state),
   units: selectToolsUnits(state),
   tags: selectToolsTags(state),
   tool: selectSelectedTool(state),
   site: selectSelectedSite(state),
   isMobile: state.device.isMobile,
   timezone: state.timeZone,
})

export default connect((mapStateToProps), { setAnalyses, selectAnalysis, clearSelectedAnalyses, clearOngoingAnalysis, stopLiveUpdate })(DataModal)
