import { CREATE_POSITION, CREATE_TAG, DELETE_POSITION, DELETE_TAG, SET_TOOLS } from '../actions/types'


const initialState = {}

const toolsReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_TOOLS:

         return action.payload.reduce((obj, tool) => {
            obj[tool.id] = tool
            return obj
         }, {})

      case CREATE_TAG:
         return {
            ...state,
            [action.payload.tool]: {
               ...state[action.payload.tool],
               tags: [...state[action.payload.tool].tags, action.payload.id],
            },
         }

      case DELETE_TAG:
         const newTags = state[action.payload.tool].tags.filter((id) => id !== action.payload.id)
         return {
            ...state,
            [action.payload.tool]: {
               ...state[action.payload.tool],
               tags: newTags,
            },
         }

      case CREATE_POSITION:
         return {
            ...state,
            [action.payload.tool]: {
               ...state[action.payload.tool],
               positions: [...state[action.payload.tool].positions, action.payload.id],
            },
         }

      case DELETE_POSITION:
         const newPositions = state[action.payload.tool].positions.filter((id) => id !== action.payload.id)
         return {
            ...state,
            [action.payload.tool]: {
               ...state[action.payload.tool],
               positions: newPositions,
            },
         }

         // case CREATE_UNIT:
         //    return {
         //       ...state,
         //       [action.payload.tool]: {
         //          ...state[action.payload.tool],
         //          units: [...state[action.payload.tool].units, action.payload.id]
         //       }
         //    }
         // case DELETE_UNIT:
         //    const newUnits = state[action.payload.tool].units.filter((id) => id !== action.payload.id)
         //    return {
         //       ...state,
         //       [action.payload.tool]: {
         //          ...state[action.payload.tool],
         //          units: newUnits
         //       }
         //    }

      default:
         return state

   }
}

export default toolsReducer
