import DataService from '@ava/react-common/services/data-service'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { selectToolboxes, selectTools } from '@ava/react-common/store/reducers/root-reducer'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../LoadingSpinner/loading-spinner'
import SecondaryNavbar from '../../SecondaryNavbar/secondary-navbar'
import SettingsTopMenu from '../../SettingsTopMenu/settings-top-menu'
import './page-statistics.scss'


// NOTE: Currently not mobile friendly
/**
 * @param {object} props
 * @param {*} props.match
 * @param {*} props.history
 * @returns {import('react').FunctionComponentElement}
 */
export default function PageStatistics({ match, history }) {

   const toolboxes = useSelector((state) => selectToolboxes(state))
   const site = useSelector((state) => state.selectedSite)
   const tools = useSelector((state) => selectTools(state))
   const [isLoading, setLoading] = useState(true)
   const [toolboxId, selectToolbox] = useState(null)
   const [statistics, setStatistics] = useState(null)

   const dispatch = useDispatch()

   const toolboxFromRoute = match.params.toolbox

   useEffect(() => {
      if (site) {
         DataService.getStatistics(site)
            .then(setStatistics)
            .catch(() => dispatch(showDialog({ title: 'Failed to get recent activity' })))
            .finally(() => setLoading(false))
      }
   }, [site, dispatch])

   useEffect(() => {
      selectToolbox(toolboxFromRoute)
   }, [toolboxFromRoute])

   const topMenuOptions = toolboxes.map((toolbox) => ({
      title: toolbox.name,
      value: toolbox.id,
      selected: toolbox.id === toolboxId,
   }))

   const filteredTools = tools.filter((tool) => tool.toolbox === toolboxId)


   return (
      <div
         className="page-statistics"
         style={{ width: '100%', minHeight: '100vh' }}
      >
         <SecondaryNavbar
            title="Recent activity"
            onBack={() => history.push('/')}
         />
         <SettingsTopMenu
            topMenuOptions={topMenuOptions}
            onTopMenuSelect={(id) => history.push(`/statistics/${id}`)}
         />
         { isLoading
            && <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex', marginTop: '100px' }}>
               <LoadingSpinner />
            </div>
         }
         { (!isLoading && toolboxId && statistics)
            && <div style={{ padding: '20px' }}>
               <table className="activity-table" style={{ width: '100%' }}>
                  <thead>
                     <tr>
                        <th></th>
                        <th>Last 24 h</th>
                        <th>Previous 24 h</th>
                        <th>Last 7 days</th>
                        <th>Previous 7 days (14 - 7 days ago)</th>
                     </tr>
                  </thead>
                  <tbody>
                     { filteredTools && filteredTools.map((tool) => {
                        const toolStatistics = statistics[tool.id]
                        const generateString = (type) => {
                           const data = toolStatistics && toolStatistics[type]
                           if (!data || data.count == null) return '-'
                           if (data.exceeded) return `> ${data.count}`
                           return `${data.count}`
                        }
                        return (
                           <tr key={`ativity-row-${tool.id}`}>
                              <th>{tool.name}</th>
                              <td>{generateString('last24h')}</td>
                              <td>{generateString('previous24h')}</td>
                              <td>{generateString('last7Days')}</td>
                              <td>{generateString('previous7Days')}</td>
                           </tr>
                        )
                     })}
                  </tbody>
               </table>
            </div>
         }
      </div>
   )
}
