import ContinuousAnalysis from '@ava/react-common/models/continuous-analysis'
import Tool from '@ava/react-common/models/tool'
import DataService from '@ava/react-common/services/data-service'
import UserService from '@ava/react-common/services/user-service'
import { clearData, clearSelectedAnalyses, excludePosition, excludeTag, excludeTagless, setTimeframe, TIMEFRAME_BY_DATE_ANALYZED, TIMEFRAME_BY_TIMESTAMP } from '@ava/react-common/store/actions/data-actions'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { showLatestImages, showLatestResult, updateResultComponentModelChart } from '@ava/react-common/store/actions/result-component-actions'
import { setUserToolConfig } from '@ava/react-common/store/actions/user-tool-config-actions'
import { selectFilteredToolsElementsPositionIdsThatHaveAnalysisData, selectPositionFilters, selectSelectedTool, selectTagFilters, selectToolsFilteredAnalyses } from '@ava/react-common/store/reducers/root-reducer'
import { errorToMessage } from '@ava/react-common/utils'
import { dateToString } from '@ava/react-common/utils/Helper'
import { chunk } from 'lodash'
import PropTypes from 'prop-types'
import { Component, useMemo, useState } from 'react'
import { Collapse } from 'react-collapse'
import { connect } from 'react-redux'
import Arrow from '../../../assets/arrow.svg'
import { exportAnalysesToXLSX } from '../../../utils/exportAnalysesToXLSX'
import Button from '../../Button/button'
import Dropdown from '../../Dropdown/dropdown'
import LoadingIndicator from '../../LoadingIndicator/loading-indicator'
import Modal from '../../Modal/modal'
import Toggle from '../../Toggle/toggle'
import ContinuousAnalysesStatus from './continuous-analysis-statuses'
import './side-menu.scss'


class SideMenu extends Component {

   static propTypes = {
      addNewPressed: PropTypes.func,
      selectDataPressed: PropTypes.func,
      configurationPressed: PropTypes.func,
      liveUpdatePressed: PropTypes.func,
      loadingInitialData: PropTypes.bool,
      onNewChart: PropTypes.func,
   }

   constructor(props) {
      super(props)
      this.state = {
         isCollapsed: false,
         downloadImages: null,
      }
      this.toggleMenu = this.toggleMenu.bind(this)
   }

   componentDidUpdate(prevProps) {
      const { timeframe, loadingInitialData } = this.props
      const timeframeChanged = (timeframe && prevProps.timeframe) ? (prevProps.timeframe.from !== timeframe.from || prevProps.timeframe.to !== timeframe.to) : (timeframe !== prevProps.timeframe)
      if (timeframeChanged && !loadingInitialData) {
         this.setState({ isCollapsed: true })
      }
   }

   updateUserToolConfig(updates) {
      UserService
         .editUserToolConfig(
            this.props.userToolConfig,
            updates,
            this.props.selectedTool.id,
            this.props.user.id,
            this.props.setUserToolConfig
         )
         .catch((err) => {
            this.props.showDialog({
               title: 'Failed to update User Tool Config',
               text: errorToMessage(err),
            })
         })
   }


   // For mobile devices. Collapses menu.
   toggleMenu() {
      const newState = !this.state.isCollapsed
      this.setState({ isCollapsed: newState })
   }

   createTimeframe() {
      const { timeframe, timezone } = this.props
      let timeframeTxt
      if (timeframe && timeframe.by === TIMEFRAME_BY_TIMESTAMP) {
         timeframeTxt = 'Timestamp'
      } else if (timeframe && timeframe.by === TIMEFRAME_BY_DATE_ANALYZED) {
         timeframeTxt = 'Date analyzed'
      }

      if (timeframe && timeframe.from) {
         return (
            <div key="1-1">
               { timeframeTxt && <p className="title">{timeframeTxt}</p>}
               <div key="1" className="time-frame">
                  <p>{timeframe.from && dateToString(timeframe.from, timezone)}</p>
                  <p>{timeframe.to && dateToString(timeframe.to, timezone)}</p>
                  <div className="arrow" />
               </div>
            </div>
         )
      } return null
   }

   createDataInfoBox() {
      const { tagFilters, tags, resultComponents, elementsPositionsWithData, showLatestResult, showLatestImages, selectedTool, taglessFilter, positionFilters, positions, filteredAnalyses } = this.props
      const content = []

      content.push(this.createTimeframe())

      content.push(
         <div
            key="section-analyses-count"
            className="tgl-btn-row"
            style={{ marginBottom: '6px' }}
         >
            <p className="title">Analyses</p>
            <p style={{ textAlign: 'center', minWidth: '18px' }}>{filteredAnalyses.length}</p>
         </div>
      )

      // CHARTS
      content.push(
         <CollapseSection key="section-chart" collapsable={true} collapsed={true}
            title="Charts">
            {resultComponents.models
               .map((model) => {
                  const { id, chart, elements, isCustomChart } = model

                  const isDataInChart = !!elements.find((element) => (elementsPositionsWithData[element]?.length > 0))
                  if (!isDataInChart) return null

                  return (
                     <ToggleRow
                        key={id}
                        title={chart.title}
                        isOn={chart.isVisible}
                        onChange={(on) => {
                           const updatedModelChart = {
                              ...model.chart,
                              isVisible: on,
                           }
                           this.props.updateResultComponentModelChart(id, updatedModelChart)
                           this.updateUserToolConfig({
                              chartsToUpdate: [{
                                 id,
                                 isCustomChart,
                                 isVisible: on,
                              }],
                           })
                        }}
                     />
                  )
               })
               .filter((i) => i)
            }
         </CollapseSection>
      )

      // LATEST RESULT
      // To display only when continuous analysis tool: if (selectedTool && selectedTool.config && (selectedTool.config.type === Tool.Type.CONTINUOUS)) { // && liveUpdate
      content.push(
         <CollapseSection collapsable={true} collapsed={true} title="Latest result"
            key="section-latest">
            <ToggleRow
               title="Latest Images"
               isOn={resultComponents.showLatestImages}
               onChange={(on) => {
                  showLatestImages(on)
                  this.updateUserToolConfig({
                     continuousAnalysis: {
                        isLatestImagesCardVisible: on,
                     },
                  })
               }}
            />
            <ToggleRow
               title="Latest Result"
               isOn={resultComponents.showLatestResult}
               onChange={(on) => {
                  showLatestResult(on)
                  this.updateUserToolConfig({
                     continuousAnalysis: {
                        isLatestResultCardVisible: on,
                     },
                  })
               }}
            />
         </CollapseSection>
      )
      // }


      // POSITIONS
      const sortedPositionFilters = positionFilters.sort((a, b) => {
         const valA = positions[a.id] ? positions[a.id].name : ''
         const valB = positions[b.id] ? positions[b.id].name : ''
         const strA = valA ? valA.toUpperCase() : ''
         const strB = valB ? valB.toUpperCase() : ''
         if (strA < strB) return -1
         if (strA > strB) return 1
         return 0
      })
      if (sortedPositionFilters.length > 0 && selectedTool.config.type === Tool.Type.CONTINUOUS) { // Display position filters only when continuous analysis
         content.push(
            <CollapseSection key="section-position" collapsable={true} title="Positions"
               collapsed={true}>
               {sortedPositionFilters.map((filter) => {
                  const position = positions[filter.id]
                  if (!position) return null
                  return (
                     <div key={position.id} className="tgl-btn-row">
                        <p>{position.name}</p>
                        <Toggle
                           isOn={!filter.exclude}
                           onChange={(on) => {
                              this.props.excludePosition(position.id, !on)
                           }}
                        />
                     </div>
                  )
               })}
            </CollapseSection>
         )
      }

      // TAGS
      // Sort alphabetically by name (TODO: Move sorting to selector)
      const sortedTagFilters = tagFilters.sort((a, b) => {
         const valA = tags[a.id] ? tags[a.id].name : ''
         const valB = tags[b.id] ? tags[b.id].name : ''
         const strA = valA ? valA.toUpperCase() : ''
         const strB = valB ? valB.toUpperCase() : ''
         if (strA < strB) return -1
         if (strA > strB) return 1
         return 0
      })

      if (sortedTagFilters.length > 0 || taglessFilter) {
         content.push(
            <CollapseSection key="section-tag" collapsable={true} title="Tags"
               collapsed={true}>
               { taglessFilter.enabled
                  && <div key="tagless" className="tgl-btn-row">
                     <p>Show tagless</p>
                     <Toggle
                        isOn={!taglessFilter.exclude}
                        onChange={(on) => {
                           this.props.excludeTagless(!on)
                        }}
                     />
                  </div>
               }
               {sortedTagFilters.map((filter) => {
                  const tag = tags[filter.id]
                  if (!tag) return null
                  return (
                     <div key={tag.id} className="tgl-btn-row">
                        <p>{tag.name}</p>
                        <Toggle
                           isOn={!filter.exclude}
                           onChange={(on) => {
                              this.props.excludeTag(tag.id, !on)
                           }
                           } />
                     </div>
                  )
               })
               }
            </CollapseSection>
         )
      }

      // selected and filtered image ids for images download
      const filteredImageIds = { original: [], result: []}
      const selectedImageIds = { original: [], result: []}
      this.props.filteredAnalyses.forEach((analysis) => {
         const isSelected = (this.props.selectedAnalysisIds.includes(analysis.id))
         for (const item of analysis.items) {
            if (item.hasOriginalImage()) {
               filteredImageIds.original.push(item.imageId)
               if (isSelected) selectedImageIds.original.push(item.imageId)
            }
            if (item.hasResultImage()) {
               filteredImageIds.result.push(item.result.imageId)
               if (isSelected) selectedImageIds.result.push(item.result.imageId)
            }
         }
      })

      // ACTION BTN
      content.push(
         <div className="data-actions-btn" key="4">
            <Dropdown
               button={<Button className="data-action-btn" title="Actions" styleType={Button.StyleType.PRIMARY} />}
               menuItems={[
                  { value: 'export', label: 'Export Data' },
                  { value: 'downloadFilteredImages', label: 'Download Images', disabled: (filteredImageIds.original.length === 0 && filteredImageIds.result.length === 0) },
                  { value: 'downloadSelectedImages', label: 'Download Selected Images', disabled: (selectedImageIds.original.length === 0 && selectedImageIds.result.length === 0) },
                  { value: 'clearselection', label: 'Deselect all' },
                  { value: 'clear', label: 'Clear Data' },
                  { value: 'newchart', label: 'New Chart' },
               ]}
               onSelect={(value) => {
                  switch (value) {
                     case 'export':
                        exportAnalysesToXLSX(this.props.filteredAnalyses)
                        break
                     case 'downloadFilteredImages':
                        this.setState({ downloadImages: filteredImageIds })
                        break
                        case 'downloadSelectedImages':
                        this.setState({ downloadImages: selectedImageIds })
                        break
                     case 'clear':
                        this.props.clearData()
                        break
                     case 'clearselection':
                        this.props.clearSelectedAnalyses()
                        break
                     case 'newchart':
                        this.props.onNewChart()
                        break
                     default:
                        break
                  }
               }}
               dropdownToButtonWidth={true}
            />
         </div>
      )

      return content
   }



   render() {

      const { addNewPressed, selectDataPressed, configurationPressed, liveUpdate, liveUpdatePressed, isMobile, timeframe, user, selectedTool, positionStates } = this.props
      const { isCollapsed } = this.state

      const noRunningContinuousAnalyses = !(Object.values(positionStates).find((state) => state.status === ContinuousAnalysis.Status.RUNNING))

      const toolType = (selectedTool && selectedTool.config) ? selectedTool.config.type : undefined
      const isContinuous = toolType === Tool.Type.CONTINUOUS
      const isPoint = toolType === Tool.Type.POINT

      const dataInfo = () => {
         if (isMobile && !timeframe) return null
         return (
            <div className="data-info">
               {(timeframe && timeframe.from) ? this.createDataInfoBox() : (<p className="placeholder">No data selected</p>)}
            </div>
         )
      }

      if (isMobile) {
         return (
            <>
               <div className="mobile-menu">
                  { timeframe
                     && <div className={`toggle-btn-row${isCollapsed ? ' collapsed' : ''}`}>
                        <div onClick={this.toggleMenu}>
                           <div>
                              <img src={Arrow} alt="toggle" />
                              <img src={Arrow} alt="" />
                           </div>
                        </div>
                     </div>
                  }
                  <div hidden={isCollapsed}>
                     {dataInfo()}
                     <div className="flex-row" style={{ width: '100%' }}>
                        { (user.hasUserPriviledges() && addNewPressed && isPoint)
                           && <Button
                              className="side-menu-btn-large"
                              title="Add New"
                              onClick={addNewPressed}
                           />
                        }
                        { (user.hasUserPriviledges() && addNewPressed && isPoint)
                           && <div style={{ width: '16px', flexShrink: 0 }} />
                        }
                        <Button
                           className="side-menu-btn-large"
                           title="Get Data"
                           styleType={Button.StyleType.PRIMARY}
                           onClick={selectDataPressed}
                        />
                     </div>
                  </div>
                  {/* { isCollapsed &&
                     (this.createTimeframe())
                  } */}
                  {/* Toggle menu. Enabled by uncommenting following lines.*/}
               </div>

               {/* DOWNLOAD IMAGES MODAL */}
               { this.state.downloadImages && (
                  <DownloadImagesModal
                     originalImages={this.state.downloadImages.original}
                     resultImages={this.state.downloadImages.result}
                     onClose={() => this.setState({ downloadImages: null })}
                  />
               )}
            </>
         )
      }

      return (
         <>
            <div className="side-menu">

               <p className="side-menu-title">Data</p>
               <div className="side-menu-line" />

               {(user.hasUserPriviledges() && addNewPressed && isPoint)
                     && <Button
                        className="side-menu-btn-large"
                        title="Add New"
                        onClick={addNewPressed}
                     />
               }
               <Button
                  className="side-menu-btn-large"
                  title="Get Data"
                  styleType={Button.StyleType.PRIMARY} onClick={selectDataPressed}
               />
               { liveUpdatePressed
                     && <Button
                        className="side-menu-btn-large"
                        title={!liveUpdate ? 'Live Update' : 'Stop Live Update'}
                        styleType={Button.StyleType.PRIMARY} onClick={liveUpdatePressed}
                     />
               }


               {/* CONTINUOUS ANALYSIS */}
               { isContinuous
                     && <div className="data-info" style={{ marginBottom: '20px' }}>
                        <ContinuousAnalysesStatus />
                     </div>
               }


               {/* DOWNLOADED DATA INFO */}
               <div className="data-info">

                  {/* Live update status and settings */}
                  { liveUpdate
                        && <LoadingIndicator text={(noRunningContinuousAnalyses && isContinuous) ? 'No running analyses' : 'Listening analyses'} />
                  }
                  {((this.props.timeframe && this.props.timeframe.from) && this.createDataInfoBox())
                  || (!liveUpdate ? <p className="placeholder">No data selected</p> : undefined)}
               </div>

               {/* MORE */}
               <p className="side-menu-title">More</p>
               <div className="side-menu-line" />
               <div>

                  {/* If these are changed -> add options also to page-tool.js mobile navbar options*/}
                  <div className="first-menu-item">
                     <Button
                        title="Configuration"
                        styleType={Button.StyleType.TEXT}
                        onClick={configurationPressed}
                     />
                  </div>
                  <div>
                     <Button
                        disabled
                        title="Manuals"
                        styleType={Button.StyleType.TEXT}
                        onClick={() => console.log('Manuals selected')}
                     />
                  </div>


               </div>

            </div>


            {/* DOWNLOAD IMAGES MODAL */}
            { this.state.downloadImages && (
               <DownloadImagesModal
                  originalImages={this.state.downloadImages.original}
                  resultImages={this.state.downloadImages.result}
                  onClose={() => this.setState({ downloadImages: null })}
               />
            )}
         </>
      )
   }

}

const mapStateToProps = (state) => ({
   user: state.user,
   timeframe: state.timeframe,
   filteredAnalyses: selectToolsFilteredAnalyses(state),
   filteredAnalysisIds: state.filteredAnalyses,
   selectedAnalysisIds: state.selectedAnalyses,
   tagFilters: selectTagFilters(state),
   positionFilters: selectPositionFilters(state),
   positions: state.positions,
   units: state.units,
   tags: state.tags,
   isMobile: state.device.isMobile,
   liveUpdate: state.liveUpdate,
   selectedTool: selectSelectedTool(state),
   resultComponents: state.resultComponents,
   taglessFilter: state.taglessFilter,
   positionStates: state.continuousAnalysispositionStates,
   timezone: state.timeZone,
   userToolConfig: state.userToolConfig,
   elementsPositionsWithData: selectFilteredToolsElementsPositionIdsThatHaveAnalysisData(state),
})

export default connect(mapStateToProps, { excludeTag, excludeTagless, excludePosition, setTimeframe, clearData, clearSelectedAnalyses, showLatestResult, showLatestImages, updateResultComponentModelChart, setUserToolConfig, showDialog })(SideMenu)


// TODO: Improve classnames
const CollapseSection = ({ collapsable, children, title, collapsed }) => {
   const [isCollapsed, setCollapse] = useState(collapsed || false)

   return (
      <div style={{ marginBottom: '8px' }}>
         <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
            className="collapse-section"
            onClick={() => setCollapse(!isCollapsed)}
         >
            <p className="title">{title}</p>
            {collapsable
               && <button type="button">
                  { !isCollapsed ? (
                     <div
                        style={{ width: '12px', height: '2px', backgroundColor: '#fafafa', marginRight: '-2px' }}
                     />
                  ) : (
                     <img
                        className="toggle-icon"
                        style={{ height: '12px', transform: 'rotate(-90deg)' }}
                        src={Arrow}
                        alt="Collapse"
                     />
                  )}
               </button>
            }
         </div>
         <Collapse isOpened={!isCollapsed}>
            {children}
         </Collapse>
      </div>
   )
}



const ToggleRow = ({ title, isOn, onChange }) => (
   <div className="tgl-btn-row">
      <p>{title}</p>
      <Toggle
         isOn={isOn}
         onChange={onChange}
      />
   </div>
)



const DownloadImagesModal = ({ originalImages, resultImages, onClose }) => {

   const [isOriginalImgDownloadEnabled, setOriginalImgDownloadEnabled] = useState(true)
   const [isResultImgDownloadEnabled, setResultImgDownloadEnabled] = useState(true)

   const imagesToDownload = useMemo(() => {
      if (isOriginalImgDownloadEnabled && isResultImgDownloadEnabled) return [...originalImages, ...resultImages]
      if (isOriginalImgDownloadEnabled) return [...originalImages]
      if (isResultImgDownloadEnabled) return [...resultImages]
      return []
   }, [originalImages, resultImages, isOriginalImgDownloadEnabled, isResultImgDownloadEnabled])

   const imageChunks = useMemo(() => chunk(imagesToDownload, 500), [imagesToDownload]) // Max 500 images can be downloaded at once so images array is split to chunks of 500

   return (
      <Modal
         title="Download images"
         primaryBtnTitle="Download"
         primaryBtnPressed={(imagesToDownload.length <= 500) && (() => DataService.downloadImagesArchive(imagesToDownload) )}
         isPrimaryBtnDisabled={imagesToDownload.length === 0}
         secondaryBtnTitle="Cancel"
         onClose={onClose}
      >
         <div>
            <p style={{ marginBottom: 2 }}>{`Include original images (${originalImages.length})`}</p>
            <Toggle isOn={isOriginalImgDownloadEnabled} onChange={(isOn) => setOriginalImgDownloadEnabled(isOn)} />
         </div>
         <div>
            <p style={{ marginBottom: 2, marginTop: 8 }}>{`Include result images (${resultImages.length})`}</p>
            <Toggle isOn={isResultImgDownloadEnabled} onChange={(isOn) => setResultImgDownloadEnabled(isOn)} />
         </div>

         {(imageChunks.length > 1) && <>
            <br />
            <p>Maximum of 500 images can be downloaded at once.</p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
               {imageChunks.map((images, i) => (
                  <Button
                     key={i}
                     style={{ width: 200 }}
                     styleType={Button.StyleType.PRIMARY}
                     title={`Download part ${i+1}`}
                     onClick={() => DataService.downloadImagesArchive(images)}
                  />
               ))}
            </div>
         </>}
      </Modal>
   )

}
