import { useTypedSelector } from '@ava/react-common/utils'
import React, { useRef } from 'react'
import { Transition } from 'react-transition-group'
import Button from '../Button/button'

/**
 * @typedef {object} ModalComponentProps
 * @property {string} [title]
 * @property {string} [message]
 * @property {boolean} visible
 * @property {ModalButton[]} [buttons]
 * @property {boolean} [disabled] `Default: false`
 * @property {boolean} [cancelable] `Default: false`
 * @property {boolean} [animated] `Default: true`
 * @property {() => void} onClose
 */
/**
 * @typedef {object} ModalButton
 * @property {string} text
 * @property {() => void} [onPress] Closes dialog by default
 * @property {('primary'|'outlined'|'danger')} [style]
 * @property {boolean} [disabled]
 */
/**
 * @param {import('react').PropsWithChildren<ModalComponentProps>} props
 * @returns {React.FunctionComponentElement}
 */
export default function Dialog(props) {
   const { children, title, message, visible, disabled = false, cancelable = false, animated = true, onClose } = props

   const dialogAnimationDuration = animated ? 180 : 0
   const bgAnimationDuration = animated ? 120 : 0
   const footerRef = useRef()

   const useSelector = useTypedSelector()
   const isMobile = useSelector((selector) => selector.device.isMobile)


   const buttons = (props.buttons || [{ text: 'Done', style: 'primary' }])
      ?.map((btn, i) => ({
         ...btn,
         style: btn.style
            || (props.buttons && ((i < (props.buttons.length) - 1 || props.buttons?.length === 1) && 'primary'))
            || 'outlined', // Last button is default button
      }))

   const backgroundTapped = (e) => {
      if (e.target.className === 'main-dialog') {
         e.stopPropagation()
         if (cancelable && !disabled) onClose()
      }
   }

   // Animation styles
   // Styles are disabled if mobile since translate property breaks the layout
   const initialDialogStyle = {
      transition: `all ${bgAnimationDuration}ms ease-in`,
      opacity: 0,
   }

   const initialDialogDialogStyle = {
      transition: `all ${dialogAnimationDuration}ms ease-in`,
      opacity: 0,
      transform: `translateY(20px)`,
   }

   const bgTransitionStyles = {
      entering: { opacity: 1 },
      entered: { opacity: 1 },
      exiting: { opacity: 0 },
   }

   const dialogTransitionStyles = {
      entering: { opacity: 1, transform: undefined },
      entered: { opacity: 1, transform: undefined },
      exiting: {
         transition: 'all 120ms ease-out', // faster exit animation
         transform: 'translateY(20px)',
         opacity: 0,
      },
   }

   const dialogStyles = { display: 'block' }

   return (
      <Transition
         timeout={bgAnimationDuration}
         in={visible}
         appear
         onExited={onClose}
         exit={true} // Disable animations on mobile devices
         enter={true}
      >
         { (status) => (
            <div
               className="main-dialog"
               style={{
                  ...(!visible && { pointerEvents: 'none' }),
                  position: 'fixed',
                  bottom: 0,
                  top: 0,
                  left: 0,
                  right: 0,
                  margin: 0,
                  padding: 0,
                  backgroundColor: 'hsla(0, 0%, 0%, 0.2)',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                  ...initialDialogStyle,
                  ...bgTransitionStyles[status],
               }}
               onClick={backgroundTapped}
            >
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     backgroundColor: '#fff',
                     boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09)',
                     minWidth: '300px',
                     width: '400px',
                     maxWidth: 'calc(100vw - 24px)',
                     maxHeight: 'calc(100vh - 20px)',
                     userSelect: 'none',
                     ...(isMobile && {
                        maxWidth: 'calc(100vw - 8px)',
                        maxHeight: '100vh',
                     }),
                     ...dialogStyles,
                     ...initialDialogDialogStyle,
                     ...dialogTransitionStyles[status],
                  }}
               >
                  <div style={{ padding: '24px', flex: 1 }}>

                     {/* Title */}
                     <p style={{ fontWeight: 400, fontSize: '18px' }}>
                        { title }
                     </p>

                     {/* Body */}
                     { message && <span style={{ whiteSpace: 'pre-line' }}>{message}</span> }
                     { children }

                  </div>

                  {/* Footer */}
                  <div style={{ margin: '0 12px', direction: 'rtl' }} ref={footerRef}>

                     {/* Footer line */}
                     <div style={{ height: '1px', backgroundColor: '#e0e0e0', marginBottom: '16px' }} />

                     {/* Buttons */}
                     <div style={{ float: 'right' }}>
                        { buttons.map((btn) => (
                           <Button
                              key={btn.text}
                              title={btn.text}
                              onClick={btn.onPress || onClose}
                              styleType={btn.style}
                              style={{ marginLeft: '8px' }}
                              disabled={btn.disabled}
                           />
                        ))}
                     </div>
                  </div>

               </div>
            </div>
         )}
      </Transition>
   )
}
