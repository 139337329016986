// import { isEmpty } from 'lodash'
// import { AnalysisImageMetadata } from '../models/analysis'
// import { selectSelectedTool } from '../reducers/root-reducer'
import { batch } from 'react-redux'
import Analysis from '../../models/analysis'
import Unit from '../../models/unit'
import { clearResultComponents } from './result-component-actions'
import { ADD_POSITION_FILTER, ADD_TAG_FILTER, ADD_UNIT_FILTER, BEND_TIMEFRAME, CLEAR_DATA, CLEAR_SELECTED_ANALYSES, EDIT_ANALYSIS, ENABLE_TAGLESS_FILTERING, EXCLUDE_POSITION, EXCLUDE_TAG, EXCLUDE_TAGLESS, EXCLUDE_UNIT, REMOVE_ANALYSIS, SELECT_ANALYSIS, SELECT_TOOL, SET_ANALYSES, SET_CONTINUOUS_ANALYSIS_POS_STATE, SET_POSITION_FILTERS, SET_TAG_FILTERS, SET_TIMEFRAME, SET_TIMEFRAME_SELECTION, SET_UNIT_FILTERS, START_LIVE_UPDATE, STOP_LIVE_UPDATE, UNSELECT_ANALYSIS, UPDATE_ANALYSES, UPDATE_TIMEFRAME, UPDATE_TIMEFRAME_SELECTION, SET_LIVE_UPDATE_FOLLOW_RANGE, SET_TIMEFRAME_ZOOM_SELECTION, SET_LIVE_UPDATE_PAUSED } from './types'
import { clearUserToolConfig } from './user-tool-config-actions'

// ANALYSES
/**
 * @param {string} tool
 * @param {Analysis[]} analyses
 * @param {{ from: Date, to: Date }} [timeframe]
 * @param {string} [timeframeBy] Defaults to TIMEFRAME_BY_TIMESTAMP
 * @param {{excludeTagless?: boolean, enabledTagFilters?: Array<string>}} [options]
 */
export function setAnalyses(tool, analyses, timeframe, timeframeBy, options = {}) {
   return (dispatch, getState) => {

      const { units } = getState()

      let dateFrom = null
      let dateTo = null
      if (!timeframe) {
         // Get oldest and newest dates and create timeframe
         analyses.forEach((obj) => {
            const date = new Date(obj.timestamp)
            if (!dateFrom) {
               dateFrom = date
               dateTo = date
            } else if (date < dateFrom) {
               dateFrom = date
            } else if (date > dateTo) {
               dateTo = date
            }
         })
      } else {
         dateFrom = timeframe.from
         dateTo = timeframe.to
      }

      const unitFilters = []
      analyses.forEach((analysis) => {
         if (!unitFilters.includes(analysis.unit)) {
            if (units[analysis.unit]) unitFilters.push(analysis.unit)
         }
      })

      const tags = []
      let hasTaglessItems = false

      const positions = []
      for (const analysis of analyses) {

         // POSITIONS
         analysis.items.forEach((item) => {
            if (item.position) {
               if (positions.indexOf(item.position) === -1) {
                  positions.push(item.position)
               }
            }
         })

         // TAGS
         if (!hasTaglessItems && analysis.tags.length === 0) hasTaglessItems = true
         for (const id of analysis.tags) {
            if (tags.indexOf(id) === -1) {
               tags.push(id)
            }
         }

      }
      dispatch(enableTaglessFiltering(hasTaglessItems))
      if (options.excludeTagless) dispatch(excludeTagless(true))
      dispatch(setTagFilters(tags))
      if (options.enabledTagFilters) {
         for (const tag of tags) {
            if (!options.enabledTagFilters.includes(tag)) {
               dispatch(excludeTag(tag, true))
            }
         }
      }
      const timeframeByValue = timeframeBy || TIMEFRAME_BY_TIMESTAMP
      dispatch(setTimeframe(timeframeByValue, dateFrom, dateTo))
      dispatch(setTimeframeSelection(dateFrom, dateTo))
      dispatch(setUnitFilters(unitFilters))
      dispatch(setPositionFilters(positions))


      dispatch({
         type: SET_ANALYSES,
         payload: { tool, analyses },
      })
   }
}

// export function addAnalysis(analysis, limit, sort) {
//    return dispatch => {
//       const state = store.getState()
//       const tagFilters = state.tagFilters
//       const unitFilters = state.unitFilters
//       const taglessFilter = state.taglessFilter
//       const timeframe = state.timeframe
//       const { timestamp, tags, unit } = analysis

//       if (!timeframe) {
//          dispatch(setTimeframe(TIMEFRAME_BY_TIMESTAMP, timestamp, timestamp))
//       } else if (!timeframe.to || timestamp > timeframe.to) dispatch(updateTimeframe(undefined, undefined, timestamp)) // TODO: Check if works or should we convert to date object!
//       if (isEmpty(unitFilters) || !unitFilters[unit]) dispatch(addUnitFilter(unit))
//       if ( !taglessFilter.enabled && (!tags || tags.length === 0)) dispatch(enableTaglessFiltering(true))

//       if (tags && tags.length > 0) { // See tags in setAnalysis -> filters deleted tags. TODO: Check if should be added here
//          tags.forEach(tag => {
//             if (isEmpty(tagFilters) || !tagFilters[tag]) {
//                dispatch(addTagFilter(tag))
//             }
//          })
//       }
//       dispatch({
//          type: ADD_ANALYSIS,
//          payload: {
//             analysis,
//             limit,
//             sort,
//          }
//       })
//    }
// }


export function updateAnalyses(tool, analyses) {
   return {
      type: UPDATE_ANALYSES,
      payload: { tool, analyses },
   }
}

export function editAnalysis(tool, id, tags, unit, comment) {
   return {
      type: EDIT_ANALYSIS,
      payload: { tool, id, tags, unit, comment },
   }
}

export function removeAnalysis(tool, id) {
   return {
      type: REMOVE_ANALYSIS,
      payload: { tool, id },
   }
}

export function selectAnalysis(analysisId, deselectOthers = false) {
   return {
      type: SELECT_ANALYSIS,
      payload: { analysisId, deselectOthers },
   }
}
export function deselectAnalysis(analysisId) {
   return {
      type: UNSELECT_ANALYSIS,
      payload: analysisId,
   }
}
export function clearSelectedAnalyses() {
   return {
      type: CLEAR_SELECTED_ANALYSES,
   }
}

// /**
//  * @param {Object.<string, AnalysisImageMetadata>} imageMetadatas { <imageId>: AnalysisImageMetadata, ... }
//  */
// export function keepImages(imageMetadatas) {
//    return dispatch => {
//       const updatedAnalyses = []
//       const state = store.getState()
//       for (const imageId in imageMetadatas) {
//          const imageMetadata = imageMetadatas[imageId]
//          const { analysisId, isKept } = imageMetadata
//          let analysis = undefined
//          analysis = updatedAnalyses.find(analysis => analysis.id === analysisId)
//          if (!analysis) {
//             analysis = state.analyses.find(a => a.id === analysisId)
//             updatedAnalyses.push(analysis)
//          }
//          if (!analysis || !analysis.items) continue
//          for (const item of analysis.items) {
//             if (item.imageId === imageId) {
//                item.imageMetadata.isKept = isKept
//             } else if (item.result && item.result.imageId === imageId) {
//                item.result.imageMetadata.isKept = isKept
//             }
//          }
//       }
//       dispatch( updateAnalyses(updatedAnalyses) )
//    }
// }

// CONTINUOUS ANALYSES
export function setContinuousAnalysisPositionState(position, state) {
   return {
      type: SET_CONTINUOUS_ANALYSIS_POS_STATE,
      payload: { position, state },
   }
}


// LIVE UPDATE (CONTINUOUS ANALYSES)
export function startLiveUpdate(tool) {
   return {
      type: START_LIVE_UPDATE,
      payload: { tool },
   }
}

export function stopLiveUpdate() {
   return {
      type: STOP_LIVE_UPDATE,
   }
}

// FOLLOW RANGE
/** @type {import('../reducers/live-update-follow-range-reducer').LiveUpdateFollowRange} */
export const LIVE_UPDATE_DEFAULT_FOLLOW_RANGE = 'all'

/** @param {import('../reducers/live-update-follow-range-reducer').LiveUpdateFollowRange} followRange */
export function setLiveUpdateFollowRange(followRange) {
   return {
      type: SET_LIVE_UPDATE_FOLLOW_RANGE,
      payload: { followRange },
   }
}


// POSITION FILTERS
export function setPositionFilters(positions) {
   return {
      type: SET_POSITION_FILTERS,
      payload: positions,
   }
}

export function addPositionFilter(position) {
   return {
      type: ADD_POSITION_FILTER,
      payload: position,
   }
}

export function excludePosition(id, exclude) {
   return {
      type: EXCLUDE_POSITION,
      payload: { id, exclude },
   }
}


// UNIT FILTERS
/**
 *
 * @param {Unit} units
 */
export function setUnitFilters(units) {
   return {
      type: SET_UNIT_FILTERS,
      payload: units,
   }
}
export function addUnitFilter(unit) {
   return {
      type: ADD_UNIT_FILTER,
      payload: unit,
   }
}
export function excludeUnit(id, exclude) {
   return {
      type: EXCLUDE_UNIT,
      payload: { id, exclude },
   }
}


// TAG FILTERS
export function setTagFilters(tags) {
   return {
      type: SET_TAG_FILTERS,
      payload: tags,
   }
}
export function addTagFilter(tag) {
   return {
      type: ADD_TAG_FILTER,
      payload: tag,
   }
}

export function excludeTag(id, exclude) {
   return {
      type: EXCLUDE_TAG,
      payload: { id, exclude },
   }
}

// TAGLESS FILTER
export function excludeTagless(exclude) {
   return {
      type: EXCLUDE_TAGLESS,
      payload: exclude,
   }
}
export function enableTaglessFiltering(enable) {
   return {
      type: ENABLE_TAGLESS_FILTERING,
      payload: enable,
   }
}


// TOOL
export function selectTool(tool) {
   return (dispatch) => {
      batch(() => {
         dispatch(clearData())
         dispatch(clearUserToolConfig())
         dispatch(clearResultComponents())
         dispatch({
            type: SELECT_TOOL,
            payload: tool,
         })
      })
   }
}


// TIMEFRAME
export const TIMEFRAME_BY_TIMESTAMP = 'timestamp'
export const TIMEFRAME_BY_DATE_ANALYZED = 'dateAnalyzed'

export function setTimeframe(by, from, to) {
   return {
      type: SET_TIMEFRAME,
      payload: { by, from, to },
   }
}

/**
 * @param {boolean} isPaused
 * @param {string} [dueToMessage]
 */
export function setLiveUpdatePaused(isPaused, dueToMessage) {
   return {
      type: SET_LIVE_UPDATE_PAUSED,
      payload: {
         isPaused,
         dueToMessage, // (isPaused && ), // Due to message is kept until so fade messages still show
      },
   }
}

export function updateTimeframe(by, from, to) {
   return {
      type: UPDATE_TIMEFRAME,
      payload: { by, from, to },
   }
}

/**
 * Increases timeframe "to" if "to" param is larger than existing value and
 * decreases timeframe "from" if "from" param is smaller than existing value.
 * @param {Date} from
 * @param {Date} to
 */
export function bendTimeframe(from, to) {
   return {
      type: BEND_TIMEFRAME,
      payload: { from, to },
   }
}

/**
 * Updates timeframe selection and updates liveUpdateFollowRange according to it.
 * @param {*} from
 * @param {*} to
 * @param {{ from: Date, to: Date }} [timeframeReference] If timeframe reference is defined, liveUpdateFollowRange and selection are updated relative to it
 */
export function setTimeframeSelection(from, to, timeframeReference) {
   return (dispatch, getState) => {
      batch(() => {
         const state = getState()
         const isLiveUpdate = !!state.liveUpdate
         const liveUpdateFollowRange = state.liveUpdateFollowRange
         const timeframe = state.timeframe
         const timeframeSelection = state.timeframeSelection

         let newFrom = from
         let newTo = to

         if (isLiveUpdate) {
            let newFollowRange = null

            if (!timeframeSelection) {
               newFollowRange = LIVE_UPDATE_DEFAULT_FOLLOW_RANGE
            } else {
               const isSelectionFromAtTheStart = (from - (timeframeReference || timeframe).from <= 0)
               const isSelectionToAtTheEnd = (to - (timeframeReference || timeframe).to >= 0)

               // Only set follow range if timeframeSelection.to is at the end of timeframe
               if (isSelectionToAtTheEnd) {
                  newTo = timeframe.to
                  if (isSelectionFromAtTheStart) {
                     newFrom = timeframe.from
                     if (LIVE_UPDATE_DEFAULT_FOLLOW_RANGE === 'all' || to - from <= LIVE_UPDATE_DEFAULT_FOLLOW_RANGE) {
                        newFollowRange = LIVE_UPDATE_DEFAULT_FOLLOW_RANGE
                     } else {
                        newFollowRange = 'all'
                     }
                  } else {
                     newFollowRange = (to - from)
                     newFrom = timeframe.to - newFollowRange
                  }

               }
            }

            if (liveUpdateFollowRange !== newFollowRange) {
               dispatch(setLiveUpdateFollowRange(newFollowRange))
            }
         }

         dispatch({
            type: SET_TIMEFRAME_SELECTION,
            payload: { from: newFrom, to: newTo },
         })
      })
   }
}

export function updateTimeframeSelection(from, to) {
   return {
      type: UPDATE_TIMEFRAME_SELECTION,
      payload: { from, to },
   }
}

export function setTimeframeZoomSelection(from, to) {
   return {
      type: SET_TIMEFRAME_ZOOM_SELECTION,
      payload: { from, to },
   }
   // return (dispatch, getState) => {
   //    const state = getState()
   //    const timeframe = state.timeframe
   //    dispatch({
   //       type: SET_TIMEFRAME_ZOOM_SELECTION,
   //       payload: {
   //          from: Math.max(timeframe.from, from),
   //          to: Math.min(timeframe.to, to)
   //       }
   //    })
   // }
}

/**
 * @param {Date|number} [from]
 * @param {Date|number} [to]
 */
export function updateTimeframeZoomSelection(from, to) {
   return (dispatch, getState) => {
      const { timeframeSelection } = getState()
      if (!timeframeSelection) throw new Error("Timeframe selection doesn't exist")
      dispatch(setTimeframeZoomSelection(
         from ?? timeframeSelection.from,
         to ?? timeframeSelection.to
      ))
   }
}


// ALL DATA
export function clearData() {
   return {
      type: CLEAR_DATA,
   }
}
