import { selectToolboxes, selectTools } from '@ava/react-common/store/reducers/root-reducer'
import classNames from 'classnames/bind'
import { isEmpty } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Footer from '../Footer/footer'
import Navbar from '../Navbar/navbar'
import MobileSiteSelector from './MobileSiteSelector/mobile-site-selector'
import './page-home.css'
import ToolboxComponent from './ToolboxComponent/toolbox-component'

class PageHome extends Component {

   render() {
      const { toolboxes, tools, isMobile, site, sites } = this.props

      return (
         <div className="page-home">
            <div className="page-home-header">
               <Navbar transparent={true} />
               <h1>Advanced Visual Analysis</h1>
            </div>

            <div className={classNames('g-container', { mobile: isMobile })}>

               <div className="home-page-content">
                  <div className="main-container">
                     {(isMobile && site)
                     && <MobileSiteSelector />
                     }

                     {(sites && isEmpty(sites) && !site)
                     && <h3 className="no-sites-label">No sites available</h3>
                     }

                     {toolboxes.map((toolbox) => {
                        const boxTools = []
                        tools.forEach((tool) => {
                           if (tool.toolbox === toolbox.id) {
                              boxTools.push(tool)
                           }
                        })
                        return (
                           <ToolboxComponent key={toolbox.id} history={this.props.history} toolbox={toolbox.id} />
                        )
                     })}
                  </div>
               </div>

            </div>
            <Footer styleType="default" />
         </div>
      )
   }

}

const mapStateToProps = (state) => ({
   toolboxes: selectToolboxes(state),
   tools: selectTools(state),
   site: state.selectedSite,
   sites: state.sites,
   isMobile: state.device.isMobile,
})

export default connect(mapStateToProps)(PageHome)
