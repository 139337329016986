import React, { Component } from 'react'
import './content-container.css'

export default class ContentContainer extends Component {

   render() {

      const { style } = this.props

      // If you do changes to render, check "observeForInnerContainerScrollbarVisibility()" in BottomMenu and "observeContentContainerChilds()" in PageTool
      return (
         <div className="content-container" style={style}>
            <div className="inner-container">
               {this.props.children}
            </div>
         </div>
      )
   }

}
