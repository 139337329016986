import Tool from '@ava/react-common/models/tool'
import { deselectAnalysis, selectAnalysis, TIMEFRAME_BY_DATE_ANALYZED, TIMEFRAME_BY_TIMESTAMP } from '@ava/react-common/store/actions/data-actions'
import { selectSelectedTool, selectToolsFilteredAnalyses } from '@ava/react-common/store/reducers/root-reducer'
import { detectBrowser, sortedStringFromArray } from '@ava/react-common/utils/Helper'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Draggable from 'react-draggable'
import { connect } from 'react-redux'
import { Column, Table } from 'react-virtualized'
import Triangle from '../../../assets/triangle.svg'
// import sassVariables from '../../../shared.scss'
import './bottom-menu-table.css'

const sassVariables = { // TODO: After https://github.com/facebook/create-react-app/pull/10511 issue has been fixed, import sassVariables from the shared.scss file
   sideMenuWidth: '236px',
   navbarHeight: '60px',
}

// TODO: There is separate reusable component for react-virtualized table. Replace this with reuseable one when it has all the required functionality.
class BottomMenuTable extends Component {

   static propTypes = {
      height: PropTypes.number.isRequired,
   }

   constructor(props) {
      super(props)

      this.SortDirection = {
         ASC: 'ASC',
         DESC: 'DESC',
      }

      this.state = {
         viewWidth: 0, // TODO: Move to redux
         sortBy: 'timestamp',
         sortDirection: this.SortDirection.DESC,
         sortedList: [],
         widths: {},
      }

      this.deltaX = 0
      this.resizeCounter = 0
   }

   setupView = () => {
      const sortBy = 'timestamp'
      const sortDirection = this.SortDirection.DESC
      const sectionCount = 6
      const widths = {
         timestamp: 1 / sectionCount,
         dateAnalyzed: 1 / sectionCount,
         unit: 1 / sectionCount,
         tags: 1 / sectionCount,
         comment: 1 / sectionCount,
         position: 1 / sectionCount,
      }

      this.setState({
         sortBy,
         sortDirection,
         widths,
      }, () => this.sortList({ sortBy, sortDirection }))
   }

   componentDidUpdate(prevProps) {
      const { analyses, timeZone, toolId } = this.props
      if (prevProps.analyses !== analyses || prevProps.timeZone !== timeZone) {
         const { sortBy, sortDirection } = this.state
         this.sortList({ sortBy, sortDirection })
      }

      if (this.props.width !== prevProps.width) this.updateWindowDimensions()

      if (toolId !== prevProps.tool.id) {
         // Reset settings when tool is changed
         this.setupView()
      }
   }

   componentDidMount() {
      const { sortBy, sortDirection } = this.state
      this.setupView()
      this.updateWindowDimensions()
      this.sortList({ sortBy, sortDirection })
   }

   SortIndicator = (direction) => (
      <img
         alt="sort"
         className={classNames('triangle-sort-icon', { rotated: direction === this.SortDirection.DESC })}
         src={Triangle}
      />
   )

   // This function sorts the list and modifies it for the table
   sortList = ({ sortBy, sortDirection }) => {
      const { analyses, positions, units, timeZone, tags } = this.props
      const isContinuous = this.props.tool.config.type === Tool.Type.CONTINUOUS
      let rows = []

      const sortedAnalyses = [...analyses]

      if (sortBy === 'timestamp' || sortBy === 'dateAnalyzed') {
         sortedAnalyses.sort((a, b) => {
            // Sorting by date or number
            if (sortDirection === this.SortDirection.ASC) {
               return a[sortBy] - b[sortBy]
            }
            return b[sortBy] - a[sortBy]

         })
      }

      // Set data to correct format
      rows = sortedAnalyses.map((analysis) => {
         const validatedTags = analysis.tags.filter((id) => !!tags[id]) // Check if does exist
         const tagNames = validatedTags.map((id) => tags[id].name)
         const tagStr = sortedStringFromArray(tagNames)

         const validatedPositions = analysis.positions.filter((id) => !!positions[id])
         const positionNames = validatedPositions.map((id) => positions[id].name)
         const positionStr = sortedStringFromArray(positionNames)

         let hasError = false
         let hasExpection = false
         let hasWarning = false
         analysis.items.forEach((item) => {
            if (item.error) hasError = true
            if (item.exception) hasExpection = true
            if (item.warning) hasWarning = true
         })

         const timestampString = analysis.getTimestampString((!!isContinuous), timeZone)
         const dateAnalyzedString = analysis.getDateAnalyzedString((!!isContinuous), timeZone)

         return ({
            id: analysis.id,
            error: hasError,
            exception: hasExpection,
            warning: hasWarning,
            timestamp: timestampString,
            dateAnalyzed: dateAnalyzedString,
            author: analysis.author,
            unit: units[analysis.unit].name,
            tags: tagStr,
            position: positionStr,
            comment: analysis.comment,
         })
      })

      // Sort by strings
      let sortedRows = rows
      if (sortBy === 'unit' || sortBy === 'comment' || sortBy === 'tags' || sortBy === 'position') {
         sortedRows = rows.sort((a, b) => {

            let valA
            let valB

            if (sortBy === 'comment') {
               valA = a.comment
               valB = b.comment
            } else if (sortBy === 'unit') {
               valA = a.unit
               valB = b.unit
            } else if (sortBy === 'tags') {
               valA = a.tags
               valB = b.tags
            } else if (sortBy === 'position') {
               valA = a.position
               valB = b.position
            }

            const strA = valA ? valA.toUpperCase() : ''
            const strB = valB ? valB.toUpperCase() : ''

            if (strA < strB) {
               if (sortDirection === 'ASC') {
                  return -1
               }
               return 1

            } if (strA > strB) {
               if (sortDirection === 'ASC') {
                  return 1
               }
               return -1

            }
            return 0
         })
      }

      this.setState({ sortedList: sortedRows, sortBy, sortDirection })
   }


   changeSelection = (id) => {
      let isSelected = false

      this.props.selectedAnalysisIds.forEach((selectedID) => {
         if (id === selectedID) {
            isSelected = true
         }
      })
      if (isSelected) {
         this.props.deselectAnalysis(id)
      } else {
         this.props.selectAnalysis(id)
      }
   }

   updateWindowDimensions = () => {
      const sideMenuWidth = Number(sassVariables.sideMenuWidth.replace('px', '')) // convert to number
      const width = this.props.width - sideMenuWidth // full width - side menu width
      this.setState({ viewWidth: width })
   }

   rowClassName = ({ index }) => {
      if (index < 0) {
         return 'headerRow'
      }
      const rowItem = this.state.sortedList[index]
      const selected = this.props.selectedAnalysisIds.includes(rowItem.id)

      let className = index % 2 === 0 ? 'evenRow' : 'oddRow'
      if (selected) className += ' selectedRow' // Hightlight selected rows
      if (rowItem.error || rowItem.exception) className += ' errorRow' // Red bg to error rows
      if (rowItem.warning) className += ' warningRow' // Yellow bg to warning rows

      return className

   }

   render() {
      const { widths, viewWidth, sortBy, sortDirection, sortedList } = this.state

      return (
         <Table
            width={viewWidth} // calculate this and height!!!!!!
            height={this.props.height}
            headerHeight={26}
            rowHeight={30}
            rowClassName={this.rowClassName}
            headerClassName={'bottom-table-header'}
            rowCount={sortedList.length}
            rowGetter={({ index }) => sortedList[index]}
            onRowClick={(item) => this.changeSelection(item.rowData.id)}
            /* noRowsRenderer={this._noRowsRenderer}
            overscanRowCount={overscanRowCount}
            */
            sort={this.sortList}
            sortBy={sortBy}
            sortDirection={sortDirection}
         >
            <Column
               disableSort={false}
               headerRenderer={this.headerRenderer}
               dataKey={TIMEFRAME_BY_TIMESTAMP}
               label="Timestamp"
               width={widths.timestamp * viewWidth}
            />
            <Column
               headerRenderer={this.headerRenderer}
               dataKey={TIMEFRAME_BY_DATE_ANALYZED}
               label="Date Analyzed"
               width={widths.dateAnalyzed * viewWidth}
            />
            <Column
               disableSort={false}
               headerRenderer={this.headerRenderer}
               dataKey="unit"
               label="Processing Unit"
               width={widths.unit * viewWidth}
            />
            <Column
               disableSort={false}
               headerRenderer={this.headerRenderer}
               dataKey="position"
               label="Position"
               width={widths.position * viewWidth}
            />
            <Column
               disableSort={false}
               headerRenderer={this.headerRenderer}
               dataKey="tags"
               label="Tags"
               width={widths.tags * viewWidth}
            />
            <Column
               disableSort={false}
               headerRenderer={this.headerRenderer}
               dataKey="comment"
               label="Comment"
               width={widths.comment * viewWidth}
            />
         </Table>
      )
   }

   headerRenderer = ({
      // columnData,
      dataKey,
      disableSort,
      label,
      sortBy,
      sortDirection,
   }) => (
      <React.Fragment key={dataKey}>
         <div className="ReactVirtualized__Table__headerTruncatedText">
            {label}
         </div>
         <div>
            {(!disableSort && sortBy === dataKey)
               && this.SortIndicator(sortDirection)
            }
         </div>
         <Draggable
            axis="x"
            defaultClassName="DragHandle"
            defaultClassNameDragging="DragHandleActive"
            onDrag={(event, { deltaX }) => this.resizeRow({
               dataKey,
               deltaX,
            })
            }
            position={{ x: 0 }}
            zIndex={999}
         >
            <span className="DragHandleIcon" onClick={(event) => event.stopPropagation()}>⋮</span>
         </Draggable>
      </React.Fragment>
   )

   resizeRow = ({ dataKey, deltaX }) => {
      const isContinuous = (this.props.tool.config.type === Tool.Type.CONTINUOUS)

      // IE and Edge: Reduces update frequency for better performance
      this.deltaX += deltaX // deltaX is saved to variable. Since update frequency is reduced the total delta value needs to be tracked.
      const browser = detectBrowser()
      if (browser === 'IE11' || browser === 'MSIE' || browser === 'EDGE') {
         if (this.resizeCounter < 4) {
            this.resizeCounter += 1
            return
         }
         this.resizeCounter = 0

      }


      this.setState((prevState) => {
         const prevWidths = prevState.widths
         const percentDelta = this.deltaX / this.state.viewWidth
         this.deltaX = 0

         let nextDataKey = ''
         switch (dataKey) {
            case 'timestamp':
               nextDataKey = 'dateAnalyzed'
               break
            case 'dateAnalyzed':
               nextDataKey = 'unit'
               break
            case 'unit':
               if (isContinuous) {
                  nextDataKey = 'position'
                  break
               }
               nextDataKey = 'tags'
               break
            case 'position': {
               nextDataKey = 'tags'
               break
            }
            case 'tags':
               nextDataKey = 'comment'
               break
            default:
               break
         }

         return {
            widths: {
               ...prevWidths,
               [dataKey]: prevWidths[dataKey] + percentDelta,
               [nextDataKey]: prevWidths[nextDataKey] - percentDelta,
            },
         }
      })
   }

}

const mapStateToProps = (state) => ({
   analyses: selectToolsFilteredAnalyses(state),
   selectedAnalysisIds: state.selectedAnalyses,
   units: state.units,
   tags: state.tags,
   positions: state.positions,
   width: state.device.dimensions.width,
   tool: selectSelectedTool(state),
   toolId: state.selectedTool,
   timeZone: state.timeZone,
   // exclusiveRendering: state.resultComponents.exclusiveRendering,
})

export default connect(mapStateToProps, { selectAnalysis, deselectAnalysis })(BottomMenuTable)
