import UserService from '@ava/react-common/services/user-service'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '../Button/button'
import Input from '../Input/input'
import LoadingSpinner from '../LoadingSpinner/loading-spinner'
import Modal from '../Modal/modal'
import './user-modal.css'


class UserModal extends Component {

   static propTypes = {
      onClose: PropTypes.func.isRequired,
   }

   constructor(props) {
      super(props)

      this.views = {
         userInfo: 'USER_INFO',
         changePassword: 'CHANGE_PASSWORD',
      }

      this.state = {
         isLoading: false,
         currentView: this.views.userInfo,
         newPassword: '',
         repeatedPassword: '',
         oldPassword: '',
         error: undefined,
      }

   }

   validatePassword = () => {
      const { newPassword, repeatedPassword, oldPassword } = this.state
      const { user } = this.props
      this.setState({ error: undefined })

      // Validate
      if ((!newPassword.trim() || newPassword.trim === '') || (!repeatedPassword.trim() || repeatedPassword.trim === '') || (!oldPassword.trim() || oldPassword.trim === '')) {
         this.setState({ error: 'All fields are required' })
         return
      }
      if (newPassword !== repeatedPassword) {
         this.setState({ error: 'Passwords do not match' })
         return
      }
      if (newPassword.length < 8) {
         this.setState({ error: 'Passwords needs to be at least 8 characters' })
         return
      }
      // TODO: Add more validation methods (e.g. special character needed)

      // Save
      UserService.changePassword(user.id, newPassword, oldPassword)
         .then(() => {
            this.setState({ currentView: this.views.userInfo })
         })
         .catch((err) => {
            this.setState({ error: err })
         })
   }


   render() {

      const { user, onClose } = this.props
      const { isLoading, currentView, newPassword, repeatedPassword, oldPassword, error } = this.state

      if (!user) return null

      const passwordView = this.views.changePassword
      const userView = this.views.userInfo
      const isPasswordView = (currentView === passwordView)
      const isUserView = (currentView === userView)

      return (
         <Modal
            title={isPasswordView ? 'Change Password' : 'Account'}
            // isPrimaryBtnDisabled={(inDelete || inEdit || createNewItem) ? true : false}
            primaryBtnTitle={isPasswordView ? 'Save' : 'Close'}
            primaryBtnPressed={isPasswordView ? this.validatePassword : onClose}
            secondaryBtnTitle={isPasswordView ? 'Cancel' : undefined}
            secondaryBtnPressed={() => this.setState({ currentView: this.views.userInfo, error: undefined })}
            onClose={onClose}
            disabled={isLoading}
            // error={errorMessage}
            width="560px"
            height="460px"


            leftBtnTitle="Change Password"
         >
            <div className="user-modal-content-container">
               { isLoading && <LoadingSpinner />}
               { isPasswordView
                  && <div className="change-password-view">
                     <Input type="password" placeholder="New password" value={newPassword}
                        onChange={(value) => this.setState({ newPassword: value })} />
                     <Input type="password" placeholder="Repeat new password" value={repeatedPassword}
                        onChange={(value) => this.setState({ repeatedPassword: value })} />
                     <Input type="password" placeholder="Old password" value={oldPassword}
                        onChange={(value) => this.setState({ oldPassword: value })} />
                     { error && <p className="error-label">{error}</p> }
                  </div>
               }
               { isUserView && <UserView user={user} onPasswordChange={() => this.setState({ currentView: this.views.changePassword })} />}
            </div>

         </Modal>
      )
   }

}

const mapStateToProps = (state) => ({
   user: state.user,
})

export default connect(mapStateToProps)(UserModal)



const UserView = (props) => {
   const { user, onPasswordChange } = props
   const roleStr = user.accessLevel ? user.accessLevel.charAt(0).toUpperCase() + user.accessLevel.slice(1) : ' - '
   return (
      <div>
         <Row title="First Name" value={user.firstName || ''} />
         <Row title="Last Name" value={user.lastName || ''} />
         <Row title="Role" value={roleStr} />

         {(user.hasLocalStrategy())
            && <Button
               styleType={Button.StyleType.TEXT}
               title="Change password"
               onClick={onPasswordChange}
            />
         }
      </div>
   )
}
const Row = (props) => {
   const { title, value } = props
   return (
      <div className="flex-row" style={{ marginBottom: '6px' }}>
         <p style={{ minWidth: '100px' }}><strong>{title}:</strong></p>
         <p>{value}</p>
      </div>
   )
}
