import Tool from '@ava/react-common/models/tool'
import AuthService from '@ava/react-common/services/auth-service'
import { clearLocalSession } from '@ava/react-common/store/actions/auth-actions'
import { selectTool } from '@ava/react-common/store/actions/data-actions'
import { selectSite } from '@ava/react-common/store/actions/site-actions'
import { selectToolboxes } from '@ava/react-common/store/reducers/root-reducer'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MetrisLogo from '../../assets/metris-logo.svg'
import MetrisAVALogo from '../../assets/metrisAva.svg'
import MetrisHeader from '../../assets/metrisHeader.png'
import DropdownButton from '../DropdownButton/dropdown-button'
import MobileNavbar from '../MobileNavbar/mobile-navbar'
import RouterLink from '../RouterLink/RouterLink'
import UserModal from '../UserModal/user-modal'
import InfoModal from './info-modal'
import './navbar.css'
import TimeDropdown from './TimeDropdown/time-dropdown'
import SupportModal from '../SupportModal/support-modal'

class Navbar extends Component {

   static contextTypes = {
      transparent: PropTypes.bool,
   }

   constructor(props) {
      super(props)

      this.state = {
         userModalVisible: false,
         infoModalVisible: false,
         supportModalVisible: false,
         userManagementModalVisible: false,
      }
   }

   menuItemSelected = (value) => {

      const { selectedSiteId, selectSite } = this.props

      switch (value) {
         case 'info':
            this.setState({ infoModalVisible: true })
            break
         case 'account':
            this.setState({ userModalVisible: true })
            break
         case 'userManagement':
            this.props.history.push('/user-management')
            break
         case 'userMenu':
            this.props.history.push('/user-menu')
            break
         case 'connectMobile':
            this.props.history.push('/connect-mobile')
            break
         case 'signOut':
            AuthService
               .logout()
               .then(this.props.clearLocalSession)
               .catch(window.location.reload)
            break
         case 'support':
            this.setState({ supportModalVisible: true })
            break
         default:
            if (!selectedSiteId || value !== selectedSiteId) {
               selectSite(value)
                  .then(() => localStorage.setItem('currentSite', value))
                  .catch((err) => {
                     // eslint-disable-next-line no-alert
                     alert(err) // TODO: show custom dialog instead of alert
                     localStorage.removeItem('currentSite')
                  })
            }
            break
      }
   }

   modals = () => {
      const { userModalVisible, infoModalVisible, supportModalVisible } = this.state
      return (
         <>
            { userModalVisible
               && <UserModal
                  onClose={() => this.setState({ userModalVisible: false })}
               />
            }
            {infoModalVisible
               && <InfoModal
                  onClose={() => this.setState({ infoModalVisible: false })}
               />
            }
            { supportModalVisible
               && <SupportModal
                  onClose={() => this.setState({ supportModalVisible: false })}
               />
            }
         </>
      )
   }

   render() {

      const { isMobile, sites, tools, toolboxes, selectedSiteId, selectedToolId, selectTool, transparent, user } = this.props

      // Create options for dropdown menus
      const toolMenuOptions = []
      let siteMenuOptions = []

      // Sites
      if (sites) {
         siteMenuOptions = Object.keys(sites).map((key) => ({ value: key, label: sites[key].name }))
      }

      // Tools
      toolboxes.forEach((toolbox) => {
         toolMenuOptions.push({ section: toolbox.name })
         for (const key in tools) {
            const tool = tools[key]
            if (tool.toolbox === toolbox.id) {
               toolMenuOptions.push({
                  value: key,
                  label: tool.name,
                  disabled: tool.level === Tool.Level.LOCKED,
               })
            }
         }
      })

      const menuItems = [
         { section: 'General' },
         { value: 'info', label: 'Version', disabled: false },
         { value: 'account', label: 'Account', disabled: false },
         { value: 'userManagement', label: 'User Management', hidden: !((user && user.hasAdminPriviledges())) },
         { value: 'userMenu', label: 'Preinitialized Users', hidden: !((user && user.hasAdminPriviledges())) },
         { value: 'support', label: 'Support' },
         { value: 'connectMobile', label: 'Connect mobile app' },
         { value: 'signOut', label: 'Sign Out' },
         { section: 'Current Site' },
         ...siteMenuOptions,
      ]

      const mobileMenuItems = [
         { value: 'info', label: 'Version', disabled: false },
         { value: 'account', label: 'Account', disabled: false },
         { value: 'support', label: 'Support' },
         { value: 'connectMobile', label: 'Connect mobile app' },
         { value: 'signOut', label: 'Sign Out' },
      ]

      if (isMobile) {
         return (
            <>
               <MobileNavbar
                  logo={true}
                  menuItems={mobileMenuItems}
                  menuItemSelected={this.menuItemSelected}
               />
               {this.modals()}
            </>
         )
      }

      return (
         <div className={classNames('navbar', { 'transparent-navbar': transparent })}>

            <div className="nav-items-group left">
               <RouterLink to="/" onClick={() => selectTool(null)}>
                  <img
                     className="metris-ava-logo home-link"
                     alt="Logo"
                     src={MetrisAVALogo}
                  />
               </RouterLink>
               {(toolMenuOptions.length > 0)
                  && <DropdownButton
                     title={(selectedToolId && tools[selectedToolId]) ? tools[selectedToolId].name : 'Select Tool'} // TODO: Add language option
                     menuItems={toolMenuOptions}
                     selected={selectedToolId}
                     onSelect={(value) => {
                        if (!selectedToolId || value !== selectedToolId) {
                           selectTool(value)
                           this.props.history.push(`/tools/${value}`)
                        }
                     }}
                  />
               }
            </div>
            { !transparent
               && <img className="navbar-bg-img" alt="" src={MetrisHeader} />
            }
            <div className="nav-items-group">
               { true
                  && <TimeDropdown />
               }
               <DropdownButton
                  title={user ? `${user.firstName} ${user.lastName}` : ''}
                  subtitle={
                     (selectedSiteId && (sites[selectedSiteId] ? sites[selectedSiteId].name : 'Access Denied'))
                     || ''
                  }
                  menuItems={menuItems}// {siteMenuOptions}
                  selected={selectedSiteId}
                  onSelect={this.menuItemSelected}
                  alignRight={true}
               />

               {this.modals()}

               <img className="metris-logo" alt="" src={MetrisLogo} />
            </div>
         </div>
      )
   }

}


const mapStateToProps = (state) => ({
   selectedToolId: state.selectedTool,
   selectedSiteId: state.selectedSite,
   sites: state.sites,
   tools: state.tools,
   toolboxes: selectToolboxes(state),
   isMobile: state.device.isMobile,
   user: state.user,
})

export default withRouter(connect(mapStateToProps, { selectTool, selectSite, clearLocalSession })(Navbar))
