export default class LoginMethod {

   /**
    * Create a Tag
    * @param {string} id
    * @param {string} url
    * @param {string} title
    * @param identifierUri
    * @param {boolean} isHidden
    */
   constructor(id, url, title, identifierUri, isHidden) {
      this.id = id
      this.url = url
      this.title = title
      this.identifierUri = identifierUri
      this.isHidden = isHidden
   }

   /**
    * @param {Object} json
    * @returns {LoginMethod}
    */
   static fromJson(json) {
      return new LoginMethod(json.id, json.url, json.title, json.identifierUri, json.hidden || json.isHidden)
   }

   /**
    * @param {Object[]} jsonArr
    * @returns {LoginMethod[]}
    */
   static fromJsonArr(jsonArr) {
      return jsonArr.map((json) => new LoginMethod(json.id, json.url, json.title, json.identifierUri, json.hidden || json.isHidden)
      )
   }

}
