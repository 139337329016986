import { SET_TOOLBOXES } from '../actions/types'


const initialState = {}

const toolboxesReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_TOOLBOXES:
         return action.payload.reduce((obj, box) => {
            obj[box.id] = box
            return obj
         }, {})

      default:
         return state

   }
}

export default toolboxesReducer
