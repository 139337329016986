import React, { useState, useEffect, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import { getConvertedAndRoundedValue, getLanguageString } from '@ava/react-common/utils/Helper'

const staticChartOptions = {
   // title: { style: { display : 'none' } },
   title: { text: '', margin: 40, style: { fontSize: '16px', fontFamily: '"Gilroy", sans-serif', fontWeight: 300 } },
   chart: {
      height: 186,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      animation: false,
      type: 'column',
      style: {
         fontFamily: '"Gilroy", sans-serif',
      },
      // margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      overflow: 'visible',
   },
   xAxis: {
      categories: [],
      // Test data - uncomment this and test data in series to display data
      // categories: [
      //   '0-50',
      //   '50-100',
      //   '100-150',
      //   '150-200',
      //   '200-250',
      //   '250-300',
      // ],
      crosshair: true,
      title: { text: null, margin: 6, style: { fontSize: '12px', fontFamily: '"Gilroy", sans-serif', fontWeight: 400 } },
   },
   yAxis: {
      min: 0,
      title: { text: null, margin: 6, style: { fontSize: '12px', fontFamily: '"Gilroy", sans-serif', fontWeight: 400 } },
   },
   tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<td style="padding:0">Value: <b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
   },
   plotOptions: {
      column: {
         pointPadding: 0,
         borderWidth: 0,
         groupPadding: 0,
         shadow: false,
         animation: {
            duration: 0,
         },
      },
   },
   series: [{
      name: 'Data',
      // test data
      // data: [80, 100, 20, 129.2, 144.0, 35.6],
      data: [],
      // color: "#0075BE",
   }],
   legend: {
      enabled: false,
   },
   credits: {
      enabled: false,
   },
}


ColumnChart.propTypes = {
   resultElements: PropTypes.object.isRequired, // Analysis result elements
   height: PropTypes.number, // chart height
}
/**
 * @typedef {object} ColumnChartScale
 * @property {number} min
 * @property {number} max
 */
/**
 * @param {object} props
 * @param {any[]} props.resultElements
 * @param {any[]} props.componentElements
 * @param {number} props.height
 * @param {string} props.title
 * @param {string} props.yAxisTitle
 * @param {string} props.xAxisTitle
 * @param {Object<string, string>} props.xAxisLabels
 * @param {string} props.unit
 * @param {ColumnChartScale} props.scale
 * @param {Highcharts.AxisPlotLinesOptions[]} props.plotLines
 * @param {boolean} [props.round]
 * @returns {import('react').FunctionComponentElement}
 */
export default function ColumnChart({ resultElements, componentElements, height, title, yAxisTitle, xAxisTitle, xAxisLabels, round, unit, scale, plotLines }) {
   const [chartOptions, setChartOptions] = useState(staticChartOptions)
   const chartRef = useRef()

   // Update chart options
   useEffect(() => {
      if (!chartRef.current.chart) return

      /** @type {Highcharts.Chart} */
      const highchart = chartRef.current.chart
      const yAxis = highchart.yAxis[0]
      const xAxis = highchart.xAxis[0]

      const titleStr = title
      // if (unit) titleStr += `(${unit})`
      highchart.setTitle({ text: titleStr })
      yAxis.setTitle({ text: yAxisTitle })
      xAxis.setTitle({ text: xAxisTitle })
      if (height) highchart.setSize(null, height, false)

      // Scale
      if (scale) {
         yAxis.setExtremes(scale.min, scale.max, false)
      } else {
         yAxis.setExtremes(null, null, false)
      }

      // PlotLines - https://www.highcharts.com/docs/chart-concepts/plot-bands-and-plot-lines
      // Remove previous
      yAxis.plotLinesAndBands.forEach((line) => {
         yAxis.removePlotLine(line.id)
      })
      // Create new
      plotLines?.forEach((line) => {
         yAxis.addPlotLine(line)
      })

   }, [xAxisTitle, yAxisTitle, title, chartRef, unit, scale, height, plotLines])

   // Update values
   useEffect(() => {
      if (resultElements && componentElements) {
         const seriesData = []
         const categories = []
         Object.keys(componentElements).forEach((key) => {
            const element = componentElements[key]
            const origValue = resultElements[key]
            if (origValue !== null && element) {
               const value = getConvertedAndRoundedValue(element, origValue, round)
               seriesData.push(value)
               // If xAxisLabel is defined use it, otherwise use element name
               if (xAxisLabels?.[key]) {
                  categories.push(xAxisLabels[key])
               } else {
                  categories.push(getLanguageString(element.name))
               }
            }
         })

         // @ts-ignore
         setChartOptions({
            series: [
               { data: seriesData },
            ],
            xAxis: {
               categories,
            },
         })
      }
   }, [resultElements, componentElements, xAxisLabels, round])

   return (
      <div style={{ height: '100%', width: '100%', display: 'block', position: 'relative' }}>
         <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={chartOptions}
         />
      </div>
   )
}
