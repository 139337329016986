export const SET_TIMEFRAME = 'SET_TIMEFRAME'
export const SET_LIVE_UPDATE_PAUSED = 'SET_LIVE_UPDATE_PAUSED'
export const UPDATE_TIMEFRAME = 'UPDATE_TIMEFRAME'
export const BEND_TIMEFRAME = 'BEND_TIMEFRAME'
export const SET_TIMEFRAME_SELECTION = 'SET_TIMEFRAME_SELECTION'
export const UPDATE_TIMEFRAME_SELECTION = 'UPDATE_TIMEFRAME_SELECTION'
export const SET_TIMEFRAME_ZOOM_SELECTION = 'SET_TIMEFRAME_ZOOM_SELECTION'
export const SET_ANALYSES = 'SET_ANALYSES'
export const UPDATE_ANALYSES = 'UPDATE_ANALYSES'
export const ADD_ANALYSES = 'ADD_ANALYSES'
export const REMOVE_ANALYSIS = 'REMOVE_ANALYSIS'
// export const SET_SEPARATE_UNITS = "SEPARATE_UNITS"
export const SELECT_ANALYSIS = 'SELECT_ANALYSIS'
export const UNSELECT_ANALYSIS = 'UNSELECT_ANALYSIS'
export const CLEAR_SELECTED_ANALYSES = 'CLEAR_SELECTED_ANALYSES'
export const SET_CONTINUOUS_ANALYSIS_POS_STATE = 'SET_CONTINUOUS_ANALYSIS_POS_STATE'
export const START_LIVE_UPDATE = 'START_LIVE_UPDATE'
export const STOP_LIVE_UPDATE = 'STOP_LIVE_UPDATE'
export const SET_LIVE_UPDATE_FOLLOW_RANGE = 'SET_LIVE_UPDATE_FOLLOW_RANGE'
export const SET_SITE = 'SET_SITE'
export const SET_SITES = 'SET_SITES'
export const UPDATE_SITE_TIMEZONE = 'UPDATE_SITE_TIMEZONE'
export const SET_UNITS = 'SET_UNITS'
export const SET_TAGS = 'SET_TAGS'
export const SET_DCS_TAGS = 'SET_DCS_TAGS'
export const SET_SIZE = 'SET_SIZE'
export const CLEAR_DATA = 'CLEAR_DATA'
export const SET_UNIT_FILTERS = 'SET_UNIT_FILTERS'
export const ADD_UNIT_FILTER = 'ADD_UNIT_FILTER'
export const SET_TAG_FILTERS = 'SET_TAG_FILTERS'
export const ADD_TAG_FILTER = 'ADD_TAG_FILTER'
export const SET_POSITIONS = 'SET_POSITIONS'
export const SET_POSITION_FILTERS = 'SET_POSITION_FILTERS'
export const ADD_POSITION_FILTER = 'ADD_POSITION_FILTER'

export const DELETE_POSITION = 'DELETE_POSITION'
export const EDIT_POSITION = 'EDIT_POSITION'
export const CREATE_POSITION = 'CREATE_POSITION'

export const SET_TOOLBOXES = 'SET_TOOLBOXES'
export const SET_TOOLS = 'SET_TOOLS'
export const SELECT_TOOL = 'SELECT_TOOL'

export const DELETE_TAG = 'DELETE_TAG'
export const RENAME_TAG = 'RENAME_TAG'
export const DELETE_UNIT = 'DELETE_UNIT'
export const EDIT_UNIT = 'EDIT_UNIT'
export const CREATE_TAG = 'CREATE_TAG'
export const CREATE_UNIT = 'CREATE_UNIT'

export const EDIT_ANALYSIS = 'EDIT_ANALYSIS'


export const EXCLUDE_POSITION = 'EXCLUDE_POSITION'
export const EXCLUDE_UNIT = 'EXCLUDE_UNIT'
export const EXCLUDE_TAG = 'EXCLUDE_TAG'

export const EXCLUDE_TAGLESS = 'EXCLUDE_TAGLESS'
export const ENABLE_TAGLESS_FILTERING = 'ENABLE_TAGLESS_FILTERING'


export const SET_USER = 'SET_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const SET_USER_TOOL_CONFIG = 'SET_USER_TOOL_CONFIG'
export const CLEAR_USER_TOOL_CONFIG = 'CLEAR_USER_TOOL_CONFIG'

export const SET_ONGOING_ANALYSIS = 'SET_ONGOING_ANALYSIS'
export const CLEAR_ONGOING_ANALYSIS = 'CLEAR_ONGOING_ANALYSIS'

/* Result components */
export const CLEAR_RESULT_COMPONENTS = 'CLEAR_RESULT_COMPONENTS'
export const SET_RESULT_COMPONENT_MODELS = 'SET_RESULT_COMPONENT_MODELS'
export const UPDATE_RESULT_COMPONENT_MODEL_CHART = 'UPDATE_RESULT_COMPONENT_MODEL_CHART'
export const ADD_RESULT_COMPONENT_MODEL = 'ADD_RESULT_COMPONENT_MODEL'
export const REMOVE_RESULT_COMPONENT_MODEL = 'REMOVE_RESULT_COMPONENT_MODEL'
export const SHOW_LATEST_RESULT = 'SHOW_LATEST_RESULT'
export const SHOW_LATEST_IMAGES = 'SHOW_LATEST_IMAGES'
export const SET_EXCLUSIVE_RENDERING = 'SET_EXCLUSIVE_RENDERING'

export const SET_TIMEZONE = 'SET_TIMEZONE'


export const SHOW_DIALOG = 'SHOW_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'



/* ONLY REACT-NATIVE HMI */
export const SET_CURRENT_INSTANCE = 'SET_CURRENT_INSTANCE'
export const SELECT_UNIT = 'SELECT_UNIT'
