import { useEffect, useRef } from 'react'

/**
 * Doesn't trigger effect callback when extraDeps change
 *
 * @param {(changedDependencies: boolean[]) => void} effect Effect contains "changedDependencies" parameter that contain boolean array of what dependencies have been changed after previous update
 * @param {React.DependencyList} deps Only rerender when these dependencies change
 * @param {React.DependencyList} [extraDeps] Dependencies that are used but don't call the effect callback on change
 * @param {boolean} [dontTriggerInitial] If effect callback should be triggered on initialize
 */
export default function useEffectWhen(effect, deps, extraDeps = [], dontTriggerInitial = false) {
   const allDeps = [...deps, ...extraDeps]
   const whenRef = useRef(deps || [])

   const initial = (whenRef.current === deps)
   const changedDependencies = whenRef.current.map((w, i) => w !== deps[i])
   const hasDepsChanged = initial
      ? !dontTriggerInitial
      : changedDependencies.includes(true)

   whenRef.current = deps
   const nullDeps = allDeps.map(() => null)

   return useEffect(
      hasDepsChanged ? () => effect(changedDependencies) : () => {},
      hasDepsChanged ? allDeps : nullDeps
   )
}
