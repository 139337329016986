import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Arrow from '../../assets/arrow.svg'
// import sassVariables from '../../shared.scss'
import Button from '../Button/button'
import './secondary-navbar.css'

const sassVariables = { // TODO: After https://github.com/facebook/create-react-app/pull/10511 issue has been fixed, import sassVariables from the shared.scss file
   sideMenuWidth: '236px',
   navbarHeight: '60px',
}


class SecondaryNavbar extends Component {

   static propTypes = {
      title: PropTypes.string,
      onBack: PropTypes.func,
   }

   render() {

      const { title, children, onBack } = this.props

      const navbarHeight = sassVariables.navbarHeight
      return (
         <div className="secondary-navbar" style={{ height: navbarHeight, width: '100%' }}>
            <Button
               onClick={onBack || (() => this.props.history.goBack())}
               styleType={Button.StyleType.ICON}
               iconSrc={Arrow}
            />
            <h3>{title}</h3>
            {children}
         </div>
      )

   }

}

export default withRouter(SecondaryNavbar)
