import { batch } from 'react-redux'
import Position from '../../models/position'
import Tag from '../../models/tag'
import Tool from '../../models/tool'
import Toolbox from '../../models/toolbox'
import Unit from '../../models/unit'
import DataService from '../../services/data-service'
import { getLanguageString, modifyDateToTimezone } from '../../utils/Helper'
import { selectTool } from './data-actions'
import { clearOngoingAnalysis } from './ongoing-analysis-actions'
import { clearResultComponents } from './result-component-actions'
import { setPositions, setTags, setUnits } from './setup-actions'
import { setTimeZone } from './timezone-actions'
import { setToolboxes, setTools } from './toolbox-actions'
import { SET_SITE, SET_SITES, UPDATE_SITE_TIMEZONE } from './types'
import { clearUserToolConfig } from './user-tool-config-actions'


/**
 * @param {string} currentSite
 */
export function loadSites(currentSite) {
   /**
    * @param dispatch
    * @returns {Promise<string>} - Selected site id
    */
   return async (dispatch) => {
      try {
         const sites = await DataService.getSites()

         let selectSiteId
         for (const site of sites) {
            if (currentSite === site.id) {
               selectSiteId = currentSite
               break
            }
         }

         dispatch({
            type: SET_SITES,
            payload: sites,
         })

         if (selectSiteId) {
            await dispatch(selectSite(selectSiteId))
            return selectSiteId
         } if (sites.length > 0) {
            const id = sites[0].id
            await dispatch(selectSite(id))
            return id
         }
      } catch(err) {}
      return null
   }
}

export function updateSiteTimezone(site, timeZone) {
   return {
      type: UPDATE_SITE_TIMEZONE,
      payload: { timeZone, site },
   }
}

export function selectSite(site) {
   return async (dispatch) => {
      try {
         const data = await DataService.getSite(site)

         /* Parse sites data*/
         const toolboxes = data.toolboxes.map((box) => new Toolbox(box.id, getLanguageString(box.name)))

         const localTags = []
         const tools = data.tools.map((tool) => {

            const tags = data.tags.filter((tag) => tag.tool === tool.id) // TODO: Check why whole tag object is under tool instead of id -> REFACTOR AND FIX!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            // ADD HARDCODED #has_images tag for continuous analyses (tools that save images only once in time period)
            if (tool.config && tool.config.type === Tool.Type.CONTINUOUS) {
               localTags.push(Tag.getHasImagesTag(tool.id))
               tags.push(Tag.getHasImagesTag(tool.id))// tags.push(Tag.getHasImagesTagId(tool.id))
            }

            const tagIds = tags.map((tag) => tag.id)
            const positions = data.positions.filter((position) => position.tool === tool.id)
            const positionIds = positions.map((p) => p.id)
            return new Tool(tool.id, tool.config, getLanguageString(tool.name), tool.toolbox, tool.level, getLanguageString(tool.description), tool.resultModels, tagIds, positionIds)
         })
         const tags = data.tags.map((tag) => new Tag(tag.id, tag.name, tag.tool))

         localTags.forEach((localTag) => tags.push(localTag))

         const units = data.units.map((unit) => new Unit(unit.id, unit.name, unit.toolbox, unit.tools))
         const positions = data.positions.map((position) => new Position(position.id, position.name, position.unit))

         let timeZone = data.timeZone || null
         // Detect if site time === local time. If is, use only local time.
         if (timeZone) {
            const localDate = new Date()
            const timeZoneDate = modifyDateToTimezone(localDate, timeZone)
            if (localDate.getTime() === timeZoneDate.getTime()) timeZone = null
         }

         batch(() => {
            dispatch(clearUserToolConfig())
            dispatch(clearResultComponents())
            dispatch(clearOngoingAnalysis())
            dispatch(selectSiteSuccess(site))
            dispatch(setTools(tools))
            dispatch(selectTool(null))
            dispatch(setUnits(units))
            dispatch(setTags(tags))
            dispatch(setPositions(positions))
            dispatch(setToolboxes(toolboxes))
            dispatch(setTimeZone(timeZone))
         })

      } catch(err) {
         console.error(`Site change failed: ${err}`)
         throw `Site change failed: ${err}`
      }
   }
}
export const selectSiteSuccess = (site) => ({
   type: SET_SITE,
   payload: site,
})
