export default class Unit {

   /**
    * Create a "Processing unit"
    * @param {string} id
    * @param {string} name
    * @param {string} toolbox
    * @param {string[]} tools
    */
   constructor(id, name, toolbox, tools) {
      this.id = id
      this.name = name
      this.toolbox = toolbox
      this.tools = tools || []
   }


   /**
    * Creates "Processing units" from Json Object
    * @param {object} json
    * @returns {Unit}
    */
   static fromJson(json) {
      if (!json) return null
      return new Unit(json.id, json.name, json.toolbox, json.tools)
   }

}
