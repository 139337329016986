import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './top-menu.css'

// TODO: Determine if other units are deselected when unit is selected (add toggle)
export default class TopMenu extends Component {

   static propTypes = {
      options: PropTypes.array.isRequired,
      onSelect: PropTypes.func,
      style: PropTypes.object,
      disabled: PropTypes.bool,
   }

   /*
      options schema:
      {
         title: "Title",
         value: "value",
         selected: true
      }
   */

   menuButton = (button) => {

      const buttonClass = classNames({
         'top-menu-button': true,
         selected: button.selected,
      })

      return (
         <button
            className={buttonClass}
            key={button.value}
            onClick={() => {
               if (this.props.disabled) return
               this.props.onSelect(button.value)
            }}
         >
            {button.title}
         </button>
      )
   }


   render() {
      const { options, style, disabled } = this.props
      if (options.length === 0) return null

      return (
         <div className={classNames('top-menu', { disabled })} style={style}>
            <div className="flex-row" style={{ height: '100%' }}>
               {options.map((option) => this.menuButton(option))}
            </div>
         </div>
      )
   }

}
