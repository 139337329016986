import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './button.css'

export default class Button extends Component {

   static StyleType = Object.freeze({
      PRIMARY: 'primary',
      OUTLINED: 'outlined',
      ICON: 'icon',
      TEXT: 'text',
      DANGER: 'danger',
   })

   static propTypes = {
      title: PropTypes.string,
      styleType: PropTypes.string,
      iconSrc: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      style: PropTypes.object,
   }

   static defaultProps = {
      styleType: Button.StyleType.OUTLINED,
   }


   getClassName() {
      let className = 'button '
      className += this.props.styleType
      if (this.props.disabled) {
         className += ' disabled'
      }
      return className
   }


   render() {

      const { title, onClick, disabled, iconSrc, style } = this.props

      return (
         <button
            className={`${this.getClassName()} ${this.props.className}`}
            onClick={onClick && onClick}
            disabled={disabled}
            style={style || {}}
         >
            { title }
            { iconSrc
               && <img
                  className="button-icon"
                  src={iconSrc}
                  alt=""
               />
            }
         </button>
      )
   }

}
