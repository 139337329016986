import { CLOSE_DIALOG, SHOW_DIALOG } from '../actions/types'


/**
 * @typedef {object} ModalButton
 * @property {string} text
 * @property {() => void} [onPress]
 * @property {('default'|'cancel'|'destructive')} [style]
 */

/**
 * @typedef {object} Dialog
 * @property {string} [id] Dialog id that can be used for making sure right dialog is closed when calling closeDialog action
 * @property {string} [title]
 * @property {string} [text]
 * @property {ModalButton[]} [buttons]
 * @property {boolean} [cancelable]
 */
const initialState = null

export default function DialogReducer(state = initialState, action) {
   switch (action.type) {

      case SHOW_DIALOG: {
         const { id, title, text, buttons, options } = action.payload
         return { id, title, text, buttons, options }
      }

      case CLOSE_DIALOG:
         if (action.payload.id && (state?.id !== action.payload.id)) return state
         return null

      default:
         return state

   }
}
