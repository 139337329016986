import { useEffectWhen } from '@ava/react-common/hooks'
import UserService from '@ava/react-common/services/user-service'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { removeResultComponentModel, updateResultComponentModelChart } from '@ava/react-common/store/actions/result-component-actions'
import { setUserToolConfig } from '@ava/react-common/store/actions/user-tool-config-actions'
import { cleanObject, errorToMessage } from '@ava/react-common/utils'
import Highcharts from 'highcharts'
import Highstock from 'highcharts/highstock'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store from '../../../store'
import Button from '../../Button/button'
import Card from '../../Card/card'
import Chart from '../../Charts/chart'
import ColumnChart from '../../Charts/column-chart'
import Dialog from '../../Dialog/dialog'
import Input from '../../Input/input'
import Toggle from '../../Toggle/toggle'
import TimeframeComponent from '../TimeframeComponent/timeframe-component'
import './chart-card.css'


/**
 * @param {object} props
 * @param {{ chart: any, id: string, unit: string, isCustomChart: boolean }} props.component
 * @param {any[]} props.elements
 * @param {import('@ava/react-common/models').Analysis[]} props.analyses
 * @param {object} props.elementsPositionsWithData
 * @returns {import('react').FunctionComponentElement}
 */
export default function ChartCard({ component, elements, analyses, elementsPositionsWithData }) {
   const { chart, id, unit, isCustomChart } = component

   const dispatch = useDispatch()

   const [isAutoscaling, setAutoscaling] = useState(
      (chart.defaultType !== Chart.Type.BOOLEAN)
         ? (chart.autoscale ?? (!((chart.editedScale || chart.defaultScale))))
         : undefined
   )
   const [isVisible, setVisible] = useState(chart.isVisible)
   const [yMinStr, setYMinStr] = useState(chart.editedScale?.min?.toString() ?? '')
   const [yMaxStr, setYMaxStr] = useState(chart.editedScale?.max?.toString() ?? '')
   const yMin = yMinStr ? parseFloat(yMinStr.replace(/,/g, '.')) : null
   const yMax = yMaxStr ? parseFloat(yMaxStr.replace(/,/g, '.')) : null

   const [showPlotLines, setShowPlotLines] = useState(chart.showPlotLines ?? true)
   const [plotLinesStr, setPlotLinesStr] = useState(chart.editedPlotLines?.map((line) => line.value).join(',') ?? '') // object with values is parsed to string.
   const [showAveragePlotLines, setShowAveragePlotlines] = useState(chart.showAveragePlotLines ?? false)
   const [showDetailedStatistics, setShowDetailedStatistics] = useState(chart.showDetailedStatistics ?? false)
   const [isFullScreen, setFullScreen] = useState(false)
   const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false)

   const userToolConfig = useSelector((state) => state.userToolConfig)
   const user = useSelector((state) => state.user)
   const selectedTool = useSelector((state) => state.selectedTool)


   // Y min and Y max validations depend on each others values so we must revalidate after the other value changes
   const refYMinInput = useRef()
   const refYMaxInput = useRef()
   useEffect(() => { refYMaxInput.current?.validate() }, [yMinStr])
   useEffect(() => { refYMinInput.current?.validate() }, [yMaxStr])


   // FIXME: Why the chart height doesn't auto scale when height is set to null (https://api.highcharts.com/highcharts/chart.height)
   const refChartCard = useRef()
   const [fullScreenChartHeight, setFullScreenChartHeight] = useState()
   useEffectWhen(() => {
      if (!isFullScreen) return
      let fullHeight = refChartCard.current.getBoundingClientRect().height
      // Reduce chart legends height form full height
      const chartLegends = refChartCard.current.getElementsByClassName('chart-legends')[0]
      if (chartLegends) fullHeight -= chartLegends.getBoundingClientRect().height
      if (fullScreenChartHeight !== fullHeight) setFullScreenChartHeight(fullHeight - 24) // reduce 24 (bottom padding) because TimeframeComponent is set to bottom using style "bottom: -24px"
   }, [refChartCard, isFullScreen, elementsPositionsWithData], [fullScreenChartHeight])


   useEffect(() => {
      Highcharts.charts.forEach((chart) => chart && chart.reflow())
      Highstock.charts.forEach((chart) => chart && chart.reflow())
   }, [isFullScreen])

   // Edit user tool chart config when it is edited
   useEffectWhen(([hasIsVisibleChanged, hasIsAutoscalingChanged, hasYMinChanged, hasYMaxChanged, hasPlotLinesStrChanged, hasShowPlotLinesChanged, hasAveragePlotLinesChanges, hasDetailedStatisticsChanged]) => {
      const chartToUpdate = { id, isCustomChart }
      if (hasIsVisibleChanged) chartToUpdate.isVisible = isVisible

      // Line chart properties
      if (chart.defaultType !== Chart.Type.BOOLEAN) {
         if (hasIsAutoscalingChanged) chartToUpdate.isAutoscaling = isAutoscaling
         if (hasYMinChanged || hasYMaxChanged) chartToUpdate.scale = { min: yMin, max: yMax }
         if (hasPlotLinesStrChanged) chartToUpdate.plotLines = plotLinesStr?.split(',').map((item) => ({ value: Number(item) })) || null
         if (hasShowPlotLinesChanged) chartToUpdate.showPlotLines = showPlotLines
         if (hasAveragePlotLinesChanges) chartToUpdate.showAveragePlotLines = showAveragePlotLines
         if (hasDetailedStatisticsChanged) chartToUpdate.showDetailedStatistics = showDetailedStatistics
      }

      UserService
         .editUserToolConfig(
            userToolConfig,
            { chartsToUpdate: [chartToUpdate] },
            selectedTool,
            user.id,
            (newConfig) => {
               dispatch(updateResultComponentModelChart(
                  id,
                  cleanObject({
                     ...chart,
                     ...cleanObject({
                        isVisible: chartToUpdate.isVisible,
                        autoscale: chartToUpdate.isAutoscaling,
                        editedScale: chartToUpdate.scale,
                        editedPlotLines: chartToUpdate.plotLines,
                        showPlotLines: chartToUpdate.showPlotLines,
                        showAveragePlotLines: chartToUpdate.showAveragePlotLines,
                        showDetailedStatistics: chartToUpdate.showDetailedStatistics,
                     }),
                  })
               ))

               dispatch(setUserToolConfig(newConfig))
            }
         )
         .catch((err) => {
            dispatch(showDialog({
               title: 'Failed to update User Tool Config',
               text: errorToMessage(err),
            }))
         })
   }, [isVisible, isAutoscaling, yMin, yMax, plotLinesStr, showPlotLines, showAveragePlotLines, showDetailedStatistics], [id, chart, isCustomChart, userToolConfig, selectedTool, user, dispatch], true)


   const deleteChart = () => {
      const { userToolConfig, selectedTool, user } = store.getState()
      UserService
         .editUserToolConfig(
            userToolConfig,
            { chartsToRemove: [id] },
            selectedTool,
            user.id,
            (newConfig) => {
               dispatch(removeResultComponentModel(id))
               dispatch(setUserToolConfig(newConfig))
               setDeleteDialogVisible(false)
            }
         )
         .catch((err) => {
            dispatch(showDialog({
               title: 'Failed to update User Tool Config',
               text: errorToMessage(err),
            }))
         })
   }

   const generateChart = (elements, title) => { // TODO: Don't redraw chart when it is not visible (scrolling)

      // Get scale
      const scaleMin = chart.editedScale?.min ?? chart.defaultScale?.min
      const scaleMax = chart.editedScale?.max ?? chart.defaultScale?.max
      const chartScale = isAutoscaling ? null : { min: scaleMin, max: scaleMax } // if (scale === null) is autoscaling

      // Get plotlines
      const plotLinesConfig = chart.editedPlotLines ?? chart.defaultPlotLines
      const plotLines = !showPlotLines ? [] : plotLinesConfig?.map((line) => ({ // If showPlotLines === true, pass plotlines to chart
         color: '#FF0000',
         width: 2,
         dashStyle: 'ShortDot',
         zIndex: 2,
         ...line,
      }))

      if (chart.defaultType === Chart.Type.COLUMN_CHART) {
         return (
            <ColumnChartComponent
               elements={elements}
               component={component}
               analyses={analyses}
               scale={chartScale}
               height={isFullScreen ? fullScreenChartHeight : 320}
               plotLines={plotLines}
            />
         )
      }
      return (
         <Chart
            title={title || chart.title}
            unit={unit}
            defaultType={chart.defaultType}
            types={chart.types}
            elements={elements}
            elementsPositionsWithData={elementsPositionsWithData}
            roundPrecision={chart.round}
            scale={chartScale}
            height={isFullScreen ? fullScreenChartHeight : 340}
            plotLines={plotLines}
            showAveragePlotLines={chart.showAveragePlotLines}
            showDetailedStatistics={chart.showDetailedStatistics}
         />
      )

   }


   const generateChartOptions = () => {

      if (chart.defaultType === Chart.Type.BOOLEAN && !isCustomChart) return // Boolean chart has only delete option
      return (
         <div className="chart-options-container">
            <div className="chart-options">

               { chart.defaultType !== Chart.Type.BOOLEAN
                  && <>
                     <div className="flex-row">
                        <p><strong>Scale:</strong></p>

                        <Input
                           ref={refYMinInput}
                           disabled={isAutoscaling}
                           className="scale-input"
                           value={yMinStr}
                           onChange={(val) => setYMinStr(val || null)}
                           placeholder={`${chart.defaultScale?.min}`}
                           regexGroups={['-?[0-9]+?(\\.|,)?([0-9]+)?']}
                           allowedCharacters={['0-9', '-', ',', '.']}
                           allowEmpty={true}
                           customValidation={(value) => {
                              if (yMax == null && yMax !== 0) return true
                              return (value < yMax)
                           }}
                        />
                        <p>-</p>
                        <Input
                           ref={refYMaxInput}
                           disabled={isAutoscaling}
                           className="scale-input"
                           value={yMaxStr || ''}
                           placeholder={`${chart.defaultScale?.max}`}
                           onChange={(val) => setYMaxStr(val || null)}
                           regexGroups={['-?[0-9]+?(\\.|,)?([0-9]+)?']}
                           allowedCharacters={['0-9', '-', ',', '.']}
                           allowEmpty={true}
                           customValidation={(value) => {
                              if (yMin == null && yMin !== 0) return true
                              return (value > yMin)
                           }}
                        />

                        <p>auto</p>
                        <Toggle
                           isOn={isAutoscaling}
                           onChange={(on) => setAutoscaling(on)}
                        />
                     </div>
                     <div style={{ marginTop: '16px' }}>
                        <div className="flex-row" style={{ justifyContent: 'space-between' }}>
                           <p><strong>Plot lines:</strong></p>
                           <Toggle
                              isOn={showPlotLines}
                              onChange={setShowPlotLines}
                           />
                        </div>
                        <p style={{ fontSize: '12px' }}>{'Values separated by comma e.g. "20.4,50"'}</p>
                        <Input
                           disabled={!showPlotLines}
                           value={plotLinesStr}
                           onChange={setPlotLinesStr}
                           placeholder={chart.defaultPlotLines?.map((line) => line.value).join(',')}
                           regexGroups={['(-)?([0-9]+)?(\\.)?([0-9]+)', '((,(-)?(([0-9]+)?(\\.)?([0-9]+))+)+)?']}
                           allowedCharacters={[',', '.', '0-9', '-']}
                           allowEmpty={true}
                        />
                     </div>
                     <div style={{ marginTop: '16px' }}>
                        <div className="flex-row" style={{ justifyContent: 'space-between' }}>
                           <p><strong>Average plot lines:</strong></p>
                           <Toggle
                              isOn={showAveragePlotLines}
                              onChange={setShowAveragePlotlines}
                           />
                        </div>
                     </div>
                     <div style={{ marginTop: '16px' }}>
                        <div className="flex-row" style={{ justifyContent: 'space-between' }}>
                           <p><strong>Detailed statistics:</strong></p>
                           <Toggle
                              isOn={showDetailedStatistics}
                              onChange={setShowDetailedStatistics}
                           />
                        </div>
                     </div>
                  </>
               }

               {/* Only custom charts can be deleted */}
               { isCustomChart
                  && <Button
                     title="Delete Chart"
                     style={{ marginTop: '16px' }} // TODO: Add proper styling (card-menu-btn max-height = 24px)
                     styleType={Button.StyleType.DANGER}
                     onClick={() => setDeleteDialogVisible(true)}
                  />
               }
            </div>
         </div>
      )
   }

   // Check required parameters
   if (!elements || !chart) {
      console.log('Pass all required parameters to ChartCard component')
      return null
   }

   return (
      <Card
         menuCustomComponent={generateChartOptions()}
         onClose={() => setVisible(false)}
         isFullScreen={isFullScreen}
         onFullScreen={() => setFullScreen(!isFullScreen)}
      >
         <div
            ref={refChartCard}
            className="chart-card"
            style={{ height: isFullScreen ? '100%' : undefined }}
         >
            {generateChart(elements)}
         </div>

         <Dialog
            visible={isDeleteDialogVisible}
            title={'Delete Chart?'}
            message="Are you sure? This cannot be undone."
            buttons={[{
               text: 'DELETE',
               style: 'danger',
               onPress: deleteChart,
            }, {
               text: 'CANCEL',
            }]}
            onClose={() => setDeleteDialogVisible(false)}
         />

         { isFullScreen
            && <TimeframeComponent
               style={{
                  width: '100vw',
                  left: '-16px', // Card padding left
                  bottom: '-24px', // Card padding bottom
                  position: 'relative',
               }}
            />
         }

      </Card>
   )
}



const ColumnChartComponent = ({ elements, component, analyses, scale, height, plotLines }) => {
   const latest = analyses.slice(-1)[0]

   return (
      latest.items.map((item, i) => (
         <ColumnChart
            key={`column-chart--${i}`}
            height={height}
            resultElements={item.result && item.result.elements}
            xAxisLabels={component.chart.xAxisLabels}
            yAxisTitle={component.chart.yAxisTitle}
            xAxisTitle={component.chart.xAxisTitle}
            round={component.chart.round}
            title={component.chart.title}
            componentElements={elements}
            unit={component.unit}
            scale={scale}
            plotLines={plotLines}
         />
      ))
   )
}
