import User from '@ava/react-common/models/user'
import UserService from '@ava/react-common/services/user-service'
import { selectSites } from '@ava/react-common/store/reducers/root-reducer'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Input from '../../Input/input'
import Modal from '../../Modal/modal'
import ReactSelect from '../../ReactSelect/react-select'
import Toggle from '../../Toggle/toggle'
import './user-management-modal.css'


class UserManagementModal extends Component {

   static propTypes = {
      onClose: PropTypes.func.isRequired,
   }

   constructor(props) {
      super(props)

      this.state = {
         user: props.user, // if user exists -> is editing
         isLoading: false,
         username: '',
         firstName: '',
         lastName: '',
         role: undefined,
         error: undefined,
         allowedSites: [],

         createdUser: undefined,
         tempPassword: undefined,

         siteOptions: props.sites.map((site) => ({
            value: site.id,
            label: site.name,
         })),
      }

      this.roleOptions = User.getRoleOptions()
   }

   componentDidMount() {
      const { user } = this.props
      const { siteOptions } = this.state
      // Setupview
      if (user) {
         const role = this.roleOptions.find((role) => role.value === user.accessLevel)

         const allowedSites = !user.rules.sites ? null : siteOptions.filter((site) => user.rules.sites.includes(site.value))

         this.setState({
            username: user.username || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            role: role || undefined,
            error: undefined,
            allowedSites,
         })
      }
   }

   createUser = () => {
      const { username, firstName, lastName, role, allowedSites } = this.state

      /* VALIDATE */
      if (!username.trim() || !firstName.trim() || !lastName.trim() || !role) {
         this.setState({ error: 'All fields are required' })
         return
      }

      const accessLevel = role.value
      this.setState({ isLoading: true, error: undefined })

      const rules = new User.Rules(
         allowedSites ? allowedSites.map((site) => site.value) : null
      )

      UserService.createUser(username, firstName, lastName, accessLevel, rules)
         .then((result) => {
            this.props.onUserCreated(result.user)
            this.setState({ isLoading: false, tempPassword: result.temporaryPassword, createdUser: result.user })
         })
         .catch((err) => {
            this.setState({ error: 'User creation failed', isLoading: false })
            console.error(err)
         })
   }

   editUser = () => {
      const { user, firstName, lastName, role, allowedSites } = this.state

      /* VALIDATE */
      if (!firstName.trim() || !lastName.trim() || !role) {
         this.setState({ error: 'All fields are required' })
         return
      }

      const accessLevel = role.value
      this.setState({ isLoading: true, error: undefined })


      let sites
      if (accessLevel === User.AccessLevel.ADMIN) sites = user.rules.sites
      else sites = (allowedSites ? allowedSites.map((site) => site.value) : null)
      const rules = new User.Rules(sites)

      UserService
         .editUser(
            user.id,
            user.firstName !== firstName ? firstName : undefined,
            user.lastName !== lastName ? lastName : undefined,
            user.accessLevel !== accessLevel ? accessLevel : undefined,
            JSON.stringify(user.rules) !== JSON.stringify(rules) ? rules : undefined
         )
         .then((user) => {
            this.props.onUserEdited(user)
         })
         .catch((err) => {
            this.setState({ error: 'Failed to edit user', isLoading: false })
            console.error('ERROR Editing user:', err.stack)
         })
   }

   render() {

      const { onClose } = this.props
      const { user, isLoading, error, username, firstName, lastName, role, tempPassword, createdUser, allowedSites, siteOptions } = this.state

      return (
         <Modal
            id="user-management-modal"
            title={(createdUser && undefined) || (user ? 'Edit user' : 'Create user')}
            primaryBtnTitle={createdUser ? 'Close' : 'Save'}
            primaryBtnPressed={(createdUser && onClose) || (user ? this.editUser : this.createUser)}
            secondaryBtnTitle="Cancel"
            secondaryBtnPressed={onClose}
            onClose={onClose}
            disabled={isLoading}
            error={error}
            width="520px"
            height="680px"
         >
            <div className="user-modal-content-container">

               { !createdUser
                  && <div className="user-manage-view">
                     <h4 style={{ marginBottom: '2px' }}>Username</h4>
                     <Input
                        value={username}
                        disabled={!!user}
                        onChange={(username) => this.setState({ username })
                        }
                     />
                     <h4 style={{ marginBottom: '2px' }}>First Name</h4>
                     <Input
                        value={firstName}
                        onChange={(value) => this.setState({ firstName: value })
                        }
                     />
                     <h4 style={{ marginBottom: '2px' }}>Last Name</h4>
                     <Input
                        value={lastName}
                        onChange={(value) => this.setState({ lastName: value })
                        }
                     />

                     <h4 style={{ marginTop: '20px' }}>Access level</h4>
                     <ReactSelect
                        isMulti={false}
                        options={this.roleOptions}
                        value={role}
                        onChange={(role) => {
                           this.setState({ role })
                        }}
                     />

                     {(!role || role.value !== User.AccessLevel.ADMIN) && (
                        <div>
                           <div className="allowed-sites-row">
                              <h4 style={{ marginTop: '20px' }}>Allowed sites</h4>
                              <div className="allowed-sites-tgl-container">
                                 <p>All sites:</p>
                                 <Toggle
                                    isOn={allowedSites === null}
                                    onChange={(on) => this.setState({ allowedSites: on ? null : [] })
                                    }
                                 />
                              </div>
                           </div>
                           { allowedSites
                              && <ReactSelect
                                 isMulti={true}
                                 options={siteOptions}
                                 value={allowedSites}
                                 onChange={(value) => this.setState({ allowedSites: value || [] })
                                 }
                              />
                           }
                        </div>
                     )}


                  </div>
               }

               { createdUser && <UserCreatedView user={createdUser} password={tempPassword} /> }
            </div>
         </Modal>
      )
   }

}

const mapStateToProps = (state) => ({
   sites: selectSites(state),
})

export default connect(mapStateToProps)(UserManagementModal)



const UserCreatedView = (props) => {
   const { user, password } = props

   if (!user || !user.username || !password) {
      return null
   }

   return (
      <div>
         <div className="selectable-user-row">
            <p style={{ width: '100px' }}><strong>Username:</strong></p>
            <p>{user.username}</p>
         </div>

         <div className="selectable-user-row">
            <p style={{ width: '100px' }}><strong>Password:</strong></p>
            <p>{password}</p>
         </div>
      </div>
   )
}
