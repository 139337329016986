import { useState } from 'react'
import useEffectWhen from './useEffectWhen'

/**
 * @param {any} value
 * @param {boolean} keepUnchanged Keeps the value unchanged while this is true and updates the value when this turns back to false
 */
export default function useKeepValueUnchangedWhile(value, keepUnchanged) {
   const [valueToBeStayedAt, setValueToBeStayedAt] = useState()
   useEffectWhen(() => {
      if (keepUnchanged) {
         setValueToBeStayedAt(value)
      } else {
         setValueToBeStayedAt(undefined)
      }
   }, [keepUnchanged], [value])

   return valueToBeStayedAt || value
}
