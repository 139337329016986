import { CLEAR_DATA, SET_TIMEFRAME_ZOOM_SELECTION } from '../actions/types'

/**
 * Timeframe zoom selection for TimeFrameSlider
 *
 * @typedef {object} TimeframeZoomSelection
 * @property {Date} from
 * @property {Date} to
 */
const initialState = null


/**
 * @type {import("./root-reducer").ReduxReducer<TimeframeZoomSelection>}
 */
export default function timeframeZoomSelectionReducer(state = initialState, action) {
   switch (action.type) {

      case SET_TIMEFRAME_ZOOM_SELECTION:
         return {
            from: new Date(action.payload.from),
            to: new Date(action.payload.to),
         }

      case CLEAR_DATA:
         return null

      default:
         return state

   }
}
