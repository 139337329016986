import { statusCodeToMessage } from './StatusCodes'


/**
 * @param {any} error
 * @returns {string}
 */
export function errorToMessage(error) {
   if (typeof error === 'string') return error
   if (error.isAxiosError && error.response) {
      if (error.response.data?.message) return error.response.data.message
      return statusCodeToMessage(error.response.status)
   }
   return error.message
}

/**
 * @param {any} error
 * @returns {boolean}
 */
export function isNetworkError(error) {
   return (error.message === 'Network Error')
}

/**
 * @param {any} error
 * @returns {boolean}
 */
export function isApiClientError(error) {
   if (!error.isAxiosError || !error.response) return false
   return (error.response.status.toString()[0] === '4')
}
/**
 * @param {any} error
 * @returns {boolean}
 */
export function isApiServerError(error) {
   if (!error.isAxiosError || !error.response) return false
   return (error.response.status.toString()[0] === '5')
}

/**
 * `NOTE: Only for axios requests`
 * @param {any} error
 * @param {number} [statusCode] Optional status code check.
 * @returns {boolean}
 */
export function isApiError(error, statusCode) {
   if (!error.isAxiosError || !error.response) return false
   if (statusCode) return (error.response.status === statusCode)
   return true
}
