import { deselectAnalysis, selectAnalysis, setLiveUpdatePaused, setTimeframeSelection } from '@ava/react-common/store/actions/data-actions'
import { selectToolsFilteredAnalyses, selectToolsFilteredPositions } from '@ava/react-common/store/reducers/root-reducer'
import { dateToString, detectBrowser, generateColor, getConvertedAndRoundedValue, getLanguageString, round } from '@ava/react-common/utils/Helper'
import { getTimezoneOffset } from 'date-fns-tz'
import { EventEmitter } from 'events'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'
import boost from 'highcharts/modules/boost'
import { cloneDeep, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ChartLegends from '../ChartLegends/chart-legends'
import './chart.css'

const chartPauseEventEmitter = new EventEmitter()

HighchartsMore(Highcharts)
boost(Highcharts)


class Chart extends Component {

   static Type = {
      LINE: 'line',
      BOOLEAN: 'boolean',
      COLUMN_CHART: 'columnChart',
   }

   static propTypes = {
      defaultType: PropTypes.oneOf(Object.values(Chart.Type)),
      types: PropTypes.array,
      elements: PropTypes.object.isRequired,
      scale: PropTypes.object, // null === autoscale
      title: PropTypes.string,
      unit: PropTypes.string,
      roundPrecision: PropTypes.number,
      plotLines: PropTypes.array, // Highcharts plotlines https://api.highcharts.com/highcharts/xAxis.plotLines
      showAveragePlotLines: PropTypes.bool,
      showDetailedStatistics: PropTypes.bool,
      height: PropTypes.number.isRequired,
      elementsPositionsWithData: PropTypes.object.isRequired, // { <elementId>, <positionIdsWithData>[]> }
      legends: PropTypes.object,
   }

   constructor(props) {
      super(props)
      this.state = {
         dontUpdate: false,
         messageOverChart: null,
         useBoostModule: (props.defaultType !== 'boolean'), // Boost module is not used at all on boolean chart because highchart html elements are edited in boolean chart
         throttleUpdate: false,
         elementStatistics: undefined, // Object with key ELEMENT_ID and params: min, max, mean, count. Statistics for the element (all positions combined)
         positionStatistics: undefined, // Object where key is ELEMENT_ID and value is object with key POSITION_ID, that contains mean, min, max for that position
      }
      this.setInitialVars()
      this.chartRef = React.createRef()
      /** @type {Highcharts.Chart|undefined} */
      this.highchart = undefined
      this.chartOptions = this.generateChartOptions()
      this.prevAnalysesPositions = []
      this.prevAnalysesElements = []
   }

   setInitialVars = (keepNecessaryDataForRedraw) => {
      this.series = []
      this.indexToAnalysisId = {}
      this.hasAnalysesChangedDuringDontUpdate = false
      this.hasTimeframeChangedDuringDontUpdate = false
      this.wasChartVisibleInScrollAreaLastUpdate = undefined
      this.hoverMoveEndWaitTime = 3000
      this.hoverMoveTimeout = undefined
      this.lastMessageOverChart = null

      const seriesCurrentValueElements = keepNecessaryDataForRedraw ? this.boolChartData.seriesCurrentValueElements : {}
      const seriesTitleElements = keepNecessaryDataForRedraw ? this.boolChartData.seriesTitleElements : {}

      // Bool chart
      this.boolChartData = {
         seriesIndexes: {},
         seriesValues: {},
         seriesExtraPointAdded: {},
         seriesCurrentValueElements,
         seriesTitleElements,
         idToName: {},
         redrawRedrawed: false,
         drawingTooltips: false,
         legends: undefined,
      }
      this.boolChartOptions = {
         rowHeight: 56,
         rowPaddingTop: 6,
         maxTimeBetweenPointsBeforeCut: 60000,
         additionalTimeForLonelyVal: 10000, // Creates another point after single bool val. Otherwise single boolean values width would be zero
      }

   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                  LIFECYCLE METHODS                 |
   \*__________________________________________________*/

   componentDidMount() {
      this.highchart = Highcharts.charts[this.chartRef.current.firstChild.getAttribute('data-highcharts-chart')]

      this.chartRef.current.oncontextmenu = () => false
      this.chartRef.current.addEventListener('mousemove', this.onMouseMove)
      this.chartRef.current.addEventListener('mouseleave', this.onMouseLeave)
      this.chartRef.current.addEventListener('mouseup', this.onMouseUpChart)

      const contentContainerElements = document.getElementsByClassName('content-container') // This is used for getting page-tool scroll view top and bottom positions
      this.contentContainer = contentContainerElements ? contentContainerElements[0] : undefined

      chartPauseEventEmitter.addListener('pause', this.pauseListener)

      this.contentContainer?.addEventListener('scroll', this.onContentContainerScroll)
      this.wasChartVisibleInScrollAreaLastUpdate = this.isChartFullScreenOrVisibleInScrollArea()

      this.generateChartSeries(this.props.filteredAnalyses)

      this.setNewChartOptions()

      // const { max } = this.getXAxisExtremes()
      // const min = this.highchart.xAxis[0].getExtremes().min
      // this.highchart.xAxis[0].setExtremes(min, max, false)
   }

   componentWillUnmount() {
      this.chartRef.current.removeEventListener('mousemove', this.onMouseMove)
      this.chartRef.current.removeEventListener('mouseleave', this.onMouseLeave)
      this.chartRef.current.removeEventListener('mouseup', this.onMouseUpChart)
      chartPauseEventEmitter.removeListener('pause', this.pauseListener)

      this.contentContainer?.removeEventListener('scroll', this.onContentContainerScroll)
   }

   getSnapshotBeforeUpdate() {
      this.timeStart = new Date().getTime()
      return null
   }

   componentDidUpdate(prevProps, prevState) {
      const { liveUpdatePaused, filteredAnalyses, elementsPositionsWithData, showDetailedStatistics } = this.props
      const { positionStatistics } = this.state
      let redraw = false

      if (prevState.positionStatistics !== positionStatistics || showDetailedStatistics !== prevProps.showDetailedStatistics) {
         this.updateLegends(this.series || [])
      }

      const changedAnalyses = this.hasAnalysesChanged(prevProps.filteredAnalyses, filteredAnalyses)

      if (this.props.liveUpdate && liveUpdatePaused !== prevProps.liveUpdatePaused) {
         if (liveUpdatePaused.isPaused) {
            const message = liveUpdatePaused.dueToMessage
            this.lastMessageOverChart = message
            this.setState({
               dontUpdate: true,
               messageOverChart: message,
            })
         } else {
            this.setState({
               dontUpdate: false,
               messageOverChart: null,
            })
         }
      }

      const isChartVisibleInScrollArea = this.isChartFullScreenOrVisibleInScrollArea()
      const dontUpdateChart = (this.state.throttleUpdate || this.state.dontUpdate || !isChartVisibleInScrollArea)

      if (dontUpdateChart) {
         if (!this.hasAnalysesChangedDuringDontUpdate) {
            this.analysesChangedDuringDontUpdate = changedAnalyses
            this.hasAnalysesChangedDuringDontUpdate = this.analysesChangedDuringDontUpdate.state !== 'notChanged'
         } else if (changedAnalyses.state === 'added' && this.analysesChangedDuringDontUpdate?.state === changedAnalyses.state) {
            this.analysesChangedDuringDontUpdate.analysesRemovedFromStart += changedAnalyses.analysesRemovedFromStart
            this.analysesChangedDuringDontUpdate.changedAnalyses.push(...changedAnalyses.changedAnalyses)
         } else if (changedAnalyses.state === 'new') {
            this.analysesChangedDuringDontUpdate = changedAnalyses
            this.hasAnalysesChangedDuringDontUpdate = true
         }

         if (this.props.timeframeSelection !== prevProps.timeframeSelection) {
            this.hasTimeframeChangedDuringDontUpdate = true
         }
      }
      this.wasChartVisibleInScrollAreaLastUpdate = isChartVisibleInScrollArea

      // ChartOptions
      const newChartOptions = this.updateChartOptions(prevProps, prevState)
      if (newChartOptions) redraw = true


      if (!dontUpdateChart) {

         // Analyses changes
         const analysesChanged = this.hasAnalysesChangedDuringDontUpdate ? this.analysesChangedDuringDontUpdate : changedAnalyses

         if (this.hasAnalysesChangedDuringDontUpdate || analysesChanged.state !== 'notChanged') {
            this.hasAnalysesChangedDuringDontUpdate = false
            this.analysesChangedDuringDontUpdate = undefined


            let newPositions = false
            for (const element in this.props.elements) {
               if (prevProps.elementsPositionsWithData[element].length !== elementsPositionsWithData[element].length) {
                  newPositions = true
                  break
               }
            }


            // TODO: Remove analyses from start using addPoints shift parameter when "analysesChanged.analysesRemovedFromStart > 0" instead of generating chart again?
            if (analysesChanged.state === 'new' || (analysesChanged.state === 'added' && (analysesChanged.analysesRemovedFromStart > 0 || newPositions))) {

               // GENERATE WHOLE CHART AGAIN
               if (analysesChanged.state === 'new') {
                  this.setInitialVars(true)
               }

               // Clear series
               while (this.highchart.series.length > 0) {
                  this.highchart.series[0].remove(false)
               }
               if (this.props.defaultType === Chart.Type.BOOLEAN) { // Recreate tooltip series for boolean chart
                  this.highchart.addSeries({ id: 'boolChartTooltipSeries', data: [], type: 'line' }, false)
               }

               // Add series, analyses and update chart options
               this.generateChartSeries(filteredAnalyses)
               if (analysesChanged.state === 'new') this.setNewChartOptions()
               else this.updateChartOptions()
            } else {
               // ADD NEW POINTS TO END
               this.addNewAnalyses(analysesChanged.changedAnalyses)
            }

            redraw = true
         }

         // Timeframe selection changed
         if ((this.props.timeframeSelection !== prevProps.timeframeSelection) || this.hasTimeframeChangedDuringDontUpdate) {
            const { from, to } = this.props.timeframeSelection
            this.highchart.xAxis[0].setExtremes(+from, +to, false)
            this.hasTimeframeChangedDuringDontUpdate = false
            redraw = true
         }
      }

      // Redraw
      if (redraw) this.redrawChart()

      // Throttling
      if (!this.state.throttleUpdate && !prevState.throttleUpdate) {
         this.tookTime = new Date().getTime() - this.timeStart
         if (this.tookTime > 100) {
            let updateAfter
            if (this.tookTime < 250) {
               updateAfter = 1000
            } else if (this.tookTime < 500) {
               updateAfter = this.tookTime * 4
            } else if (this.tookTime < 750) {
               updateAfter = this.tookTime * 3
            } else {
               updateAfter = this.tookTime * 2
            }
            this.setState({ throttleUpdate: true }, () => {
               setTimeout(() => {
                  this.setState({ throttleUpdate: false })
               }, updateAfter)
            })
         }
         // console.log(this.tookTime + "ms");
      }
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                    CHART EVENTS                    |
   \*__________________________________________________*/

   analysisSelected = (analysisId) => {
      if (!this.props.selectedAnalysisIds.includes(analysisId)) {
         this.props.selectAnalysis(analysisId)
      } else {
         this.props.deselectAnalysis(analysisId)
      }
   }

   onMouseMove = () => {
      if (this.props.defaultType !== Chart.Type.BOOLEAN) {
         this.checkBoostModuleUsage()
      }

      clearTimeout(this.hoverMoveTimeout)
      this.hoverMoveTimeout = setTimeout(() => {
         if (this.props.defaultType !== Chart.Type.BOOLEAN && !this.state.useBoostModule) {
            this.setState({ useBoostModule: true })
         }
         chartPauseEventEmitter.emit('pause', false)
      }, this.hoverMoveEndWaitTime)

      chartPauseEventEmitter.emit('pause', true)
   }

   onMouseLeave = () => {
      clearTimeout(this.hoverMoveTimeout)
      if (this.props.defaultType !== Chart.Type.BOOLEAN && !this.state.useBoostModule) {
         this.setState({ useBoostModule: true })
      }
      chartPauseEventEmitter.emit('pause', false)
   }

   onMouseUpChart = (e) => {
      if (e.button === 2) {
         e.preventDefault()
         const { dataMin, dataMax } = this.getXAxisExtremes()
         this.highchart.xAxis[0].setExtremes(dataMin, dataMax)
         this.props.setTimeframeSelection(dataMin, dataMax)
      }
   }

   /** @param {boolean} pause */
   pauseListener = (pause) => {
      if (this.props.liveUpdate) {
         if (pause) {
            if (this.state.dontUpdate) return
            this.props.setLiveUpdatePaused(true, 'Updating paused due to mouse movement')
         } else {
            if (!this.state.dontUpdate) return
            this.props.setLiveUpdatePaused(false)
         }
      }
   }

   onContentContainerScroll = () => {
      if (!this.wasChartVisibleInScrollAreaLastUpdate && this.isChartFullScreenOrVisibleInScrollArea()) {
         this.forceUpdate()
      }
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                   CHART METHODS                    |
   \*__________________________________________________*/

   /**
    * When there is lots of data, Highchart sometimes throws internal error when redraw is called.
    * Not sure if boost module is part of the issue.
    */
   redrawChart() {
      try {
         this.highchart.redraw()
      } catch(err) {
         console.error('Failed to redraw chart', err)
      }
   }

   generateChartOptions() {
      const { filteredAnalyses, defaultType, title, unit, height, plotLines } = this.props
      const context = this

      let chartTitle = title || 'Chart'
      if (unit) chartTitle += ` (${unit})`


      if (filteredAnalyses == null) return

      /**
       * @type {import('highcharts').Options}
       */
      const chartOptions = {
         chart: {
            type: defaultType || Chart.Type.LINE,
            style: {
               fontFamily: '"Gilroy", sans-serif',
            },
            events: {},
            /* resetZoomButton: {
               theme: { display: 'none' }
            }, */
            height,
            // marginRight: 28, this prevents autoscale
            marginBottom: 0,
            zoomType: 'x',
            animation: false,
         },

         credits: {
            enabled: false,
         },

         title: {
            text: chartTitle,
            style: {
               fontWeight: 'normal',
            },
         },

         subtitle: false,

         boost: {
            enabled: true,  // Using boost.enabled property to disable boosting doesn't clear the boost line when zoomed (AVA-1944), so we use plotOptions.series.boostThreshold to enable/disable booosting (https://api.highcharts.com/highcharts/plotOptions.series.boostThreshold).
            // useGPUTranslations: true, // Not used because causes rendering issues due to floating point precision (https://api.highcharts.com/highcharts/boost.useGPUTranslations)
            // usePreallocated: true,    // Not used because causes rendering issues
         },

         navigator: {
            enabled: false,
         },
         scrollbar: {
            enabled: true, // Enabled for live redrawing but hidden using height 0
            liveRedraw: (detectBrowser() !== 'IE11'), // Live reload not enabled on IE
            height: 0, // Remove from css if scrollbar is used: .chart .highcharts-scrollbar-arrow { display: none }
         },
         rangeSelector: {
            enabled: false,
            // selected: 2,
            /* buttonTheme: { // styles for the buttons
               width: 24,
               fill: 'white',
               style: {

               },
               states: {
                  hover: {
                     fill: 'white',
                     stroke: 'none',
                     style: {
                        color: '#E46C01'
                     }
                  },
                  select: {
                     fill: 'white',
                     stroke: 'none',
                     style: {
                        color: '#E46C01'
                     }
                  }
               }
            }*/
         },

         xAxis: {
            type: 'datetime',
            startOnTick: false,
            endOnTick: false,
            minPadding: 0.05,
            maxPadding: 0.05,
            ordinal: false,
            minRange: 10,
            crosshair: {
               enabled: true,
            },
            events: {
               setExtremes(e) {
                  // Update timeframe selection when chart is zoomed manually
                  if (e.trigger === 'zoom') {
                     context.props.setTimeframeSelection(e.min, e.max)
                  }
               },
            },
         },
         yAxis: {
            title: null,
            labels: {
               align: 'left',
               x: 0,
               y: -3,
               format: '{value}', // `{value} ${unit ? unit : ""}`
            },
            plotLines: plotLines ? [...plotLines] : undefined, // (note: average plotlines are calculated in updateChartOptions)
            showLastLabel: true,
         },

         legend: {
            enabled: false,
            align: 'left',
            verticalAlign: 'bottom',
            itemStyle: {
               fontWeight: '300',
               fontSize: '14px',
            },
            padding: 0,
         },

         tooltip: {
            shared: true,
            useHTML: true,
            formatter: Chart.getTooltipFormatter(context),
         },

         plotOptions: {
            series: {
               // enableMouseTracking: false,
               animation: false,
               cursor: 'pointer',
               point: {
                  events: {
                     click() {
                        const analysisId = context.indexToAnalysisId[this.series.userOptions.id][this.dataGroup ? this.dataGroup.start : this.index]
                        context.analysisSelected(analysisId)
                     },
                  },
               },
               marker: {
                  lineWidth: 1,
                  radius: 6,
               },
               lineWidth: 2,
               boostThreshold: (this.props.defaultType !== 'boolean') ? 500 : 0, // https://api.highcharts.com/highcharts/plotOptions.series.boostThreshold
               // turboThreshold: 0,
            },

         },

         series: [],

         responsive: {
            rules: [{
               condition: {
                  maxWidth: 500,
               },
               chartOptions: {
                  legend: {
                     layout: 'horizontal',
                     align: 'center',
                     verticalAlign: 'bottom',
                  },
               },
            }],
         },
      }

      // Boolean chart specific settings
      if (defaultType === Chart.Type.BOOLEAN) {
         chartOptions.chart.type = undefined
         chartOptions.series = [{ id: 'boolChartTooltipSeries', data: [], type: 'line' }] // Extra series for tooltips when zoomed closely
         chartOptions.legend.enabled = false
         chartOptions.plotOptions.line = { lineColor: 'transparent' }
         chartOptions.plotOptions.area = { lineColor: 'transparent' }
         chartOptions.plotOptions.series.lineWidth = this.boolChartOptions.rowHeight + 32 // Line is the clickable part of points so its size is increased
         chartOptions.plotOptions.series.states = {
            hover: { enabled: false },
            inactive: { opacity: 1 }, // Don't change inactive series opacity when hovering other series
         }
         chartOptions.chart.events.redraw = this.onBoolChartRedraw
         chartOptions.tooltip = {
            shared: true,
            useHTML: true,
            formatter: Chart.getTooltipFormatterForBoolChart(context),
         }
         chartOptions.yAxis = {
            labels: {
               enabled: false,
            },
         }
      }

      return chartOptions
   }

   /**
    * Set chart options for new chart
    */
   setNewChartOptions() {
      if (this.props.timeframeSelection) {
         this.highchart.xAxis[0].setExtremes(+this.props.timeframeSelection.from, +this.props.timeframeSelection.to, false)
         this.updateChartOptions()
      }
   }

   /**
    * @param {Object} [prevProps]
    * @param {Object} [prevState]
    * @returns {boolean} Should highchart be redrawn
    */
   updateChartOptions(prevProps = {}, prevState = {}) {
      const { scale, plotLines, liveUpdate, height, timeZone, defaultType, showAveragePlotLines, selectedAnalysisIds, filteredAnalyses } = this.props
      const { elementStatistics } = this.state
      let redraw = false
      const data = {}

      // TIME ZONE
      if (timeZone !== prevProps.timeZone) {
         Object.assign(data, {
            time: {
               timezoneOffset: -getTimezoneOffset(timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone) / 60000,
            },
         })
         redraw = true
      }

      // SCALE - (not for boolean chart)
      if (defaultType !== 'boolean' && scale !== prevProps.scale && ((!scale || !prevProps.scale) || scale.min !== prevProps.scale.min || scale.max !== prevProps.scale.max)) {
         if (scale) {
            this.highchart.yAxis[0].setExtremes(scale.min, scale.max, false)
         } else {
            this.highchart.yAxis[0].setExtremes(null, null, false)
         }
         redraw = true
      }

      // PlotLines - https://www.highcharts.com/docs/chart-concepts/plot-bands-and-plot-lines
      if (defaultType !== 'boolean' && (plotLines !== prevProps.plotLines || showAveragePlotLines !== prevProps.showAveragePlotLines || (showAveragePlotLines && elementStatistics !== prevState.elementStatistics))) {
         const yAxis = this.highchart.yAxis[0]
         // Remove all previous plotlines
         yAxis.plotLinesAndBands.forEach((line) => {
            yAxis.removePlotLine(line.id)
         })
         // Create new
         plotLines?.forEach((line) => {
            const copy = { ...line }
            yAxis.addPlotLine(copy)
         })
         // Create new average plot lines
         if (showAveragePlotLines && !isEmpty(elementStatistics)) {
            Object.keys(elementStatistics).forEach((elementId, index) => {
               const { mean } = elementStatistics[elementId]
               const color = generateColor(index)
               const averagePlotLine = {
                  color,
                  width: 1,
                  dashStyle: 'LongDash',
                  zIndex: 2,
                  value: mean,
               }
               yAxis.addPlotLine(averagePlotLine)
            })
         }

      }
      // xAxis plotlines to indicate selected items
      const xAxis = this.highchart.xAxis[0]
      xAxis.plotLinesAndBands.forEach((line) => { // Remove previous (TODO: Optimize?)
         xAxis.removePlotLine(line.id)
      })
      if (selectedAnalysisIds) {
         selectedAnalysisIds.forEach((id, index) => {
            const analysis = filteredAnalyses.find((analysis) => analysis.id === id)
            if (analysis) {
               const selectionPlotline = {
                  color: index === selectedAnalysisIds.length - 1 ? '#0075BE' : '#9fa09f', // Blue highlight to latest selection
                  width: 1,
                  dashStyle: 'LongDash',
                  zIndex: 2,
                  value: analysis.timestamp,
               }
               xAxis.addPlotLine(selectionPlotline)
            }
         })
      }


      // ANIMATION
      if (!prevProps.liveUpdate !== !liveUpdate) {
         if (liveUpdate) {
            Object.assign(data, {
               // Uncomment to remove the whole animation. Now only plot animation is removed
               // chart: {
               //    animation: false
               // },
               plotOptions: {
                  line: {
                     animation: false,
                  },
               },
            })
         }
         else {
            Object.assign(data, {
               // chart: {
               //    animation: true
               // },
               plotOptions: {
                  line: {
                     animation: true,
                  },
               },
            })
         }
         redraw = true
      }

      if (prevState.useBoostModule !== this.state.useBoostModule) {
         if (this.state.useBoostModule) {
            Object.assign(data, {
               // boost: { enabled: true },
               plotOptions: {
                  series: {
                     boostThreshold: 500,
                  },
               },
            })
         } else {
            Object.assign(data, {
               // boost: { enabled: false }, // Using boost.enabled property to disable boosting doesn't clear the boost line when zoomed AVA-1944
               plotOptions: {
                  series: {
                     boostThreshold: 0, // "To disable boosting on the series, set the boostThreshold to 0"
                  },
               },
            })
         }
         redraw = true
      }

      // HEIGHT
      if (this.props.defaultType !== 'boolean' && height !== prevProps.height) { // TODO: custom height for boolean chart
         this.highchart.setSize(undefined, height) // This triggers redraw
         redraw = false
      }

      // Update
      try {
         this.highchart.update(data, false)
      } catch(err) {
         if (err.message !== 'Highcharts is not defined') throw err // After highchart upgrade from 8 to 9 Highchart was not initialized as fast as before and this error might happen at start.
      }

      return redraw
   }

   getXAxisExtremes = () => {
      const extremes = this.highchart.xAxis[0].getExtremes()
      return extremes
   }

   checkBoostModuleUsage = () => {
      const sum = this.getChartPointsVisibleCount()
      if (sum < 2 * this.chartRef.current.offsetWidth) {
         if (this.state.useBoostModule) {
            this.setState({ useBoostModule: false })
         }
      } else {
         if (!this.state.useBoostModule) {
            this.setState({ useBoostModule: true })
         }
      }
   }

   isChartFullScreenOrVisibleInScrollArea = () => {
      if (this.chartRef.current.parentElement.parentElement.classList.contains('is-full-screen')) return true // TODO: Better way to check if chart is in fullscreen
      const { top: cTop, bottom: cBottom } = this.chartRef.current.getBoundingClientRect() // add height: cHeight if needed
      const { top: sTop, bottom: sBottom } = this.contentContainer.getBoundingClientRect()
      return (cTop >= sTop && cTop < sBottom - 50) || (cBottom <= sBottom && cBottom > sTop)
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                 CHART DATA METHODS                 |
   \*__________________________________________________*/

   generateChartSeries(analyses = []) {
      if (this.props.defaultType === 'boolean') {
         this.generateChartSeriesForBoolChart()
         return this.addNewAnalysesForBoolChart(analyses)
      }

      const { indexToAnalysisId, seriesData, arearangeSeriesData } = this.getSeriesDataFromAnalyses(analyses)

      const { elements, positions, unitFilters, roundPrecision: chartRoundPrecision } = this.props

      const elementKeys = Object.keys(elements)
      const isSingleElement = (elementKeys.length === 1)

      const markSeriesGroups = {}

      for (const element in elements) {
         const elementIndex = elementKeys.indexOf(element)
         const resultModelElement = elements[element]
         const { name, mean, standardDeviation } = resultModelElement

         // Prioritize chart roundPrecision over element roundPrecision
         const roundPrecision = (chartRoundPrecision !== undefined ? chartRoundPrecision : resultModelElement.round)

         // POSITIONS SEPARATED TO DIFFERENT LINES
         for (const i in positions) {
            const position = positions[i]
            if (unitFilters[position.unit]?.exclude) continue
            if (!this.props.elementsPositionsWithData[element].includes(position.id)) continue

            const dashStyle = [
               'Solid', 'Dot', 'Dash', 'LongDash', 'DashDot', 'LongDashDot', 'LongDashDotDot',
               'ShortDash', 'ShortDot', 'ShortDashDot', 'ShortDashDotDot',
            ][0]// [i]
            const symbol = ['circle', 'square', 'triangle', 'diamond', 'triangle-down'][i]
            const color = generateColor(elementKeys.length * Number(i) + elementIndex) // TODO: Check if color from element can be used
            let customType

            if (!markSeriesGroups[position.id]) markSeriesGroups[position.id] = []
            markSeriesGroups[position.id].push({
               id: `${element}-${position.id}`,
               element,
               name: !isSingleElement ? getLanguageString(name) : position.name,
               data: seriesData[`${element}-${position.id}`] || [],
               color,
               position: position.id,
               roundPrecision,
               dashStyle,
               // lineWidth: 3,
               zIndex: 1,
               dataGrouping: {
                  enabled: false, // TODO: Should we use dataGrouping when not live update?
               },
               marker: {
                  // enabled: true,
                  // radius: 5,
                  symbol,
               },
            })

            /* Standard deviation */
            if (mean && standardDeviation) {
               customType = 'standard-deviation'
               markSeriesGroups[position.id].push({
                  id: `${element}-${position.id}-std`,
                  element,
                  data: arearangeSeriesData[`${element}-${position.id}`] || [],
                  type: 'arearange',
                  customType,
                  lineWidth: 0.5,
                  linkedTo: ':previous',
                  roundPrecision,
                  color,
                  fillOpacity: 0.3,
                  zIndex: 0,
                  states: {
                     hover: {
                        enabled: false,
                     },
                  },
               })
            }

         }

      }

      let series = []
      for (const i in markSeriesGroups) {
         series = series.concat(markSeriesGroups[i])
      }

      this.series = cloneDeep(series)

      series.forEach((s) => {
         if (!this.indexToAnalysisId[s.id]) this.indexToAnalysisId[s.id] = (indexToAnalysisId[s.id] || [])
         this.highchart.addSeries(s, false)
      })

      // Statistics
      this.calculateStatistics()

      // Legends
      this.updateLegends(this.series)

   }

   /**
    *
    * @param {any[]} chartSeries
    */
   updateLegends = (chartSeries) => {
      const { showDetailedStatistics } = this.props

      const legends = {}
      for (const series of chartSeries) {
         const { position, element, customType, color } = series
         const statistics = this.state.positionStatistics?.[element]?.[position]

         if (!legends[position]) legends[position] = []
         legends[position].push({
            element,
            customType,
            color,
            details: (statistics && showDetailedStatistics) && `Values: ${statistics.count}, Min: ${statistics.min}, Max: ${statistics.max}, Average: ${statistics.mean}, STD: ${statistics.standardDeviation}`,
         })
      }

      this.setState({ legends })
   }

   /**
    * @param {import('@ava/react-common/models/index').Analysis[]} analyses
    * @returns {{ indexToAnalysisId: Object, seriesData: Object, arearangeSeriesData: Object }}
    */
   getSeriesDataFromAnalyses(analyses) {

      const indexToAnalysisId = {}
      const seriesData = {}
      const arearangeSeriesData = {}

      // Other charts (line)
      const { elements, positions, roundPrecision: chartRoundPrecision } = this.props

      for (const element in elements) {
         const resultModelElement = elements[element]
         const { mean, standardDeviation } = resultModelElement

         const markSeriesData = {}

         for (const analysis of (analyses || [])) {
            const { id: analysisId, timestamp, items } = analysis
            for (const item of items) {
               const { result, position } = item
               if (!result?.elements || !position) continue
               const id = `${element}-${position}`
               if (!markSeriesData[id]) markSeriesData[id] = []

               /* NEW DATA FOR SERIES */
               let newData

               // Mean & standard deviation (arearange series)
               if (mean && standardDeviation) {
                  if (result.elements[mean.id] == null) continue
                  const meanValue = getConvertedAndRoundedValue(result.elements[mean.id], mean, chartRoundPrecision)
                  const stdValue = getConvertedAndRoundedValue(result.elements[standardDeviation.id], mean, chartRoundPrecision)
                  newData = {
                     analysisId,
                     x: timestamp.getTime(),
                     mean: meanValue,
                     std: stdValue,
                  }
               }
               // Normal (line series)
               else {
                  if (result.elements[element] == null) continue
                  const value = result.elements[element]
                  const convertedValue = getConvertedAndRoundedValue(resultModelElement, value, chartRoundPrecision)
                  newData = {
                     analysisId,
                     x: timestamp.getTime(),
                     y: convertedValue,
                  }
               }

               markSeriesData[id].push(newData)
            }
         }


         // POSITIONS SEPARATED TO DIFFERENT LINES
         for (const i in positions) {
            const position = positions[i]
            const id = `${element}-${position.id}`
            if (!markSeriesData[id]) continue

            // Get highchart series
            seriesData[id] = []
            indexToAnalysisId[id] = []
            if (mean && standardDeviation) arearangeSeriesData[id] = []

            // Update chart
            markSeriesData[id].forEach((item) => {
               indexToAnalysisId[id].push(item.analysisId)

               // Mean & standard deviation (arearange series)
               if (mean && standardDeviation) {
                  seriesData[id].push([item.x, item.y])
                  arearangeSeriesData[id].push([item.x, item.mean - item.std, item.mean + item.std])
               }
               // Normal (line series)
               else {
                  seriesData[id].push([item.x, item.y])
               }
            })
         }
      }

      return { indexToAnalysisId, seriesData, arearangeSeriesData }
   }

   /**
    * @param {import('@ava/react-common/models/index').Analysis[]} analyses
    * @returns {void}
    */
   addNewAnalyses(analyses) {

      // Boolean chart
      if (this.props.defaultType === 'boolean') return this.addNewAnalysesForBoolChart(analyses)

      // Every time values are updated to chart, update also statistics
      this.calculateStatistics()

      // Get series data, update indexToAnalysisId and data to chart
      const { indexToAnalysisId, seriesData, arearangeSeriesData } = this.getSeriesDataFromAnalyses(analyses)

      for (const id in indexToAnalysisId) {
         if (!this.indexToAnalysisId[id]) this.indexToAnalysisId[id] = []
         this.indexToAnalysisId[id].push(...indexToAnalysisId[id])
      }

      for (const id in seriesData) {
         const series = this.highchart.series.find((s) => s.userOptions.id === id)
         seriesData[id].forEach((point) => series.addPoint(point, false))
      }
      for (const id in arearangeSeriesData) {
         const arearangeSeries = this.highchart.series.find((s) => s.userOptions.id === `${id}-std`)
         arearangeSeriesData[id].forEach((point) => arearangeSeries.addPoint(point, false))
      }

   }

   /** Calculate statistics from analyses and set to state */
   calculateStatistics = () => {

      /** @type {import('@ava/react-common/models/analysis').default[]} */
      const analyses = this.props.filteredAnalyses

      // Boolean chart does not support statistics
      if (this.props.defaultType === 'boolean') return

      // Other charts (line)
      const { elements, positions, roundPrecision: chartRoundPrecision } = this.props


      // Initialize statistics object
      /**
       * @typedef {object} PositionStatistics
       * @property {number[]} values All values, required for standard deviation calculation
       * @property {number} totalVal Used for calculating mean value
       * @property {number} numOfItems
       * @property {number} [minValue]
       * @property {number} [maxValue]
       */
      /** @type {{ [element: string]: { [position: string]: PositionStatistics }}} */
      const statistics = {} // { positionId: [value] } Original unmodified values. Since averages needs to be calculated from original values and not rounded values original values needs to be stored
      for (const element in elements) {
         statistics[element] = {}
         for (const pos of positions) {
            statistics[element][pos.id] = {
               values: [],
               totalVal: 0,
               numOfItems: 0,
            }
         }
      }

      // Loop trough analyses and generate statistics
      for (const analysis of (analyses || [])) {

         const { items } = analysis
         for (const item of items) {
            const { result, position } = item
            if (!result?.elements || !position) continue

            for (const element in item.result.elements) {
               if (!statistics[element]) continue

               const resultElementValue = item.result.elements[element]

               if (statistics[element][item.position].minValue == null || statistics[element][item.position].minValue > resultElementValue) {
                  statistics[element][item.position].minValue = resultElementValue
               }
               if (statistics[element][item.position].maxValue == null || statistics[element][item.position].maxValue < resultElementValue) {
                  statistics[element][item.position].maxValue = resultElementValue
               }

               if (elements[element].mean && elements[element].standardDeviation) continue // Statistics not supported for mean and std charts
               else { // Normal (line series)
                  if (result.elements[element] == null) continue
                  statistics[element][item.position].values.push(resultElementValue)
                  statistics[element][item.position].totalVal += resultElementValue
                  statistics[element][item.position].numOfItems += 1
               }
            }
         }
      }

      // Position statistics
      const newPositionStatistics = {}

      for (const element in elements) {
         newPositionStatistics[element] = {}

         const roundPrecision = (chartRoundPrecision !== undefined ? chartRoundPrecision : elements[element].round) // Prioritize chart roundPrecision over element roundPrecision

         for (const pos of positions) {
            const { numOfItems, totalVal, minValue, maxValue, values } = statistics[element][pos.id]

            newPositionStatistics[element][pos.id] = {
               mean: getConvertedAndRoundedValue(elements[element], totalVal / numOfItems, roundPrecision),
               min: getConvertedAndRoundedValue(elements[element], minValue, roundPrecision),
               max: getConvertedAndRoundedValue(elements[element], maxValue, roundPrecision),
               count: numOfItems,
               standardDeviation: values.length
                  ? getConvertedAndRoundedValue(
                     elements[element],
                     Math.sqrt(values.map((x) => (x - (totalVal / numOfItems)) ** 2).reduce((a, b) => a + b) / numOfItems),
                     roundPrecision
                  )
                  : null,
            }
         }
      }

      // Element statistics (combine data from all positions)
      const newElementStatistics = {}

      for (const element in elements) {
         const roundPrecision = (chartRoundPrecision !== undefined ? chartRoundPrecision : elements[element].round) // Prioritize chart roundPrecision over element roundPrecision

         const elementValues = []
         let elementTotalVal = 0
         let elementNumOfItems = 0
         let elementMin
         let elementMax

         for (const pos of positions) {
            const { numOfItems, values, totalVal, minValue, maxValue } = statistics[element][pos.id]
            if (elementMin == null || elementMin > minValue) elementMin = minValue
            if (elementMax == null || elementMax < maxValue) elementMax = maxValue
            elementTotalVal += totalVal
            elementNumOfItems += numOfItems
            elementValues.push(...values)
         }

         newElementStatistics[element] = {
            mean: getConvertedAndRoundedValue(elements[element], elementTotalVal / elementNumOfItems, roundPrecision),
            min: getConvertedAndRoundedValue(elements[element], elementMin, roundPrecision),
            max: getConvertedAndRoundedValue(elements[element], elementMax, roundPrecision),
            count: elementNumOfItems,
            standardDeviation: elementValues.length
               ? getConvertedAndRoundedValue(
                  elements[element],
                  Math.sqrt(elementValues.map((x) => (x - (elementTotalVal / elementNumOfItems)) ** 2).reduce((a, b) => a + b) / elementNumOfItems),
                  roundPrecision
               )
               : null,
         }
      }

      this.setState({ positionStatistics: newPositionStatistics, elementStatistics: newElementStatistics })
   }

   /**
    *
    * @param {import('@ava/react-common/models').Analysis[]} prevAnalyses
    * @param {import('@ava/react-common/models').Analysis[]} analyses
    */
   hasAnalysesChanged = (prevAnalyses, analyses) => {
      if (analyses !== prevAnalyses) {
         const analysesLength = analyses.length
         if (analysesLength < prevAnalyses.length) {
            return { state: 'new' }
         }
         let analysesRemovedFromStart = 0
         let existingDataStarted = false
         let newItemsCount = 0
         let j = 0
         for (let i = 0; j < analysesLength; ++i) {
            if (!existingDataStarted && i === analysesLength) return { state: 'new' }
            if (!prevAnalyses[i] || prevAnalyses[i].id !== analyses[j].id) {
               if (!existingDataStarted) {
                  ++analysesRemovedFromStart
               } else {
                  // Check if there is new position
                  let hasNewPos = false
                  for (const pos of analyses[j].positions) {
                     if (!this.prevAnalysesPositions.includes(pos)) {
                        this.prevAnalysesPositions.push(pos)
                        hasNewPos = true
                     }
                  }
                  if (hasNewPos) return { state: 'new' }

                  // Check if there are new elements
                  let hasNewElement = false
                  analyses[j].items.forEach(({ result }) => {
                     for (const element in Object.keys(result?.elements || {})) {
                        if (!this.prevAnalysesElements.includes(element)) {
                           this.prevAnalysesElements.push(element)
                           hasNewElement = true
                        }
                     }
                  })
                  if (hasNewElement) return { state: 'new' }

                  ++newItemsCount
               }
            } else {
               existingDataStarted = true
            }
            if (existingDataStarted) ++j
         }
         if (newItemsCount === 0) {
            return analysesLength === prevAnalyses.length
               ? { state: 'notChanged' }
               : { state: 'new' }
         }
         if (newItemsCount > 100) return { state: 'new' }
         if (!existingDataStarted) return { state: 'new' }
         return { state: 'added', changedAnalyses: [...analyses].slice(-newItemsCount), analysesRemovedFromStart }

      }
      return { state: 'notChanged' }
   }

   /**
    * @param {Chart} context
    * @returns {() => string}
    */
   static getTooltipFormatter(context) {
      return function getTooltip() {
         let prevPosition
         // Group by positions if there are multiple elements per position
         const groupByPositions = !!(this.points.map((p) => p.series.userOptions.position).find((p, i, self) => (self.indexOf(p) !== i)))

         return this.points.reduce((s, point, i) => {
            let { dataGroup } = point.point
            const positionId = point.series.userOptions.position
            let min
            let max
            // let firstTimestamp = undefined Uncomment if needed
            let timestampRangeEnd // dataGroup
            if (dataGroup && dataGroup.length === 1) dataGroup = undefined
            if (dataGroup) {
               const groupingTimeRange = this.points[0].series.currentDataGrouping.totalRange
               const { start, length } = dataGroup
               const series = context.series.find((s) => s.name === point.point.series.name)
               // firstTimestamp = series.data[start][0]
               timestampRangeEnd = new Date(this.x + groupingTimeRange - 1)
               // timestampRangeEnd = new Date(series.data[start+length-1][0]) // last datagroup
               for (let i = start; i < start + length; ++i) {
                  const y = series?.data[i][1]
                  if (!min) min = y
                  if (!max) max = y
                  if (min > y) min = y
                  else if (max < y) max = y
               }
            }
            const { customType, roundPrecision, color } = point.series.options

            const xDate = new Date(this.x)
            const currentYear = new Date().getFullYear()
            const timestampString = dateToString(xDate, context.props.timeZone, true, !dataGroup, xDate.getFullYear() === currentYear)
            const lastTimestampString = timestampRangeEnd ? dateToString(timestampRangeEnd, context.props.timeZone, true, !dataGroup, timestampRangeEnd.getFullYear() === currentYear) : ''

            const dateText = (i > 0 ? '' : !dataGroup) // (Add only to first)
               ? `<small style="font-size: 10px">${timestampString}</small><br/>`
               : `<small style="font-size: 10px">${timestampString} - ${lastTimestampString}</small><br/>`

            // Position grouping
            let positionGroupText = ''
            if (groupByPositions && prevPosition !== positionId) {
               positionGroupText = `<span style="font-size: 12px; margin-top: 4px"><b>${context.props.positions.find((p) => p.id === positionId).name}</b></span>`
               prevPosition = positionId
            }

            let iconSvg = ''
            let valuesText = ''

            // Standard deviation (arearange series)
            if (customType === 'standard-deviation') {
               const mean = point.series.linkedParent.yData[point.point.index]
               const meanRounded = round(mean, roundPrecision)
               const stdRounded = round(point.point.high - mean, roundPrecision)

               valuesText = `&nbsp;&nbsp;&nbsp;&nbsp;[min: <b>${meanRounded - stdRounded}</b>,
                  max: <b>${meanRounded + stdRounded}</b>,
                  std: <b>${stdRounded}</b>]<br/>`
            }
            // Other
            else {
               const value = (roundPrecision !== undefined ? round(point.y, roundPrecision) : point.y)

               iconSvg = `<svg height="8" width="8" style="margin-top: -2.5px">
                     <circle cx="4" cy="4" r="3" fill="${color}" />
                  </svg>`

               valuesText = !dataGroup // (Add only to first)
                  ? `${point.series.name}: <b>${value}</b><br/>`
                  : `${point.series.name}:<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;- average: <b>${value}</b><br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;- min: <b>${min}</b><br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;- max: <b>${max}</b><br/>`
            }

            return `
               ${s}
               ${dateText}
               ${positionGroupText}
               ${iconSvg}
               ${valuesText}
            `
         }, '')
      }
   }

   getChartPointsCount = () => {
      let count = 0
      for (const series of this.highchart.series) {
         if (series.userOptions.id !== 'highcharts-navigator-series') {
            count += series.data.length
         }
      }
      return count
   }

   getChartPointsVisibleCount = () => {
      const { min, max } = this.getXAxisExtremes()
      let series
      for (const s of this.highchart.series) {
         if (s.userOptions.id === 'highcharts-navigator-series') continue
         series = s
         break
      }
      if (!series) return 0
      let count = 0
      // @ts-ignore (xData is used instead of points because xData contains always all items)
      for (const x of series.xData) {
         if (x >= min && x <= max) ++count
      }
      return count
   }

   getChartFirstAndLastXValues = () => { // dataMin and dataMax is undefined from getXAxisExtremes() before chart is drawn so this is needed in some cases
      if (this.highchart.series.length === 0) {
         return { xFirst: 0, xLast: 0 }
      }
      // @ts-ignore
      const xData = this.highchart.series[0].xData
      return {
         xFirst: xData[0],
         xLast: xData[xData.length - 1],
      }
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |             BOOL CHART SPECIFIC METHODS            |
   \*__________________________________________________*/

   onBoolChartRedraw = () => {
      const { min, max } = this.getXAxisExtremes()
      const { seriesValues, seriesIndexes } = this.boolChartData

      if ((Number.isNaN(min) || Number.isNaN(max)) && Object.values(seriesValues).flat().length > 0) { // Bool chart data doesn't come immediately so we have to set the default extremes here
         const rsButtons = this.highchart.rangeSelector.buttons
         const btnAllIndex = this.highchart.rangeSelector.buttonOptions.findIndex((o) => o.type === 'all')
         rsButtons[btnAllIndex].element.onclick()
      }

      const width = this.chartRef.current
         .getElementsByClassName(`highcharts-plot-background`)[0].width.baseVal.value

      const zoomedEnoughToShowAllElementsPoints = {}
      for (const id of Object.keys(seriesValues)) {
         // const seriesDataXLength = this.highchart.series[seriesIndexes[element].true].xData.length + this.highchart.series[seriesIndexes[element].false].xData.length
         const seriesPointsLength = this.highchart.series[seriesIndexes[id].true].points.length + this.highchart.series[seriesIndexes[id].false].points.length


         let length = 0
         for (const x of this.highchart.series[seriesIndexes[id].true].xData) {
            if (x <= max) {
               if (x >= min) ++length
            } else break
         }
         for (const x of this.highchart.series[seriesIndexes[id].false].xData) {
            if (x <= max) {
               if (x >= min) ++length
            } else break
         }
         if (seriesPointsLength - length < -10) {
            // console.log(element, "ZOOM REQUIRED", seriesPointsLength - length)
            if (this.state.messageOverChart !== 'Zoom required') {
               this.lastMessageOverChart = 'Zoom required'
               this.setState({ messageOverChart: 'Zoom required' })
            }
         } else if (this.state.messageOverChart === 'Zoom required') {
            this.setState({ messageOverChart: null })
         }

         let length2 = 0
         for (const val of seriesValues[id]) {
            if (val.time <= max) {
               if (val.time >= min) ++length2
            } else break
         }

         zoomedEnoughToShowAllElementsPoints[id] = (length2 / width < 0.5)
      }
      if (!this.boolChartData.redrawRedrawed) {
         if (![...Object.values(zoomedEnoughToShowAllElementsPoints)].includes(false)) {
            if (!this.boolChartData.drawingTooltips) {
               this.boolChartData.redrawRedrawed = true
               this.drawBoolChartTooltips()
            }
         } else if (this.boolChartData.drawingTooltips) {
            if (this.boolChartData.drawingTooltips) {
               this.boolChartData.redrawRedrawed = true
               this.removeBoolChartTooltips()
            }
         }
      } else {
         this.boolChartData.redrawRedrawed = false
      }
   }

   drawBoolChartTooltips = () => {
      this.indexToAnalysisId.boolChartTooltipSeries = []
      const addedTooltipPoints = []
      for (const id of Object.keys(this.boolChartData.seriesValues)) {
         for (const val of this.boolChartData.seriesValues[id]) {
            if (!addedTooltipPoints.includes(val.time)) {
               addedTooltipPoints.push(val.time)
               this.highchart.series[0].addPoint([val.time, 1], false)
               this.indexToAnalysisId.boolChartTooltipSeries.push(val.analysisId)
            }
         }
      }
      this.boolChartData.drawingTooltips = true
      this.redrawChart()
   }

   removeBoolChartTooltips = () => {
      this.indexToAnalysisId.boolChartTooltipSeries = []
      const len = this.highchart.series[0].xData.length
      for (let i = 0; i < len; ++i) {
         this.highchart.series[0].setData([], false)
      }
      this.boolChartData.drawingTooltips = false
      this.redrawChart()
   }

   generateChartSeriesForBoolChart() {

      // First remove earlier generated series elements and clean values
      for (const id in this.boolChartData.seriesCurrentValueElements) {
         this.boolChartData.seriesCurrentValueElements[id].remove()
         this.boolChartData.seriesTitleElements[id].remove()
      }
      this.boolChartData.seriesCurrentValueElements = {}
      this.boolChartData.seriesTitleElements = {}

      const { positions, elements } = this.props
      const { rowHeight, rowPaddingTop } = this.boolChartOptions
      const { seriesCurrentValueElements, seriesTitleElements, seriesValues, seriesIndexes } = this.boolChartData

      let allEarlierPositionsLength = 0
      const allPositionsLength = Object.keys(elements).reduce((count, elementId) => (count + this.props.elementsPositionsWithData[elementId].length), 0)

      for (const element in elements) {

         // POSITIONS SEPARATED TO DIFFERENT LINES
         const elementPositionsLength = this.props.elementsPositionsWithData[element].length
         let posIndex = 0
         for (const position of positions) {
            const { id: posId, name: posName } = position
            const id = `${element}-${posId}`

            if (!this.props.elementsPositionsWithData[element].includes(posId)) continue

            this.boolChartData.idToName[id] = (Object.keys(elements).length === 1)
               ? posName
               : `${getLanguageString(elements[element].name)} (${posName})`

            seriesValues[id] = []
            seriesIndexes[id] = {
               true: 1 + allEarlierPositionsLength * 2 + posIndex * 2,
               false: 1 + allEarlierPositionsLength * 2 + posIndex * 2 + 1,
            }

            this.highchart.addSeries({
               // enableMouseTracking: false,
               id: `${id}-true`,
               data: [],
               type: 'area',
               color: '#0075be',
            }, false)
            this.highchart.addSeries({
               // enableMouseTracking: false,
               id: `${id}-false`,
               data: [],
               type: 'area',
               color: 'lightgray',
            })

            // Transform translate doesn't effect bottom boolean row somehow unless translate y is larger than 0 ?!!
            // scaleY is used for adding padding between rows
            this.chartRef.current
               .getElementsByClassName(`highcharts-series-${seriesIndexes[id].true}`)[0]
               .style.transform = `translate(10px, -${((seriesIndexes[id].true - 1) / 2) * (rowHeight + rowPaddingTop) + 1}px) scaleY(${(rowHeight / (rowPaddingTop + rowHeight))})`
            this.chartRef.current
               .getElementsByClassName(`highcharts-series-${seriesIndexes[id].false}`)[0]
               .style.transform = `translate(10px, -${((seriesIndexes[id].true - 1) / 2) * (rowHeight + rowPaddingTop) + 1}px) scaleY(${rowHeight / (rowPaddingTop + rowHeight)})`
            this.chartRef.current
               .getElementsByClassName(`highcharts-series-group`)[0]
               .style.transform = `translateY(${(elementPositionsLength + allEarlierPositionsLength) * rowPaddingTop + rowHeight - 13}px)`

            seriesCurrentValueElements[id] = document.createElement('div')
            seriesCurrentValueElements[id].className = `current-state-${id}`
            seriesCurrentValueElements[id].style.position = 'absolute'
            seriesCurrentValueElements[id].style.width = '24px'
            seriesCurrentValueElements[id].style.height = `${rowHeight - rowPaddingTop}px`
            seriesCurrentValueElements[id].style.right = '14px'
            seriesCurrentValueElements[id].style.top = `${87 + ((elementPositionsLength + allEarlierPositionsLength * 2) - 1 - (seriesIndexes[id].true - 1) / 2) * (rowHeight + rowPaddingTop)}px`
            this.chartRef.current.appendChild(seriesCurrentValueElements[id])

            seriesTitleElements[id] = document.createElement('div')
            seriesTitleElements[id].style = 'pointer-events: none'
            seriesTitleElements[id].innerHTML = `<p style="font-size: 14px"><strong>${this.boolChartData.idToName[id]}</strong></p>`
            seriesTitleElements[id].className = `series-title-${id}`
            seriesTitleElements[id].style.position = 'absolute'
            seriesTitleElements[id].style.left = '36px'
            // TODO: Check order with three boolean charts

            seriesTitleElements[id].style.top = `${90
               + (allPositionsLength - ((elementPositionsLength + allEarlierPositionsLength * 2) - (((posIndex - elementPositionsLength) * -1) + ((seriesIndexes[id].true - 1) / 2) - 1 - posIndex)))
               * (rowHeight + rowPaddingTop)
            }px`
            this.chartRef.current.appendChild(seriesTitleElements[id])

            ++posIndex
         }

         allEarlierPositionsLength += elementPositionsLength
      }
      this.chartRef.current.style.paddingRight = '24px'

      let boolRows = 0
      for (const element in elements) {
         boolRows += this.props.elementsPositionsWithData[element].length
      }
      this.highchart.yAxis[0].setExtremes(0, boolRows, false)

      this.highchart.setSize( // This triggers redraw
         undefined,
         70 + Object.keys(seriesIndexes).length * (rowHeight + rowPaddingTop) // 70 = chart height without any boolean rows
      )
   }

   /**
    * @param {import('@ava/react-common/models/index').Analysis[]} analyses
    */
   addNewAnalysesForBoolChart = (analyses) => {
      const { elements, positions } = this.props
      const { seriesIndexes, seriesValues, drawingTooltips, seriesCurrentValueElements } = this.boolChartData
      const { maxTimeBetweenPointsBeforeCut, additionalTimeForLonelyVal } = this.boolChartOptions

      const values = {}

      for (const element in elements) {

         // POSITIONS SEPARATED TO DIFFERENT LINES
         for (const position of positions) {
            const id = `${element}-${position.id}`

            if (!this.props.elementsPositionsWithData[element].includes(position.id)) continue

            if (!this.indexToAnalysisId[`${id}-true`]) {
               this.indexToAnalysisId[`${id}-true`] = []
               this.indexToAnalysisId[`${id}-false`] = []
            }

            analyses.forEach((analysis) => {
               const { id: analysisId, timestamp, items } = analysis
               for (const item of items) {
                  const { result, position: posId } = item
                  if (!result?.elements || posId !== position.id) continue
                  if (result.elements[element] == null) continue
                  if (!values[id]) values[id] = []

                  values[id].push({
                     analysisId,
                     time: timestamp.getTime(),
                     // value: Math.round(Math.random()) ? 1 : null,
                     value: result.elements[element] ? 1 : null,
                  })
               }
            })
         }
      }

      const addedTooltipPoints = []
      for (const id in values) {

         const positionValues = (values[id] instanceof Array) ? values[id] : [values[id]]

         for (const i in positionValues) {
            const { analysisId, time, value } = positionValues[i]
            let { time: prevTime, value: prevValue } = (seriesValues[id][seriesValues[id].length - 1] || {})

            let maxTimeBetweenPointsBeforeCutExceeded = false
            if (time - prevTime > maxTimeBetweenPointsBeforeCut) {
               maxTimeBetweenPointsBeforeCutExceeded = true
               prevTime = undefined
               prevValue = undefined
            }

            if (drawingTooltips && !addedTooltipPoints.includes(time)) {
               addedTooltipPoints.push(time)
               this.highchart.series[0].addPoint([time, 1], false)
               this.indexToAnalysisId.boolChartTooltipSeries.push(analysisId)
            }

            const point = [time, value]
            const oppositePoint = [time, value ? null : 1]

            if (maxTimeBetweenPointsBeforeCutExceeded) {
               this.boolChartData.seriesExtraPointAdded[id] = null
               const { time: prevTime, value: prevVal } = (seriesValues[id][seriesValues[id].length - 1] || {})
               if (prevVal !== undefined) {
                  this.highchart.series[seriesIndexes[id].true].addPoint([prevTime + (additionalTimeForLonelyVal), prevVal], false)
                  this.highchart.series[seriesIndexes[id].false].addPoint([prevTime + (additionalTimeForLonelyVal), prevVal ? null : 1], false)
                  this.indexToAnalysisId[`${id}-true`].push(analysisId)
                  this.indexToAnalysisId[`${id}-false`].push(analysisId)
               }
               this.highchart.series[seriesIndexes[id].true].addPoint([time, null], false)
               this.highchart.series[seriesIndexes[id].false].addPoint([time, null], false)
               this.indexToAnalysisId[`${id}-true`].push(analysisId)
               this.indexToAnalysisId[`${id}-false`].push(analysisId)
            } else if (this.boolChartData.seriesExtraPointAdded[id]) {
               this.highchart.series[seriesIndexes[id][this.boolChartData.seriesExtraPointAdded[id]]].removePoint(this.highchart.series[seriesIndexes[id][this.boolChartData.seriesExtraPointAdded[id]]].xData.length - 1, false)
               this.indexToAnalysisId[`${id}-${this.boolChartData.seriesExtraPointAdded[id]}`].pop()
               this.boolChartData.seriesExtraPointAdded[id] = null
            }

            seriesValues[id].push(positionValues[i])

            // ON
            if (value === null && prevValue === 1 && !maxTimeBetweenPointsBeforeCutExceeded) {
               this.highchart.series[seriesIndexes[id].true].addPoint(oppositePoint, false)
               this.indexToAnalysisId[`${id}-true`].push(analysisId)
            }
            if (prevValue !== value || (maxTimeBetweenPointsBeforeCutExceeded && prevValue === value)) {
               this.highchart.series[seriesIndexes[id].true].addPoint(point, false)
               this.indexToAnalysisId[`${id}-true`].push(analysisId)
            }

            // OFF
            if (value === 1 && prevValue === null && !maxTimeBetweenPointsBeforeCutExceeded) {
               this.highchart.series[seriesIndexes[id].false].addPoint([time, value], false)
               this.indexToAnalysisId[`${id}-false`].push(analysisId)
            }
            if (prevValue === undefined || prevValue !== value || (maxTimeBetweenPointsBeforeCutExceeded && prevValue === value)) {
               this.highchart.series[seriesIndexes[id].false].addPoint(oppositePoint, false)
               this.indexToAnalysisId[`${id}-false`].push(analysisId)
            }

            if (!maxTimeBetweenPointsBeforeCutExceeded && Number(i) === positionValues.length - 1) {
               if (Number(i) === positionValues.length - 1 && value === prevValue) {
                  if (value) {
                     this.boolChartData.seriesExtraPointAdded[id] = 'true'
                     this.highchart.series[seriesIndexes[id].true].addPoint(point, false)
                     this.indexToAnalysisId[`${id}-true`].push(analysisId)
                  }
                  else {
                     this.boolChartData.seriesExtraPointAdded[id] = 'false'
                     this.highchart.series[seriesIndexes[id].false].addPoint(oppositePoint, false)
                     this.indexToAnalysisId[`${id}-false`].push(analysisId)
                  }
               }
            }
         }

         if (this.props.liveUpdate) {
            seriesCurrentValueElements[id].style.background = positionValues[positionValues.length - 1].value ? '#0075be' : 'lightgray'
         } else {
            // let { value: valBeforeLastVal } = (seriesValues[position][seriesValues[position].length - 2] || {})
            const { analysisId, time, value } = (seriesValues[id][seriesValues[id].length - 1] || {})
            // if (valBeforeLastVal !== value) {
            if (value) {
               this.highchart.series[seriesIndexes[id].true].addPoint([time + 1000, 1], false)
               this.indexToAnalysisId[`${id}-true`].push(analysisId)
            } else {
               this.highchart.series[seriesIndexes[id].false].addPoint([time + 1000, 1], false)
               this.indexToAnalysisId[`${id}-false`].push(analysisId)
            }
            // }
         }

      }
   }

   /**
    * @param {Chart} context
    * @returns {() => string}
    */
   static getTooltipFormatterForBoolChart(context) {
      return function getTooltip() {
         const { additionalTimeForLonelyVal } = context.boolChartOptions
         const { seriesValues } = context.boolChartData

         const results = []
         for (const id of Object.keys(seriesValues)) {

            let result = seriesValues[id].find((val) => (val.time === this.x))
            if (!result?.elements) { // If result elements are not found, check if last extra point is within x and "additionalTimeForLonelyVal" area
               const point = seriesValues[id][seriesValues[id].length - 1]
               if (Math.abs(point.time - this.x) <= additionalTimeForLonelyVal) result = point
            }

            if (result) {
               results.push({
                  positionName: context.boolChartData.idToName[id],
                  time: result.time,
                  value: result.value,
               })
            }
         }

         const xDate = new Date(this.x)
         const currentYear = new Date().getFullYear()
         const timestampString = dateToString(xDate, context.props.timeZone, true, true, xDate.getFullYear() === currentYear)

         let tooltip = `<small style="font-size: 10px">${timestampString}</small><br/>`
         for (let i = results.length - 1; i >= 0; --i) {
            const result = results[i]
            tooltip += `${result.positionName}: <b>${!!result.value}</b><br/>`// `<b>${result.position}:</b> ${result.value ? true : false}<br/>`
            // tooltip += `${getLanguageString(context.props.elements[result.position].name)}: <b>${result.value ? true : false}</b><br/>`//`<b>${result.position}:</b> ${result.value ? true : false}<br/>`
         }
         return tooltip
      }
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                       RENDER                       |
   \*__________________________________________________*/

   render() {

      const { elementStatistics } = this.state
      const { elements, showAveragePlotLines } = this.props

      return (
         <div className="chart" ref={this.chartRef}>
            <HighchartsReact
               highcharts={Highcharts}
               constructorType="stockChart"
               allowChartUpdate={false}
               options={this.chartOptions}
            />
            <div
               className="message-over-chart"
               style={{ opacity: this.state.messageOverChart ? 0.4 : 0 }}
            >
               <div>
                  <p>{this.lastMessageOverChart}</p> {/* State isn't used because text would disappear before fade out */}
               </div>
            </div>
            <ChartLegends
               chart={this.highchart}
               legends={this.state.legends}
            />
            <div style={{ marginTop: '8px' }}>
               {!isEmpty(elementStatistics) && Object.keys(elementStatistics).map((elementId, index) => {
                  const element = elements[elementId]
                  const { min, max, mean, standardDeviation, count } = elementStatistics[elementId]
                  const color = generateColor(index)
                  return (
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={`element-statistics-${elementId}`}>
                        <p style={{ marginBottom: 0, fontSize: '12px' }}>
                           <b style={{ marginRight: '4px' }}>{`${getLanguageString(element.name)}:`}</b>
                           {`Values: ${count}, Min: ${min}, Max: ${max}, `}
                           {`Average: ${mean}, `}
                           {`STD: ${standardDeviation}`}
                        </p>
                        {showAveragePlotLines && (
                           <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div style={{ backgroundColor: color, height: '2px', width: '10px', marginLeft: '10px' }} />
                              <div style={{ backgroundColor: color, height: '2px', width: '10px', marginLeft: '4px' }} />
                           </div>
                        )}
                     </div>
                  ) }
               )}
            </div>
         </div>
      )

   }

}

const mapStateToProps = (state) => ({
   unitFilters: state.unitFilters,
   filteredAnalyses: selectToolsFilteredAnalyses(state),
   selectedAnalysisIds: state.selectedAnalyses,
   positions: selectToolsFilteredPositions(state),
   liveUpdate: state.liveUpdate,
   timeZone: state.timeZone,
   timeframeSelection: state.timeframeSelection,
   liveUpdatePaused: state.liveUpdatePaused,
})

export default connect(mapStateToProps, { selectAnalysis, setLiveUpdatePaused, deselectAnalysis, setTimeframeSelection })(Chart)
