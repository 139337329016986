import Position from '../../models/position'
import { CREATE_POSITION, DELETE_POSITION, EDIT_POSITION, SET_POSITIONS } from '../actions/types'

const initialState = []


const positionsReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_POSITIONS:
         return action.payload.reduce((obj, pos) => {
            obj[pos.id] = pos
            return obj
         }, {})

      case DELETE_POSITION:
         const newState = { ...state }
         delete newState[action.payload.id]
         return newState

      case EDIT_POSITION:
         const newState1 = {
            ...state,
            [action.payload.id]: {
               ...state[action.payload.id],
               name: action.payload.name,
            },
         }
         return newState1

      case CREATE_POSITION:
         const newPosition = new Position(action.payload.id, action.payload.name, action.payload.unit)
         const newState2 = {
            ...state,
            [action.payload.id]: newPosition,
         }
         return newState2

      default:
         return state

   }
}

export default positionsReducer
