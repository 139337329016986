import { CLEAR_DATA, CLEAR_SELECTED_ANALYSES, REMOVE_ANALYSIS, SELECT_ANALYSIS, UNSELECT_ANALYSIS } from '../actions/types'

const initialState = []

const selectedAnalysesReducer = (state = initialState, action) => {
   switch (action.type) {

      case SELECT_ANALYSIS:
         const { analysisId, deselectOthers } = action.payload
         // If item already exis -> return
         if (state.indexOf(analysisId) !== -1) return state
         return deselectOthers ? [analysisId] : [...state, analysisId]

      case UNSELECT_ANALYSIS:
         return state.filter((id) => id !== action.payload)

      case REMOVE_ANALYSIS:
         return state.filter((id) => id !== action.payload)

      case CLEAR_SELECTED_ANALYSES:
      case CLEAR_DATA:
         return []

      default:
         return state

   }
}

export default selectedAnalysesReducer
