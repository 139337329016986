import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Dialog from '../Dialog/dialog'
import MobileNavbar from '../MobileNavbar/mobile-navbar'
import SecondaryNavbar from '../SecondaryNavbar/secondary-navbar'
import SideNavigationMenu from '../SideNavigationMenu/side-navigation-menu'
import './settings-container.scss'

/**
 * @template T
 * @param {object} props
 * @param {string} props.title
 * @param {Object<T, { title: string, component: React.ReactNode }>} props.sections
 * @param {T} props.initial
 * @returns {React.FunctionComponentElement}
 */
export default function SettingsContainer({ sections, title, initial }) {
   const [selectedMenu, setSelectedMenu] = useState(initial)
   const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
   const [errorDialogMessage, setErrorDialogMessage] = useState(undefined)
   const isMobile = useSelector((state) => state.device.isMobile)
   const history = useHistory()
   const windowDimensions = useSelector((state) => state.device.dimensions)

   useEffect(() => {
      const width = isMobile ? windowDimensions.width : windowDimensions.width - 236 // full width - side menu width
      const height = windowDimensions.height - 64
      setDimensions({ width, height })
   }, [isMobile, windowDimensions])


   const menuOptions = sections && Object.keys(sections).map((key) => {
      const selected = (key === selectedMenu)
      const obj = {
         value: key,
         title: sections[key].title,
      }
      obj.selected = selected
      return obj
   })

   return (
      <div className="page-tool-configuration" style={{ maxHeight: '100vh', overflowY: 'hidden' }}>
         { !isMobile
            && <SecondaryNavbar
               title={title}
            />
         }
         <div className="settings-container__content-container">
            {isMobile ? (
               <MobileNavbar
                  backPressed={() => history.goBack()}
               />
            ) : (
               <SideNavigationMenu
                  options={menuOptions}
                  onSelect={(value) => setSelectedMenu(value)}
               />
            )}

            <InnerContainer isMobile={isMobile}>
               {sections && Object.keys(sections).map((key) => {
                  if (key !== selectedMenu) return null
                  const Component = sections[key].component
                  return (
                     <Component
                        key={key}
                        onError={setErrorDialogMessage}
                        dimensions={dimensions}
                     />
                  )
               })}
            </InnerContainer>
         </div>

         <Dialog
            visible={errorDialogMessage}
            title="Error"
            message={errorDialogMessage}
            buttons={[{ text: 'OK' }]}
            onClose={() => setErrorDialogMessage(undefined)}
         />
      </div>
   )
}


const InnerContainer = ({ children, isMobile }) => (
   <div className={classNames('settings-container__inner-container', { mobile: isMobile })}>
      {children}
   </div>
)
