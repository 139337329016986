import SocketSiteNamespace from '@ava/react-common/models/socket-site-namespace'
import Tag from '@ava/react-common/models/tag'
import Tool from '@ava/react-common/models/tool'
import DataService from '@ava/react-common/services/data-service'
import SocketIOService from '@ava/react-common/services/socket-io-service'
import UserService from '@ava/react-common/services/user-service'
import { clearData, deselectAnalysis, excludeTagless, LIVE_UPDATE_DEFAULT_FOLLOW_RANGE, selectAnalysis, selectTool, setAnalyses, setContinuousAnalysisPositionState, setLiveUpdateFollowRange, startLiveUpdate, stopLiveUpdate, TIMEFRAME_BY_TIMESTAMP } from '@ava/react-common/store/actions/data-actions'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { clearOngoingAnalysis } from '@ava/react-common/store/actions/ongoing-analysis-actions'
import { addResultComponentModel, setResultComponentModels, showLatestImages, showLatestResult } from '@ava/react-common/store/actions/result-component-actions'
import { setUserToolConfig } from '@ava/react-common/store/actions/user-tool-config-actions'
import { selectSelectedTool, selectToolsAnalyses, selectToolsFilteredAnalyses, selectUnitFilters } from '@ava/react-common/store/reducers/root-reducer'
import { errorToMessage } from '@ava/react-common/utils'
import { getBeginningOfDate, getEndOfDate } from '@ava/react-common/utils/Helper'
import classNames from 'classnames'
import Highcharts from 'highcharts'
import Highstock from 'highcharts/highstock'
import { isEqual } from 'lodash'
import memoize from 'memoize-one'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { addAnalyses, addAnalysis } from '../../actions/local-data-actions'
import AndritzLogo from '../../assets/andritzLogoTxt.svg'
import Dialog from '../Dialog/dialog'
import Footer from '../Footer/footer'
import LoadingSpinner from '../LoadingSpinner/loading-spinner'
import MobileNavbar from '../MobileNavbar/mobile-navbar'
import Navbar from '../Navbar/navbar'
import AnalysisCard from './AnalysisCard/analysis-card'
import BottomMenu from './BottomMenu/bottom-menu'
import ChartCardsContainer from './ChartCardsContainer/chart-cards-container'
import ContentContainer from './ContentContainer/content-container'
import CreateChartModal from './CreateChartModal/create-chart-modal'
import DataModal from './DataModal/data-modal'
import ImportModal from './ImportModal/import-modal'
import LatestImagesCard from './LatestImagesCard/latest-images-card'
import MobileDataList from './MobileDataList/mobile-data-list'
import './page-tool.scss'
import SectionTitle from './SectionTitle/section-title'
import SideMenu from './SideMenu/side-menu'
import TimeframeComponent from './TimeframeComponent/timeframe-component'
import UnitMenu from './UnitMenu/unit-menu'


// TODO: Separate components out of PageTool class -> currently code is hard to read

class PageTool extends Component {

   constructor(props) {
      super(props)

      this.state = {
         isImportModalVisible: false,
         isDataModalVisible: false,
         scrollTo: null,
         isChartModalVisible: false,
         isFullScreen: false,
         drawAndritzLogo: true,
      }

      this.toolContentContainer = React.createRef()
      this.contentContainer = undefined
      this.innerContainerObserver = undefined // for drawing andritz logo

      // in desktop view these are in side menu
      this.mobileMenuOptions = [
         { label: 'Configuration', value: 'configuration' },
         { label: 'DCS tags', value: 'dcs-tags' },
         { label: 'Info', value: 'value', disabled: true },
         { label: 'Manuals', value: 'manuals', disabled: true },
      ]

      this.cancelGettingAnalyses = undefined

      // Socket IO
      this.unsubFromAnalyses = undefined
      this.unsubFromOwnAnalysisCompletions = undefined
      this.unsubContinuousAnalysisStatuses = undefined

      this.socketSiteNamespace = new SocketSiteNamespace(props.selectedSite)
   }

   componentDidMount() {
      const { selectedTool, history } = this.props

      this.getUserToolConfigAndSetModels()
      this.getInitialData()
      this.observeContentContainerChilds()

      // If tool type is "continuous", listen to positions statuses
      if (this.props.selectedTool.config.type === Tool.Type.CONTINUOUS) {
         this.listenToContinuousAnalysisPositionsStatuses()
      }

      // Listen to own analysis completions
      this.listenToOwnAnalyses()

      // Set tool filter
      this.socketSiteNamespace.setFilter({ tool: selectedTool.id })

      // automatically start live update
      const queryParams = history.location.search
      if (queryParams.includes('liveupdate=true') && selectedTool?.config.type === 'continuous') {
         this.liveUpdatePressed()
      }
      if (queryParams.includes('fullscreen=true')) {
         this.setState({ isFullScreen: true })
      }
      // remove query params from url
      window.history.pushState({}, document.title, history.location.pathname)

      // Handle browser back button press. Reload page when back button pressed. Otherwise some data may stay in redux that should be cleared
      this.popstateListener = window.addEventListener('popstate', this.onBrowserBackPressed)
   }

   componentWillUnmount() {
      if (this.cancelGettingAnalyses) this.cancelGettingAnalyses()
      if (this.innerContainerObserver) this.innerContainerObserver.disconnect()

      window.removeEventListener('popstate', this.onBrowserBackPressed)

      this.stopListeningToOwnAnalyses()
      this.socketSiteNamespace.clean()

      this.props.stopLiveUpdate()
      this.props.clearOngoingAnalysis()
      this.props.clearData()

   }

   componentDidUpdate(prevProps) {
      const { scrollTo } = this.state
      const { selectedTool, ongoingAnalysis, liveUpdate } = this.props

      if (ongoingAnalysis && this.cancelGettingAnalyses) this.cancelGettingAnalyses()

      if (prevProps.selectedTool !== selectedTool) {
         if (this.cancelGettingAnalyses) this.cancelGettingAnalyses()
         this.stopListeningContinuousAnalysisStatuses()

         // if tool is changed -> load initial data
         this.getUserToolConfigAndSetModels()
         this.getInitialData()

         // If tool changed, stop listening to ongoing analysis
         if (prevProps.selectedTool !== selectedTool) {
            this.props.clearOngoingAnalysis()
         }

         // If tool type is "continuous", listen to positions statuses
         if (selectedTool && selectedTool.config.type === Tool.Type.CONTINUOUS) {
            this.listenToContinuousAnalysisPositionsStatuses()
         }

         // On tool change
         if (selectedTool !== prevProps.selectedTool) {
            this.socketSiteNamespace.setFilter({ tool: selectedTool.id })
         }
      }

      // If liveUpdate was changed
      if (prevProps.liveUpdate !== liveUpdate) {
         if (liveUpdate) this.listenToAnalyses()
         else this.stopListeningToAnalyses()
      }

      /*
      // Stop if continuous analysis ended
      if (liveUpdate) {
         const noRunningAnalyses = !(Object.values(positionStates).find(status => status))
         if (noRunningAnalyses) {
            this.props.stopLiveUpdate()
         }
      }
      */

      // Scroll to correct position when returning from selected item (Item card in mobile view)
      if (prevProps.selectedAnalyses !== this.props.selectedAnalyses && this.props.selectedAnalyses.length === 0) {
         if (scrollTo) {
            window.scrollTo(0, scrollTo)
            this.setState({ scrollTo: null })
         }
      }

      // NOTE: Scroll down on selection is disabled so it is easier to inspect data from charts and select multiple items
      // Scroll to selected item
      // if (selectedAnalyses.length > prevProps.selectedAnalyses.length && selectedAnalyses.length !== 0) {
      //    this.scrollToRecentlySelectedItem()
      // }

   }


   onBrowserBackPressed = () => {
      if (this.props.history.action === 'POP') {
         this.props.history.push('/')
         this.props.selectTool(null)
      }
   }



   getUserToolConfigAndSetModels = async () => {
      const { user, selectedTool, showLatestResult, showLatestImages, setResultComponentModels } = this.props
      if (!selectedTool) return

      const userToolConfig = await UserService
         .getUserToolConfig(user.id, selectedTool.id)
         .catch((err) => {
            this.props.showDialog({
               title: 'Failed to load User Tool Config',
               text: errorToMessage(err),
            })
         })

      // Show latest result
      const { isLatestResultCardVisible, isLatestImagesCardVisible } = userToolConfig.continuousAnalysis
      showLatestResult(isLatestResultCardVisible !== undefined ? isLatestResultCardVisible : false)
      showLatestImages(isLatestImagesCardVisible !== undefined ? isLatestImagesCardVisible : true)

      this.props.setUserToolConfig(userToolConfig)

      const models = []

      // Predefined component models
      const resultModel = selectedTool.resultModels[0] // Currently only one model (0.0)
      if (resultModel?.components) {
         resultModel.components.forEach((model) => {
            if (!model.chart) return // Add only components with chart

            const usersChartConfig = userToolConfig.charts.predefined.find((chart) => chart.component === model.id)
            models.push(
               UserService.userChartConfigToResultComponentModel(usersChartConfig, model)
            )
         })
      }

      // Custom result component models
      userToolConfig.charts.custom.forEach((chart) => {
         models.push(
            UserService.userChartConfigToResultComponentModel(chart)
         )
      })
      setResultComponentModels(models)
   }

   getInitialData = () => {
      const { selectedTool, selectedSite, isMobile } = this.props
      if (!selectedTool || !selectedSite) return
      // Set timeframe TODO: Move to helper
      this.setState({ loadingInitialData: true })
      const dateFrom = getBeginningOfDate(new Date())
      dateFrom.setDate(dateFrom.getDate() - 7 * 4 * 4) // One week
      const dateTo = getEndOfDate(new Date())
      /* Get data from db */
      const { promise, cancel } = DataService
         .getAnalyses({
            tool: selectedTool.id,
            dateFrom,
            dateTo,
            site: selectedSite,
            limit: 3000,
         })
      this.cancelGettingAnalyses = () => {
         this.cancelGettingAnalyses = undefined
         cancel()
      }
      promise
         .then((analyses) => {
            if (analyses.length === 0) return // No data found

            analyses.forEach((a) => {
               this.setLocalTagsForAnalysis(a)
            })
            this.props.setAnalyses(
               selectedTool.id,
               analyses,
               undefined,
               undefined,
               {
                  excludeTagless: (selectedTool.config.type === Tool.Type.CONTINUOUS && analyses.find((a) => a.tags.length > 0)), // Exclude tagless only if CONTINUOUS tool and there are analyses with tags
                  enabledTagFilters: (selectedTool.config.type === Tool.Type.CONTINUOUS)
                     ? [Tag.getHasImagesTagId(selectedTool.id)]
                     : undefined,
               }
            )
            // Autoselect if only one item
            if (analyses.length === 1 && !isMobile) this.props.selectAnalysis(analyses[0].id)
         })
         .catch((err) => {
            console.error('Error fetching initial data', err)
         })
         .finally(() => {
            this.cancelGettingAnalyses = undefined
            this.setState({ loadingInitialData: false })
         })
   }



   liveUpdatePressed = async () => {
      const { selectedTool, liveUpdate } = this.props

      // - Start -
      if (!liveUpdate) {
         if (this.cancelGettingAnalyses) this.cancelGettingAnalyses()
         this.props.clearOngoingAnalysis()
         this.props.clearData()
         // Get 5 latest items. Timeframe is also limited
         const dateFrom = new Date()
         dateFrom.setHours(dateFrom.getHours() - 1)
         const dateTo = new Date()

         this.props.startLiveUpdate(selectedTool.id)

         const { promise, cancel } = DataService
            .getAnalyses({
               tool: selectedTool.id,
               dateFrom,
               dateTo,
               site: this.props.selectedSite,
               queryDateBy: TIMEFRAME_BY_TIMESTAMP,
               limit: 100,
            })
         this.cancelGettingAnalyses = cancel
         const analyses = await promise
            .catch(() => {
               console.warn('Error gettings previous data')
            })

         this.cancelGettingAnalyses = undefined

         this.props.setLiveUpdateFollowRange(LIVE_UPDATE_DEFAULT_FOLLOW_RANGE)
         if ((analyses || []).length === 0) return // No data found

         analyses.forEach((a) => {
            this.setLocalTagsForAnalysis(a)
         })
         this.props.addAnalyses(
            selectedTool.id,
            analyses,
            { sort: true, timeframe: [undefined, dateTo] }
         )

         /*
         // Allow live update only
         const noRunningAnalyses = !(Object.values(positionStates).find(status => status))
         if (noRunningAnalyses) {
            this.setState({
               alertDialogTitle: "Warning",
               alertDialogMessage: "There are no running analyses"
            })
         }
         // Running analyses exists
         else {
            this.props.clearData()
            this.props.startLiveUpdate(selectedTool.id)
         }*/
      }
      // - Stop -
      else {
         this.props.stopLiveUpdate()
      }
   }

   setLocalTagsForAnalysis = (analysis) => { // TODO: Where should this go?
      const localTags = []
      if (this.props.tools[analysis.tool].config.type === Tool.Type.CONTINUOUS) {
         if (analysis.items.find((i) => i.imageId)) {
            localTags.push(Tag.getHasImagesTagId(analysis.tool))
         }
      }
      analysis.tags.push(...localTags)
   }

   // Analyses
   listenToOwnAnalyses() {
      const { onConnectedPromise, unsubscribe } = SocketIOService.subscribeToOwnAnalysisCompletions({
         onDisconnect: () => {
            if (!this.props.ongoingAnalysis) return
            this.props.clearOngoingAnalysis()
            this.setState({
               alertDialogTitle: 'Connection lost',
               alertDialogMessage: `You won't be notified when the analysis is ready.`,
            })
         },
         onNewAnalysis: (err, newAnalysis) => {
            if (err) {
               this.props.clearOngoingAnalysis()
               this.setState({ alertDialogMessage: err.message })
               return
            }
            this.setLocalTagsForAnalysis(newAnalysis) // Local Tags
            this.props.addAnalysis(newAnalysis.tool, newAnalysis)
            this.props.selectAnalysis(newAnalysis.id)
            this.props.clearOngoingAnalysis()
            this.props.excludeTagless(false)
         },
      })
      this.unsubFromOwnAnalysisCompletions = unsubscribe
      onConnectedPromise.catch((err) => {
         console.error('Failed to connect to websocket', err)
         this.props.clearOngoingAnalysis()
         this.setState({ alertDialogMessage: 'Connection failed' })
      })
   }

   stopListeningToOwnAnalyses() {
      if (this.unsubFromOwnAnalysisCompletions) {
         this.unsubFromOwnAnalysisCompletions()
         this.unsubFromOwnAnalysisCompletions = undefined
      }
   }

   listenToAnalyses() {
      this.unsubFromAnalyses = this.socketSiteNamespace.subscribeToNewAnalyses((_, newAnalysis) => {
         this.setLocalTagsForAnalysis(newAnalysis) // Local Tags
         this.props.addAnalysis(newAnalysis.tool, newAnalysis, { limit: 5500 })

         // Show tagless items by default if point analysis
         if (this.props.selectedTool.config.type === Tool.Type.POINT) {
            this.props.excludeTagless(false)
         }
      }).unsubscribe
   }

   stopListeningToAnalyses() {
      if (this.unsubFromAnalyses) {
         this.unsubFromAnalyses()
         this.unsubFromAnalyses = undefined
      }
   }


   // Continuous analysis positions statuses
   listenToContinuousAnalysisPositionsStatuses() {
      const { positionStates } = this.props
      this.unsubContinuousAnalysisStatuses = this.socketSiteNamespace.subscribeToContinuousAnalysisState((state) => {
         if (!isEqual(positionStates[state.position], state)) {
            this.props.setContinuousAnalysisPositionState(state.position, state)
         }
      })
   }

   stopListeningContinuousAnalysisStatuses() {
      if (this.unsubContinuousAnalysisStatuses) {
         this.unsubContinuousAnalysisStatuses()
         this.unsubContinuousAnalysisStatuses = undefined
      }
   }


   isAnalyzing() {
      return !!this.props.ongoingAnalysis
   }

   static scrollToY = (scrollableElement, y, duration) => {
      let start
      const startScrollPos = scrollableElement.scrollTop
      const targetScrollPos = y

      window.requestAnimationFrame(function step(timestamp) {
         if (!start) start = timestamp
         const progress = (timestamp - start) / duration
         const easeInOutSineProgress = -(Math.cos(Math.PI * progress) - 1) / 2
         const newY = startScrollPos + ((targetScrollPos - startScrollPos) * easeInOutSineProgress)
         if (progress < 1) {
            scrollableElement.scrollTo(0, newY)
            window.requestAnimationFrame(step)
         } else {
            scrollableElement.scrollTo(0, y)
         }
      })
   }

   scrollToRecentlySelectedItem() {
      const { selectedAnalyses } = this.props
      const cardId = `card-selected-analysis-${selectedAnalyses[selectedAnalyses.length - 1]}`
      const card = document.getElementById(cardId)
      if (card !== null) {
         if (this.contentContainer === undefined) {
            this.contentContainer = document.getElementsByClassName('content-container')[0]
         }
         const y = this.contentContainer.scrollTop + card.getBoundingClientRect().top
         // if (y < this.contentContainer.scrollTop || y > this.contentContainer.offsetHeight + this.contentContainer.scrollTop - card.offsetHeight) { // Check if card is not in middle of the screen and doesn't fit to the screen
         this.scrollToY(this.contentContainer, y - (116 + 38), 400) // TOP
         // scrollToY(this.contentContainer, y - this.contentContainer.offsetHeight + card.offsetHeight - 40, 400) // BOTTOM
         // }
      }
   }


   // TODO: Check performance
   generateChartAndSelectedAnalysesCards = memoize(({ unitFilters, selectedAnalyses, analyses, filteredAnalyses, resultComponents, selectedTool, user, userToolConfig }) => { // TODO: add error handling for ItemCard (if model does not exist)
      const uiComponents = []

      for (const unitFilter of unitFilters) {
         // Render only units that are not filtered
         if (unitFilter.exclude) continue

         /* Graphs */
         uiComponents.push(
            <ChartCardsContainer
               key={unitFilter.id}
            />
         )

         /* Latest analysis and latest analysis images */
         if ((resultComponents.showLatestResult || resultComponents.showLatestImages) && selectedTool) { // To display only when continuous ananlysis: && (selectedTool.config.type === Tool.Type.CONTINUOUS)
            let latestResult
            const latestResultWithImages = resultComponents.showLatestImages
            filteredAnalyses.forEach((item) => {
               // Latest result
               if (resultComponents.showLatestResult) {
                  if (!latestResult) latestResult = item
                  if (item.dateAnalyzed > latestResult.dateAnalyzed) latestResult = item
               }
            })
            if (latestResultWithImages) {
               uiComponents.push(
                  <LatestImagesCard
                     key="latest-analysis-images"
                     // analysisId={latestResultWithImages.id}
                  />
               )
            }
            if (latestResult) {
               uiComponents.push(
                  <SectionTitle
                     key="latest-result"
                     title="Latest result"
                  />
               )
               uiComponents.push(
                  <AnalysisCard
                     key="latest-analysis"
                     analysisId={latestResult.id}
                     showNoImagePlaceholders={true}
                     onClose={() => {
                        this.props.showLatestResult(false)
                        UserService
                           .editUserToolConfig(
                              userToolConfig,
                              { continuousAnalysis: { isLatestResultCardVisible: false } },
                              selectedTool.id,
                              user.id,
                              this.props.setUserToolConfig
                           )
                           .catch((err) => {
                              this.props.showDialog({
                                 title: 'Failed to update User Tool Config',
                                 text: errorToMessage(err),
                              })
                           })
                     }}
                  />
               )
            }
         }

         /* Selected analyses */
         let first = true
         for (let i = selectedAnalyses.length - 1; i >= 0; --i) {
            const item = analyses.find((a) => a.id === selectedAnalyses[i])
            if (!item || item.unit !== unitFilter.id) continue
            if (first) {
               uiComponents.push(
                  <SectionTitle
                     key="selected-items"
                     title="Selected"
                  />
               )
            }
            uiComponents.push(
               <AnalysisCard
                  key={item.id}
                  id={`card-selected-analysis-${item.id}`}
                  analysisId={item.id}
                  onClose={() => this.props.deselectAnalysis(item.id)}
                  isHighlighted={i === selectedAnalyses.length - 1}
               />
            )
            first = false
         }
      }
      return uiComponents.map((chartCard) => chartCard)
   })


   mobileMenuItemsSelected = (value) => {
      switch (value) {
         case 'configuration':
            this.props.history.push(`/tools/${this.props.selectedTool.id}/configuration`)
            break
         default:
            break
      }
   }


   observeContentContainerChilds = () => {
      if (this.props.isMobile) return
      const contentContainer = document.getElementsByClassName('content-container')[0]
      const innerContainer = contentContainer.firstChild // innerContainer

      this.innerContainerObserver = new MutationObserver(() => {
         const childs = innerContainer.childNodes
         let notBlockingChilds = 0
         for (const child of childs) {
            if (child.classList.contains('andritz-logo-container')) {
               ++notBlockingChilds
            }
         }
         const drawAndritzLogo = (childs.length === notBlockingChilds)
         if (drawAndritzLogo !== this.state.drawAndritzLogo) {
            this.setState({ drawAndritzLogo })
         }
      })
      this.innerContainerObserver.observe(innerContainer, { childList: true })
   }


   render() {

      const { filteredAnalyses, selectedAnalyses, selectedTool, isMobile } = this.props
      const { isImportModalVisible, isDataModalVisible, alertDialogMessage, alertDialogTitle, loadingInitialData, isChartModalVisible, isFullScreen, drawAndritzLogo } = this.state

      const sideMenu = () => (
         <SideMenu
            selectDataPressed={() => this.setState({ isDataModalVisible: true })}
            liveUpdatePressed={this.liveUpdatePressed}
            configurationPressed={() => this.props.history.push(`/tools/${selectedTool.id}/configuration`)}
            loadingInitialData={loadingInitialData}
            addNewPressed={() => this.setState({ isImportModalVisible: true })}
            onNewChart={() => this.setState({ isChartModalVisible: true })}
         />
      )

      if (!selectedTool) return null

      return (
         <span>
            { isMobile ? (
            /* ---- MOBILE ---- */

               <div className="page-tool-mobile">
                  <div hidden={selectedAnalyses.length !== 0 || isDataModalVisible || isImportModalVisible}>
                     <MobileNavbar
                        title={selectedTool.name}
                        menuItems={this.mobileMenuOptions}
                        menuItemSelected={(value) => this.mobileMenuItemsSelected(value)}
                        backPressed={() => {
                           this.props.history.push('/') }
                        }
                     />
                     <div className="menu-and-data">
                        <MobileDataList
                           analysisSelected={() => this.setState({
                              scrollTo: window.pageYOffset,
                           })}
                        />
                        { sideMenu() }
                     </div>
                  </div>
                  { this.isAnalyzing()
                     && <div className="ongoing-analysis-loading-spinner-container mobile-ongoing-spinner">
                        <LoadingSpinner />
                     </div>
                  }
                  { selectedAnalyses.length > 0
                     && <AnalysisCard
                        analysisId={selectedAnalyses[0]}
                     />
                  }
               </div>
            ) : (
            /* ---- DESKTOP ---- */

               <div className="page-tool-container">
                  <Navbar />
                  <div className="page-tool">
                     { sideMenu() }
                     <div id="tool-content-container" className={classNames('tool-content-container', { 'is-full-screen': isFullScreen })} ref={this.toolContentContainer}>

                        <UnitMenu
                           isFullScreen={isFullScreen}
                           onFullScreen={() => this.setState({ isFullScreen: !isFullScreen }, () => {
                              Highcharts.charts.forEach((chart) => chart && chart.reflow())
                              Highstock.charts.forEach((chart) => chart && chart.reflow())
                           })}
                        />

                        {(this.props.timeframe && +this.props.timeframe.from !== +this.props.timeframe.to)
                           && <TimeframeComponent />
                        }

                        <ContentContainer>

                           { this.isAnalyzing()
                              && <div className="ongoing-analysis-loading-spinner-container">
                                 <LoadingSpinner />
                              </div>
                           }

                           {/* Show andritz logo if no data rendered inside content container */}
                           <div style={drawAndritzLogo ? { opacity: 1 } : undefined} className="andritz-logo-container">
                              <img className="andritz-logo" src={AndritzLogo} alt="" />
                           </div>

                           {(filteredAnalyses.length > 0) // TODO: Make separate component for cleaner code
                              && this.generateChartAndSelectedAnalysesCards(this.props)
                           }

                        </ContentContainer>

                        { !isFullScreen
                           && <BottomMenu />
                        }

                     </div>

                  </div>
                  <Footer styleType="small" />
               </div>
            )}

            {/* ---- MODALS ---- */}

            { isImportModalVisible
               && <ImportModal onClose={() => this.setState({ isImportModalVisible: false })} />
            }
            { isDataModalVisible
               && <DataModal onClose={() => this.setState({ isDataModalVisible: false })} />
            }

            { isChartModalVisible
               && <CreateChartModal onClose={() => this.setState({ isChartModalVisible: false })} />
            }

            {/* ---- DIALOGS ---- */}
            <Dialog
               visible={alertDialogMessage}
               title={alertDialogTitle || 'Alert'}
               message={alertDialogMessage}
               buttons={[{ text: 'OK' }]}
               onClose={() => this.setState({ alertDialogMessage: null, alertDialogTitle: null })}
            />
         </span>
      )
   }

}

const mapStateToProps = (state) => ({
   user: state.user,
   analyses: selectToolsAnalyses(state),
   filteredAnalyses: selectToolsFilteredAnalyses(state),
   selectedAnalyses: state.selectedAnalyses,
   selectedTool: selectSelectedTool(state),
   tools: state.tools,
   timeframe: state.timeframe,
   unitFilters: selectUnitFilters(state),
   tagFilters: state.tagFilters,
   positionFilters: state.positionFilters,
   isMobile: state.device.isMobile,
   selectedSite: state.selectedSite,
   ongoingAnalysis: state.ongoingAnalysis,
   liveUpdate: state.liveUpdate,
   resultComponents: state.resultComponents,
   positionStates: state.continuousAnalysispositionStates,
   userToolConfig: state.userToolConfig,
})

export default withRouter(connect(mapStateToProps, { setAnalyses, addAnalyses, selectAnalysis, clearData, clearOngoingAnalysis, startLiveUpdate, stopLiveUpdate, setResultComponentModels, addResultComponentModel, showLatestResult, setUserToolConfig, showLatestImages, deselectAnalysis, addAnalysis, setContinuousAnalysisPositionState, setLiveUpdateFollowRange, selectTool, excludeTagless, showDialog })(PageTool))
