export default class Position {

   /**
    * Create a Position
    * @param {string} id
    * @param {string} name
    * @param {string} unit UUID
    */
   constructor(id, name, unit) {
      this.id = id
      this.name = name
      this.unit = unit
   }


   /**
    * Creates Position from Json Object
    * @param {Object} json
    * @returns {Position}
    */
   static fromJson(json) {
      if (!json) return null
      return new Position(json.id, json.name, json.unit)
   }

}
