import { CLEAR_USER, SET_USER } from '../actions/types'

const initialState = {
   isAuthenticated: false,
   isAuthenticating: true, // getCurrentUser is called on App componentDidMount
}

const authReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_USER:
         return {
            isAuthenticated: true,
            isAuthenticating: false,
         }

      case CLEAR_USER:
         return {
            isAuthenticated: false,
            isAuthenticating: false,
         }

      default:
         return state

   }
}

export default authReducer
