import { ContinuousAnalysis } from '@ava/react-common/models'
import SocketSiteNamespace from '@ava/react-common/models/socket-site-namespace'
import Tool from '@ava/react-common/models/tool'
import { setContinuousAnalysisPositionState } from '@ava/react-common/store/actions/data-actions'
import { useTypedSelector } from '@ava/react-common/utils/store-utils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Lock from '../../../assets/lock.svg'
import Button from '../../Button/button'
import RouterLink from '../../RouterLink/RouterLink'
import './tool-card.css'

/**
 * @param {object} props
 * @param {Tool} props.tool
 * @returns {import('react').FunctionComponentElement}
 */
export default function ToolCard({ tool }) {
   const useSelector = useTypedSelector()
   const isMobile = useSelector((state) => state.device.isMobile)
   const selectedSite = useSelector((state) => state.selectedSite)
   const positionStates = useSelector((state) => state.continuousAnalysispositionStates)
   const [runningPositionsCount, setRunningPositionsCount] = useState(0)

   const dispatch = useDispatch()


   // For listening tool status
   useEffect(() => {
      if (tool.config.type === Tool.Type.CONTINUOUS) {
         const socketSiteNamespace = new SocketSiteNamespace(selectedSite, { tool: tool.id })
         socketSiteNamespace.subscribeToContinuousAnalysisState((state) => {
            dispatch(setContinuousAnalysisPositionState(state.position, state))
         })
         return () => {
            socketSiteNamespace.clean()
         }
      }
   }, [tool, selectedSite, dispatch])


   useEffect(() => {
      if (tool.config.type === Tool.Type.CONTINUOUS) {
         const positions = tool.positions
         let counter = 0
         positions.forEach((positionId) => {
            if (positionStates[positionId]?.status === ContinuousAnalysis.Status.RUNNING) counter++
         })
         setRunningPositionsCount(counter)
      }
   }, [positionStates, tool])

   /**
    * @param {string} str
    * @returns {string}
    */
   function getFirstLetters(str) {
      const matches = str.match(/\b(\w)/g)
      const acronym = matches.join('') // JSON
      return acronym
   }

   const isLocked = (tool.level === Tool.Level.LOCKED)
   if (!tool) return null

   return isMobile ? (
   // -- MOBILE --

      <RouterLink
         to={`/tools/${tool.id}`}
         disabled={isLocked}
      >
         <div className="tool-card-mobile">
            <div className="card-img-mobile">
               { isLocked
                  ? <img className="lock" src={Lock} alt="Locked" />
                  : <p>{getFirstLetters(tool.name)}</p>
               }
            </div>
            <div className="tool-card-txt-mobile">
               <h3>{tool.name}</h3>
               <p className="tool-desc-mobile">{tool.description}</p>
            </div>
            <div className="cell-separator" />
         </div>
      </RouterLink>
   ) : (
   // -- DESKTOP --

      <div className="tool-card">
         <div className="tool-card-content">
            <RouterLink
               to={`/tools/${tool.id}`}
               disabled={isLocked}
            >
               <div className={'card-img-container'}>
                  { isLocked
                     ? <img className="lock" src={Lock} alt="Locked" />
                     : <p>{getFirstLetters(tool.name)}</p>
                  }
               </div>
            </RouterLink>
            <h3>{tool.name}</h3>
            <p className="tool-level">{tool.level}</p>
            { tool.config?.type === Tool.Type.CONTINUOUS && <p className="tool-analyses-status">{`running: ${runningPositionsCount}/${tool.positions.length}`}</p> }
            <p className="tool-description">{tool.description}</p>
            <div className="card-btn-container">
               <RouterLink
                  to={`/tools/${tool.id}`}
                  disabled={isLocked}
               >
                  <Button
                     title="SELECT"
                     disabled={isLocked}
                  />
               </RouterLink>
            </div>
            <div className="blue-bottom-border" />
         </div>
      </div>
   )
}
