import DataService from '@ava/react-common/services/data-service'
import { createTag, deleteTag, renameTag } from '@ava/react-common/store/actions/configuration-action'
import { selectToolsTags } from '@ava/react-common/store/reducers/root-reducer'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from '../../Dialog/dialog'
import Input from '../../Input/input'
import Modal from '../../Modal/modal'
import SettingsTopMenu from '../../SettingsTopMenu/settings-top-menu'
import VirtualizedTable from '../../VirtualizedTable/virtualized-table'

class TagSettings extends Component {

   static propTypes = {
      onError: PropTypes.func,
      dimensions: PropTypes.object,
   }

   state = {
      isModalVisible: false,
      isDeleteDialogVisible: false,
      isLoading: false,
      selectedTag: undefined,
      isEditing: false,
      inputValue: undefined,
   }


   createNewTag = () => {
      const { onError, createTag, tool, site } = this.props
      const { inputValue } = this.state

      const name = inputValue && inputValue.trim()
      if (!name || name === '') {
         onError('Add name')
         return
      }

      DataService.createTag(inputValue, tool, site).then((tag) => {
         createTag(tag.id, tag.name, tag.tool)
         this.setState({ selectedTag: tag.id })
         this.exitEditing()
      }).catch((err) => {
         onError(err)
         console.error(err)
      })
   }

   renameTag = () => {
      const { onError, renameTag } = this.props
      const { selectedTag, inputValue } = this.state

      const name = inputValue && inputValue.trim()
      if (!name || name === '') {
         onError('Name is required')
         return
      }

      this.setState({ isLoading: true })

      DataService.renameTag(selectedTag, inputValue).then(() => {
         renameTag(selectedTag, inputValue)
         this.exitEditing()
      }).catch(() => {
         onError('Failed to rename tag')
         this.exitEditing()
      })
   }

   exitEditing = () => {
      this.setState({ isEditing: false, inputValue: undefined, isLoading: false, isModalVisible: false })
   }


   render() {
      const { tags, deleteTag, onError, tool, dimensions, user } = this.props
      const { isDeleteDialogVisible, selectedTag, isModalVisible, isEditing, inputValue, isLoading } = this.state

      const isEditingEnabled = user.hasUserPriviledges()

      const items = tags.map((tag) => ({
         id: tag.id,
         name: tag.name,
      }))

      const columns = [
         {
            label: 'Name',
            dataKey: 'name',
            width: 1,
            sortable: true,
         },
      ]

      return (
         <div>
            <SettingsTopMenu
               primaryBtnTitle="Add new"
               primaryBtnPressed={() => this.setState({ isModalVisible: true })}
               primaryBtnDisabled={!isEditingEnabled}
               actionOptions={[
                  { value: 'edit', label: 'Edit' },
                  { value: 'delete', label: 'Delete', type: 'danger', hidden: false },
               ]}
               onActionSelected={(action) => {
                  if (action === 'edit') {
                     const selectedTagObj = tags.find((tag) => tag.id === selectedTag)
                     const tagName = selectedTagObj && selectedTagObj.name
                     if (!tagName) return
                     this.setState({ isEditing: true, inputValue: tagName, isModalVisible: true })
                  } else if (action === 'delete') {
                     this.setState({ isDeleteDialogVisible: true })
                  }
               }}
               actionsDisabled={!selectedTag || !isEditingEnabled || !tags.find((tag) => tag.id === selectedTag) || tags.find((tag) => tag.id === selectedTag).isLocal} // TODO: Simplify
            />
            <VirtualizedTable
               width={dimensions.width}
               height={dimensions.height - 52} // secondary menu height === 52px
               items={items}
               selectedItems={selectedTag ? [selectedTag] : []}
               onItemSelected={(id) => {
                  if (id === selectedTag) this.setState({ selectedTag: undefined })
                  else this.setState({ selectedTag: id })
               }}
               columns={columns}
               initialSortBy="unit"
               headerHeight={34}
               rowHeight={34}
            />


            { (isModalVisible && isEditingEnabled)
            && <Modal
               disabled={isLoading}
               width="420px"
               title={isEditing ? 'Edit Tag' : 'New Tag'}
               onClose={() => this.exitEditing()}
               primaryBtnTitle="Save"
               secondaryBtnTitle="Cancel"
               primaryBtnPressed={isEditing ? this.renameTag : this.createNewTag}
            >
               <p style={{ marginBottom: '2px' }}><strong>Name:</strong></p>
               <Input
                  style={{ marginBottom: '12px' }}
                  autoFocus={true}
                  value={inputValue || ''}
                  onChange={(value) => this.setState({ inputValue: value })}
               />
            </Modal>

            }

            <Dialog
               visible={selectedTag && isDeleteDialogVisible && isEditingEnabled}
               title={`Delete ${tags.find((tag) => tag.id === selectedTag)?.name}?`}
               message="Are you sure? This cannot be undone."
               buttons={[{
                  text: 'DELETE',
                  style: 'danger',
                  onPress: () => {
                     this.setState({ isLoading: true })
                     DataService.deleteTag(selectedTag).then(() => {
                        this.setState({ isDeleteDialogVisible: false, selectedTag: undefined })
                        deleteTag(selectedTag, tool)
                        this.exitEditing()
                     }).catch((error) => {
                        console.error(error)
                        onError('Failed to delete tag')
                        this.setState({ isDeleteDialogVisible: false })
                        this.exitEditing()
                     })
                  },
               }, {
                  text: 'CANCEL',
               }]}
               onClose={() => this.setState({ isDeleteDialogVisible: false })}
            />

         </div>
      )
   }

}

const mapStateToProps = (state) => ({
   tags: selectToolsTags(state),
   site: state.selectedSite,
   tool: state.selectedTool,
   user: state.user,
})

export default connect(mapStateToProps, { deleteTag, createTag, renameTag })(TagSettings)
