import { useDispatch, useSelector } from 'react-redux'


/** @returns {import("../store/reducers/root-reducer").ReduxSelector} */
export function useTypedSelector() {
   return useSelector
}

/** @returns {import("../store/reducers/root-reducer").ReduxDispatch} */
export function useTypedDispatch() {
   return useDispatch()
}
