import { ADD_RESULT_COMPONENT_MODEL, CLEAR_RESULT_COMPONENTS, REMOVE_RESULT_COMPONENT_MODEL, SET_EXCLUSIVE_RENDERING, SET_RESULT_COMPONENT_MODELS, SHOW_LATEST_IMAGES, SHOW_LATEST_RESULT, UPDATE_RESULT_COMPONENT_MODEL_CHART } from './types'

export function setResultComponentModels(models) {
   return {
      type: SET_RESULT_COMPONENT_MODELS,
      payload: models,
   }
}

export function updateResultComponentModelChart(id, chart) {
   return {
      type: UPDATE_RESULT_COMPONENT_MODEL_CHART,
      payload: { id, chart },
   }
}

export function addResultComponentModel(model) {
   return {
      type: ADD_RESULT_COMPONENT_MODEL,
      payload: model,
   }
}

export function removeResultComponentModel(model) {
   return {
      type: REMOVE_RESULT_COMPONENT_MODEL,
      payload: model,
   }
}

export function showLatestResult(show) {
   return {
      type: SHOW_LATEST_RESULT,
      payload: show,
   }
}

export function showLatestImages(show) {
   return {
      type: SHOW_LATEST_IMAGES,
      payload: show,
   }
}

export function clearResultComponents() {
   return {
      type: CLEAR_RESULT_COMPONENTS,
   }
}

export function setExclusiveRendering(bool) {
   return {
      type: SET_EXCLUSIVE_RENDERING,
      payload: bool,
   }
}
