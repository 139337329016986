import { useKeepValueUnchangedWhile } from '@ava/react-common/hooks'
import { setLiveUpdatePaused, setTimeframeSelection, setTimeframeZoomSelection } from '@ava/react-common/store/actions/data-actions'
import { selectToolsFilteredAnalyses, selectToolsFilteredPositions } from '@ava/react-common/store/reducers/root-reducer'
import { generateColor } from '@ava/react-common/utils'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TimeframeButtons from '../../TimeframeButtons/timeframe-buttons'
import TimeframeSlider from '../../TimeframeSlider/timeframe-slider'


/**
 * @param {{
 *    style?: React.CSSProperties,
 * }} props
 * @returns {import('react').FunctionComponentElement}
 */
export default function TimeframeComponent({ style }) {
   const analyses = useSelector((state) => selectToolsFilteredAnalyses(state))
   const timeframeObj = useSelector((state) => state.timeframe)
   const timeframeSelectionObj = useSelector((state) => state.timeframeSelection)
   const timeframeZoomSelectionObj = useSelector((state) => state.timeframeZoomSelection)
   const positions = useSelector((state) => selectToolsFilteredPositions(state))
   const timeZone = useSelector((state) => state.timeZone)
   const liveUpdatePaused = useSelector((state) => state.liveUpdatePaused)
   const dispatch = useDispatch()

   const tf = useMemo(() => (
      timeframeObj && [timeframeObj.from, timeframeObj.to]
   ), [timeframeObj])
   const tfs = useMemo(() => (
      timeframeSelectionObj && [timeframeSelectionObj.from, timeframeSelectionObj.to]
   ), [timeframeSelectionObj])
   const tfzs = useMemo(() => (
      timeframeZoomSelectionObj && [timeframeZoomSelectionObj.from, timeframeZoomSelectionObj.to]
   ), [timeframeZoomSelectionObj])
   const timeframe = useKeepValueUnchangedWhile(tf, liveUpdatePaused.isPaused)
   const timeframeSelection = useKeepValueUnchangedWhile(tfs, liveUpdatePaused.isPaused)
   const timeframeZoomSelection = useKeepValueUnchangedWhile(tfzs, liveUpdatePaused.isPaused)

   const dataPoints = useMemo(() => {
      if (Object.keys(analyses).length === 0) return []
      const dataPoints = []
      for (const pos of positions) {
         dataPoints.push({
            color: generateColor(dataPoints.length),
            data: analyses
               .map((analysis) => {
                  // Check if has items with result
                  if (!analysis?.items?.find((item) => item.position === pos.id && item.result?.elements)) return undefined
                  return analysis.timestamp
               })
               .filter((a) => a),
         })
         if (dataPoints[dataPoints.length - 1].data.length === 0) {
            delete dataPoints[dataPoints.length - 1]
         }
      }
      return dataPoints.filter((d) => d)
   }, [analyses, positions])

   return (
      <div className="timeframe-component"
         style={{
            padding: '16px 20px 12px',
            backgroundColor: '#000F1A',
            ...style,
         }}
      >
         <div style={{ display: 'flex' }}>

            {(timeframe && +timeframe[0] !== +timeframe[1])
               && <TimeframeButtons
                  timeframe={timeframe}
                  selection={timeframeSelection}
                  zoomSelection={timeframeZoomSelection}
                  onSelectionChange={(from, to) => {
                     dispatch(setTimeframeSelection(from, to))
                  }}
                  onZoomSelectionChange={(from, to) => {
                     dispatch(setTimeframeZoomSelection(from, to))
                  }}
               />
            }

            <TimeframeSlider
               style={{ flex: 1 }}
               selectionType="timeframe"
               isZoomSliderEnabled={false} // zoom slider is disabled for now because it doesn't add any value unless "infinite zoom / scroll" is implemented that would automatically load new data when zoomed or when scrolled
               onSelectionChange={(from, to) => {
                  dispatch(setTimeframeSelection(from, to, { from: timeframe[0], to: timeframe[1] }))
               }}
               onZoomSelectionChange={(from, to) => {
                  dispatch(setTimeframeZoomSelection(from, to))
               }}
               onBeingChanged={(isBeingChanged) => {
                  dispatch(setLiveUpdatePaused(isBeingChanged, 'Updating paused due to time frame is being changed'))
               }}
               timeframe={timeframe}
               zoomSelection={timeframeZoomSelection}
               selection={timeframeSelection}
               dataPoints={dataPoints}
               timeZone={timeZone}
            />
         </div>

      </div>
   )

}
