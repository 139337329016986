import { useIsMouseOver } from '@ava/react-common/hooks'
import { selectSelectedTool } from '@ava/react-common/store/reducers/root-reducer'
import { getLanguageString } from '@ava/react-common/utils'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 * @typedef LegendObj
 * @property {string} element
 * @property {("standard-deviation")} [customType]
 * @property {string} color
 * @property {string} [details]
 */

/**
 * @param {{
 *    chart: import('highcharts/highstock').Chart,
 *    legends: Object.<string, LegendObj[]>, // object key is positionId
 * }} props
 * @returns {import('react').FunctionComponentElement}
 */
export default function ChartLegends({ chart, legends }) {
   const positions = useSelector((state) => state.positions)
   const elements = useSelector((state) => selectSelectedTool(state).resultModels[0].elements)
   if (!chart || !legends) return null

   /** Determines if legends are positions or elements. If legends are elements, group them by position */
   const groupByPositions = !!(Object.values(legends).find((l) => l.length > 1))

   return (
      <div className="chart-legends" style={{ marginTop: '12px' }}>
         {Object.keys(legends).map((positionId) => {
            if (!positions[positionId]) return undefined

            // Legend is position
            if (!groupByPositions) {
               const legend = legends[positionId][0]
               return (
                  <Legend
                     key={`${positionId}-${legend.element}`}
                     chart={chart}
                     name={positions[positionId].name}
                     position={positionId}
                     legend={legend}
                  />
               )
            }
            // Legend is element

            return (
               <div
                  key={`legend-group-${positionId}`}
                  style={{ display: 'inline-block', marginLeft: '12px' }}
               >
                  <div style={{
                     fontSize: '14px',
                     fontWeight: 'normal',
                  }}>
                     { positions[positionId].name }
                  </div>
                  { legends[positionId].map((legend) => (<Legend
                     key={legend.element}
                     chart={chart}
                     name={getLanguageString(elements[legend.element].name)}
                     legend={legend}
                     position={positionId}
                  />)
                  )}
               </div>
            )


         }).filter((l) => l)} {/* Filter out undefined items */}
      </div>
   )
}


/**
 * @param {{
 *    chart: import('highcharts/highstock').Chart,
 *    legend: LegendObj,
 *    position: string,
 *    name: string,
 * }} props
 * @returns {import('react').FunctionComponentElement}
 */
function Legend({ chart, legend, position, name }) {
   const refLegend = useRef()
   const { customType, color, element, details } = legend

   // Show/Hide series depending on isEnabled state
   const [isEnabled, setEnabled] = useState(true)
   useEffect(() => {
      const series = chart.series.find((s) => s.userOptions.id === `${element}-${position}`)
      series.setVisible(isEnabled)
   }, [isEnabled, chart.series, element, position])


   // When enabled legend is hovered, fade out all the other lines
   const isMouseOver = useIsMouseOver(refLegend)
   useEffect(() => {
      if (!isEnabled || !isMouseOver) return
      const series = chart.series.filter((s) => {
         // If standard deviation chart
         if (customType === 'standard-deviation') { // Don't hide the std series
            return (s.userOptions.id !== `${element}-${position}` && s.userOptions.id !== `${element}-${position}-std`)
         }
         // If normal chart

         return (s.userOptions.id !== `${element}-${position}`)

      })
      series.forEach((s) => s.setState('inactive'))
      return () => {
         series.forEach((s) => s.setState('normal'))
      }
   }, [isMouseOver, isEnabled, chart.series, customType, element, position])

   return (
      <div
         ref={refLegend}
         onClick={() => setEnabled(!isEnabled)}
         style={{
            display: 'inline-block',
            marginRight: '16px',
            paddingLeft: '4px',
            cursor: 'pointer',
            opacity: isEnabled ? 1 : 0.4,
         }}
      >
         <div style={{
            background: color,
            width: '10px',
            height: '10px',
            // borderRadius: "5px",
            marginRight: '6px',
            display: 'inline-block',
            position: 'relative',
            top: '1px',
         }} />
         <div style={{
            fontSize: '13px',
            display: 'inline-block',
         }}>
            { name }
            { details && <p style={{ marginBottom: 0, fontSize: '12px', display: 'inline-block', marginLeft: '4px' }}> ({details})</p> }
         </div>
      </div>
   )
}
