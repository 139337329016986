import React, { Component } from 'react'
import Footer from '../Footer/footer'
import LoginBox from './LoginBox/login-box'
import './page-login.css'


export default class PageLogin extends Component {

   render() {
      return (
         <div>
            <div className="page-login-content">
               <LoginBox loginMethod={this.props.match.params.loginMethod} />
            </div>
            <Footer styleType="default" />
         </div>
      )
   }

}
