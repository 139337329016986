import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { cloneDeep, merge } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { colorLuminance, generateColor, getLanguageString, getConvertedAndRoundedValue } from '@ava/react-common/utils/Helper'
import { selectPositionFilters } from '@ava/react-common/store/reducers/root-reducer'
import './pie-chart.css'
import HoverInfo from '../Hoverable/hover-info'


const staticChartOptions = {
   title: { style: { display: 'none' } },
   chart: {
      // animation: false,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      style: {
         fontFamily: '"Gilroy", sans-serif',
      },
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
   },
   tooltip: { enabled: false },
   plotOptions: {
      pie: {
         size: '100%',
         animation: false,
         dataLabels: {
            enabled: false,
         },
      },
      series: {
         states: {
            hover: {
               enabled: false,
            },
         },
      },
   },
   series: {
      colorByPoint: true,
   },
   credits: {
      enabled: false,
   },
}


class PieChart extends Component {

   static propTypes = {
      elements: PropTypes.object.isRequired,
      item: PropTypes.object.isRequired,
      animate: PropTypes.bool,
   }

   static defaultProps = {
      animate: true,
   }

   constructor(props) {
      super(props)

      this.state = {
         chartOptions: cloneDeep(staticChartOptions),
         legendElements: [],
      }

   }

   componentDidMount() {
      this.updatePlotOptions()
   }

   componentDidUpdate(prevProps) {
      const { elements, item, isMobile, size } = this.props
      if (JSON.stringify(prevProps.item) !== JSON.stringify(item) || JSON.stringify(prevProps.elements) !== JSON.stringify(elements) || prevProps.size !== size || prevProps.isMobile !== isMobile) {
         this.updatePlotOptions()
      }
   }

   async updatePlotOptions() {
      const { item, elements, size, isMobile } = this.props

      // Update height without animation first
      await new Promise((resolve) => {
         let height = 130
         if (isMobile) height = ((size === 's' || size === 'xs') ? 128 : 140)

         if (this.state.chartOptions.chart.height === height) {
            resolve()
            return
         }
         this.setState({
            chartOptions: merge(
               cloneDeep(staticChartOptions),
               { chart: { animation: false, height } }
            ),
         }, resolve)
      })

      const seriesData = []
      const legendElements = []

      for (const key in elements) {
         const value = item.result.elements[key]
         if (value == null) continue

         const resultModelElement = elements[key]
         const color = resultModelElement.color || generateColor(0)
         const name = getLanguageString(resultModelElement.name)
         const description = resultModelElement.description && getLanguageString(resultModelElement.description)
         const modifiedValue = getConvertedAndRoundedValue(resultModelElement, value)

         seriesData.push(cloneDeep({
            name,
            color,
            borderColor: colorLuminance(color, -0.2),
            y: modifiedValue,
         }))

         legendElements.push(
            <div key={name} className="legend">
               <div
                  className="legend-dot"
                  style={{
                     backgroundColor: color,
                     borderColor: colorLuminance(color, -0.2),
                  }}
               />
               <HoverInfo
                  disabled={!description}
                  title={name}
                  description={description}
               >
                  <p>{`${name} ${modifiedValue}%`}</p>
               </HoverInfo>
            </div>
         )
      }

      // Add missing percentages
      let missing = 100.0
      seriesData.forEach((item) => {
         missing -= item.y
      })
      missing = Math.round(missing * 100) / 100
      if (missing > 0) {
         seriesData.push({
            name: 'missing',
            color: '#ECECEC',
            borderColor: colorLuminance('#ECECEC', -0.2),
            y: missing,
         })
      }

      const newOptions = merge(
         cloneDeep(staticChartOptions),
         {
            chart: {
               height: this.state.chartOptions.chart.height,
               animation: !!this.props.animate, // Re-enable animation if animation enabled
            },
            series: { data: seriesData },
         }
      )

      this.setState({
         chartOptions: newOptions,
         legendElements,
      })

   }


   render() {
      const { chartOptions, legendElements } = this.state
      const { elements, item, isMobile } = this.props

      if (!item.result.elements) return null
      // Don't render if there is no data in result for given elements
      let render = false
      Object.keys(elements).forEach((e) => {
         const element = item.result.elements[e]
         if (element != null) render = true
      })
      if (!render) return null

      return (
         <div
            className="pie-chart-container"
            data--is-mobile={isMobile}
         >
            <div className="pie-chart">
               <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
               />
            </div>
            <div className="legends">
               {legendElements}
            </div>
         </div>
      )
   }

}

const mapStateToProps = (state) => ({
   size: state.device.size,
   positions: selectPositionFilters,
   isMobile: state.device.isMobile,
})

export default connect(mapStateToProps)(PieChart)
