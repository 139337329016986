export default class UserToolConfig {

   /**
    * @typedef {Object} ChartsPredefinedConfig
    * @property {string} component
    * @property {boolean} isVisible
    * @property {boolean} isAutoscaling
    * @property {{ min: number, max: number }} scale
    */
   /**
    * @typedef {Object} ChartsCustomConfig
    * @property {string} id
    * @property {string} unit
    * @property {string} title
    * @property {boolean} isVisible
    * @property {boolean} isAutoscaling
    * @property {string[]} elements
    * @property {{ min: number, max: number }} scale
    */
   /**
    * @typedef {Object} ContinuousAnalysisConfig
    * @property {boolean} isLatestImagesCardVisible // TODO: isXXXVisible properties are now for all tools. Move these settings somewhere else
    * @property {boolean} isLatestResultCardVisible
    */

   /**
    * Create User Tool Config
    * @param {{ predefined: ChartsPredefinedConfig[], custom: ChartsCustomConfig[] }} charts
    * @param {ContinuousAnalysisConfig=} continuousAnalysis
    */
   constructor(charts, continuousAnalysis) {
      this.charts = charts
      this.continuousAnalysis = continuousAnalysis
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                       STATIC                       |
   \*__________________________________________________*/

   static fromJson(data) {
      return new UserToolConfig(data.charts, data.continuousAnalysis)
   }

}
