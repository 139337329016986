import { Buffer } from 'buffer'
import User from '../models/user'
import UserToolConfig from '../models/user-tool-config'
import axiosAvaApiClient from '../utils/axiosAvaApiClient'

export default class UserService {

   /**
    * @param {string} userId
    * @param {string} newPassword
    * @param {string} [oldPassword]
    * @returns {Promise}
    */
   static changePassword(userId, newPassword, oldPassword) {
      return new Promise((resolve, reject) => {

         const params = {
            newPassword: Buffer.from(newPassword).toString('base64'),
         }
         if (oldPassword) params.oldPassword = Buffer.from(oldPassword).toString('base64')

         axiosAvaApiClient.post(`/users/${userId}/changePassword`, params)
            .then((response) => {
               resolve(response.data)
            })
            .catch((err) => {
               console.log(err.response.data)
               reject(`Failed to change password: ${err.response.data}`)
               // switch (err.response.status) {
               //    case StatusCodes.CONFLICT:
               //       reject(err.response.data)
               //       break
               //    default:
               //       console.error("Failed to change password: " + err.response.data)
               //       reject("Failed to change password")
               // }
            })
      })
   }

   /**
    * @param {string} userId
    * @returns {Promise<{ id: string, firstName: string, lastName: string }>}
    */
   static async getUser(userId) {
      const response = await axiosAvaApiClient.get(`/users/${userId}`)
      return response.data
   }

   /**
    * @param {string} [accessLevel]
    * @returns {Promise<User[]>}
    */
   static async getUsers(accessLevel) {
      const response = await axiosAvaApiClient.get('/users', { params: { accessLevel } })
      return User.createUsersFromJson(response.data || [])
   }


   static async createUser(username, firstName, lastName, accessLevel, rules) {
      const response = await axiosAvaApiClient.post(`/users/`, {
         username,
         firstName,
         lastName,
         accessLevel,
         rules,
      })
      return response.data
   }

   static editUser(userId, firstName, lastName, accessLevel, rules) {
      return new Promise((resolve, reject) => {

         const params = {}
         if (firstName) params.firstName = firstName
         if (lastName) params.lastName = lastName
         if (accessLevel) params.accessLevel = accessLevel
         if (rules) params.rules = rules

         axiosAvaApiClient.patch(`/users/${userId}`, params)
            .then((result) => {
               const user = User.userFromJson(result.data)
               resolve(user)
            })
            .catch((err) => {
               reject(err)
            })
      })
   }


   static removeUser(userId) {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient
            .delete(`/users/${userId}`)
            .then(resolve)
            .catch((err) => {
               console.log(err.response.data)
               reject('Failed to remove user')
            })
      })
   }


   /* TOOL CONFIG */
   /**
    * @param {string} userId
    * @param {string} tool
    */
   static async getUserToolConfig(userId, tool) {
      const response = await axiosAvaApiClient.get(`/users/${userId}/config/${tool}`)
      return UserToolConfig.fromJson(response.data)
   }

   /**
    * @param {string} userId
    * @param {string} tool
    * @param {{ charts?: { predefined?: any[], custom?: any[] }, continuousAnalysis?: any[] }} config
    */
   static async saveUserToolConfig(userId, tool, config) {
      const params = config
      await axiosAvaApiClient.put(`/users/${userId}/config/${tool}`, params)
   }

   /**
    * @param {*} userToolConfig
    * @param {{
    *   continuousAnalysis?: {
    *      isLatestResultCardVisible?: boolean,
    *      isLatestImagesCardVisible?: boolean,
    *   },
    *   chartsToUpdate?: {
    *      id: string,
    *      isCustomChart: boolean,
    *      isVisible?: boolean,
    *      isAutoscaling?: boolean,
    *      scale?: { min: number, max: number }
    *      showPlotLines: boolean,
    *      showAveragePlotLines: boolean,
    *      showDetailedStatistics: boolean,
    *   }[],
    *   chartsToAdd?: {
    *      id: string,
    *      unit: string,
    *      title: string,
    *      isVisible?: boolean,
    *      elements: string[],
    *      isAutoscaling?: boolean,
    *      scale?: { min: number, max: number },
    *      showPlotLines: boolean,
    *      showAveragePlotLines: boolean,
    *      showDetailedStatistics: boolean,
    *   }[]
    *   chartsToRemove?: string[],
    * }} updates Updates only given optional parameters. "chartsToAdd" and "chartsToRemove" supports only custom chart
    * @param {string} selectedToolId
    * @param {string} userId
    * @param {(updatedConfig: *) => void} [callback] returns updated config before saving it to db
    * @returns {Promise}
    */
   static async editUserToolConfig(userToolConfig, updates, selectedToolId, userId, callback) {
      const { continuousAnalysis, chartsToUpdate, chartsToAdd, chartsToRemove } = updates

      const config = {
         ...userToolConfig,
         charts: {
            ...userToolConfig.charts,
         },
      }

      if (continuousAnalysis) {
         const { isLatestResultCardVisible, isLatestImagesCardVisible } = continuousAnalysis
         config.continuousAnalysis = {
            ...userToolConfig.continuousAnalysis,
         }

         if (isLatestResultCardVisible !== undefined) {
            config.continuousAnalysis.isLatestResultCardVisible = isLatestResultCardVisible
         }

         if (isLatestImagesCardVisible !== undefined) {
            config.continuousAnalysis.isLatestImagesCardVisible = isLatestImagesCardVisible
         }
      }

      if (chartsToUpdate) {
         for (const chartToUpdate of chartsToUpdate) {
            const { id, isCustomChart, isVisible, isAutoscaling, scale, plotLines, showPlotLines, showAveragePlotLines, showDetailedStatistics } = chartToUpdate

            const chartType = isCustomChart ? 'custom' : 'predefined'
            const chartIdField = isCustomChart ? 'id' : 'component'

            config.charts[chartType] = userToolConfig.charts[chartType].filter((c) => (c[chartIdField] !== id))
            const updatedChart = {
               ...userToolConfig.charts[chartType].find((c) => c[chartIdField] === id),
               [chartIdField]: id,
            }
            if (isVisible !== undefined) {
               updatedChart.isVisible = isVisible
            }
            if (isAutoscaling !== undefined) {
               updatedChart.isAutoscaling = isAutoscaling
            }
            // TODO: Save empty scale and plotlines!!!!
            if (scale) {
               updatedChart.scale = scale
            }
            if (plotLines) {
               updatedChart.plotLines = plotLines
            }
            if (showPlotLines !== undefined) {
               updatedChart.showPlotLines = showPlotLines
            }
            if (showAveragePlotLines !== undefined) {
               updatedChart.showAveragePlotLines = showAveragePlotLines
            }
            if (showDetailedStatistics !== undefined) {
               updatedChart.showDetailedStatistics = showDetailedStatistics
            }
            config.charts[chartType].push(updatedChart)
         }
      }

      if (chartsToAdd) {
         for (const chartToAdd of chartsToAdd) {
            config.charts.custom.push(chartToAdd)
         }
      }

      if (chartsToRemove) {
         for (const id of chartsToRemove) {
            config.charts.custom = config.charts.custom.filter((c) => c.id !== id)
         }
      }

      if (callback) callback(config)

      await this.saveUserToolConfig(userId, selectedToolId, config)
   }

   /**
    * Converts user chart config model to result component model (See tools-config resultModels -> components)
    * @param {*} chartConfig
    * @param {*} [predefinedModel] required if predefined chart
    * @returns {*} resultComponentModel
    */
   static userChartConfigToResultComponentModel(chartConfig, predefinedModel) {
      const { id, unit, title, isVisible, isAutoscaling, elements, scale, plotLines, showPlotLines, showAveragePlotLines, showDetailedStatistics } = chartConfig || { isVisible: true }
      const isCustomChart = !predefinedModel
      if (!id && !predefinedModel) throw new Error('"predefinedChart" parameter is required when transforming predefined chart config to ResultComponentModel')

      let defaultType

      const model = {
         chart: {
            isVisible: !!isVisible,
         },
         isCustomChart: !!isCustomChart,
      }

      // User generated chart properties
      if (isCustomChart) {
         defaultType = chartConfig.defaultType,
         model.chart.defaultType = defaultType
         model.chart.types = chartConfig.types || [defaultType],
         model.id = id
         model.unit = unit
         model.chart.title = title
         model.elements = elements

         // All but boolean chart
         if (defaultType !== 'boolean') {
            model.chart.autoscale = !!isAutoscaling
            model.chart.editedScale = scale
            model.chart.editedPlotLines = plotLines
            model.chart.showPlotLines = !!showPlotLines
            model.chart.showAveragePlotLines = !!showAveragePlotLines
            model.chart.showDetailedStatistics = !!showDetailedStatistics
         }
      }

      // Predefined chart properties
      else {
         defaultType = predefinedModel.chart.defaultType
         model.chart.defaultType = defaultType
         model.chart.types = predefinedModel.chart.types,
         model.id = predefinedModel.id
         model.unit = predefinedModel.unit
         model.elements = predefinedModel.elements
         model.chart.title = predefinedModel.chart.title

         // All but boolean chart
         if (defaultType !== 'boolean') {
            model.chart.autoscale = !!(isAutoscaling ?? predefinedModel.chart.autoscale) // TODO: Or only !!isAutoscaling
            model.chart.defaultScale = predefinedModel.chart.scale
            model.chart.editedScale = scale
            model.chart.defaultPlotLines = predefinedModel.chart.plotLines
            model.chart.editedPlotLines = plotLines
            model.chart.showPlotLines = showPlotLines ?? predefinedModel.chart.showPlotLines
            model.chart.showAveragePlotLines = !!showAveragePlotLines
            model.chart.showDetailedStatistics = !!showDetailedStatistics
         }
      }

      return model
   }


   /* PREINITIALIZED USERS */
   static getPreinitializedUsers() {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.get('/preInitializedUsers')
            .then((response) => {
               if (!response.data) return resolve([])
               resolve(response.data)
            })
            .catch((err) => {
               console.error('Failed to get pre initialized users', err)
               reject('Failed to get pre initialized users')
            })
      })
   }

   static addPreinitializedUsers(data) {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.post('/preInitializedUsers', data)
            .then((response) => resolve(response.data))
            .catch((err) => {
               console.error('Failed to add pre initialized users', err)
               reject('Failed to add pre initialized users')
            })
      })
   }

   static deletePreinitializedUsers(users) {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.delete('/preInitializedUsers', { data: { usernames: users } })
            .then(() => resolve())
            .catch((err) => {
               console.error('Failed remove preinitialized users', err.response)
               reject('Failed remove preinitialized users')
            })
      })
   }

}
