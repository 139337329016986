import { cloneDeep } from 'lodash'
import { SHOW_LATEST_RESULT, CLEAR_RESULT_COMPONENTS, SET_RESULT_COMPONENT_MODELS, ADD_RESULT_COMPONENT_MODEL, SHOW_LATEST_IMAGES, SET_EXCLUSIVE_RENDERING, REMOVE_RESULT_COMPONENT_MODEL, UPDATE_RESULT_COMPONENT_MODEL_CHART } from '../actions/types'

const initialState = {
   showLatestResult: false,
   showLatestImages: false,
   models: [],
   exclusiveRendering: false,
}

/*
   show latest analyzed item card
   showLatestResult: bool

   show latest analysis images
   showLatestImages: bool

   Models for rendering ui elements e.g. charts
   models: [{Object}]

   exclusiveRendering: Render only exclusive components and prevents others from rendering. If set to true, component itself knows if it should render (e.g. if card is fullscreen it should render and exlusive rendering prevents other components from rendering)
*/

const resultComponentsReducer = (state = initialState, action) => {
   let newState

   switch (action.type) {

      case SET_RESULT_COMPONENT_MODELS:
         newState = cloneDeep(state)
         newState.models = action.payload
         return newState

      case ADD_RESULT_COMPONENT_MODEL:
         newState = cloneDeep(state)
         newState.models.push(action.payload)
         return newState

      case UPDATE_RESULT_COMPONENT_MODEL_CHART:
         const index = state.models.findIndex((model) => model.id === action.payload.id)
         if (index === -1) return state
         newState = cloneDeep(state)
         newState.models[index].chart = action.payload.chart
         return newState

      case REMOVE_RESULT_COMPONENT_MODEL:
         newState = cloneDeep(state)
         newState.models = newState.models.filter((model) => (model.id !== action.payload))
         return newState

      case SHOW_LATEST_RESULT:
         newState = cloneDeep(state)
         newState.showLatestResult = action.payload
         return newState

      case CLEAR_RESULT_COMPONENTS:
         return initialState

      case SHOW_LATEST_IMAGES:
         newState = cloneDeep(state)
         newState.showLatestImages = action.payload
         return newState

      case SET_EXCLUSIVE_RENDERING:
         newState = cloneDeep(state)
         newState.exclusiveRendering = action.payload
         return newState

      default:
         return state

   }
}

export default resultComponentsReducer
