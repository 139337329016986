import React, { Component } from 'react'
import './loading-indicator.css'
import PropTypes from 'prop-types'

export default class LoadingIndicator extends Component {

   static propTypes = {
      text: PropTypes.string,
   }

   render() {

      const { text } = this.props

      return (
         <div className="loading-indicator">
            { text && <span>{text}</span>}
            <span className="l-1"></span>
            <span className="l-2"></span>
            <span className="l-3"></span>
            <span className="l-4"></span>
            <span className="l-5"></span>
            <span className="l-6"></span>
         </div>
      )
   }

}
