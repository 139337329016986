export default class ContinuousAnalysis {

   /**
    * Create a ContinuousAnalysis
    * @param {Buffer} id UUID Buffer
    * @param {Buffer} site UUID Buffer
    * @param {string} tool
    * @param {Buffer} unit UUID Buffer
    * @param {Buffer} position UUID Buffer
    * @param {Object} parameters Values for additional parameters that are defined in tools-config for continuous analysis
    * @param {boolean} isAdditionalConfigurationRequired If additional configuration is required before CA can be started
    * @param {{ userId: string, timestamp: string }} [startMetadata]
    * @param {{ userId: string, timestamp: string, reason: string }} [stopMetadata]
    */
   constructor(id, site, tool, unit, position, parameters, isAdditionalConfigurationRequired, startMetadata, stopMetadata) {
      this.id = id
      this.site = site
      this.tool = tool
      this.unit = unit
      this.position = position
      this.parameters = parameters
      this.isAdditionalConfigurationRequired = isAdditionalConfigurationRequired
      this.startMetadata = startMetadata
      this.stopMetadata = stopMetadata
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                       STATIC                       |
   \*__________________________________________________*/

   /**
    * Creates ContinuousAnalyses from json array
    * @param {Object[]} jsonArr
    * @returns {ContinuousAnalysis[]}
    */
   static fromJsonArray(jsonArr) {
      return jsonArr
         .map((json) => ContinuousAnalysis.fromJson(json))
         .filter((ca) => ca)
   }

   /**
    * Creates ContinuousAnalysis from json
    * @param {Object} json
    * @returns {ContinuousAnalysis|null}
    */
   static fromJson(json) { // TODO: add validation
      const { id, site, tool, unit, position, parameters, isAdditionalConfigurationRequired, startMetadata, stopMetadata } = json
      return new ContinuousAnalysis(id, site, tool, unit, position, parameters, isAdditionalConfigurationRequired, startMetadata, stopMetadata)
   }


   /**
    * @param {ContinuousAnalysis} continuousAnalysis
    * @param {import('../store/reducers/continuous-analysis-reducer').ContinuousAnalysisPositionState} positionState
    */
   static getStatus(continuousAnalysis, positionState) {
      if (!positionState || positionState.status === ContinuousAnalysis.Status.LOADING) return ContinuousAnalysis.Status.LOADING
      if (!continuousAnalysis || continuousAnalysis.isAdditionalConfigurationRequired) return 'not configured'
      return positionState.status
   }

}

/**
 * @readonly
 * @enum {string}
 */
ContinuousAnalysis.Status = Object.freeze({
   LOADING: 'loading',
   START_REQUESTED: 'start-requested',
   STOP_REQUESTED: 'stop-requested',
   STARTING: 'starting',
   RUNNING: 'running',
   PAUSED: 'paused',
   NOSOURCE: 'nosource',
   STOPPING: 'stopping',
   STOPPED: 'stopped',
   FAILED: 'failed',
})

ContinuousAnalysis.StatusTextToColor = Object.freeze({
   [ContinuousAnalysis.Status.LOADING]: '#989898',
   [ContinuousAnalysis.Status.START_REQUESTED]: '#989898',
   [ContinuousAnalysis.Status.STOP_REQUESTED]: '#989898',
   [ContinuousAnalysis.Status.STARTING]: '#989898',
   [ContinuousAnalysis.Status.RUNNING]: '#0075BE',
   [ContinuousAnalysis.Status.PAUSED]: '#989898',
   [ContinuousAnalysis.Status.NOSOURCE]: '#be0000',
   [ContinuousAnalysis.Status.STOPPING]: '#989898',
   [ContinuousAnalysis.Status.STOPPED]: '#be0000',
   [ContinuousAnalysis.Status.FAILED]: '#be0000',
})
