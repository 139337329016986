/* eslint-disable max-classes-per-file */
import memoizeOne from 'memoize-one'
import { dateToString } from '../utils/Helper'
import AnalysisErrorCodes from './analysis-error-codes.json'


export default class Analysis {

   /**
    * Create an Analysis.
    * @param {string} id UUID
    * @param {string} site UUID
    * @param {string} unit UUID
    * @param {string} tool
    * @param {string} version
    * @param {string} author
    * @param {string[]} tags array of UUIDs
    * @param {Date} timestamp Custom timestamp for analysis or earliest item.dateTaken
    * @param {Date} dateAnalyzed The time first item is analyzed
    * @param {string} comment
    * @param {string[]} positions items positions (UUIDs)
    * @param {AnalysisItem[]} items
    */
   constructor(id, site, unit, tool, version, author, tags, timestamp, dateAnalyzed, comment, positions, items) {
      this.id = id
      this.version = version
      this.site = site
      this.unit = unit
      this.tool = tool
      this.tags = tags || []
      this.dateAnalyzed = dateAnalyzed && new Date(dateAnalyzed)
      this.timestamp = timestamp && new Date(timestamp)
      this.comment = comment
      this.author = author
      // this.dateUpdated = dateUpdated
      this.positions = positions
      this.items = items && items.map((item) => {
         if (item.dateTaken) item.dateTaken = new Date(item.dateTaken)
         if (item.dateAnalyzed) item.dateAnalyzed = new Date(item.dateAnalyzed)
         return item
      })
      this.getDateAnalyzedString = memoizeOne(this._getDateAnalyzedString)
      this.getTimestampString = memoizeOne(this._getTimestampString)
   }

   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                      METHODS                       |
   \*__________________________________________________*/

   _getDateAnalyzedString(showSeconds, timeZone) {
      if (!this.dateAnalyzed) return null
      if (showSeconds) {
         return dateToString(this.dateAnalyzed, timeZone, true, true, true)
      }
      return dateToString(this.dateAnalyzed, timeZone, true)

   }

   _getTimestampString(showSeconds, timeZone) {
      if (!this.timestamp) return null
      if (showSeconds) {
         return dateToString(this.timestamp, timeZone, true, true, true)
      }
      return dateToString(this.timestamp, timeZone, true)

   }


   isImageKept(imageId) {
      if (!this.items) return undefined
      for (const item of this.items) {
         if (item.imageId === imageId) {
            if (!item.imageMetadata) return undefined
            return item.imageMetadata.isKept
         } if (item.result && item.result.imageId === imageId) {
            if (!item.result.imageMetadata) return undefined
            return item.result.imageMetadata.isKept
         }
      }
   }

   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                       STATIC                       |
   \*__________________________________________________*/

   /**
    * Get analysis item image URL
    * @param {string} baseUrl
    * @param {string} fileId
    * @returns {string}
    */
   static getFileUrl(baseUrl, fileId) {
      return `${baseUrl}/api/images/${fileId}`
   }

   /**
    * @param {string} baseUrl
    * @param {string} fileId
    * @returns {string}
    */
   static getFileThumbUrl(baseUrl, fileId) {
      return `${baseUrl}/api/thumbnails/${fileId}`
   }



   /**
    * Creates array of Analyses from jsonArray
    * @param {Object[]} array
    * @returns {Analysis[]}
    */
   static fromJsonArray(array) {
      const analyses = []
      array?.forEach((jsonAnalysis) => {
         const analysis = Analysis.fromJson(jsonAnalysis)
         if (analysis) analyses.push(analysis)
      })
      return analyses
   }

   /**
    * Creates Analysis from jsonObject
    * @param {Object} json
    * @returns {Analysis}
    */
   static fromJson(json) {

      if (!json.timestamp || !json.id || !json.site || !json.unit) { // TODO: if validation is needed, improve it. Otherwise validate only in api
         console.log('Required parameter missing')
         return false
      }

      const items = []
      for (const jsonItem of json.items) {
         const item = AnalysisItem.fromJson(jsonItem)
         if (item) items.push(item)
      }

      return new Analysis(
         json.id,
         json.site,
         json.unit,
         json.tool,
         json.version,
         json.author,
         json.tags,
         new Date(json.timestamp),
         json.dateAnalyzed ? new Date(json.dateAnalyzed) : null,
         json.comment,
         json.positions,
         items
      )
   }


   /**
    * Get analysis result error code
    * @param {Object} error
    * @returns {string}
    */
   static getErrorMessage(error) {

      const errors = AnalysisErrorCodes

      if (!errors[error.code]) return error.code
      let message = errors[error.code]
      const messageParams = message.match(/\{(.*?)\}/g)

      if (messageParams) {
         for (const mp of messageParams) {
            const param = mp.replace('{', '').replace('}', '')
            message = message.replace(mp, error[param])
         }
      }
      return message
   }

}



/*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
|                                                    |
|                   ANALYSIS ITEM                    |
|                                                    |
\*__________________________________________________*/

/**
 * Class representing a single analysed item in an {@link Analysis}
 */
export class AnalysisItem {

   /**
    * Create an AnalysisItem.
    * @param {Date} dateTaken
    * @param {Date} dateAnalyzed
    * @param {string} imageId
    * @param {object} imageMetadata
    * @param {string} position
    * @param {object} result
    * @param {string} warning
    * @param {object} error
    * @param {string} exception
    */
   constructor(dateTaken, dateAnalyzed, imageId, imageMetadata, position, result, warning, error, exception) {
      this.dateTaken = dateTaken
      this.dateAnalyzed = dateAnalyzed
      this.imageId = imageId
      this.imageMetadata = imageMetadata
      this.position = position
      this.result = result
      this.warning = warning
      this.error = error // { code: String, <any>: String }
      this.exception = exception
   }

   hasOriginalImage() {
      return !!(this.imageId && this.imageMetadata)
   }

   hasResultImage() {
      return !!(this.result && this.result.imageId && this.result.imageMetadata)
   }

   hasOriginalImageThumbnail() {
      return !!this.imageId
   }

   hasResultImageThumbnail() {
      return !!(this.result && this.result.imageId)
   }


   /**
    * Creates AnalysisItem from jsonObject
    * @param {Object} json
    * @returns {AnalysisItem}
    */
   static fromJson(json) {

      if (!json.dateTaken || !json.dateAnalyzed || !json.position) {
         console.log('Required parameter missing for AnalysisItem')
         return
      }

      return new AnalysisItem(
         new Date(json.dateTaken),
         new Date(json.dateAnalyzed),
         json.imageId,
         json.imageMetadata ? AnalysisImageMetadata.fromJson(json.imageMetadata) : null,
         json.position,
         json.result,
         json.warning,
         json.error,
         json.exception
      )
   }

}


export class AnalysisImageMetadata {

   /**
    * Create an AnalysisImageMetadata.
    * @param {string} analysisId
    * @param {string} contentType
    * @param {boolean} isResult
    * @param {boolean} isKept
    */
   constructor(analysisId, contentType, isResult, isKept) {
      this.analysisId = analysisId
      this.contentType = contentType
      this.isResult = !!isResult
      this.isKept = !!isKept
   }


   /**
    * Creates AnalysisImageMetadata from Json Object
    * @param {Object} json
    * @returns {AnalysisImageMetadata}
    */
   static fromJson(json) {
      if (!json.analysisId) {
         console.log('Required parameter missing for AnalysisImageMetadata')
         return
      }
      return new AnalysisImageMetadata(json.analysisId, json.contentType, json.isResult, json.isKept)
   }

}
