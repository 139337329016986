import { CLEAR_USER, CLEAR_USER_TOOL_CONFIG, SET_USER_TOOL_CONFIG } from '../actions/types'

const initialState = {}

const userToolConfigReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_USER_TOOL_CONFIG:
         return action.payload

      case CLEAR_USER_TOOL_CONFIG:
      case CLEAR_USER:
         return {}

      default:
         return state

   }
}

export default userToolConfigReducer
