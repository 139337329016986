import { SELECT_UNIT } from '../actions/types'

const initialState = null

const SelectedUnitReducer = (state = initialState, action) => {
   switch (action.type) {
      case SELECT_UNIT:
         return action.payload
      default:
         return state

   }
}

export default SelectedUnitReducer
