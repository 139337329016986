import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './dropdown.css'


export default class Dropdown extends Component {

   constructor(props) {
      super(props)

      this.state = {
         origButton: this.props.button,
         buttonRef: React.createRef(),
      }

      this.buttonDom = undefined
      const button = React.cloneElement(
         this.props.button,
         { ref: this.state.buttonRef }
      )
      this.dropdownDom = undefined
      this.dropdownRef = React.createRef()

      this.state.button = button
      this.anywhereClickListener = undefined
   }

   static getDerivedStateFromProps(props, state) {
      if (props.button !== state.button) { return {
         button: React.cloneElement(
            props.button,
            { ref: state.buttonRef }
         ),
      } }
      return null
   }

   closeDropdown = () => {
      this.dropdownDom.removeAttribute('data-dropdown-visible')
      if (this.anywhereClickListener) {
         window.removeEventListener('click', this.anywhereClickListener)
         this.anywhereClickListener = undefined
      }
   }

   componentDidMount() {
      // eslint-disable-next-line react/no-find-dom-node
      this.dropdownDom = ReactDOM.findDOMNode(this.dropdownRef.current) // TODO: Don't use ReactDOM.findDOMNode
      // eslint-disable-next-line react/no-find-dom-node
      this.domButton = ReactDOM.findDOMNode(this.state.buttonRef.current)

      this.domButton.addEventListener('click', () => {
         if (this.dropdownDom.getAttribute('data-dropdown-visible') === 'true') {
            this.closeDropdown()
         } else {
            this.dropdownDom.setAttribute('data-dropdown-visible', 'true')
            this.setState({
               dropdownWidth: this.dropdownRef.current.clientWidth,
            })
            window.addEventListener('click', this.anywhereClickListener = ((e) => {
               let target = e.target
               while (target) {
                  if (target.isSameNode(this.domButton.parentElement)) {
                     e.stopPropagation()
                     break
                  }
                  target = target.parentElement
               }
               if (target === null) {
                  this.closeDropdown()
               }
            }))
         }
      })
   }

   componentWillUnmount() {
      if (this.anywhereClickListener) {
         window.removeEventListener('click', this.anywhereClickListener)
      }
   }

   render() {
      const { id, menuItems, selected, onSelect, className, disabled, customComponent, width, direction, alignRight, dropdownToButtonWidth, menuStyle, itemStyle } = this.props

      let classN = 'custom-dropdown'
      if (className) classN += ` ${className}`

      // Transform (alignRight & direction)
      let transform
      if (alignRight && this.domButton) {
         transform = `translateX(${-(this.state.dropdownWidth - this.domButton.clientWidth)}px)`
      }
      if (direction === 'left') {
         transform = `translateX(${-this.state.dropdownWidth + 20}px)`
      } else if (direction === 'right' && this.domButton) {
         transform = `translateX(${this.domButton.clientWidth - 20}px)`
      }

      // Render
      return (
         <div
            id={id}
            className={classN}
            style={disabled ? { pointerEvents: 'none', backgroudColor: 'red' } : {}}
         >
            { this.state.button }
            <div style={{ position: 'relative' }}>
               <div
                  className={'custom-dropdown-menu'}
                  style={{
                     transform,
                     width: (dropdownToButtonWidth && this.domButton)
                        ? this.domButton.offsetWidth
                        : width,
                     ...menuStyle,
                  }}
                  ref={this.dropdownRef}
               >
                  {/* MENU ITEMS */}
                  { menuItems && menuItems.map((options, index) => {
                     if (options.hidden) return null
                     // Section (title)
                     if (options.section !== undefined) {
                        return (
                           <span key={index}>
                              { options.section
                                 && <p className="dropdown-section-title">{ options.section }</p>
                              }
                              <div className="dropdown-divider" />
                           </span>
                        )
                     }
                     // Menu item
                     return (
                        <p
                           key={index}
                           className={classNames('custom-dropdown-item', { disabled: options.disabled, selected: options.value === selected })}
                           style={itemStyle}
                           onClick={() => {
                              if (options.disabled !== true && onSelect) {
                                 this.closeDropdown()
                                 onSelect(options.value)
                              }
                           }}
                           data-type={options.type}
                        >
                           { options.label }
                        </p>
                     )

                  })}


                  {/* CUSTOM COMPONENT*/}
                  {customComponent
                     && <div className="dropdown-custom-component">
                        {customComponent}
                     </div>
                  }


               </div>
            </div>
         </div>
      )
   }



   static propTypes = {
      id: PropTypes.string,
      selected: PropTypes.any,
      onSelect: PropTypes.func,
      button: PropTypes.object.isRequired,
      menuItems: PropTypes.array,
      dropdownArrow: PropTypes.bool,
      alignRight: PropTypes.bool,
      direction: PropTypes.string, // "left", "right", "bottom" (default)
      disabled: PropTypes.bool,
      customComponent: PropTypes.any,
      dropdownToButtonWidth: PropTypes.bool,
      menuStyle: PropTypes.object,
      itemStyle: PropTypes.object,
   }

   static defaultProps = {
      alignRight: false,
      direction: 'bottom',
   }

}
