import { batch } from 'react-redux'
import { clearData, selectTool } from './data-actions'
import { setPositions, setTags, setUnits } from './setup-actions'
import { selectSiteSuccess } from './site-actions'
import { setToolboxes, setTools } from './toolbox-actions'
import { CLEAR_USER, SET_USER } from './types'


export const setUser = (user) => ({
   type: SET_USER,
   payload: user,
})

export const clearUser = () => ({
   type: CLEAR_USER,
})


export function clearLocalSession() {
   return (dispatch) => {
      batch(() => {
         dispatch(clearUser())
         dispatch(clearData())
         dispatch(setTools([]))
         dispatch(selectTool(null))
         dispatch(selectSiteSuccess(null))
         dispatch(setUnits([]))
         dispatch(setTags([]))
         dispatch(setPositions([]))
         dispatch(setToolboxes([]))
      })
   }
}
