import { CLEAR_DATA, SET_TIMEFRAME_SELECTION, UPDATE_TIMEFRAME_SELECTION } from '../actions/types'

/**
 * Timeframe selection for TimeFrameSlider
 * If single point is only wanted to be selected, leave to value to undefined
 *
 * @typedef {object} TimeframeSelection
 * @property {Date} from
 * @property {Date} [to]
 */
const initialState = null


/**
 * @type {import("./root-reducer").ReduxReducer<TimeframeSelection>}
 */
const timeframeSelectionReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_TIMEFRAME_SELECTION:
         return {
            from: new Date(action.payload.from),
            to: new Date(action.payload.to),
         }

      case UPDATE_TIMEFRAME_SELECTION:
         const updated = { ...state }
         const { from, to } = action.payload
         if (from) updated.from = new Date(from)
         if (to) updated.to = new Date(to)
         return updated

      case CLEAR_DATA:
         return null

      default:
         return state

   }
}

export default timeframeSelectionReducer
