export default class Tag {

   /**
    * Create a Tag
    * @param {string} id
    * @param {string} name
    * @param {string} tool
    * @param {bool} isLocal
    */
   constructor(id, name, tool, isLocal = false) {
      this.id = id
      this.name = name
      this.tool = tool
      this.isLocal = isLocal
   }


   /**
    * Creates Tag from Json Object
    * @param {Object} json
    * @returns {Tag}
    */
   static fromJson(json) {
      if (!json) return null
      return new Tag(json.id, json.name, json.tool)
   }

   /**
    * Generates id for "has images" tag
    * @param toolId
    * @returns {string}
    */
   static getHasImagesTagId(toolId) {
      return `${toolId}_has_images`
   }

   /**
    * Generates local "has images" tag
    * @param toolId
    * @returns {Tag}
    */
   static getHasImagesTag(toolId) {
      return new Tag(this.getHasImagesTagId(toolId), '#has_images', toolId, true)
   }


}
