import Analysis from '@ava/react-common/models/analysis'
import DataService from '@ava/react-common/services/data-service'
import { showDialog } from '@ava/react-common/store/actions/dialog-actions'
import { selectToolsPositions } from '@ava/react-common/store/reducers/root-reducer'
import { errorToMessage } from '@ava/react-common/utils'
// import axiosAvaApiClient from '@ava/react-common/utils/axiosAvaApiClient'
import { getConvertedAndRoundedValue, getLanguageString, loadImage } from '@ava/react-common/utils/Helper'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keepImages } from '../../../actions/local-data-actions'
import StarRegular from '../../../assets/star-regular.svg'
import StarSolid from '../../../assets/star-solid.svg'
import Button from '../../Button/button'
import Card from '../../Card/card'
import PieChart from '../../Charts/pie-chart'
import HoverInfo from '../../Hoverable/hover-info'
import Image from '../../Image/image'
import './analysis-position.css'


/**
 * @param {object} props
 * @param {*} props.resultModel
 * @param {(imageId: string, fileType: ('image'|'video'), fileContentType: string, analysisId: string) => void} props.selectImage
 * @param {boolean} props.showNoImagePlaceholders
 * @returns {import('react').FunctionComponentElement}
 */
export default function AnalysisPosition({ item, resultModel, selectImage, showNoImagePlaceholders }) {
   const positions = useSelector((state) => state.positions)
   const isMobile = useSelector((state) => state.device.isMobile)
   const toolsPositions = useSelector((state) => selectToolsPositions(state))

   if (!resultModel || !resultModel.elements || !resultModel.components) return null
   const elements = resultModel.elements
   const components = resultModel.components

   const getImages = () => {

      const getFileUrl = (src, thumbnail) => {
         if (thumbnail) return Analysis.getFileThumbUrl(process.env.PUBLIC_URL, src)
         return Analysis.getFileUrl(process.env.PUBLIC_URL, src)
      }

      const images = []
      if (item.imageId || showNoImagePlaceholders) {
         const hasThumbnail = item.hasOriginalImageThumbnail()
         const hasImage = item.hasOriginalImage()
         let src
         if (hasThumbnail || hasImage || showNoImagePlaceholders) {
            if (!item.imageId) src = null
            else if (isMobile) src = getFileUrl(item.imageId, !hasImage) // Use full sized images always on mobile
            else src = getFileUrl(item.imageId, true)
            const fileContentType = item.imageMetadata?.contentType
            const fileType = fileContentType?.split('/')[0]
            images.push(
               <ImageBox
                  key="orig-img"
                  title="Original"
                  src={src}
                  item={item}
                  type={fileType}
                  contentType={fileContentType}
                  onClick={item.imageMetadata && (() => selectImage(item.imageId, fileType, fileContentType, item.imageMetadata.analysisId))}
                  isMobile={isMobile}
               />
            )
         }
      }
      if (item.result?.imageId || showNoImagePlaceholders) {
         let src
         const hasThumbnail = item.hasResultImageThumbnail()
         const hasImage = item.hasResultImage()
         if (hasThumbnail || hasImage || showNoImagePlaceholders) {
            if (!item.result?.imageId) src = null
            else if (isMobile) src = getFileUrl(item.result?.imageId, !hasImage) // Use full sized images always on mobile
            else src = getFileUrl(item.result?.imageId, true)
            const fileContentType = item.result?.imageMetadata?.contentType
            const fileType = fileContentType?.split('/')[0]
            images.push(
               <ImageBox
                  key="analyzed-img"
                  title="Analyzed"
                  src={src}
                  item={item}
                  isResult={true}
                  type={fileType}
                  contentType={fileContentType}
                  onClick={item.result?.imageMetadata && (() => selectImage(item.result.imageId, fileType, fileContentType, item.result.imageMetadata.analysisId))}
                  isMobile={isMobile}
               />
            )
         }
      }
      return images
   }

   /* Generate result components */
   const resultComponents = []
   if (item.result && elements && components) {
      components.forEach((component, index) => {
         if (!component.elements) return
         // LIST
         if (component.list) {
            resultComponents.push(
               <ListBox
                  key={`result-components-list-${index}`}
                  component={component}
                  item={item}
                  elements={elements}
                  isMobile={isMobile}
               />
            )
         }
         // PIE CHART
         if (component.pieChart) {
            resultComponents.push(
               <PieChartBox
                  key={`pie-chart-box-${index}`}
                  component={component}
                  elements={elements}
                  item={item}
                  isMobile={isMobile}
               />
            )
         }

         // COLUMN CHART - columnChart has been moved as separate chart - enable when this is needed
         // if (component.columnChart) {
         //    // Check if value (currently woodscan does not have diameter for every site)
         //    var resultsExist = false
         //    if (item.result && item.result.elements && component.elements && component.elements.length !== 0) {
         //       component.elements.forEach(key => {
         //          if (item.result.elements[key]) resultsExist = true
         //       })
         //    }

         //    if (resultsExist) {
         //       resultComponents.push(
         //          <Box maxWidth="600px" minWidth='500px' isMobile={isMobile} key={"column-chart-box-" + index}>
         //             <ColumnChart
         //                modelElements={elements}
         //                resultElements={item.result && item.result.elements}
         //                component={component}
         //             />
         //          </Box>
         //       )
         //    }
         // }

      })
   }

   const showTitles = toolsPositions.length > 1
   const position = positions[item.position]
   const title = (position && position.name) ? position.name : '-'

   /* MOBILE */
   if (isMobile) {
      return (
         <Card className="analysis-position" title={title}>
            <h4>Result</h4>
            {(item.error || item.exception) && <ErrorBox item={item} isMobile={true} /> }
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
               {resultComponents.map((box) => box) }
            </div>
            {getImages()}
         </Card>
      )
   }

   /* DESKTOP */
   return (
      <div className="analysis-position" style={{ width: 'calc(100% + 16px)', height: '' }}>
         <hr style={{ marginTop: '16px' }} />
         {showTitles && <h4 style={{ color: '#989898', fontWeight: 300, fontSize: 18 }}>{title}</h4>}
         <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-8px', marginRight: '-8px', marginBottom: '-16px', backgroundColor: 'light-gray', width: '100%' }}>
            {getImages()}
            { resultComponents.map((box) => box) }
            {(item.error || item.exception) && <ErrorBox item={item} />}
         </div>


      </div>
   )
}


const Box = ({ className, children, minWidth, maxWidth, style = {}, isMobile = false }) => {
   if (isMobile) return children

   return (
      <div
         className={className}
         style={{
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingBottom: '16px',
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 0,
            minWidth,
            maxWidth,
            ...style,
         }}
      >
         {children}
      </div>
   )
}

const ErrorBox = ({ item, isMobile = false }) => (
   <Box isMobile={isMobile} style={{ minWidth: 460 }}>
      { item.error && (
         <div className="flex-row" style={{ alignItems: 'flex-start', marginBottom: 12 }}>
            <p className="error-message-title">Error: </p>
            <p>{Analysis.getErrorMessage(item.error)}</p>
         </div>
      )}
      { item.exception && (
         <div className="flex-row" style={{ alignItems: 'flex-start' }}>
            <p className="error-message-title">Exception: </p>
            <p>{item.exception}</p>
         </div>
      )}
   </Box>
)


const ImageBox = ({ src, type, item, title, onClick, isMobile, isResult, contentType }) => {
   const [error, setError] = useState('')
   const [isThumbnailLoaded, setThumbnailLoaded] = useState(false)
   const selectedToolId = useSelector((state) => state.selectedTool)
   const dispatch = useDispatch()

   useEffect(() => {
      if (src && !isMobile) {
         loadImage(src)
            .then(() => setThumbnailLoaded(true))
            .catch(() => setError('Failed to load'))
      }
   }, [type, src, isMobile])

   const isImgKept = isResult
      ? !!(item.result?.imageMetadata?.isKept)
      : !!(item.imageMetadata?.isKept)

   if (isMobile) {
      return (
         <div className="mobile-img-container">
            <h4>{title}</h4>
            {(src && !error) ? (<>
               {(type === 'image') && <Image alt={title} src={src} />}
               {(type === 'video') && (
                  <video style={{ width: '100%', maxHeight: 320, background: 'black' }} controls>
                     <source src={src} type={contentType} />
                  </video>
               )}
            </>) : (
               <div>{ error || `No ${type}` }</div>
            )}
         </div>
      )
   }

   return (
      <Box
         className="image-box"
         minWidth="252px"
         maxWidth="440px"
         style={{ minHeight: 186 }}
      >
         {(isResult ? item.hasResultImage() : item.hasOriginalImage()) && (
            <Button
               className={classNames('btn-keep', { 'btn-keep--is-kept': isImgKept })}
               styleType={Button.StyleType.ICON}
               iconSrc={isImgKept ? StarSolid : StarRegular}
               onClick={async () => {
                  const key = isResult ? item.result.imageId : item.imageId
                  await DataService
                     .keepImages({ [key]: !isImgKept })
                     .then((imageMetadata) => dispatch(keepImages(selectedToolId, imageMetadata)))
                     .catch((err) => {
                        dispatch(showDialog({
                           title: 'Failed to set images to be kept',
                           text: errorToMessage(err),
                        }))
                     })
               }}
            />
         )}
         <div className="image-container"
            style={{
               cursor: onClick ? 'pointer' : undefined,
               width: '100%',
               maxWidth: '424px',
               height: '100%',
               display: 'flex',
            }}
            onClick={onClick}
         >
            {(src && !error) ? (
               <div className={classNames('image-holder', { 'image-holder--is-loaded': isThumbnailLoaded })}>
                  <Image
                     src={src}
                     className="image-holder__image-thumbnail"
                     alt=""
                     style={{ maxHeight: '170px' }}
                  />
               </div>
            ) : (
               <div style={{ color: '#ffffffaa', margin: 'auto' }}>
                  { error || `No ${type}` }
               </div>
            )}
         </div>
      </Box>
   )
}
ImageBox.propTypes = {
   title: PropTypes.string,
   item: PropTypes.object.isRequired,
   src: PropTypes.string,
   isMobile: PropTypes.bool,
   isResult: PropTypes.bool,
   onClick: PropTypes.func,
}

// TODO: Set fixed width to pie chart to prevent card size change when first item is selected
const PieChartBox = ({ component, elements, item, isMobile }) => {
   const chartElements = {}
   component.elements.forEach((e) => {
      if (elements[e]) {
         chartElements[e] = elements[e]
      } else {
         console.error('Requested element is not defined in result model')
      }
   })

   return (
      <Box maxWidth="412px" minWidth="220px" isMobile={isMobile}>
         <PieChart
            item={item}
            elements={chartElements}
            animate={false}
         />
      </Box>
   )
}


const ListBox = ({ component, elements, item, isMobile }) => {

   const listElements = []
   component.elements.forEach((e) => {
      if (elements[e]) {
         listElements.push(e)
      } else {
         console.error('Requested element is not defined in result model')
      }
   })

   return (
      <Box
         style={{
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateRows: 'repeat(8, 0fr)',
            flex: 'none',
         }}
         isMobile={isMobile}
      >
         {listElements.map((e, index) => {
            const resultModelElement = elements[e]
            const label = getLanguageString(resultModelElement.name)
            const description = resultModelElement.description && getLanguageString(resultModelElement.description)
            const origValue = item.result.elements[e]
            let unit = resultModelElement.convertedUnit || (resultModelElement.unit || '')
            if (unit === 'status') unit = ''
            const modifiedValue = getConvertedAndRoundedValue(resultModelElement, origValue)
            const valueString = (modifiedValue != null)
               ? `${modifiedValue} ${unit}`
               : '-'
            const pStyle = isMobile
               ? { minWidth: '110px', flexShrink: 0, fontSize: '14px' }
               : { width: '110px', flexShrink: 0, fontSize: '14px' }

            return (
               <div
                  className="flex-row"
                  style={{
                     marginBottom: '1px',
                     marginRight: '24px',
                     alignItems: 'flex-start',
                     maxWidth: (isMobile ? undefined : '256px'),
                     minWidth: '200px',
                  }} key={index}>
                  <HoverInfo
                     disabled={!description}
                     title={label}
                     description={description}
                  >
                     <p style={pStyle}><strong>{`${label}:`}</strong></p>
                  </HoverInfo>
                  <p>{valueString}</p>
               </div>
            )
         })}
      </Box>
   )
}
