export default class Tool {

   /**
    * Create a Tool
    * @param {string} id
    * @param {{ type: Tool.Type }} config
    * @param {object} name { langCode: name }
    * @param {string} toolbox
    * @param {Tool.Level} level
    * @param {object} description { langCode: name }
    * @param {object} resultModels
    * @param {string} tags UUID[]
    * @param {string[]} positions UUID[]
    */
   constructor(id, config, name, toolbox, level, description, resultModels, tags, positions) {
      this.id = id
      this.config = config
      this.name = name
      this.toolbox = toolbox
      this.level = level
      this.description = description
      this.resultModels = resultModels
      this.tags = tags || []
      this.positions = positions || []
   }


   /**
    * Creates Tool from Json Object
    * @param {Object} json
    * @returns {Tool}
    */
   static fromJson(json) {
      if (!json) return null
      return new Tool(json.id, json.config, json.name, json.toolbox, json.level, json.description, json.resultModels, json.tags, json.positions)
   }

}


/**
 * Tool Type
 * @enum {string}
 */
Tool.Type = Object.freeze({
   CONTINUOUS: 'continuous',
   POINT: 'point',
})


/**
 * Tool Level
 * @enum {string}
 */
Tool.Level = Object.freeze({
   PREVIEW: 'preview',
   BASIC: 'basic',
   PREMIUM: 'premium',
   ADVANCED: 'advanced',
   LOCKED: 'locked',
})
