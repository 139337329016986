import axios, { isAxiosError } from 'axios'
import axiosAvaApiClient from '../utils/axiosAvaApiClient'

export default class ChunkedFileUploader {

   /**
    * @param {object} props
    * @param {string} props.toolId
    * @param {string} props.positionId
    * @param {string} props.analysisId
    * @param {Blob} props.file
    * @param {string} props.fileName
    * @param {Date} props.dateTaken
    * @param {string} props.socketId
    * @param {(progressValue: number, status: 'uploading' | 'analyzing', fileChunkIndex: number) => void} props.onUploadProgress From 0 to 1
    * @param {() => void} props.onCompleted
    * @param {(err: any) => void} props.onFailure
    * @param {number} [props.chunkSize] `Default: 10 MiB`
    */
   constructor(props) {
      const { toolId, positionId, analysisId, file, fileName, dateTaken, socketId, onUploadProgress, onCompleted, onFailure, chunkSize } = props

      this.toolId = toolId
      this.positionId = positionId
      this.analysisId = analysisId
      this.dateTaken = dateTaken
      this.socketId = socketId
      /** @type {Blob} */
      this.file = file
      this.fileName = fileName
      this.numOfPartsUploaded = 0

      this.onUploadProgress = onUploadProgress
      this.onCompleted = onCompleted
      this.onFailure = onFailure

      this.isUploading = false
      this.isCompleted = false

      this.chunkSize = chunkSize || 10 * 1024 * 1024 // 10 MiB
      this.uploadProgress = 0

      this.cancelTokenSource = null

   }

   /**
    * @param {object} [options]
    * @param {number} [options.fromChunkIndex]
    * @returns {{ cancel: () => void }}
    */
   upload(options) {
      const { fromChunkIndex } = options || {}
      if (fromChunkIndex) this.numOfPartsUploaded = fromChunkIndex

      const partsCount = Math.ceil(this.file.size / this.chunkSize)
      const lastChunkSize = this.file.size % this.chunkSize

      if (this.isUploading) throw new Error(`Already uploading part: ${this.numOfPartsUploaded + 1}`)

      try {
         this.isUploading = true

         // If all parts have been uploaded
         if (partsCount === this.numOfPartsUploaded) {
            if (this.isCompleted) throw new Error('Upload is already completed')
            this.completeMultipartUpload()
            return { cancel: () => {} }
         }

         // Upload part
         this.cancelTokenSource = axios.CancelToken.source()
         const uploadChunkPromise = new Promise((resolve, reject) => {

            const isLastPart = (partsCount === this.numOfPartsUploaded + 1)

            const contentStart = this.chunkSize * this.numOfPartsUploaded
            const contentEnd = (
               this.chunkSize * this.numOfPartsUploaded
               + (isLastPart ? lastChunkSize : this.chunkSize)
            )

            // Using just this.file.slice causes upload request to throw "NetworkError" after few chunks are sliced.
            // It seems that there is some kind of bug in the file.slice method.
            // _response key in axios request object says "Attempt to get length of null array"
            // The issue was fixed by making a copy of the file before slicing it
            const chunkToUpload = new Blob([this.file], { type: this.file.type }).slice(contentStart, contentEnd, this.file.type)
            const headers = {
               'Content-Type': this.file.type,
               'Content-Range': `${contentStart}-${contentEnd - 1}`,
               // Content-Length is automatically added to request
               ...(isLastPart && {
                  'X-AVA-DateTaken': this.dateTaken.toISOString(),
                  'X-AVA-FileName': this.fileName.split(/[\\/]/).pop(),
                  'X-AVA-SocketId': this.socketId,
               }),
            }
            /** @param {import('axios').AxiosProgressEvent} e */
            const onUploadProgress = (e) => {
               const uploadedPartsMibs = (this.chunkSize * this.numOfPartsUploaded)
               const currentPartMibsUploaded = (isLastPart ? lastChunkSize : this.chunkSize) * (e.loaded / e.total)
               this.uploadProgress = (uploadedPartsMibs + currentPartMibsUploaded) / this.file.size

               const status = (this.uploadProgress === 1) ? 'analyzing' : 'uploading'
               this.onUploadProgress(this.uploadProgress, status, this.numOfPartsUploaded)
            }

            axiosAvaApiClient
               .request({
                  method: isLastPart ? 'put' : 'patch',
                  url: `/analyses/${this.toolId}/${this.analysisId}/${this.positionId}/original`,
                  data: chunkToUpload,
                  headers,
                  onUploadProgress,
                  cancelToken: this.cancelTokenSource?.token,
               })
               .then(() => resolve(undefined))
               .catch((err) => {
                  if (axios.isCancel(err)) return
                  if (isAxiosError(err) && err.response?.status === 416) return resolve(undefined) // If part was already uploaded, continue to next part
                  reject(err)
               })
         })

         uploadChunkPromise
            .then(async () => {
               // Part was uploaded
               this.numOfPartsUploaded += 1
               // Last part
               if (partsCount === this.numOfPartsUploaded) {
                  this.completeMultipartUpload()
               }
               // Other part
               else {
                  this.onUploadProgress(this.uploadProgress, 'uploading', this.numOfPartsUploaded)
                  this.isUploading = false
                  this.upload()
               }
            })
            .catch((err) => {
               this.isUploading = false
               this.onFailure(err)
            })

         const cancelMultipartUploadCallback = () => {
            this.cancelTokenSource?.cancel()
         }
         return { cancel: cancelMultipartUploadCallback }

      } catch(err) {
         this.isUploading = false
         this.onFailure(err)
         return { cancel: () => {} }
      }
   }

   /**
    * @private
    */
   completeMultipartUpload() {
      this.isUploading = false
      this.isCompleted = true
      this.uploadProgress = 0
      this.onCompleted()
   }

}
