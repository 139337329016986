import User from '@ava/react-common/models/user'
import UserService from '@ava/react-common/services/user-service'
import { selectSites } from '@ava/react-common/store/reducers/root-reducer'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Dialog from '../Dialog/dialog'
import Input from '../Input/input'
import Modal from '../Modal/modal'
import ReactSelect from '../ReactSelect/react-select'
import SettingsTopMenu from '../SettingsTopMenu/settings-top-menu'
import Toggle from '../Toggle/toggle'
import VirtualizedTable from '../VirtualizedTable/virtualized-table'

/**
 * @param {object} props
 * @param {{ width: number, height: number }} props.dimensions
 * @param {(err: string) => void)} props.onError
 * @returns {import('react').FunctionComponentElement}
 */
export default function PreInitializedUsers({ dimensions, onError }) {
   const [isLoading, setLoading] = useState(false)
   const [selectedUsers, setSelectedUsers] = useState([])
   const [isModalVisible, showModal] = useState(false)
   const [isDeleteDialogVisible, showDeleteDialog] = useState(false)
   const [users, setUsers] = useState([])
   const sites = useSelector((state) => state.sites)

   useEffect(() => {
      UserService.getPreinitializedUsers()
         .then((users) => setUsers(users))
         .catch((err) => onError(err))
   }, [setUsers, onError])


   const items = users.map((user) => {
      // Add site name
      let sitesString = '-'
      if (user.rules.sites && sites) {
         user.rules.sites.forEach((siteId, index) => {
            const site = sites[siteId]
            if (site && index === 0) sitesString = site.name
            else if (site) sitesString += `, ${site.name}`
         })
      } else {
         sitesString = '(All)'
      }
      // Admins have always access to all sites
      if (user.accessLevel === 'admin') sitesString = '(All)'
      return ({
         id: user.username,
         username: user.username,
         role: user.accessLevel,
         sites: sitesString,
      })
   })

   const columns = [
      {
         label: 'Username',
         dataKey: 'username',
         width: 0.33,
         // type: "string" or "number" (affects sorting),
         sortable: true,
      },
      {
         label: 'Role',
         dataKey: 'role',
         width: 0.33,
         sortable: true,
      },
      {
         label: 'Sites',
         dataKey: 'sites',
         width: 0.34,
         sortable: true,
      },
   ]

   const deleteUsers = () => {
      setLoading(true)
      UserService.deletePreinitializedUsers(selectedUsers)
         .then(() => {
            const filteredUsers = users.filter((user) => !selectedUsers.includes(user.username))
            setUsers(filteredUsers)
            setSelectedUsers([])
            setLoading(false)
            showDeleteDialog(false)
         })
         .catch(() => {
            setLoading(false)
            showDeleteDialog(false)
            onError('Failed to delete users')
         })
   }

   return (
      <div>
         <SettingsTopMenu
            primaryBtnTitle="Add new"
            primaryBtnPressed={() => showModal(true)}
            // primaryBtnDisabled={!isEditingEnabled}
            actionOptions={[
               { value: 'clear', label: 'Clear selection', type: 'default', hidden: false },
               { value: 'delete', label: 'Delete', type: 'danger', hidden: false },
            ]}
            onActionSelected={(action) => {
               if (action === 'delete') {
                  showDeleteDialog(true)
               } else if (action === 'clear') {
                  setSelectedUsers([])
               }
            }}
            actionsDisabled={!selectedUsers || selectedUsers.length === 0}
         />
         <VirtualizedTable
            width={dimensions ? dimensions.width : 0}
            height={dimensions ? dimensions.height - 52 : 0} // secondary menu height === 52px
            selectedItems={selectedUsers}
            onItemSelected={(id) => {
               const deselect = selectedUsers.includes(id)
               if (deselect) {
                  const filtered = selectedUsers.filter((user) => user !== id)
                  setSelectedUsers(filtered)
               } else {
                  const newSelectedUsers = [...selectedUsers, id]
                  setSelectedUsers(newSelectedUsers)
               }
            }}
            items={items}
            columns={columns}
            initialSortBy="username"
            headerHeight={34}
            rowHeight={34}
         />

         { (isModalVisible)
            && <NewUserModal
               onClose={() => showModal(false)}
               onError={onError}
               onUsersSaved={(addedUsers) => {
                  setUsers([...users, ...addedUsers])
                  showModal(false)
               }}
            />
         }


         <Dialog
            visible={isDeleteDialogVisible}
            title={'Delete selected users?'}
            message="Are you sure? This cannot be undone."
            buttons={[{
               text: 'DELETE',
               style: 'danger',
               onPress: deleteUsers,
            }, {
               text: 'CANCEL',
            }]}
            onClose={() => showDeleteDialog(false)}
            disabled={isLoading}
         />

      </div>
   )
}



const NewUserModal = ({ onClose, onError, onUsersSaved }) => {
   const [isLoading, setLoading] = useState(false) // TODO: Check if this should be in parent
   const [error, setError] = useState(null)
   // const [inputValue, setInputValue] = useState("")
   const [usernames, setUsernames] = useState([''])
   const [selectedSites, setSelectedSites] = useState([])
   const [siteOptions, setSiteOptions] = useState([])
   const [activeInput, setActiveInput] = useState(null) // active input index
   const [selectedRole, selectRole] = useState(null)
   const sites = useSelector((state) => selectSites(state))
   const inputRefs = useRef([])

   useEffect(() => {
      inputRefs.current = inputRefs.current.slice(0, usernames.length)
   }, [usernames])

   useEffect(() => {
      // Filter out empty inputs
      let removedIndex = null
      const filtered = usernames.filter((name, index) => {
         const trimmedName = name.trim()
         if (!trimmedName && index !== (usernames.length - 1) && index !== activeInput) {
            removedIndex = index
            return false
         }
         return true
      })
      if (filtered.length !== usernames.length && removedIndex) {
         setUsernames(filtered)
         console.log(activeInput, removedIndex)
         if (activeInput > removedIndex) inputRefs.current[activeInput - 1].focus() // If focused input is in array after the removed input, focus needs to be given to previous input. Otherwise jusmps over one.
      }
   }, [usernames, activeInput])

   useEffect(() => {
      if (sites) {
         const siteOptions = sites.map((site) => ({
            value: site.id,
            label: site.name,
         }))
         setSiteOptions(siteOptions)
      }
   }, [sites])

   const saveUsers = () => {
      setError(null)
      if (!selectedRole) return setError('Role is required')
      if (usernames.length === 0) return setError('Add atleast one username')
      const sites = selectedSites && selectedSites.map((site) => site.value)
      const trimmedNames = usernames.map((name) => name.trim())
      const data = {
         accessLevel: selectedRole && selectedRole.value,
         usernames: trimmedNames.filter((un) => un !== ''),
         rules: { sites },
      }
      setLoading(true)
      UserService.addPreinitializedUsers(data)
         .then(onUsersSaved)
         .catch(onError)
         .finally(() => setLoading(false))
   }

   const roleOptions = User.getRoleOptions()

   return (
      <Modal
         disabled={isLoading}
         width="420px"
         height="520px"
         title="Add preinitialized users"
         onClose={onClose}
         primaryBtnTitle="Save"
         secondaryBtnTitle="Cancel"
         primaryBtnPressed={saveUsers}
         error={error}
      >
         <p style={{ marginBottom: '2px' }}><strong>Usernames:</strong></p>
         { usernames.map((username, index) => (
            <Input
               ref={(input) => {
                  inputRefs.current[index] = input
                  return inputRefs.current[index]
               }}
               onFocus={() => setActiveInput(index)}
               key={`user-input-${index}`}
               style={{ marginBottom: '12px' }}
               autoFocus={index === 0}
               value={username}
               onChange={(txt) => {
                  const updated = [...usernames]
                  updated[index] = txt
                  if (updated[updated.length - 1] !== '') {
                     updated.push('')
                  }
                  setUsernames(updated)
               }}
               onEnterPressed={() => {
                  if (index !== usernames.length - 1) inputRefs.current[index + 1].focus()
               }}
            />
         ))}

         <p style={{ marginBottom: '2px' }}><strong>Role:</strong></p>
         <ReactSelect
            style={{ marginBottom: '12px' }}
            isMulti={false}
            options={roleOptions}
            value={selectedRole}
            onChange={(value) => selectRole(value)}
            // disabled={isEditing}
         />

         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', marginTop: '16px' }}>
            <strong>
               Sites:
            </strong>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'right', marginLeft: 'auto', marginRight: '6px' }}>
               <span style={{ marginRight: '12px' }}>
                  All sites:
               </span>
               <Toggle
                  isOn={selectedSites === null}
                  onChange={(on) => {
                     setSelectedSites(on ? null : [])
                  }}
               />
            </div>
         </div>
         { selectedSites
            && <ReactSelect
               style={{ marginBottom: '12px' }}
               isMulti={true}
               options={siteOptions}
               value={selectedSites}
               onChange={(value) => setSelectedSites(value || [])}
               disabled={isLoading}
            />
         }
      </Modal>
   )
}
