import DataService from '@ava/react-common/services/data-service'
import { createPosition, deletePosition, editPosition } from '@ava/react-common/store/actions/configuration-action'
import { selectToolsPositions, selectToolsUnits } from '@ava/react-common/store/reducers/root-reducer'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../Dialog/dialog'
import Input from '../../Input/input'
import Modal from '../../Modal/modal'
import ReactSelect from '../../ReactSelect/react-select'
import SettingsTopMenu from '../../SettingsTopMenu/settings-top-menu'
import VirtualizedTable from '../../VirtualizedTable/virtualized-table'

/**
 * @param {object} props
 * @param {{ width: number, height: number }} props.dimensions
 * @param {(err: string) => void} props.onError
 * @returns {import('react').FunctionComponentElement}
 */
export default function PositionSettings({ dimensions, onError }) {
   const dispatch = useDispatch()
   const [selectedUnit, selectUnit] = useState(undefined)
   const [isLoading, setLoading] = useState(false)
   const [selectedPosition, selectPosition] = useState(undefined)
   const [isModalVisible, showModal] = useState(false)
   const [isDeleteDialogVisible, showDeleteDialog] = useState(false)
   const [inputValue, setInputValue] = useState(undefined)
   const [isEditing, setEditing] = useState(undefined)
   const [selectValue, setSelectValue] = useState(undefined)
   const positions = useSelector((state) => selectToolsPositions(state))
   const units = useSelector((state) => selectToolsUnits(state))
   const site = useSelector((state) => state.selectedSite)
   const tool = useSelector((state) => state.selectedTool)
   const user = useSelector((state) => state.user)
   const isEditingEnabled = user.hasEngineerPriviledges()

   useEffect(() => {
      if (units && units.length > 0 && !selectedUnit) {
         selectUnit(units[0].id)
      }
   }, [selectedUnit, units])

   const items = []
   positions.forEach((position) => {
      const unitId = position.unit
      const unit = units.find((u) => u.id === unitId)
      if (unit && unitId === selectedUnit) {
         const unitName = unit.name ? unit.name : ' '
         items.push({
            id: position.id,
            name: position.name,
            unit: unitName,
            sortable: false,
         })
      }
   })

   const columns = [
      {
         label: 'Name',
         dataKey: 'name',
         width: 0.5,
         // type: "string" or "number" (affects sorting),
         sortable: false,
      },
      {
         label: 'Processing Unit',
         dataKey: 'unit',
         width: 0.5,
         // type: "string" or "number" (affects sorting),
         sortable: false,
      },
   ]

   const unitOptions = units.map((unit) => ({ value: unit.id, label: unit.name }))

   const exitEditing = () => {
      setEditing(false)
      setInputValue(undefined)
      setLoading(false)
      showModal(false)
      setSelectValue(undefined)
   }

   const saveEditedPosition = () => {
      if (!inputValue || !inputValue.trim()) {
         onError('Name is required')
         return
      }
      setLoading(true)
      DataService.editPosition(selectedPosition, inputValue)
         .then(() => {
            dispatch(editPosition(selectedPosition, inputValue))
            exitEditing()
         })
         .catch(() => {
            onError('Failed to save changes')
            exitEditing()
         })
   }

   const saveNewPosition = () => {
      const name = inputValue && inputValue.trim()
      if (!name || name === '') return onError('Name is required')
      const unit = selectValue && selectValue.value
      if (!unit) return onError('Processing unit is required')
      setLoading(true)

      // Save to database
      DataService.createPosition(name, unit, tool, site)
         .then((result) => {
            // Set to redux
            dispatch(createPosition(result.id, result.name, result.unit, result.tool))
            selectPosition(result.id)
            exitEditing()
         })
         .catch((err) => {
            console.error(err)
            onError('Failed to create position')
            setLoading(false)
         })
   }

   const topMenuOptions = units.map((unit) => ({
      title: unit.name,
      value: unit.id,
      selected: (unit.id === selectedUnit),
   }))


   return (
      <div>
         <SettingsTopMenu
            primaryBtnTitle="Add new"
            primaryBtnPressed={() => {
               const unit = unitOptions.find((option) => selectedUnit === option.value)
               if (unit) setSelectValue(unit)
               showModal(true)
            }}
            primaryBtnDisabled={!isEditingEnabled}
            actionOptions={[
               { value: 'edit', label: 'Edit' },
               { value: 'delete', label: 'Delete', type: 'danger', hidden: false },
            ]}
            onActionSelected={(action) => {
               if (action === 'edit') {
                  const selectedPos = positions.find((position) => position.id === selectedPosition)
                  const positionName = selectedPos && selectedPos.name
                  const unit = unitOptions.find((item) => selectedPos.unit === item.value)
                  if (!positionName || !unit) return
                  setEditing(true)
                  setInputValue(positionName)
                  setSelectValue(unit)
                  showModal(true)
               } else if (action === 'delete') {
                  showDeleteDialog(true)
               }
            }}
            actionsDisabled={!selectedPosition || !isEditingEnabled}
            topMenuOptions={topMenuOptions}
            onTopMenuSelect={(value) => selectUnit(value)}
            topMenuDisabled={isEditing}
         />
         <VirtualizedTable
            width={dimensions.width}
            height={dimensions.height - 52} // secondary menu height === 52px
            selectedItems={selectedPosition ? [selectedPosition] : []}
            onItemSelected={(id) => {
               if (id === selectedPosition) selectPosition(undefined)
               else selectPosition(id)
            }}
            items={items}
            columns={columns}
            initialSortBy="name"
            headerHeight={34}
            rowHeight={34}
         />

         { (isModalVisible && isEditingEnabled)
            && <Modal
               disabled={isLoading}
               width="420px"
               height="400px"
               title={isEditing ? 'Edit Position' : 'New Position'}
               onClose={() => {
                  exitEditing()
               }}
               primaryBtnTitle="Save"
               secondaryBtnTitle="Cancel"
               primaryBtnPressed={isEditing ? saveEditedPosition : saveNewPosition}
            >
               <p style={{ marginBottom: '2px' }}><strong>Name:</strong></p>
               <Input
                  style={{ marginBottom: '12px' }}
                  autoFocus={true}
                  value={inputValue || ''}
                  onChange={(value) => setInputValue(value)}
               />

               <p style={{ marginBottom: '2px' }}><strong>Processing unit:</strong></p>
               <ReactSelect
                  style={{ marginBottom: '12px' }}
                  isMulti={false}
                  options={unitOptions}
                  value={selectValue}
                  onChange={(value) => setSelectValue(value)}
                  disabled={isEditing} /* Positions unit cannot be changed. It must be set when position is created. Hence disabled if editing */
               />
            </Modal>
         }


         <Dialog
            visible={isEditingEnabled && selectedPosition && isDeleteDialogVisible}
            title={`Delete ${positions.find((p) => p.id === selectedPosition)?.name}?`}
            message="Are you sure? This cannot be undone."
            buttons={[{
               text: 'DELETE',
               style: 'danger',
               onPress: () => {
                  setLoading(true)
                  DataService.deletePosition(selectedPosition).then(() => {
                     showDeleteDialog(false)
                     dispatch(deletePosition(selectedPosition, tool))
                     exitEditing()
                     selectPosition(undefined)
                  }).catch((error) => {
                     console.error(error)
                     onError('Failed to delete position')
                     showDeleteDialog(false)
                     exitEditing()
                  })
               },
            }, {
               text: 'CANCEL',
            }]}
            onClose={() => showDeleteDialog(false)}
         />

      </div>
   )
}
