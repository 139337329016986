import { setAxiosAvaClientBaseUrl } from '@ava/react-common/utils/axiosAvaApiClient'
import 'core-js/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import 'regenerator-runtime'
import App from './App'
import './index.css'
import { unregister } from './registerServiceWorker'
import store from './store'

setAxiosAvaClientBaseUrl(`${process.env.PUBLIC_URL}/api/`)

createRoot(document.getElementById('root')).render(
   <Provider store={store}>
      <App />
   </Provider>
)

unregister() // We had the service worker, need this to remove it from past visitors
