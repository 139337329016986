import { selectSelectedTool } from '@ava/react-common/store/reducers/root-reducer'
import { useTypedSelector } from '@ava/react-common/utils'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Dialog from '../../Dialog/dialog'
import MobileNavbar from '../../MobileNavbar/mobile-navbar'
import SecondaryNavbar from '../../SecondaryNavbar/secondary-navbar'
import SideNavigationMenu from '../../SideNavigationMenu/side-navigation-menu'
import DCSTagsSettings from './dcs-tags-settings'
import ImageSettings from './image-settings'
import './page-tool-configuration.scss'
import PositionSettings from './position-settings'
import TagSettings from './tag-settings'

/**
 * @returns {import('react').FunctionComponentElement}
 */
export default function PageToolConfiguration() {

   const history = useHistory()
   const useSelector = useTypedSelector()

   const isMobile = useSelector((state) => state.device.isMobile)
   const windowDimensions = useSelector((state) => state.device.dimensions)
   const selectedTool = useSelector((state) => selectSelectedTool(state))
   const user = useSelector((state) => state.user)

   const [selectedMenu, setSelectedMenu] = useState('positions')
   const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
   const [errorDialogMessage, setErrorDialogMessage] = useState(undefined)


   useEffect(() => {
      const width = isMobile ? windowDimensions.width : windowDimensions.width - 236 // full width - side menu width
      const height = windowDimensions.height - 64
      setDimensions({ width, height })
   }, [isMobile, windowDimensions])


   const options = [
      {
         title: 'Positions',
         value: 'positions',
      },
      {
         title: 'Tags',
         value: 'tags',
      },
      {
         title: 'DCS Tags',
         value: 'dcs_tags',
         disabled: !user.hasUserPriviledges(),
      },
      {
         title: 'Image settings',
         value: 'image_settings',
         disabled: !user.hasUserPriviledges(),
      },
   ]

   const menuOptions = options.map((option) => {
      const selected = (option.value === selectedMenu)
      const obj = option
      obj.selected = selected
      obj.disabled = option.disabled
      return obj
   })

   return (
      <div className="page-tool-configuration" style={{ maxHeight: '100vh', overflowY: 'hidden' }}>
         { !isMobile
            && <SecondaryNavbar
               title={`Configuration - ${selectedTool.name}`}
            />
         }
         <div className="page-tool-configuration__container">
            {isMobile ? (
               <MobileNavbar
                  backPressed={() => history.goBack()}
               />
            ) : (
               <SideNavigationMenu
                  options={menuOptions}
                  onSelect={(value) => setSelectedMenu(value)}
               />
            )}

            <InnerContainer isMobile={isMobile}>
               { selectedMenu === 'image_settings'
                     && <ImageSettings
                        onError={((error) => setErrorDialogMessage(error))}
                        dimensions={dimensions}
                     />
               }
               { selectedMenu === 'positions'
                     && <PositionSettings
                        onError={((error) => setErrorDialogMessage(error))}
                        dimensions={dimensions}
                     />
               }
               { selectedMenu === 'tags'
                     && <TagSettings
                        onError={((error) => setErrorDialogMessage(error))}
                        dimensions={dimensions}
                     />
               }
               { selectedMenu === 'dcs_tags'
                     && <DCSTagsSettings
                        onError={((error) => setErrorDialogMessage(error))}
                        dimensions={dimensions}
                     />
               }
            </InnerContainer>
         </div>

         <Dialog
            visible={errorDialogMessage}
            title="Error"
            message={errorDialogMessage}
            buttons={[{ text: 'OK' }]}
            onClose={() => setErrorDialogMessage(undefined)}
         />

      </div>
   )
}


const InnerContainer = ({ children, isMobile }) => (
   <div className={classNames('page-tool-configuration__inner-container', { mobile: isMobile })}>
      {children}
   </div>
)
