import { SELECT_TOOL } from '../actions/types'

const initialState = null

const toolReducer = (state = initialState, action) => {
   switch (action.type) {

      case SELECT_TOOL:
         return action.payload

      default:
         return state

   }
}

export default toolReducer
