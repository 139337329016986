import { SET_SITES, UPDATE_SITE_TIMEZONE } from '../actions/types'

const initialState = null

const sitesReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_SITES:
         return action.payload.reduce((obj, site) => {
            obj[site.id] = site
            return obj
         }, {})
      case UPDATE_SITE_TIMEZONE:
         return {
            ...state,
            [action.payload.site]: {
               ...state[action.payload.site],
               timeZone: action.payload.timeZone,
            },
         }

      default:
         return state

   }
}

export default sitesReducer
