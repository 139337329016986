import { SET_TIMEZONE } from '../actions/types'

// If state === null, local timezone is used
const initialState = null


const timeZoneReducer = (state = initialState, action) => {
   switch (action.type) {

      case SET_TIMEZONE:
         return action.payload
      default:
         return state

   }
}

export default timeZoneReducer
